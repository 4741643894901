import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
// import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import logoPng from "../../assets/images/logo.png";
import { forgotPassword } from "store/actions";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSent: false,
      error: false
    };
  }

  emailAfterEffect = (sent) => {
    if (sent) {
      this.setState({
        emailSent: true
      })
      setTimeout(() => {
        this.setState({
          emailSent: false
        })
        this.props.history.push('login')
      }, 5000);
    } else {
      this.setState({
        error: true
      })
      setTimeout(() => {
        this.setState({
          error: false
        })
      }, 5000);
    }
  }


  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Set a new password</h5>
                          <p> Please enter you email to reset your password.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span
                            style={{
                              backgroundColor: "#032224",
                            }}
                            className="avatar-title rounded-circle">
                            <img
                              src={logoPng}
                              alt=""
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.state.error ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {'There was a problem while reseting your password, please try again!'}
                        </Alert>
                      ) : null}
                      {this.state.emailSent ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {'Recovery password sent successfully, please check your email and login using that password!'}
                        </Alert>
                      ) : null}

                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().email().required(
                            "Please Enter Your Email"
                          ),
                        })}
                        onSubmit={values => {
                          // this.props.userForgetPassword(values, this.props.history);
                          this.props.onForgotPassword(values.email, this.emailAfterEffect)

                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="text-end">
                              <button
                                className="btn btn-primary w-md"
                                type="submit"
                              >
                                Reset
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link to="login" className="fw-medium text-primary">
                      Login
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
  onForgotPassword: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};


const mapStateToProps = ({ }) => ({
});

const mapDispatchToProps = dispatch => ({
  onForgotPassword: (email, afterEffect) => dispatch(forgotPassword(email, afterEffect))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForgetPasswordPage));
