import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

const getAnnouncments = (token, clubId) => {
    return get(url.GET_ANNOUNCMENTS(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

const deleteAnnouncment = (token, id) => {
    return del(url.DELETE_ANNOUNCMENT(id), {
        headers: {
            Authorization: token,
        },
    });
};

const updateAnnouncment = (token, data) => {
    return put(url.ANNOUNCMENT, {
        id: data.id,
        clubId: data.clubId,
        title: data.title,
        description: data.description,
        image: data.image,
        displayOrder: data.displayOrder
    }, {
        headers: {
            Authorization: token,
        },
    })
}

const archiveAnnouncment = (token, data) => {
    return put(url.ARCHIVE_ANNOUNCMENT, {
        announcementId: data.id,
        archive: data.archive
    }, {
        headers: {
            Authorization: token,
        },
    })
}

const addAnnouncment = (token, data) =>
    post(
        url.ANNOUNCMENT,
        {
            clubId: data.clubId,
            title: data.title,
            description: data.description,
            image: data.image,
            displayOrder: data.displayOrder
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );

export {
    getAnnouncments, addAnnouncment, deleteAnnouncment, updateAnnouncment, archiveAnnouncment
};
