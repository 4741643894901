import { bookSpaceAPI, cancelBookingReq, cancelSpaceBookingReq, checkInMembersBooking, getBookings, getBookingsLeaderAPI, getEventWaitlistAPI, getEventWaitlistLeaderAPI, getPublicBookingsReq, getSpaceBookings, getSpacesAPI, getUserSpaceBookingsAPI } from "helpers";
import { takeEvery, call, put } from "redux-saga/effects";
import { getBookingsSuccess, checkInMemberOnEventFail, getSpaceBookingsSuccess, checkInMemberOnEventSuccess, getSpaceBookingsFail, getBookingsFail, cancelBookingFail, cancelSpaceBookingSuccess, cancelSpaceBookingFail, getPublicBookingsSuccess, getPublicBookingsFail, getUserSpaceBookingsSuccess, getUserSpaceBookingsFail, getEventWaitlistSuccess, getEventWaitlistFail, bookSpaceSuccess, bookSpaceFail, getSpacesSuccess, getSpacesFail } from "store/actions";
import { BOOK_SPACE, CANCEL_BOOKING, CANCEL_SPACE_BOOKING, GET_BOOKINGS, GET_BOOKINGS_LEADER, GET_EVENT_WAITLIST, GET_EVENT_WAITLIST_LEADER, GET_PUBLIC_BOOKING, GET_SPACES, GET_SPACE_BOOKINGS, GET_USER_SPACE_BOOKINGS, UPDATE_CHECKIN_MEMBER } from "./actionTypes";

export function* fetchSpaceBookings({ payload: { date, clubId } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getSpaceBookings, token, clubId, date);
    let res = []
    if (response.data) {
      res = response?.data.map((data, index) =>
        Object.assign(data, {
          id: index,
          bookingId: data.id
        })
      );
    }
    yield put(getSpaceBookingsSuccess(res));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getSpaceBookingsFail(error));
  }
}

export function* cancelBooking({ payload: { bookingId, eventId, userId } }) {
  try {
    const token = localStorage.getItem("token");
    const response = yield call(cancelBookingReq, token, bookingId, eventId, userId);
    yield put(checkInMemberOnEventSuccess(response.data));
  } catch (error) {
    yield put(cancelBookingFail(error));
  }
}

export function* cancelSpaceBooking({ payload: { bookingId } }) {
  try {
    const token = localStorage.getItem("token");
    yield call(cancelSpaceBookingReq, token, bookingId);
    yield put(cancelSpaceBookingSuccess());
  } catch (error) {
    yield put(cancelSpaceBookingFail(error));
  }
}

export function* fetchBookings({ payload: { id } }) {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(getBookings, token, id);
    const res = response.data.map((data, index) =>
      Object.assign(data, {
        id: index,
        booking_id: data.id,
        status_text: data.status === 1 ? "Active" : "Canceled",
        late_cancel: data.canceled === 1 ? "True" : "False",
        checked_in: data.checked_in === 1 ? "Attended" : "Absent",
      })
    );

    yield put(getBookingsSuccess(res));
  } catch (error) {
    yield put(getBookingsFail(error));
  }
}

export function* fetchBookingsLeader({ payload: { id } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getBookingsLeaderAPI, token, id);
    const res = response.data.map((data, index) =>
      Object.assign(data, {
        id: index,
        booking_id: data.id,
        status_text: data.status === 1 ? "Active" : "Canceled",
        late_cancel: data.canceled === 1 ? "True" : "False",
        checked_in: data.checked_in === 1 ? "Attended" : "Absent",
      })
    );
    yield put(getBookingsSuccess(res));
  } catch (error) {
    yield put(getBookingsFail(error));
  }
}

export function* fetchEventWaitlist({ payload: { id } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getEventWaitlistAPI, token, id);
    yield put(getEventWaitlistSuccess(response.data));
  } catch (error) {
    yield put(getEventWaitlistFail(error));
  }
}
export function* fetchEventWaitlistLeader({ payload: { id } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getEventWaitlistLeaderAPI, token, id);
    yield put(getEventWaitlistSuccess(response.data));
  } catch (error) {
    yield put(getEventWaitlistFail(error));
  }
}

export function* fetchPublicBookings({ payload: { eventId } }) {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(getPublicBookingsReq, eventId);
    const res = response.data.map((data, index) =>
      Object.assign(data, {
        id: index,
        status_text: data.status === 1 ? "Active" : "Canceled",
      })
    );
    yield put(getPublicBookingsSuccess(res));
  } catch (error) {
    yield put(getPublicBookingsFail(error));
  }
}

export function* checkInMember({ payload: { id } }) {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(checkInMembersBooking, token, id);

    yield put(checkInMemberOnEventSuccess(response.data));
  } catch (error) {
    yield put(checkInMemberOnEventFail(error));
  }
}

export function* bookSpace({ payload: { spaceId, type, date, startTime, endTime, showSuccessToast } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(bookSpaceAPI, token, spaceId, type, date, startTime, endTime);
    yield put(bookSpaceSuccess(response.data));
    showSuccessToast(`Space Booked`, 2000, true)
  } catch (error) {
    yield put(bookSpaceFail(error));
    showSuccessToast(`Space Booked`, 2000, false)
  }
}

export function* fetchSpaces({ payload: { clubId } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getSpacesAPI, token, clubId);
    yield put(getSpacesSuccess(response.data));
  } catch (error) {
    yield put(getSpacesFail(error));
  }
}

export function* fetchUserSpaceBookings({ payload }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getUserSpaceBookingsAPI, token, payload);
    yield put(getUserSpaceBookingsSuccess(response.data));
  } catch (error) {
    yield put(getUserSpaceBookingsFail(error));
  }
}

function* bookingsSaga() {
  yield takeEvery(GET_SPACE_BOOKINGS, fetchSpaceBookings);
  yield takeEvery(GET_USER_SPACE_BOOKINGS, fetchUserSpaceBookings);
  yield takeEvery(GET_BOOKINGS, fetchBookings);
  yield takeEvery(GET_BOOKINGS_LEADER, fetchBookingsLeader);
  yield takeEvery(UPDATE_CHECKIN_MEMBER, checkInMember);
  yield takeEvery(CANCEL_BOOKING, cancelBooking);
  yield takeEvery(CANCEL_SPACE_BOOKING, cancelSpaceBooking);
  yield takeEvery(GET_PUBLIC_BOOKING, fetchPublicBookings);
  yield takeEvery(GET_EVENT_WAITLIST, fetchEventWaitlist);
  yield takeEvery(GET_EVENT_WAITLIST_LEADER, fetchEventWaitlistLeader);
  yield takeEvery(BOOK_SPACE, bookSpace);
  yield takeEvery(GET_SPACES, fetchSpaces);
}

export default bookingsSaga;
