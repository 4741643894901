import {
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  GET_MEMBER_BY_ID_SUCCESS,
  GET_MEMBER_BY_ID_FAIL,
  GET_CANCELED_MEMBERS_SUCCESS,
  GET_CANCELED_MEMBERS_FAIL,
  GET_ONBOARDING_MEMBERS_SUCCESS,
  GET_ONBOARDING_MEMBERS_FAIL,
  GET_NON_ATTENDEES_SUCCESS,
  GET_NON_ATTENDEES_FAIL,
  GET_NON_ATTENDEES,
  GET_DOOR_ENTRIES,
  GET_DOOR_ENTRIES_SUCCESS,
  GET_DOOR_ENTRIES_FAIL,
  GET_MEMBERS,
  DOORDECK_ENTRIES_MEMBER,
  DOORDECK_ENTRIES_MEMBER_SUCCESS,
  DOORDECK_ENTRIES_MEMBER_FAIL,
  GET_ONBOARDING_BOOKINGS,
  GET_ONBOARDING_BOOKINGS_SUCCESS,
  GET_ONBOARDING_BOOKINGS_FAIL,
  GET_HIGH_RISK_MEMBERS_SUCCESS,
  GET_USER_SUBSCRIPTION_BY_ID_SUCCESS,
  RESET_BILLING_DATE,
  GET_CANCELED_MEMBERS,
  GET_MEMBER_NOTES_SUCCESS,
  GET_USER_PAUSES_SUCCESS,
  GET_NEW_MEMBERS_SUCCESS,
  GET_ALL_COUPONS_SUCCESS,
  GET_APPLIED_COUPONS,
  GET_APPLIED_COUPONS_SUCCESS,
  GET_APPLIED_COUPONS_FAIL,
  GET_APPLICANTS,
  GET_APPLICANTS_SUCCESS,
  GET_APPLICANTS_FAIL,
  GET_CLUB_LEADER_MEMBERS,
  GET_CLUB_LEADER_MEMBERS_SUCCESS,
  GET_CLUB_LEADER_MEMBERS_FAIL,
} from "./actionTypes";
const INIT_STATE = {
  error: {},
  members: [],
  canceledMembers: [],
  loadingCanceledMembers: false,
  nonAttendees: [],
  nonAttendeesLoading: false,
  loadingActiveMembers: false,
  member: null,
  membersNotOnBoarded: [],
  doorEntries: [],

  doordeckEntriesMember: [],
  loadingDoordeckEntriesMember: false,

  onboardingBookings: [],
  loadingOnboardingBookings: false,

  highRiskMemers: [],

  notes: [],

  nextBillingDate: null,

  userPauses: [],

  newMembers: [],

  allCoupons: [],

  appliedCoupons: [],
  loadingAppliedCoupons: false,

  applicants: [],
  loadingApplicants: false,

  clubLeaderMembers: [],
  loadingClubLeaderMembers: false

};

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEMBERS:
      return {
        ...state,
        loadingActiveMembers: true
      };
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload,
        loadingActiveMembers: false
      };
    case GET_APPLIED_COUPONS:
      return {
        ...state,
        loadingAppliedCoupons: true
      };
    case GET_APPLIED_COUPONS_SUCCESS:
      return {
        ...state,
        appliedCoupons: action.payload,
        loadingAppliedCoupons: false
      };
    case GET_APPLIED_COUPONS_FAIL:
      return {
        ...state,
        loadingAppliedCoupons: false
      };
    case GET_APPLICANTS:
      return {
        ...state,
        loadingApplicants: true
      };
    case GET_APPLICANTS_SUCCESS:
      return {
        ...state,
        applicants: action.payload,
        loadingApplicants: false
      };
    case GET_APPLICANTS_FAIL:
      return {
        ...state,
        loadingApplicants: false
      };
    case GET_ALL_COUPONS_SUCCESS:
      return {
        ...state,
        allCoupons: action.payload,
      };
    case GET_NEW_MEMBERS_SUCCESS:
      return {
        ...state,
        newMembers: action.payload,
      };

    case GET_MEMBER_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
      };

    case GET_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingActiveMembers: false
      };

    case GET_ONBOARDING_BOOKINGS:
      return {
        ...state,
        loadingOnboardingBookings: true
      };
    case GET_ONBOARDING_BOOKINGS_SUCCESS:
      return {
        ...state,
        onboardingBookings: action.payload,
        loadingOnboardingBookings: false
      };

    case GET_HIGH_RISK_MEMBERS_SUCCESS:
      return {
        ...state,
        highRiskMemers: action.payload,
      };
    case GET_ONBOARDING_BOOKINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingOnboardingBookings: false
      };

    case DOORDECK_ENTRIES_MEMBER:
      return {
        ...state,
        loadingDoordeckEntriesMember: true
      };
    case DOORDECK_ENTRIES_MEMBER_SUCCESS:
      return {
        ...state,
        doordeckEntriesMember: action.payload,
        loadingDoordeckEntriesMember: false
      };

    case DOORDECK_ENTRIES_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDoordeckEntriesMember: false
      };
    case GET_CANCELED_MEMBERS:
      return {
        ...state,
        loadingCanceledMembers: true
      };
    case GET_CANCELED_MEMBERS_SUCCESS:
      return {
        ...state,
        canceledMembers: action.payload,
        loadingCanceledMembers: false
      };

    case GET_CANCELED_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingCanceledMembers: false
      };

    case GET_MEMBER_BY_ID_SUCCESS:
      return {
        ...state,
        member: action.payload,
      };

    case RESET_BILLING_DATE:
      return {
        ...state,
        nextBillingDate: null,
      };
    case GET_USER_SUBSCRIPTION_BY_ID_SUCCESS:
      return {
        ...state,
        nextBillingDate: action.payload,
      };

    case GET_MEMBER_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ONBOARDING_MEMBERS_SUCCESS:
      return {
        ...state,
        membersNotOnBoarded: action.payload,
      };

    case GET_ONBOARDING_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_NON_ATTENDEES:
      return {
        ...state,
        nonAttendeesLoading: true
      }

    case GET_NON_ATTENDEES_SUCCESS:
      return {
        ...state,
        nonAttendeesLoading: false,
        nonAttendees: action.payload,
      };

    case GET_NON_ATTENDEES_FAIL:
      return {
        ...state,
        nonAttendeesLoading: false,
        error: action.payload,
      };
    case GET_DOOR_ENTRIES:
      return {
        ...state,
      }

    case GET_DOOR_ENTRIES_SUCCESS:
      return {
        ...state,
        doorEntries: action.payload,
      };

    case GET_DOOR_ENTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_USER_PAUSES_SUCCESS:
      return {
        ...state,
        userPauses: action.payload
      }


    case GET_CLUB_LEADER_MEMBERS:
      return {
        ...state,
        loadingClubLeaderMembers: true
      }

    case GET_CLUB_LEADER_MEMBERS_SUCCESS:
      return {
        ...state,
        loadingClubLeaderMembers: false,
        clubLeaderMembers: action.payload,
      };

    case GET_CLUB_LEADER_MEMBERS_FAIL:
      return {
        ...state,
        loadingClubLeaderMembers: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default projects;
