import { encryptMessage } from 'helpers/encryptionService'
import React from 'react'
import { MessageInput, MessageList, useChannelActionContext, useChannelStateContext } from 'stream-chat-react'

function CustomChatContent() {

    const { sendMessage } = useChannelActionContext()

    const { channel } = useChannelStateContext()


    const submitHandler = async (message) => {
        if (channel.type === 'dm') {
            if (message.attachments.length > 0) {
                await sendMessage(message)
            } else if (message.attachments.length === 0 && channel.type === 'dm') {
                await sendMessage({ ...message, text: encryptMessage(message.text) })
            } else {
                await channel.sendMessage(message)
            }
        } else {
            await sendMessage(message)
        }
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '53vw'
        }}>
            <MessageList />
            <MessageInput grow overrideSubmitHandler={submitHandler} />
        </div>
    )
}

export default CustomChatContent