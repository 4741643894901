import { get } from "./api_helper";
import * as url from "./url_helper";

const getSubClubs = token => {
    return get(url.SUB_CLUBS, {
        headers: {
            Authorization: token,
        },
    });
};

const getClubLeaderSubClubs = (token, userId) => {
    return get(url.CLUB_LEADER_SUB_CLUBS(userId), {
        headers: {
            Authorization: token,
        },
    });
};

const getSubClubsByClubIdAPI = (token, id) => {
    return get(url.SUB_CLUBS_BY_CLUB(id), {
        headers: {
            Authorization: token,
        },
    });
};

export {
    getSubClubs,
    getClubLeaderSubClubs,
    getSubClubsByClubIdAPI
}