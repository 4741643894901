import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import classnames from "classnames"


import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import { Formik, Field, Form } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { cloneEvent, getClubs, getPastEvents, getPastEventsByInstructor } from "store/actions";

class InstructorPastEventsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pastEvents: [],
            pastEvent: "",
            deleteModal: false,
            modal: false,
            selectedFiles: [],
            guestInvitesAllowed: 0,
            requiresPayment: false,
            price: 0,
            activeClub: 1,
        };

        this.handlePastEventClick = this.handlePastEventClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handlePastEventClicks = this.handlePastEventClicks.bind(this);
    }

    componentDidMount() {
        const { onGetPastEvents, onEventClone } = this.props;

        onGetPastEvents();

        onEventClone(null)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.pastEvents !== prevProps.pastEvents) {
            this.setState({
                pastEvents: this.props.pastEvents
            })
        }
    }

    handlePastEventClicks = arg => {
        this.setState({ selectedpastEvent: arg });
        this.toggle();
    };

    viewEditFormater = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => this.handleEventClick(row)}
                color="info"
            >
                View/Edit
            </Button>
        );
    };

    viewBookingsFormater = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => this.props.history.push(`/bookings/${row.title?.replace(/[?/]/g, '-')}/${row.id}`)}
                color="info"
            >
                Bookings
            </Button>
        );
    };

    handlePastEventClick = arg => {
        const pastEvent = arg;
        this.setState({
            pastEvent: {
                id: pastEvent.id,
                firstName: this.props.pastEvents.find(item => item.id === pastEvent.id)?.firstName,
                lastName: this.props.pastEvents.find(item => item.id === pastEvent.id)?.lastName,
                price: pastEvent.price,
                description: pastEvent.description,
                capacity: pastEvent.capacity,
                guestInvitesAllowed: pastEvent.guestInvitesAllowed,
                dueDate: pastEvent.dueDate,
                location: pastEvent.location,
                requiresPayment: pastEvent.requiresPayment === 1,
                startDate: pastEvent.startDate,
                startTime: pastEvent.startTime,
                title: pastEvent.title,
            },
            isEdit: true,
            guestInvitesAllowed: pastEvent.guestInvitesAllowed,
            requiresPayment: pastEvent.requiresPayment === 1,
            price: pastEvent.price,
        });

        this.toggle();
    };



    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    clubItemSelected(name, id) {
        this.setState({
            club: name,
            clubId: id,
            clubError: false
        });
    }

    handleValidDate = date => {
        const date1 = moment(date, 'YYYY-MM-DD').format("DD MMM Y");
        return date1;
    };

    handleRquirePayment = () => {
        this.setState({
            requiresPayment: !this.state.requiresPayment,
        });
    };



    render() {
        const { pastEvents, clubs, loadingPastEvents, onEventClone, history } = this.props;
        const { activeClub, pastEvent } = this.state
        const { SearchBar } = Search;
        const events = [
            {
                dataField: "image",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (

                    <img
                        src={event.image}
                        alt=""
                        className="avatar-sm"
                    />

                ),
            },
            {
                text: "Events",
                dataField: "events",
                // eslint-disable-next-line react/display-name
                filterValue: (_, event) => event?.title + event?.description,
                formatter: (_, event) => (
                    <>
                        <h5 className="font-size-14">

                            {event.title}
                        </h5>
                        <p
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            className="text-muted mb-0"
                        >

                            {event.description}
                        </p>
                    </>
                ),
            },

            {
                text: "Bookings",
                dataField: "",
                formatter: this.viewBookingsFormater
            },
            {
                text: "Action",
                dataField: "",
                formatter: (_, event) => (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle className="card-drop" tag="a">
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                    onClick={() => {
                                        onEventClone(event);
                                        history.push("/instructor-event-create");
                                    }}
                                >
                                    <i className="mdi mdi-clipboard-file font-size-16 text-danger me-1" />{" "}
                                    Clone Event
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                ),
            },
            {
                text: "Date",
                dataField: "start_date",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <>
                        {this.handleValidDate(event.start_date)}
                    </>
                ),
            },
            {
                text: "Time",
                dataField: "startTime",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <>
                        {moment(event.start_time, "HH:mm:ss").format("hh:mmA")} -{' '}
                        {moment(event.end_time, "HH:mm:ss").format("hh:mmA")}
                    </>
                ),
            },
            {
                dataField: "booking_count",
                text: "Total Bookings",
                formatter: (_, event) => (
                    <p className="text-muted mb-0">
                        {event.booking_count ? event.booking_count : 0}
                    </p>
                )
            },
            {
                dataField: "capacity",
                text: "Capacity",
                formatter: (_, event) => (
                    <p className="text-muted mb-0">
                        {event.capacity}
                    </p>
                ),
            },
            {
                dataField: "price",
                text: "Price",
                formatter: (_, event) => (
                    <p className="text-muted mb-0">
                        ${event.price}
                    </p>
                )
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: pastEvents?.length,
            custom: true,
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Past Events List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Past Events" breadcrumbItem="Past Events List" />
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>

                                                {pastEvents && <PaginationProvider
                                                    pagination={paginationFactory(pageOptions)}
                                                    columns={events}
                                                    data={pastEvents}
                                                >
                                                    {({ paginationProps, paginationTableProps }) => (
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            columns={events}
                                                            data={pastEvents}
                                                            search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>

                                                                    <Row>
                                                                        <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        // style={{ width: "400px", height: "40px" }}
                                                                        />
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                {loadingPastEvents ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap"
                                                                                    }
                                                                                    headerWrapperClasses={"thead-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                />}
                                                                            </div>
                                                                        </Col>
                                                                        {/* </Link> */}
                                                                    </Row>

                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="inner-custom-pagination d-flex">
                                                                            <div className="d-inline">
                                                                                <SizePerPageDropdownStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                            <div className="text-md-right ms-auto">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                                </PaginationProvider>}
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

InstructorPastEventsList.propTypes = {
    pastEvents: PropTypes.array,
    className: PropTypes.any,
    token: PropTypes.any,
    onGetPastEvents: PropTypes.func,
    loadingPastEvents: PropTypes.bool,
    onGetClubs: PropTypes.func,
    clubs: PropTypes.array,
    onEventClone: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ events, Login, system }) => ({
    pastEvents: events.pastEvents,
    loadingPastEvents: events.loadingPastEvents,
    token: Login.token,
});

const mapDispatchToProps = dispatch => ({
    onGetPastEvents: () => dispatch(getPastEventsByInstructor()),
    onEventClone: (event) => dispatch(cloneEvent(event)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(InstructorPastEventsList));
