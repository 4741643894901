import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import classnames from "classnames";

import * as Yup from "yup";

import {
    Col,
    Container,
    Row,
    Button,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import QRCode from "qrcode.react";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { cloneEvent, getClubLeaderSubClubs, getClubLeaderUpcomingEvents, getClubs, setImage, updateEventClubLeader } from "store/actions";
import { Field, Formik, Form } from "formik";
import FileUpload from "components/FileUpload/FileUpload";

class ClubLeaderUpcomingEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clubLeaderSubClubs: [],
            activeSubClub: null,
            eventToView: null,
            viewEvent: false,
            editEvent: false,
            eventImage: null
        };
        this.handleViewEventClick = this.handleViewEventClick.bind(this);
        this.handleEditEventClick = this.handleEditEventClick.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    handleValidDate = date => {
        const date1 = moment.utc(date).format("ddd, DD MMM Y");
        return date1;
    };

    toggle() {
        this.setState(prevState => ({
            viewEvent: !prevState.viewEvent,
        }));
    }


    componentDidMount() {
        const {
            onGetSubClubs,
            onGetClubs
        } = this.props;
        onGetSubClubs();
        onGetClubs()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.clubLeaderSubClubs !== prevProps.clubLeaderSubClubs) {
            this.setState({
                clubLeaderSubClubs: this.props.clubLeaderSubClubs,
                activeSubClub: this.props.clubLeaderSubClubs[0]
            });
        }
        if (this.state.activeSubClub !== prevState.activeSubClub) {
            this.props.onGetClubLeaderUpcomingEvents(this.state.activeSubClub.id)
        }
    }

    handleViewEventClick = arg => {
        this.setState({
            eventToView: arg,
            viewEvent: true,
            editEvent: false
        });
    };

    handleEditEventClick = arg => {
        this.setState({
            eventToView: arg,
            viewEvent: true,
            editEvent: true,
            eventImage: arg.image
        });
        this.props.onSetImage(arg.image)
    };

    viewFormater = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => this.handleViewEventClick(row)}
                color="info"
            >
                View
            </Button>
        );
    };

    editFormater = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => this.handleEditEventClick(row)}
                color="info"
            >
                Edit
            </Button>
        );
    };

    viewBookingsFormater = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => this.props.history.push(`/bookings/${row.title?.replace(/[?/]/g, '-')}/${row.id}`)}
                color="info"
            >
                Bookings
            </Button>
        );
    };

    render() {

        const { loadingClubLeaderUpcomingEvents, clubLeaderUpcomingEvents, history, onEventClone } = this.props;
        const { eventToView } = this.state
        const { SearchBar } = Search;

        const eventsColumns = [
            {
                dataField: "image",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <img src={event.image} alt="" className="avatar-sm" />
                ),
            },
            {
                text: "Event",
                dataField: "events",
                // eslint-disable-next-line react/display-name
                filterValue: (_, event) => event?.title + event?.description,
                formatter: (_, event) => (
                    <>
                        <h5 className="font-size-14">
                            {event.title}
                        </h5>
                        <p
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            className="text-muted mb-0"
                        >
                            {event.description}
                        </p>
                    </>
                ),
            },
            {
                text: "View",
                dataField: "",
                formatter: this.viewFormater
            },
            {
                text: "Edit",
                dataField: "",
                formatter: this.editFormater
            },
            {
                text: "Bookings",
                dataField: "",
                formatter: this.viewBookingsFormater
            },
            {
                text: "Action",
                dataField: '',
                formatter: (_, event) => (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="card-drop"
                                tag="a"
                            >
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                    onClick={() => {
                                        onEventClone(event)
                                        history.push('/suggest-event')
                                    }}
                                >
                                    <i className="mdi mdi-clipboard-file font-size-16 text-danger me-1" />{" "}
                                    Clone Event
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                )
            },
            {
                text: "Start date and time",
                dataField: "startDate",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <>
                        {this.handleValidDate(event.start_date)}
                        <p style={{ marginTop: 10 }}>
                            {moment(event.start_time, "HH:mm:ss").format("hh:mmA")} -{' '}
                            {moment(event.end_time, "HH:mm:ss").format("hh:mmA")}
                        </p>
                    </>
                ),
            },
            {
                dataField: "booking_count",
                text: "Total Bookings",
            },
            {
                dataField: "capacity",
                text: "Capacity",
            },
            {
                dataField: "price",
                text: "Price",
                formatter: (_, event) => (
                    <p className="text-muted mb-0">
                        ${event.price}
                    </p>
                ),
            },

        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: clubLeaderUpcomingEvents.length, // replace later with size(customers),
            custom: true,
        };

        return (
            <React.Fragment>
                <QRCode
                    id="eventQRCode"
                    style={{ width: 100 }}
                    value={this.state.qrCodeText}
                    hidden={true}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Events List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Events" breadcrumbItem="Events List" />
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Nav
                                                        pills
                                                        className="bg-light rounded"
                                                        role="tablist"
                                                    >
                                                        {this.state.clubLeaderSubClubs?.map((item, index) => {
                                                            let abrv = ''
                                                            if (this.props.clubs) {
                                                                const club = this.props.clubs.find(club => club.id === item.club_id)
                                                                if (club) {
                                                                    abrv = club.abbreviation
                                                                }
                                                            }
                                                            return (
                                                                <NavItem key={index}>
                                                                    <NavLink
                                                                        className={classnames({
                                                                            active: this.state.activeSubClub.id === item.id,
                                                                        })}
                                                                        style={{
                                                                            backgroundColor:
                                                                                this.state.activeSubClub.id === item.id
                                                                                    ? "#032224"
                                                                                    : "transparent",
                                                                        }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                activeSubClub: item,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {item.name} - {abrv}
                                                                    </NavLink>
                                                                </NavItem>
                                                            )
                                                        })}
                                                    </Nav>
                                                </div>

                                                {clubLeaderUpcomingEvents && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={eventsColumns}
                                                        data={clubLeaderUpcomingEvents}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={eventsColumns}
                                                                data={clubLeaderUpcomingEvents}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                                style={{ marginTop: 10 }}
                                                                            />
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingClubLeaderUpcomingEvents ? (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="text-success"
                                                                                            >
                                                                                                <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                                                                Loading
                                                                                            </Link>{" "}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <BootstrapTable
                                                                                            responsive
                                                                                            bordered={false}
                                                                                            striped={false}
                                                                                            classes={
                                                                                                "table align-middle table-nowrap"
                                                                                            }
                                                                                            headerWrapperClasses={
                                                                                                "thead-light"
                                                                                            }
                                                                                            {...toolkitProps.baseProps}
                                                                                            {...paginationTableProps}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                        <Modal
                                            isOpen={this.state.viewEvent}
                                            className={this.props.className}
                                            size="xl"
                                        >
                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                {"Event Details"}
                                            </ModalHeader>
                                            <ModalBody>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{
                                                        id: (eventToView && eventToView.id) || "",
                                                        title: (eventToView && eventToView.title) || "",
                                                        description: (eventToView && eventToView.description) || "",
                                                        startDate:
                                                            (eventToView &&
                                                                eventToView.start_date) ||
                                                            "",
                                                        capacity: eventToView && eventToView.capacity,
                                                        guestInvitesAllowed:
                                                            eventToView && eventToView.guest_invites_allowed,
                                                        location: (eventToView && eventToView.location) || "",
                                                        price: eventToView && eventToView.price,
                                                        startTime: (eventToView && eventToView.start_time) || "",
                                                    }}
                                                    validationSchema={Yup.object().shape(
                                                        {
                                                            title: Yup.string().trim('Please Enter Title').required("Please Enter Title"),
                                                            description: Yup.string().trim('Please Enter Description').required(
                                                                "Please Enter Description"
                                                            ),
                                                        }
                                                    )}
                                                    onSubmit={values => {
                                                        const updateEvent = {
                                                            id: eventToView.id,
                                                            title: values.title,
                                                            description: values.description,
                                                            subClubId: eventToView.host_id
                                                        }
                                                        this.props.onUpdateEvent(updateEvent);
                                                        this.setState({ eventToView: null });
                                                        this.toggle();
                                                    }}
                                                >
                                                    {() => (
                                                        <Form>
                                                            <Row>
                                                                <Col className="col-12">

                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Title</Label>
                                                                        <Field
                                                                            name="title"
                                                                            type="text"
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                            disabled={!this.state.editEvent}
                                                                        />

                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Description
                                                                        </Label>
                                                                        <Field
                                                                            name="description"
                                                                            type="text"
                                                                            component="textarea"
                                                                            rows="6"
                                                                            disabled={!this.state.editEvent}
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {!this.state.editEvent ? <>

                                                                        <div className="mb-3">
                                                                            <Label className="form-label">
                                                                                Location
                                                                            </Label>
                                                                            <Field
                                                                                name="location"
                                                                                type="text"
                                                                                disabled
                                                                                className={
                                                                                    "form-control"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label">
                                                                                Capacity
                                                                            </Label>
                                                                            <Field
                                                                                name="capacity"
                                                                                type="number"
                                                                                disabled
                                                                                className={
                                                                                    "form-control"
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                htmlFor="guestInvitesAllowed"
                                                                                className="col-form-label col-lg-2"
                                                                            >
                                                                                Guest Invites Allowed
                                                                            </Label>
                                                                            <Col lg="10">
                                                                                <Field
                                                                                    name="guestInvitesAllowed"
                                                                                    disabled

                                                                                    className={
                                                                                        "form-control"
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label">
                                                                                Start Date and Time
                                                                            </Label>
                                                                            <Col lg="10">
                                                                                <Row>
                                                                                    <Col lg="6">
                                                                                        <Field
                                                                                            name="startDate"
                                                                                            type="date"
                                                                                            disabled
                                                                                            className={
                                                                                                "form-control"
                                                                                            }
                                                                                        />
                                                                                    </Col>

                                                                                    <Col lg="6">
                                                                                        <Field
                                                                                            name="startTime"
                                                                                            type="time"
                                                                                            disabled
                                                                                            className={
                                                                                                "form-control"
                                                                                            }
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                htmlFor="price"
                                                                                className="col-form-label col-lg-2"
                                                                            >
                                                                                Price
                                                                            </Label>
                                                                            <Col lg="10">
                                                                                <Field
                                                                                    name="price"
                                                                                    disabled

                                                                                    className={
                                                                                        "form-control"
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <Label className="col-form-label col-lg-2">
                                                                                Image
                                                                            </Label>
                                                                            <Col lg="10">
                                                                                <div
                                                                                    className="dropzone-previews mt-3"
                                                                                    id="file-previews"
                                                                                >

                                                                                    <Card
                                                                                        className="mt-1 mb-0 shadow-none "
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="200"
                                                                                                        className="rounded bg-light"
                                                                                                        src={eventToView.image}
                                                                                                    />
                                                                                                </Col>

                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                </div>
                                                                            </Col>
                                                                        </div>
                                                                    </> :
                                                                        <>

                                                                            <Row>
                                                                                <FormGroup className="mb-3" row>
                                                                                    <Label className="col-form-label col-lg-2">
                                                                                        Attach Image
                                                                                    </Label>
                                                                                    <Col lg="10">
                                                                                        <FileUpload
                                                                                            image={this.state.eventImage}
                                                                                        ></FileUpload>
                                                                                    </Col>
                                                                                </FormGroup>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>

                                                                                    <div className="text-end">

                                                                                        <button
                                                                                            type="submit"
                                                                                            className="btn btn-success save-user"
                                                                                        >
                                                                                            Save
                                                                                        </button>

                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ClubLeaderUpcomingEvents.propTypes = {
    className: PropTypes.any,
    onQrDownload: PropTypes.array,
    clubLeaderSubClubs: PropTypes.array,
    clubLeaderUpcomingEvents: PropTypes.array,
    loadingClubLeaderUpcomingEvents: PropTypes.bool,
    onGetSubClubs: PropTypes.func,
    onGetClubLeaderUpcomingEvents: PropTypes.func,
    onEventClone: PropTypes.func,
    onUpdateEvent: PropTypes.func,
    onGetClubs: PropTypes.func,
    onSetImage: PropTypes.func,
    clubs: PropTypes.array,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ events, subclubs, system }) => ({
    clubLeaderSubClubs: subclubs.clubLeaderSubClubs,
    clubLeaderUpcomingEvents: events.clubLeaderUpcomingEvents,
    loadingClubLeaderUpcomingEvents: events.loadingClubLeaderUpcomingEvents,
    clubs: system.clubs,

});

const mapDispatchToProps = dispatch => ({
    onGetSubClubs: () => dispatch(getClubLeaderSubClubs()),
    onGetClubLeaderUpcomingEvents: (subClubId) => dispatch(getClubLeaderUpcomingEvents(subClubId)),
    onEventClone: (event) => dispatch(cloneEvent(event)),
    onGetClubs: () => dispatch(getClubs()),
    onSetImage: image => dispatch(setImage(image)),
    onUpdateEvent: (event) => dispatch(updateEventClubLeader(event)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ClubLeaderUpcomingEvents));
