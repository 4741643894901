import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import classnames from "classnames"


import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";

import { Formik, Field, Form } from "formik";
import Select from "react-select";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { cloneEvent, getClubs, getPastEvents } from "store/actions";

class PastEventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pastEvents: [],
      pastEvent: "",
      deleteModal: false,
      modal: false,
      selectedFiles: [],
      guestInvitesAllowed: 0,
      requiresPayment: false,
      price: 0,
      activeClub: null,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      clubSelected: { label: "No Club Selected", value: '' },
      clubsOptions: [],
    };

    this.handlePastEventClick = this.handlePastEventClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handlePastEventClicks = this.handlePastEventClicks.bind(this);
  }

  componentDidMount() {
    const { onGetClubs, onEventClone, clubs } = this.props;
    if (clubs.length === 0)
      onGetClubs();
    else {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    }
    const authUser = localStorage.getItem("authUser")
    if (JSON.parse(authUser)?.clubId) {
      this.setState({
        activeClub: JSON.parse(authUser).clubId
      })
    }

    onEventClone(null)
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeClub } = this.state
    const { onGetPastEvents } = this.props
    if (this.props.pastEvents !== prevProps.pastEvents) {
      this.setState({
        pastEvents: this.props.pastEvents
      })
    }
    if (this.props.clubs !== prevProps.clubs) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    }
    if (activeClub !== prevState.activeClub) {
      if (activeClub) {
        onGetPastEvents(activeClub)

      }
    }
  }

  handlePastEventClicks = arg => {
    this.setState({ selectedpastEvent: arg });
    this.toggle();
  };

  handlePastEventClick = arg => {
    const pastEvent = arg;
    this.setState({
      pastEvent: {
        id: pastEvent.id,
        firstName: this.props.pastEvents.find(item => item.id === pastEvent.id)?.firstName,
        lastName: this.props.pastEvents.find(item => item.id === pastEvent.id)?.lastName,
        price: pastEvent.price,
        description: pastEvent.description,
        capacity: pastEvent.capacity,
        guestInvitesAllowed: pastEvent.guestInvitesAllowed,
        dueDate: pastEvent.dueDate,
        location: pastEvent.location,
        requiresPayment: pastEvent.requiresPayment === 1,
        startDate: pastEvent.startDate,
        startTime: pastEvent.startTime,
        title: pastEvent.title,

      },
      isEdit: true,
      guestInvitesAllowed: pastEvent.guestInvitesAllowed,
      requiresPayment: pastEvent.requiresPayment === 1,
      price: pastEvent.price,
    });

    this.toggle();
  };



  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  clubItemSelected(name, id) {
    this.setState({
      club: name,
      clubId: id,
      clubError: false
    });
  }

  handleValidDate = date => {
    const date1 = moment(date).utc().format("DD MMM Y");
    return date1;
  };

  handleRquirePayment = () => {
    this.setState({
      requiresPayment: !this.state.requiresPayment,
    });
  };

  handleClubSelect = club => {
    this.setState({
      clubSelected: club,
      activeClub: club.value
    })
  }


  viewEditFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => this.handlePastEventClick(row)}
        color="info"
      >
        View
      </Button>
    );
  };
  viewBookingsFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => this.props.history.push(`/bookings/${row.title?.replace(/[?/]/g, '-')}/${row.id}`)}
        color="info"
      >
        Bookings
      </Button>
    );
  };

  render() {
    const { pastEvents, clubs, loadingPastEvents, onEventClone, history } = this.props;
    const { activeClub, pastEvent } = this.state
    const { SearchBar } = Search;
    const events = [
      {
        dataField: "image",
        text: "#",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <>
            <Link
              to={`/bookings/${event.title.replace(/[?/]/g, '-')}/${event.id}`}
            >
              <img
                src={event.image}
                alt=""
                className="avatar-sm"
              />
            </Link>
          </>
        ),
      },
      {
        text: "Events",
        dataField: "events",
        // eslint-disable-next-line react/display-name
        filterValue: (_, event) => event?.title + event?.description,
        formatter: (_, event) => (
          <>
            <h5 className="font-size-14">
              <Link
                to={`/bookings/${event.title.replace(/[?/]/g, '-')}/${event.id}`}
                className="text-dark"
              >
                {event.title}
              </Link>
            </h5>
            <p
              style={{
                width: 300,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              className="text-muted mb-0"
            >
              <Link
                to={`/bookings/${event.title.replace(/[?/]/g, '-')}/${event.id}`}
                className="text-dark"
              >
                {event.description}
              </Link>
            </p>
          </>
        ),
      },
      {
        text: "View",
        dataField: "",
        formatter: this.viewEditFormater
      },
      {
        text: "Bookings",
        dataField: "",
        formatter: this.viewBookingsFormater
      },
      {
        text: "Action",
        dataField: '',
        formatter: (_, event) => (
          <>
            <UncontrolledDropdown>
              <DropdownToggle
                className="card-drop"
                tag="a"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    onEventClone(event)
                    history.push('/events-create')
                  }}
                >
                  <i className="mdi mdi-clipboard-file font-size-16 text-danger me-1" />{" "}
                  Clone Event
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        )
      },
      {
        text: "Start Date",
        dataField: "startDate",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <>
            <Link
              to={`/bookings/${event.title.replace(/[?/]/g, '-')}/${event.id}`}
              className="text-dark"
            >
              {this.handleValidDate(event.startDate)} from{" "}
              {moment(event.startTime, "HH:mm:ss").format("hh:mm A")} to {moment(event.endTime, "HH:mm:ss").format("hh:mm A")}

            </Link>
          </>
        ),
      },
      {
        dataField: "bookingsCount",
        text: "Total Bookings",
        formatter: (_, event) => (
          <>
            <p className="text-muted mb-0">
              <Link
                to={`/bookings/${event.title.replace(/[?/]/g, '-')}/${event.id}`}
                className="text-dark"
              >
                {event.bookingCount}
              </Link>
            </p>
          </>
        )
      },
      {
        dataField: "capacity",
        text: "Capacity",
        formatter: (_, event) => (
          <>
            <p className="text-muted mb-0">
              <Link
                to={`/bookings/${event.title.replace(/[?/]/g, '-')}/${event.id}`}
                className="text-dark"
              >
                {event.capacity}
              </Link>
            </p>
          </>
        ),
      },
      {
        dataField: "price",
        text: "Price",
        formatter: (_, event) => (
          <>
            <p className="text-muted mb-0">
              <Link
                to={`/bookings/${event.title.replace(/[?/]/g, '-')}/${event.id}`}
                className="text-dark"
              >
                {event.price}$
              </Link>
            </p>
          </>
        )
      },

    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: pastEvents?.length, // replace later with size(customers),
      custom: true,
    };

    return (
      <React.Fragment>

        <div className="page-content">
          <MetaTags>
            <title>Past Events List | Groundfloor</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Past Events" breadcrumbItem="Past Events List" />

            <div className="text-sm-end">

            </div>
            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        {this.state.isMobile ?
                          <Row>
                            <Col lg="1">
                              <Select
                                styles={{
                                }}
                                onChange={
                                  this.handleClubSelect
                                }
                                options={this.state.clubsOptions}
                                value={this.state.clubSelected}
                                classNamePrefix="select2-selection"
                              />
                            </Col>
                          </Row>
                          : <div style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'
                            , marginBottom: 10
                          }}>
                            <Nav pills className="bg-light rounded" role="tablist">
                              <NavItem>
                              </NavItem>
                              {clubs?.map((item, index) => (
                                <NavItem key={index}>
                                  <NavLink
                                    className={classnames({ active: activeClub === item.id })}
                                    style={{
                                      backgroundColor: activeClub === item.id ? '#032224' : 'transparent'
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        activeClub: item.id
                                      })
                                    }}
                                  >
                                    {item.name}
                                  </NavLink>
                                </NavItem>
                              ))}
                            </Nav>
                          </div>}
                        {pastEvents && <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          columns={events}
                          data={pastEvents}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              columns={events}
                              data={pastEvents}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>

                                  <Row>
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    // style={{ width: "400px", height: "40px" }}
                                    />
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        {loadingPastEvents ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />}
                                      </div>
                                    </Col>
                                    {/* </Link> */}
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>}
                      </CardBody>
                    </Card>


                    <Modal
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {"Event Details"}
                      </ModalHeader>
                      <ModalBody>
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            id: (pastEvent && pastEvent.id) || "",
                            title: (pastEvent && pastEvent.title) || "",
                            description: (pastEvent && pastEvent.description) || "",
                            startDate:
                              (pastEvent &&
                                moment(pastEvent?.startDate, "DD-MM-YYYY").format(
                                  "YYYY-MM-DD"
                                )) ||
                              "",
                            capacity: pastEvent && pastEvent.capacity,
                            guestInvitesAllowed:
                              pastEvent && pastEvent.guestInvitesAllowed,
                            location: (pastEvent && pastEvent.location) || "",
                            price: pastEvent && pastEvent.price,
                            startTime: (pastEvent && pastEvent.startTime) || "",
                          }}
                        >
                          {() => (
                            <Form>
                              <Row>
                                <Col className="col-12">

                                  <div className="mb-3">
                                    <Label className="form-label">Title</Label>
                                    <Field
                                      name="title"
                                      type="text"
                                      className={
                                        "form-control"
                                      }
                                      disabled
                                    />

                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Description
                                    </Label>
                                    <Field
                                      name="description"
                                      type="text"
                                      component="textarea"
                                      rows="6"
                                      disabled
                                      className={
                                        "form-control"
                                      }
                                    />

                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Location
                                    </Label>
                                    <Field
                                      name="location"
                                      type="text"
                                      disabled
                                      className={
                                        "form-control"
                                      }
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Capacity
                                    </Label>
                                    <Field
                                      name="capacity"
                                      type="number"
                                      disabled
                                      className={
                                        "form-control"
                                      }
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <Label
                                      htmlFor="guestInvitesAllowed"
                                      className="col-form-label col-lg-2"
                                    >
                                      Guest Invites Allowed
                                    </Label>
                                    <Col lg="10">
                                      <Field
                                        name="guestInvitesAllowed"
                                        value={this.state.guestInvitesAllowed}
                                        disabled
                                        onChange={e =>
                                          this.setState({
                                            guestInvitesAllowed: e.target.value,
                                          })
                                        }
                                        type="number"
                                        className={
                                          "form-control"
                                        }
                                      />
                                    </Col>
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="requiresPayment"
                                      className="col-form-label col-lg-2"
                                    >
                                      Requires Payment
                                    </Label>

                                    <Col lg="10">
                                      <div className="form-check">
                                        <input
                                          disabled
                                          checked={this.state.requiresPayment}
                                          onChange={() =>
                                            this.handleRquirePayment()
                                          }
                                          className="form-check-input"
                                          type="checkbox"
                                          id="requiresPayment"
                                        />
                                      </div>
                                    </Col>
                                  </div>

                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Start Date and Time
                                    </Label>
                                    <Col lg="10">
                                      <Row>
                                        <Col lg="6">
                                          <Field
                                            name="startDate"
                                            type="date"
                                            disabled
                                            className={
                                              "form-control"
                                            }
                                          />
                                        </Col>

                                        <Col lg="6">
                                          <Field
                                            name="startTime"
                                            type="time"
                                            disabled
                                            className={
                                              "form-control"
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </div>

                                  <div className="mb-3">
                                    <Label
                                      htmlFor="price"
                                      className="col-form-label col-lg-2"
                                    >
                                      Price
                                    </Label>
                                    <Col lg="10">
                                      <Field
                                        name="price"
                                        value={this.state.price}
                                        disabled
                                        onChange={e =>
                                          this.setState({
                                            price: e.target.value,
                                          })
                                        }
                                        type="number"
                                        className={
                                          "form-control"
                                        }
                                      />
                                    </Col>
                                  </div>
                                </Col>
                              </Row>

                            </Form>
                          )}
                        </Formik>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

PastEventList.propTypes = {
  pastEvents: PropTypes.array,
  className: PropTypes.any,
  token: PropTypes.any,
  onGetPastEvents: PropTypes.func,
  loadingPastEvents: PropTypes.bool,
  onGetClubs: PropTypes.func,
  clubs: PropTypes.array,
  onEventClone: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = ({ events, Login, system }) => ({
  pastEvents: events.pastEvents,
  loadingPastEvents: events.loadingPastEvents,
  token: Login.token,
  clubs: system.clubs
});

const mapDispatchToProps = dispatch => ({
  onGetPastEvents: (clubId) => dispatch(getPastEvents(clubId)),
  onGetClubs: () => dispatch(getClubs()),
  onEventClone: (event) => dispatch(cloneEvent(event)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PastEventList));
