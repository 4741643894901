/* eslint-disable react/prop-types */
import React from 'react'

function InviteeDetails({ item }) {
    return (
        <div style={{ marginTop: 10, borderBottom: 'solid 1px', borderBottomColor: '#CED4DA' }}>
            <p>Name: {item.name}</p>
            <p>Email: {item.email}</p>
            {item.rescheduled && <p style={{ fontWeight: 'bold' }}>Rescheduled</p>}
        </div>
    )
}

export default InviteeDetails