import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Label,
    Button,
} from "reactstrap";

import {
    applyCoupon,
    bookSpace,
    getAllCoupons,
    getClubs,
    getSpaces,
} from "store/actions";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

import DefaultModal from "components/Common/DefaultModal";
import { TreeSelect } from "antd";
import moment from "moment";

class BookSpace extends Component {
    constructor() {
        super();
        this.state = {
            selectedFiles: [],
            eventImage: null,
            clubsFiltered: [],
            clubsSelected: [],
            value: [],
            clubError: false,
            confirmModal: false,
            emailOrNotification: { label: "Email", value: 'Email' },
            dataToSend: null,
            treeData: [],
            spaceSelected: null,
            space: null,
            spaceId: null,
            spaceError: false
        };
        this.handleAcceptedFiles.bind(this);
        this.showSuccessToast = this.showSuccessToast.bind(this);

    }

    filterClubs = () => {
        const { clubs } = this.props;
        return clubs.map(item => ({ label: item.name, value: item.id }));
    };

    handleEmailNotificationSelect = value => {
        this.setState({
            emailOrNotification: value,
        })

    }


    showSuccessToast(message, autoClose, success) {
        if (success) {
            toast.success(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.history.push('/space-bookings')
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    componentDidMount() {
        const {
            onGetClubs,
            clubs,
            onGetSpaces
        } = this.props;
        onGetClubs();
        if (clubs) {
            onGetSpaces(clubs.map(item => item.id).join(','));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { clubs, onGetSpaces, spaces } = this.props;
        if (clubs !== prevProps.clubs) {
            onGetSpaces(clubs.map(item => item.id).join(','));
        }
        if (spaces !== prevProps.spaces) {
            const spaceSelect = clubs.map(club => ({ title: `${club.name}`, value: `club;${club.id}`, children: spaces?.filter(space => space.club_id === club.id).map(item => ({ label: item.title, value: `${item.title};${item.id}` })) }))
            const treeData = [
                ...spaceSelect,
            ];

            this.setState({
                treeData
            });
        }
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );

        this.setState({ selectedFiles: files });
    };

    onChange = (newValue) => {
        this.setState({
            value: newValue,
            clubError: false
        })
    };


    handleConfirm = () => {
        const { onBookSpace } = this.props;
        const { dataToSend } = this.state;
        onBookSpace(dataToSend.spaceId, dataToSend.type, dataToSend.date, dataToSend.startTime, dataToSend.endTime, this.showSuccessToast)
        this.setState({
            confirmModal: false,
        });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    handleHostSelect = host => {
        const nameValue = host.split(';')
        if (['club'].includes(nameValue[0])) {
            this.setState({
                spaceSelected: null,
                space: null,
                spaceError: true
            })
        } else {
            this.setState({
                spaceSelected: nameValue[0],
                space: nameValue[0],
                spaceId: nameValue[1],
                spaceError: false
            })
        }
    }


    render() {

        const { confirmModal } = this.state

        return (
            <React.Fragment>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <DefaultModal
                    title={'Are you sure you want to book this space?'}
                    description={'You will not be able to revert this!'}
                    show={confirmModal}
                    onSendClick={this.handleConfirm}
                    onCloseClick={() => this.setState({ confirmModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title> Book Space | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        <Row>
                            <Col lg="12">
                                <Card >
                                    <CardBody style={{ height: '80vh' }}>
                                        <div style={{ display: 'flex', }}>
                                            <CardTitle style={{ marginRight: 43 }} className="mb-4">Book Space</CardTitle>
                                        </div>
                                        <Row className="mb-4 mt-4">
                                            <Label className="col-form-label col-lg-2">
                                                Select Space
                                            </Label>
                                            <Col lg="4">
                                                <TreeSelect
                                                    showSearch
                                                    style={{ width: "100%" }}
                                                    value={this.state.spaceSelected}
                                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                                    placeholder="Please select space"
                                                    allowClear
                                                    treeDefaultExpandAll
                                                    onChange={this.handleHostSelect}
                                                    treeData={this.state.treeData}
                                                />
                                                {this.state.spaceError && (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Please Select Space
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>

                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={
                                                {
                                                    startDate: "",
                                                    startTime: "",
                                                    endTime: "",

                                                }
                                            }
                                            validationSchema={Yup.object().shape({
                                                startDate: Yup.string().required(
                                                    "Please Enter Start Date"
                                                ),
                                                startTime: Yup.string().required(
                                                    "Please Enter Start Time"
                                                ),
                                                endTime: Yup.string().required(
                                                    "Please Enter End Time"
                                                ),
                                            })}

                                            onSubmit={values => {
                                                if (!this.state.spaceSelected) {
                                                    this.setState({
                                                        spaceError: true
                                                    })
                                                } else {
                                                    const space = this.props.spaces.find(item => item.id === this.state.spaceId)
                                                    this.setState({
                                                        dataToSend: {
                                                            spaceId: this.state.spaceId,
                                                            type: space.type,
                                                            date: moment(values.startDate).format(
                                                                "YYYY-MM-DD"
                                                            ),
                                                            startTime: values.startTime,
                                                            endTime: values.endTime
                                                        },
                                                        confirmModal: true
                                                    })
                                                }
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <React.Fragment>
                                                    <Form>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="startDate"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Select date
                                                            </Label>

                                                            <Col lg="2">
                                                                <Field
                                                                    name="startDate"
                                                                    type="date"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.startDate &&
                                                                            touched.startDate
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="startDate"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="email"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Select start time and end time
                                                            </Label>
                                                            <Col lg="2">
                                                                <Field
                                                                    name="startTime"
                                                                    type="time"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.startTime &&
                                                                            touched.startTime
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="startTime"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                            <Col lg="2">
                                                                <Field
                                                                    name="endTime"
                                                                    type="time"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.startTime &&
                                                                            touched.startTime
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="endTime"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="justify-content-end">
                                                            <Col lg="10">
                                                                <Button type="submit" color="primary">
                                                                    Book space
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

BookSpace.propTypes = {
    onGetClubs: PropTypes.func,
    onGetSpaces: PropTypes.func,
    onBookSpace: PropTypes.func,
    clubs: PropTypes.array,
    spaces: PropTypes.array,
    history: PropTypes.shape({
        go: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ bookings, system }) => ({
    spaces: bookings.spaces,
    clubs: system.clubs

});

const mapDispatchToProps = dispatch => ({
    onGetClubs: () => dispatch(getClubs()),
    onGetSpaces: (clubId) => dispatch(getSpaces(clubId)),
    onBookSpace: (spaceId, type, date, startTime, endTime, showSuccessToast) => dispatch(bookSpace(spaceId, type, date, startTime, endTime, showSuccessToast)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BookSpace));