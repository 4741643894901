import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";

import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    Button,
} from "reactstrap";
import Select from "react-select";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
    getClubs,
    getSubClubsByClubId,
} from "store/actions";
import DefaultModal from "components/Common/DefaultModal";

class SubClubsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subclubsByClubId: [],
            clubId: null,
            club: "No Club Selected",
            activeClub: null,
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            clubSelected: { label: "No Club Selected", value: '' },
            clubsOptions: [],
        };

    }

    componentDidMount() {
        const {
            subclubsByClubId,
            onGetSubClubs,
            onGetClubs,
            clubs
        } = this.props;

        const authUser = localStorage.getItem("authUser")
        if (JSON.parse(authUser)?.clubId) {
            this.setState({
                activeClub: JSON.parse(authUser).clubId
            })
        }
        if (clubs.length !== 0) {
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        } else {
            onGetClubs();
        }
        this.setState({
            subclubsByClubId,
        });
    }

    handleClubSelect = club => {
        this.setState({
            clubSelected: club,
            activeClub: club.value
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { onGetSubClubs } = this.props
        const { activeClub } = this.state;
        if (this.props.clubs !== prevProps.clubs) {
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        }
        if (this.props.subclubsByClubId !== prevProps.subclubsByClubId) {
            this.setState({
                subclubsByClubId: this.props.subclubsByClubId,
            });
        }
        if (activeClub !== prevState.activeClub) {
            if (activeClub) {
                onGetSubClubs(activeClub)
            }
        }
    }



    render() {
        const { subclubsByClubId, clubs, loadingSubclubsByClubId, history } = this.props;
        const { activeClub } = this.state;
        const { SearchBar } = Search;
        const subclubsByClubIdColumns = [
            {
                dataField: "image",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, subclub) => (
                    <>
                        <img src={subclub.image} alt="" className="avatar-sm" />
                        <span style={{ marginLeft: 20 }}>{subclub.name}</span>
                    </>
                ),
            },

        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: subclubsByClubId?.length, // replace later with size(customers),
            custom: true,
        };
        const tableRowEvents = {
            onClick: (e, row, rowIndex) => {
                history.push(`/sub-club-members/${row.id}`);
            },
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Sub clubs List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Sub clubs" breadcrumbItem="Sub clubs List" />
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                {this.state.isMobile ? <Row><Col>        <Select
                                                    styles={{
                                                    }}
                                                    onChange={
                                                        this.handleClubSelect
                                                    }
                                                    options={this.state.clubsOptions}
                                                    value={this.state.clubSelected}
                                                    classNamePrefix="select2-selection"
                                                />
                                                </Col></Row> : <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Nav
                                                        pills
                                                        className="bg-light rounded"
                                                        role="tablist"
                                                    >
                                                        <NavItem>
                                                        </NavItem>
                                                        {clubs?.map((item, index) => (
                                                            <NavItem key={index}>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeClub === item.id,
                                                                    })}
                                                                    style={{
                                                                        backgroundColor:
                                                                            activeClub === item.id
                                                                                ? "#032224"
                                                                                : "transparent",
                                                                    }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            activeClub: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                    </Nav>

                                                </div>}
                                                {subclubsByClubId && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={subclubsByClubIdColumns}
                                                        data={subclubsByClubId}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={subclubsByClubIdColumns}
                                                                data={subclubsByClubId}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                                style={{ marginTop: 10 }}
                                                                            />
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingSubclubsByClubId ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        rowEvents={tableRowEvents}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={"thead-light"}
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

SubClubsList.propTypes = {
    subclubsByClubId: PropTypes.array,
    className: PropTypes.any,
    onGetSubClubs: PropTypes.func,
    onGetClubs: PropTypes.func,
    loadingSubclubsByClubId: PropTypes.bool,
    clubs: PropTypes.array,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ system, subclubs }) => ({
    subclubsByClubId: subclubs.subclubsByClubId,
    loadingSubclubsByClubId: subclubs.loadingSubclubsByClubId,
    clubs: system.clubs,
});

const mapDispatchToProps = dispatch => ({
    onGetClubs: () => dispatch(getClubs()),
    onGetSubClubs: clubId => dispatch(getSubClubsByClubId(clubId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SubClubsList));
