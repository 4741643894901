import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import FileUpload from "../../components/FileUpload/FileUpload";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select"
import * as Yup from "yup";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Button,
} from "reactstrap";

import { addAnnouncment, getClubs, setImage } from "store/actions";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class AddAnnouncment extends Component {
    constructor() {
        super();
        this.state = {
            selectedFiles: [],
            events: [],
            event: "",
            eventImage: null,
            clubId: null,
            club: "No Club Selected",
            clubError: false,
            typeError: false,
            clubsSelected: [],
            clubsFiltered: [],
        };
        this.handleAcceptedFiles.bind(this);
        this.onFileUploaded = this.onFileUploaded.bind(this);
        this.handleMultiClub = this.handleMultiClub.bind(this)
    }



    clubItemSelected(name, id) {
        this.setState({
            club: name,
            clubId: id,
            clubError: false,
        });
    }

    filterClubs = () => {
        const { clubs } = this.props
        return clubs.map(item => ({ label: item.name, value: item.id }))
    }

    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    componentDidMount() {
        const { onGetClubs } = this.props;
        onGetClubs();
        this.setState({
            clubsFiltered: [
                {
                    label: 'Clubs',
                    options: this.filterClubs()
                }
            ],
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { clubs } = this.props;
        if (clubs !== prevProps.clubs) {
            this.setState({
                clubsFiltered: [
                    {
                        label: 'Clubs',
                        options: this.filterClubs()
                    }
                ],
            })
        }
    }

    handleMultiClub = clubsSelected => {
        this.setState({ clubsSelected, clubError: false })
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );

        this.setState({ selectedFiles: files });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };


    render() {
        const { clubsFiltered, clubsSelected } = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Add New Announcemnt | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Announcemnts" breadcrumbItem="Add New" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Add New Announcemnt</CardTitle>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                title: "",
                                                description: "",
                                                displayOrder: 1
                                            }}
                                            validationSchema={Yup.object().shape({
                                                title: Yup.string().required("Please Enter Title"),
                                                description: Yup.string().required("Please Enter Description"),
                                                displayOrder: Yup.number().min(1, 'Minimum Value is 1').required("Please Enter Order of this Announcment"),
                                            })}
                                            onSubmit={values => {
                                                if (clubsSelected.length === 0) {
                                                    this.setState({
                                                        clubError: true,
                                                    });
                                                } else {
                                                    clubsSelected.map(item => {
                                                        this.props.onAddAnnouncment(
                                                            {
                                                                title: values.title,
                                                                description: values.description,
                                                                clubId: parseInt(item.value),
                                                                image: this.state?.selectedFiles[0],
                                                                displayOrder: values.displayOrder
                                                            }
                                                        );
                                                    })

                                                    setTimeout(() => {
                                                        this.props.history.goBack()
                                                    }, 1500);
                                                }
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <React.Fragment>
                                                    <Form>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="name"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Title
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="title"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.title && touched.title
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="title"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="description"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Description
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="description"
                                                                    component="textarea"
                                                                    rows="6"
                                                                    type="textarea"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.description && touched.description
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="description"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="displayOrder"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Display Order
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="displayOrder"
                                                                    rows="6"
                                                                    type="number"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.displayOrder && touched.displayOrder
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="displayOrder"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="duration"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Select Club
                                                            </Label>
                                                            <Col lg="10">
                                                                <Select
                                                                    value={clubsSelected}
                                                                    isMulti={true}
                                                                    onChange={this.handleMultiClub}
                                                                    options={clubsFiltered}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                {this.state.clubError && (
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Please Select a Club
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>



                                                        <FormGroup className="mb-4" row>
                                                            <Label className="col-form-label col-lg-2">
                                                                Attach Image
                                                            </Label>
                                                            <Col lg="10">
                                                                <FileUpload
                                                                    onFileUploaded={this.onFileUploaded}
                                                                ></FileUpload>

                                                                <div
                                                                    className="dropzone-previews mt-3"
                                                                    id="file-previews"
                                                                >
                                                                    {this.state.selectedFiles.map((f, i) => {
                                                                        return (
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={i + "-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <Row className="align-items-center">
                                                                                        <Col className="col-auto">
                                                                                            <img
                                                                                                data-dz-thumbnail=""
                                                                                                height="80"
                                                                                                className="avatar-sm rounded bg-light"
                                                                                                alt={f.name}
                                                                                                src={f.preview}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Link
                                                                                                className="text-muted font-weight-bold"
                                                                                            >
                                                                                                {f.name}
                                                                                            </Link>
                                                                                            <p className="mb-0">
                                                                                                <strong>
                                                                                                    {f.formattedSize}
                                                                                                </strong>
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </Col>
                                                        </FormGroup>

                                                        <Row className="justify-content-end">
                                                            <Col lg="10">
                                                                <Button type="submit" color="primary">
                                                                    Add Announcemnt
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

AddAnnouncment.propTypes = {
    announcments: PropTypes.array,
    onAddAnnouncment: PropTypes.func,
    history: PropTypes.object,
    onGetClubs: PropTypes.func,
    clubs: PropTypes.array,
    onSetImage: PropTypes.func,

};

const mapStateToProps = ({ announcments, system }) => ({
    announcments: announcments.announcments,
    clubs: system.clubs,

});

const mapDispatchToProps = dispatch => ({
    onGetClubs: () => dispatch(getClubs()),
    onAddAnnouncment: (announcment) => dispatch(addAnnouncment(announcment)),
    onSetImage: (image) => dispatch(setImage(image)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddAnnouncment));
