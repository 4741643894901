import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import FileUpload from "../../components/FileUpload/FileUpload";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import {
    addEventInstructor,
    getAllChildrenCategories,
    setImage,
} from "store/actions";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import Switch from "components/Common/Switch";
import { TreeSelect } from "antd";

class EventCreateInstructor extends Component {
    constructor() {
        super();
        this.state = {
            startDate: new Date(),
            startTime: new Date(),
            endTime: new Date(),
            selectedFiles: [],
            event: "",
            eventImage: null,
            requiresPayment: false,
            sendPushNotification: false,
            showBookClosingTimes: false,
            checkinRequired: true,
            spotlightEvent: false,
            location: "",
            link: "",
            dropdownOpen: false,
            dropdownInstructorOpen: false,
            category: "Event",
            instructor: "No Instructor",
            instructorId: "0",
            host: '',
            hostId: null,
            clubError: false,
            locationError: false,
            interestsError: false,
            categoryError: false,
            hostError: false,
            interestsFiltered: [],
            interestsSelected: [],
            bookingConfirmation: { label: "No link", value: 0 },
            locationSelected: { label: "Select Location", value: 0 },
            hostSelected: null,
            salesEndBeforeEvent: { label: "2 hours before the event", value: 2 },

        };
        this.startDateChange.bind(this);
        this.endDateChange.bind(this);
        this.handleAcceptedFiles.bind(this);
        this.toggleCategory = this.toggleCategory.bind(this);
        this.onFileUploaded = this.onFileUploaded.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
    }

    toggleCategory() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    filterCategories = id => {
        const { childrenCategories } = this.props;
        return childrenCategories
            ?.filter(item => item.system_category_id === id)
            .map(item => ({ label: item.name, value: item.name }));
    }

    handleMulti = interestsSelected => {
        this.setState({ interestsSelected, interestsError: false });
    };

    handleBookingConfirmationSelect = bookingConfirmation => {
        this.setState({ bookingConfirmation });
    };

    handleSalesEndBeforeEventSelect = salesEndBeforeEvent => {
        this.setState({ salesEndBeforeEvent });
    };

    handleLocationSelect = location => {
        if (location.value === 'Other') {
            this.setState({
                locationSelected: location,
                location: ''
            })
        } else {
            this.setState({
                location: location.value,
                locationSelected: location,
                locationError: false
            })
        }
    }

    categoryItemSelected(value) {
        this.setState({
            category: value,
            categoryError: false
        });
    }

    startDateChange = date => {
        this.setState({
            startDate: date,
        });
    };

    endDateChange = date => {
        this.setState({
            endDate: date,
        });
    };

    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    componentDidMount() {
        const {
            eventToClone,
            onSetImage,
            onGetAllChildrenCategories,
        } = this.props;
        onGetAllChildrenCategories();
        this.setState({
            interestsFiltered: [
                {
                    label: "Interests",
                    options: [...this.filterCategories("2"), ...this.filterCategories("11")],
                },
            ],
        });
        if (eventToClone) {
            onSetImage(eventToClone.image);
            this.setState({
                category: eventToClone.category,
                locationSelected: { label: eventToClone.location, value: eventToClone.location },
                location: eventToClone.location,
                host: eventToClone.host,
                hostSelected: eventToClone.host,
                hostId: eventToClone.hostId,
                instructorId: eventToClone.instructorId,
                instructor: eventToClone.instructorName,
                selectedFiles: [
                    { name: "", preview: eventToClone.image, url: eventToClone.image },
                ],
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { childrenCategories } = this.props;

        if (childrenCategories !== prevProps.childrenCategories) {
            this.setState({
                interestsFiltered: [
                    {
                        label: "Interests",
                        options: [...this.filterCategories("2"), ...this.filterCategories("11")],
                    },
                ],
            });
        }
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );

        this.setState({ selectedFiles: files });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    handleRquirePayment = () => {
        this.setState({
            requiresPayment: !this.state.requiresPayment,
        });
    };
    handleShowBookClosingTimes = () => {
        this.setState({
            showBookClosingTimes: !this.state.showBookClosingTimes,
        });
    };

    render() {
        const bookingConfirmationSelect = [
            { label: "No link", value: 0 },
            { label: "Learn more", value: 1 },
            { label: "Purchase tickets", value: 2 },
            { label: "Book a time slot", value: 3 },
        ];

        const locationSelect = [
            { label: "Lounge", value: 'Lounge' },
            { label: "Library", value: 'Library' },
            { label: "Kitchen", value: 'Kitchen' },
            { label: "Wellness Studio", value: 'Wellness Studio' },
            { label: "Patio", value: 'Patio' },
            { label: "Banana Yoga - 376 40th Street, Oakland", value: 'Banana Yoga - 376 40th Street, Oakland' },
            { label: "835 4th St Suite B, San Rafael", value: '835 4th St Suite B, San Rafael' },
            { label: "3976 Piedmont Ave", value: "3976 Piedmont Ave" },
            { label: "2973 16th St 5th Floor", value: '2973 16th St 5th Floor' },
            { label: "239 3rd St, San Rafael, CA 94901", value: '239 3rd St, San Rafael, CA 94901' },
            { label: "Other", value: 'Other' },
        ]

        const salesEndSelect = [
            { label: "2 hours before the event", value: 2 },
            { label: "12 hours before the event", value: 12 },
            { label: "24 hours before the event", value: 24 },
            { label: "48 hours before the event", value: 48 },
        ]

        const { eventToClone, } = this.props;
        const {
            interestsFiltered,
            interestsSelected,
        } = this.state;

        const user = JSON.parse(localStorage.getItem('authUser'))

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Create New Event | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Events" breadcrumbItem="Create New" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={
                                                eventToClone
                                                    ? {
                                                        title: eventToClone.title,
                                                        description: eventToClone.description,
                                                        startDate: moment(eventToClone.startDate).format(
                                                            "YYYY-MM-DD"
                                                        ),
                                                        startTime: eventToClone.startTime,
                                                        endTime: eventToClone.endTime,

                                                        capacity: eventToClone.capacity,
                                                        price: eventToClone.price,
                                                        guestInvitesAllowed:
                                                            eventToClone.guestInvitesAllowed,
                                                        link: eventToClone.link,
                                                        category: eventToClone.category,
                                                    }
                                                    : {
                                                        title: "",
                                                        description: "",
                                                        startDate: "",
                                                        startTime: "",
                                                        endTime: "",
                                                        capacity: "",
                                                        price: "",
                                                        guestInvitesAllowed: "",
                                                        link: "",
                                                        category: "",
                                                    }
                                            }
                                            validationSchema={Yup.object().shape({
                                                title: Yup.string().trim('Please Enter Title').required("Please Enter Title"),
                                                description: Yup.string().trim('Please Enter Description').required(
                                                    "Please Enter Description"
                                                ),
                                                startDate: Yup.string().required(
                                                    "Please Enter Start Date"
                                                ),
                                                startTime: Yup.string().required(
                                                    "Please Enter Start Time"
                                                ),
                                                endTime: Yup.string().required("Please Enter End Time"),
                                                capacity: Yup.number()
                                                    .min(1, "Capacity can not be 0")
                                                    .typeError("The amount invalid")
                                                    .required("The amount is required"),
                                                guestInvitesAllowed: Yup.number()
                                                    .min(0, 'Can not be lower than 0!').typeError("It must be a number"),
                                                price: Yup.number()
                                                    .min(0, 'Can not be lower than 0!').typeError("It must be a number")
                                            })}
                                            onSubmit={values => {
                                                let startDate = values.startDate
                                                let startTime = values.startTime



                                                const startDateAndTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm')
                                                let salesEndDateAndTime = startDateAndTime

                                                if (this.state.showBookClosingTimes) {
                                                    salesEndDateAndTime = startDateAndTime.subtract(this.state.salesEndBeforeEvent.value, 'hours')
                                                }


                                                if (this.state.interestsSelected.length === 0) {
                                                    this.setState({
                                                        interestsError: true
                                                    })
                                                }
                                                if (this.state.location.length === 0) {
                                                    this.setState({
                                                        locationError: true
                                                    })
                                                }
                                                if (this.state.category === null || this.state.category?.length === 0) {
                                                    this.setState({
                                                        categoryError: true
                                                    })
                                                }
                                                if (!this.state.interestsError && !this.state.locationError && !this.state.categoryError) {
                                                    this.props.onAddEvent(
                                                        {
                                                            title: values.title,
                                                            description: values.description,
                                                            startTime: moment(
                                                                startTime,
                                                                "hh:mm A"
                                                            ).format("HH:mm:ss"),
                                                            endTime: moment(
                                                                values.endTime,
                                                                "hh:mm A"
                                                            ).format("HH:mm:ss"),
                                                            salesEnd: moment(
                                                                salesEndDateAndTime
                                                            ).format("YYYY-MM-DD HH:mm:ss"),
                                                            capacity: values.capacity,
                                                            guestInvitesAllowed:
                                                                values.guestInvitesAllowed === '' ? 0 : values.guestInvitesAllowed,
                                                            price: values.price === '' ? 0 : values.price,
                                                            location: this.state.location,
                                                            link: this.state.bookingConfirmation.value === 0 ? "" : values.link,
                                                            host: this.state.host.trim() !== '' ? this.state.host : `${user.firstName} ${user.lastName}`,
                                                            category: 'Wellness',
                                                            instructorId: user.id,
                                                            clubId: user.clubId,
                                                            instructorName: `${user.firstName} ${user.lastName}`,
                                                            requiresPayment: values.price > 0 || values.price !== '' ? 1 : 0,
                                                            sendPushNotification: false,
                                                            checkinRequired: true,
                                                            spotlight: 0,
                                                            bookingConfirmation:
                                                                this.state.bookingConfirmation.label,
                                                            image: this.state?.selectedFiles[0],
                                                            hostId: '',
                                                            interests: this.state.interestsSelected
                                                                .map(item => item.value)
                                                                .join(";"),
                                                            startDate: moment(values.startDate).format(
                                                                "YYYY-MM-DD"
                                                            ),
                                                        },
                                                        this.props.history
                                                    );

                                                }
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <React.Fragment>
                                                    <Form>
                                                        <h4 className="mb-5">Basic Info</h4>
                                                        <Label className="form-label">Event title</Label>
                                                        <span className="mb-2">Be clear and descriptive with a title that tells people what your event is about.</span>
                                                        <Row className="mb-4">
                                                            <Col lg="10">
                                                                <Field
                                                                    name="title"
                                                                    type="text"
                                                                    placeholder="Title"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.title && touched.title
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="title"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label className="form-label">Description</Label>

                                                            <Col lg="10">
                                                                <span className="mb-4">Use this section to provide more details about your event. You can include things to know, venue information, parking, accessbility options—anything that will help people know what to expect.</span>

                                                                <Field
                                                                    name="description"
                                                                    component="textarea"
                                                                    rows="6"
                                                                    type="textarea"
                                                                    placeholder="Description"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.description && touched.description
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="description"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label className="form-label">Event Host</Label>

                                                            <Col lg="10">
                                                                <p style={{ fontSize: 12, fontWeight: '400' }}>{`This field is optional.`}</p>
                                                                <Field
                                                                    type="text"
                                                                    value={this.state.host}
                                                                    placeholder="Enter host"
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            host: e.target.value,
                                                                        })}
                                                                    className={
                                                                        "form-control"
                                                                    }
                                                                />

                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label className="form-label">Event Capacity</Label>

                                                            <Col lg="10">
                                                                <span className="mb-2">Enter capacity for the event</span>

                                                                <Field
                                                                    name="capacity"
                                                                    type="number"
                                                                    placeholder="Capacity"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.capacity && touched.capacity
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="capacity"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label className="form-label">Guest Invites Allowed</Label>

                                                            <Col lg="10">
                                                                <span className="mb-2">Up to 3 guests per member allowed</span>

                                                                <Field
                                                                    placeholder="Guest Invites Allowed"
                                                                    name="guestInvitesAllowed"
                                                                    type="number"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.guestInvitesAllowed &&
                                                                            touched.guestInvitesAllowed
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="guestInvitesAllowed"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-5">
                                                            <Label className="form-label">Event Price</Label>

                                                            <Col lg="10">
                                                                <span className="mb-2">Input the  price for the event if member participation involves a fee.</span>

                                                                <Field
                                                                    name="price"
                                                                    placeholder="Price"
                                                                    type="number"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.price && touched.price
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="price"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <hr />

                                                        <h4 className="mt-5 mb-5">Location</h4>

                                                        <Row className="mb-5">
                                                            <Col lg="10">
                                                                <p style={{ fontSize: 12, fontWeight: '400' }}>Please ensure if an event is happening within the Groundfloor space, the exact location is selected from the drop-down list!</p>
                                                                <Select
                                                                    onChange={
                                                                        this.handleLocationSelect
                                                                    }
                                                                    options={locationSelect}
                                                                    value={this.state.locationSelected}
                                                                    defaultValue={{ label: "Select Location", value: 0 }}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                {this.state.locationSelected?.value === 'Other' && <Field
                                                                    type="text"
                                                                    value={this.state.location}
                                                                    style={{ marginTop: 10 }}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            location: e.target.value,
                                                                            locationError: false
                                                                        })}
                                                                    className={
                                                                        "form-control"

                                                                    }
                                                                />}
                                                                {this.state.locationError && (
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Please Select a Location
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                        <hr />

                                                        <h4 className="mt-5 mb-5">Date and time</h4>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="startDate"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Start Date
                                                            </Label>
                                                            <Col lg="10">
                                                                <Row>

                                                                    <Col lg="6">
                                                                        <Field
                                                                            name="startDate"
                                                                            type="date"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.startDate && touched.startDate
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="startDate"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="startDate"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Start Time
                                                            </Label>
                                                            <Col lg="10">
                                                                <Row className="mt-2">
                                                                    <Col lg="6">
                                                                        <Field
                                                                            name="startTime"
                                                                            type="time"
                                                                            format="HH:mm"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.startTime && touched.startTime
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="startTime"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="endTime"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                End Time
                                                            </Label>
                                                            <Col lg="10">
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <span className="mb-2">Enter the anticipated end time for the event.</span>

                                                                        <Field
                                                                            name="endTime"
                                                                            type="time"
                                                                            format="HH:mm"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.endTime && touched.endTime
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="endTime"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Final Attendee Update Deadline
                                                            </Label>
                                                            <Col lg="10">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginBottom: 10
                                                                }}>
                                                                    <span style={{ marginRight: 10 }}>OFF</span>
                                                                    <Switch active={this.state.showBookClosingTimes} parentFunc={this.handleShowBookClosingTimes} />
                                                                    <span style={{ marginLeft: 10 }}>ON</span>
                                                                </div>
                                                                <span>{`Set this as the cutoff for all attendee list changes, including the waitlist. No bookings or waitlist updates will occur after this time.`}</span>
                                                            </Col>

                                                        </Row>
                                                        {this.state.showBookClosingTimes && <Row className="mt-5 mb-4">
                                                            <Label
                                                                htmlFor="bookingConfirmation"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Select sales end
                                                            </Label>
                                                            <Col lg="8">
                                                                <Select
                                                                    onChange={
                                                                        this.handleSalesEndBeforeEventSelect
                                                                    }
                                                                    options={salesEndSelect}
                                                                    defaultValue={{ label: "2 hours before the event", value: 2 }}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </Col>
                                                        </Row>}
                                                        <hr />

                                                        <h4 className="mt-5 mb-5">Details</h4>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="bookingConfirmation"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Booking Confirmation
                                                            </Label>
                                                            <Col lg="10">
                                                                <Select
                                                                    onChange={
                                                                        this.handleBookingConfirmationSelect
                                                                    }
                                                                    options={bookingConfirmationSelect}
                                                                    defaultValue={{ label: "No link", value: 0 }}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                <ErrorMessage
                                                                    name="bookingConfirmation"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {this.state.bookingConfirmation.value !== 0 && <Row className="mb-4">
                                                            <Label
                                                                htmlFor="link"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Link
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="link"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.link && touched.link
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="link"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>}

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="duration"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Select Interests
                                                            </Label>
                                                            <Col lg="10">
                                                                <p style={{ fontSize: 12, fontWeight: '400' }}>Pick all the relevant and related themes to tag the event, this will help us automate suggestions in future!</p>
                                                                <Select
                                                                    value={interestsSelected}
                                                                    isMulti={true}
                                                                    onChange={this.handleMulti}
                                                                    options={interestsFiltered}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                {this.state.interestsError && (
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Please Select Interests
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                        <FormGroup className="mb-4" row>
                                                            <Label className="col-form-label col-lg-2">
                                                                Image
                                                            </Label>
                                                            <Col lg="10">
                                                                <span className="mb-4">Add a photo to show what your event will be about. You can upload only 1 image.</span>

                                                                <FileUpload
                                                                    onFileUploaded={this.onFileUploaded}
                                                                ></FileUpload>

                                                                <div
                                                                    className="dropzone-previews mt-3"
                                                                    id="file-previews"
                                                                >
                                                                    {this.state.selectedFiles.map((f, i) => {
                                                                        return (
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={i + "-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <Row className="align-items-center">
                                                                                        <Col className="col-auto">
                                                                                            <img
                                                                                                data-dz-thumbnail=""
                                                                                                height="80"
                                                                                                className="avatar-sm rounded bg-light"
                                                                                                alt={f.name}
                                                                                                src={f.preview}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="text-muted font-weight-bold"
                                                                                            >
                                                                                                {f.name}
                                                                                            </Link>
                                                                                            <p className="mb-0">
                                                                                                <strong>
                                                                                                    {f.formattedSize}
                                                                                                </strong>
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                        <Row className="justify-content-end">
                                                            <Col lg="10">
                                                                <Button type="submit" color="primary">
                                                                    Create Event
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

EventCreateInstructor.propTypes = {
    user: PropTypes.any,
    onAddEvent: PropTypes.func,
    onGetAllChildrenCategories: PropTypes.func,
    history: PropTypes.object,
    eventToClone: PropTypes.object,
    onSetImage: PropTypes.func,
    childrenCategories: PropTypes.array,
};

const mapStateToProps = ({ Login, system, events }) => ({
    eventToClone: events.eventToClone,
    user: Login.user,
    childrenCategories: system.childrenCategories,
});

const mapDispatchToProps = dispatch => ({
    onAddEvent: (event, history) => dispatch(addEventInstructor(event, history)),
    onSetImage: image => dispatch(setImage(image)),
    onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EventCreateInstructor));