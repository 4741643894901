import { ADD_PRODUCT, ADD_PRODUCT_FAIL, ADD_PRODUCT_SUCCESS, DELETE_PRODUCT, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_SUCCESS, GET_ORDERS, GET_ORDERS_FAIL, GET_ORDERS_SUCCESS, GET_PRODUCTS, GET_PRODUCTS_FAIL, GET_PRODUCTS_SUCCESS, ORDER_RECEIVED, ORDER_RECEIVED_FAIL, ORDER_RECEIVED_SUCCESS, UPDATE_PRODUCT, UPDATE_PRODUCT_FAIL, UPDATE_PRODUCT_SUCCESS } from "./actionTypes";

export const addProduct = (product, history) => ({
    type: ADD_PRODUCT,
    payload: { product, history },
});

export const addProductSuccess = (product) => ({
    type: ADD_PRODUCT_SUCCESS,
    payload: product,
});

export const addProductFail = error => ({
    type: ADD_PRODUCT_FAIL,
    payload: error,
});

export const getProducts = (clubId) => ({
    type: GET_PRODUCTS,
    payload: clubId
})

export const getProductsSuccess = (products) => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: products
})

export const getProductsFail = (error) => ({
    type: GET_PRODUCTS_FAIL,
    payload: error
})

export const getOrders = (clubId) => ({
    type: GET_ORDERS,
    payload: clubId
})

export const getOrdersSuccess = (orders) => ({
    type: GET_ORDERS_SUCCESS,
    payload: orders
})

export const getOrdersFail = (error) => ({
    type: GET_ORDERS_FAIL,
    payload: error
})

export const orderRecieved = (orderId) => ({
    type: ORDER_RECEIVED,
    payload: orderId
})

export const orderRecievedSuccess = () => ({
    type: ORDER_RECEIVED_SUCCESS
})

export const orderRecievedFail = (error) => ({
    type: ORDER_RECEIVED_FAIL,
    payload: error
})


export const updateProduct = (product) => ({
    type: UPDATE_PRODUCT,
    payload: product
})

export const updateProductSuccess = () => ({
    type: UPDATE_PRODUCT_SUCCESS
})

export const updateProductFail = (error) => ({
    type: UPDATE_PRODUCT_FAIL,
    payload: error
})

export const deleteProduct = (productId) => ({
    type: DELETE_PRODUCT,
    payload: productId
})

export const deleteProductSuccess = () => ({
    type: DELETE_PRODUCT_SUCCESS
})

export const deleteProductFail = (error) => ({
    type: DELETE_PRODUCT_FAIL,
    payload: error
})

