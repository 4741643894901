import Config from "API/Config";
import axios from "axios";
import CryptoJS from 'crypto-js';

const decryptData = (encryptedData, key, iv) => {
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(encryptedData),
    },
    CryptoJS.enc.Hex.parse(key),
    {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};
//pass new generated access token here
const token = localStorage.getItem("token");

//apply base url for axios
const API_URL = process.env.REACT_APP_BASE_URL

export const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

axiosApi.interceptors.response.use((response) => {
  if (response.data?.type === 'formated') {
    const decryptedData = decryptData(response.data.data, Config.Server.secretKey, Config.Server.iv);
    return { data: JSON.parse(decryptedData) }
  } else {
    return response
  }

}, (error) => {


  if (error?.response?.status === 401) {
    window.location.replace('/logout')
  }
  throw error
})

export async function get(urlex, config = {}) {
  return await axiosApi
    .get(urlex, { ...config })
    .then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then(response => {
    return response.data;
  });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
