import { GET_ORDERS, GET_ORDERS_FAIL, GET_ORDERS_SUCCESS, GET_PRODUCTS, GET_PRODUCTS_FAIL, GET_PRODUCTS_SUCCESS } from "./actionTypes";

const INIT_STATE = {
    error: {},
    products: [],
    loadingProducts: false,
    orders: [],
    loadingOrders: false,
};

const products = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                loadingProducts: true
            }
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                loadingProducts: false,
                products: action.payload
            }
        case GET_PRODUCTS_FAIL:
            return {
                ...state,
                loadingProducts: false,
            }
        case GET_ORDERS:
            return {
                ...state,
                loadingOrders: true
            }
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                loadingOrders: false,
                orders: action.payload
            }
        case GET_ORDERS_FAIL:
            return {
                ...state,
                loadingOrders: false,
            }
        default:
            return state;
    }
};

export default products;
