import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import {
  AUTO_LOGIN,
  LOGIN_USER,
  LOGOUT_USER,
  USER_LOG_OUT_CHECK,
  CHECK_TOKEN_VALID,
  FORGET_PASSWORD,
} from "./actionTypes";
import {
  loginSuccess,
  apiError,
  logoutUserSuccess,
  autoLoginSuccess,
} from "./actions";

import { postLogin, getProfile, tokenValidCheck, forgotPasswordAPI } from "helpers/backend_helper";

function* forgotPassword({ payload: { email, afterEffect } }) {
  try {
    yield call(forgotPasswordAPI, email);
    afterEffect(true)
  } catch (error) {
    afterEffect(false)
  }
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    });

    if (response) {
      localStorage.setItem("authUser", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("roleId", response.data.user.roleId);
      localStorage.setItem("userId", response.data.user.id);
      yield put(loginSuccess(response.data.user, response.data.token));
      if (response.data.user.roleId == 4) {
        history.push("/instructor-dashboard");
      } else if (response.data.user.roleId == 5) {
        history.push("/club-upcoming-events");
      } else {
        history.push("/dashboard");
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* autoLogin() {
  try {
    const user = localStorage.getItem("authUser");
    const token = localStorage.getItem("token");

    if (token) {
      yield put(autoLoginSuccess(user, token));
    } else {
      yield call(logoutUser);
    }
  } catch (error) { }
}

function* checkValidToken() {
  try {
    const token = localStorage.getItem("token");
    const response = yield call(tokenValidCheck, token);
    if (!response.result) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("token");
      localStorage.removeItem("roleId");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* checkLoggedOut() {
  try {
    const token = localStorage.getItem("token");
    const response = yield call(getProfile, token);
    if (response.data.loggedOut == 1) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("token");
      localStorage.removeItem("roleId");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    history.push("/login");
    yield put(logoutUserSuccess());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(AUTO_LOGIN, autoLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(USER_LOG_OUT_CHECK, checkLoggedOut);
  yield takeEvery(CHECK_TOKEN_VALID, checkValidToken)
  yield takeEvery(FORGET_PASSWORD, forgotPassword)
}

export default authSaga;
