import React, { Component } from "react";

import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Button,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import MetaTags from "react-meta-tags";
import PropTypes, { number } from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Select from "react-select";

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from 'draftjs-to-html';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import {
    getClubLeaderMembers,
    getClubLeaderSubClubs,
    getClubs,
    sendEmailToUserByLeader,
} from "../../store/actions";
import DefaultModal from "components/Common/DefaultModal";
import { EditorState, convertToRaw } from "draft-js";

class ClubLeaderMembersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: [],
            clubLeaderSubClubs: [],
            activeSubClub: null,
            userToSendEmail: null,
            emailTo: null,
            showModal: false,
            sendEmailModal: false,
            subject: "",
            body: "",
            userToSendEmailSelected: [],
            userToSendError: false,
            editorState: EditorState.createEmpty()
        };
        this.toggle = this.toggle.bind(this);

    }

    componentDidMount() {
        const {
            onGetSubClubs,
            onGetClubs
        } = this.props;
        onGetSubClubs();
        onGetClubs()
    }

    toggle() {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    }

    sendEmail = () => {
        this.props.onSendEmailToUser({
            userId: this.state.userToSendEmail,
            subClubId: this.state.activeSubClub.id,
            subject: this.state.subject,
            body: this.state.body
        }, this.showSuccessToast)

        this.toggle();
        this.setState({
            sendEmailModal: false,
            subject: "",
            body: "",
            userToSendEmail: null,
            userToSendEmailSelected: []
        })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };



    sendEmailFormater = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => {
                    this.setState({
                        userToSendEmail: row.id,
                        emailTo: 'user',
                        showModal: true,
                        userToSendEmailSelected: []
                    })
                }}
                color="info"
            >
                Send Email
            </Button>
        );
    };

    showSuccessToast(message, autoClose, success) {
        if (success) {
            toast.success(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.clubLeaderSubClubs !== prevProps.clubLeaderSubClubs) {
            this.setState({
                clubLeaderSubClubs: this.props.clubLeaderSubClubs,
                activeSubClub: this.props.clubLeaderSubClubs[0]
            });
        }
        if (this.state.activeSubClub !== prevState.activeSubClub) {
            if (this.props.clubLeaderMembers?.length === 0) {
                this.props.onGetClubLeaderMembers(this.state.activeSubClub.id)
            }
        }
        if (this.props.clubLeaderMembers != prevProps.clubLeaderMembers) {
            const members = this.props.clubLeaderMembers.map(item => ({ label: `${item.firstName} ${item.lastName}`, value: `${item.id}` }))
            this.setState({
                treeData: [
                    {
                        label: 'All members',
                        value: 'all',
                    },
                    ...members
                ]
            })

        }
        if (this.state.userToSendEmailSelected != prevState.userToSendEmailSelected && this.state.emailTo === 'all') {

            this.setState({
                userToSendEmail: this.state.userToSendEmailSelected.map(item => item.value).join(',')
            })
        }
        if (this.state.editorState != prevState.editorState) {
            this.setState({
                body: `<div style="color: black">${draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}</div>`
            })
        }
    }


    handleMulti = userToSendEmailSelected => {
        if (userToSendEmailSelected.length > 0) {
            if (userToSendEmailSelected[userToSendEmailSelected.length - 1].value === 'all') {
                this.setState({
                    userToSendEmailSelected: [
                        userToSendEmailSelected[userToSendEmailSelected.length - 1]
                    ],
                    userToSendError: false
                })
            } else if (userToSendEmailSelected.filter(item => item.value !== 'all').length > 0) {
                this.setState({
                    userToSendEmailSelected: userToSendEmailSelected.filter(item => item.value !== 'all'),
                    userToSendError: false
                })
            }
        } else {
            this.setState({
                userToSendEmailSelected,
                userToSendError: false
            })
        }

    };

    render() {
        const { clubLeaderMembers, loadingClubLeaderMembers, history } = this.props
        const { SearchBar } = Search;
        const columns = [
            {
                dataField: "firstName",
                text: "Member",
                // eslint-disable-next-line react/display-name
                formatter: (_, member) => (
                    <div onClick={() => history.push(`/profile/${member.id}/${this.state.activeSubClub.id}`)}>
                        <img src={member.profilePhoto} alt="" className="avatar-sm" />
                    </div>
                ),
            },
            {
                dataField: "firstName",
                text: "Name",
                sort: true,
                filterValue: (_, user) => `${user?.firstName} ${user?.lastName}`,
                formatter: (value, row) => {
                    return <div onClick={() => history.push(`/profile/${row.id}/${this.state.activeSubClub.id}`)}>
                        <span>{row.firstName} {row.lastName}
                        </span>
                    </div>
                },
            },
            {
                dataField: "bookingsCount",
                text: "Events Booked",
            },
            {
                dataField: "bookingsCancelledCount",
                text: "Events Cancelled",
            },
            {
                dataField: "firstName",
                text: "Member for",
                formatter: (value, row) => <span>{row.monthsSinceJoined >= 1 ? row.monthsSinceJoined === 1 ? `${row.monthsSinceJoined} month ` : `${row.monthsSinceJoined} months ` : ''}{row.daysSinceJoined} days</span>
            },
            {
                dataField: "firstName",
                text: "Send email",
                formatter: this.sendEmailFormater,
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: clubLeaderMembers?.length,
            custom: true,
        };

        return (
            <React.Fragment>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <DefaultModal
                    title={'Are you sure you want to send this email?'}
                    description={'You will not be able to revert this!'}
                    show={this.state.sendEmailModal}
                    onSendClick={this.sendEmail}
                    onCloseClick={() => this.setState({ sendEmailModal: false })}
                />
                <div className="page-content">

                    <MetaTags>
                        <title> Members List | Groundfloor</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title={"Members"} breadcrumbItem={"Members List"} />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                marginBottom: 10
                                            }}
                                        >
                                            <Nav
                                                pills
                                                className="bg-light rounded"
                                                role="tablist"
                                            >
                                                {this.state.clubLeaderSubClubs?.map((item, index) => {
                                                    let abrv = ''
                                                    if (this.props.clubs) {
                                                        const club = this.props.clubs.find(club => club.id === item.club_id)
                                                        if (club) {
                                                            abrv = club.abbreviation
                                                        }
                                                    }
                                                    return (
                                                        <NavItem key={index}>
                                                            <NavLink
                                                                className={classnames({
                                                                    active: this.state.activeSubClub.id === item.id,
                                                                })}
                                                                style={{
                                                                    backgroundColor:
                                                                        this.state.activeSubClub.id === item.id
                                                                            ? "#032224"
                                                                            : "transparent",
                                                                }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        activeSubClub: item,
                                                                    });
                                                                }}
                                                            >
                                                                {item.name} - {abrv}
                                                            </NavLink>
                                                        </NavItem>
                                                    )
                                                })}
                                            </Nav>
                                            <div className="text-sm-end">
                                                <Button
                                                    className="font-16 btn-block btn btn-primary"
                                                    onClick={() => this.setState({
                                                        emailTo: 'all',
                                                        showModal: true,
                                                        userToSendEmailSelected: [{
                                                            label: 'All members',
                                                            value: 'all',
                                                        }]
                                                    })}
                                                >
                                                    Send email to all members
                                                </Button>
                                            </div>
                                        </div>

                                        {(
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={columns}
                                                data={clubLeaderMembers}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={clubLeaderMembers}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row>
                                                                    <SearchBar
                                                                        {...toolkitProps.searchProps}
                                                                    />
                                                                </Row>
                                                                <Row>

                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            {loadingClubLeaderMembers ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                keyField={"email"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                            />}
                                                                        </div>
                                                                    </Col>
                                                                    {/* </Link> */}
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        )}
                                    </CardBody>
                                </Card>
                                <Modal
                                    isOpen={this.state.showModal}
                                    className={this.props.className}
                                    size="xl"
                                >
                                    <ModalHeader toggle={this.toggle} tag="h4">
                                        {"Send email"}
                                    </ModalHeader>
                                    <span style={{ fontStyle: 'italic', marginLeft: 10 }}>Note that members will be able reply to you directly to your email registered with your groundfloor account.</span>
                                    <ModalBody>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                subject: "",

                                            }}
                                            validationSchema={Yup.object().shape({
                                                subject: Yup.string().required("Please Enter Subject"),
                                            })}
                                            onSubmit={(values) => {
                                                if (this.state.emailTo === 'all' && this.state.userToSendEmailSelected.length === 0) {
                                                    this.setState({
                                                        userToSendError: true
                                                    })
                                                } else if (this.state.body === '') {
                                                    this.setState({
                                                        bodyError: true
                                                    })
                                                } else {
                                                    this.setState({
                                                        sendEmailModal: true,
                                                        subject: values.subject,
                                                        body: this.state.body
                                                    })
                                                }
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form>
                                                    <Row>
                                                        {this.state.emailTo === 'all' && <Row className="mb-4">
                                                            <Col lg="10">
                                                                <Label
                                                                    htmlFor=""
                                                                    className="col-form-label col-lg-5"
                                                                >
                                                                    Choose whom you wish to send the email to.
                                                                </Label>
                                                                <Select
                                                                    value={this.state.userToSendEmailSelected}
                                                                    isMulti={true}
                                                                    onChange={this.handleMulti}
                                                                    options={this.state.treeData}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                {this.state.userToSendError && (
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Please select whom you wish to send the email to
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>}
                                                        <Row className="mb-4">
                                                            <Col lg='10'>
                                                                <Label
                                                                    className="col-form-label col-lg-2"
                                                                >
                                                                    Subject
                                                                </Label>
                                                                <Field
                                                                    name="subject"
                                                                    placeholder="Subject"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.subject && touched.subject
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="subject"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg='10'>
                                                                <Label
                                                                    className="col-form-label col-lg-2"
                                                                >
                                                                    Body
                                                                </Label>
                                                                <Row>
                                                                    <span style={{ marginBottom: 10 }}>{`Compose your email body here, noting that a 'Hey firstName' will be automatically inserted.`}</span>
                                                                </Row>
                                                                <Editor
                                                                    editorState={this.state.editorState}
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                    editorStyle={{
                                                                        color: 'black'
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="text-end">

                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-success save-user"
                                                                >
                                                                    Send email
                                                                </button>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </ModalBody>
                                </Modal>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

ClubLeaderMembersList.propTypes = {
    className: PropTypes.any,
    clubLeaderSubClubs: PropTypes.array,
    clubLeaderMembers: PropTypes.array,
    loadingClubLeaderMembers: PropTypes.bool,
    onGetSubClubs: PropTypes.func,
    onGetClubLeaderMembers: PropTypes.func,
    onSendEmailToUser: PropTypes.func,
    onGetClubs: PropTypes.func,
    clubs: PropTypes.array,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ members, subclubs, system }) => ({
    clubLeaderSubClubs: subclubs.clubLeaderSubClubs,
    clubLeaderMembers: members.clubLeaderMembers,
    loadingClubLeaderMembers: members.loadingClubLeaderMembers,
    clubs: system.clubs,

});

const mapDispatchToProps = dispatch => ({
    onGetSubClubs: () => dispatch(getClubLeaderSubClubs()),
    onGetClubLeaderMembers: (subClubId) => dispatch(getClubLeaderMembers(subClubId)),
    onSendEmailToUser: (data, showToast) => dispatch(sendEmailToUserByLeader(data, showToast)),
    onGetClubs: () => dispatch(getClubs()),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ClubLeaderMembersList));
