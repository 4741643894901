import { GET_CLUB_LEADER_SUB_CLUBS_SUCCESS, GET_SUB_CLUBS_BY_CLUB_ID, GET_SUB_CLUBS_BY_CLUB_ID_FAIL, GET_SUB_CLUBS_BY_CLUB_ID_SUCCESS, GET_SUB_CLUBS_FAIL, GET_SUB_CLUBS_SUCCESS } from "./actionTypes";


const INIT_STATE = {

  subClubs: [],
  error: {},

  clubLeaderSubClubs: [],
  loadinClubLeaderSubClubs: false,

  subclubsByClubId: [],
  loadingSubclubsByClubId: false
};

const system = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_SUB_CLUBS_SUCCESS:
      return {
        ...state,
        subClubs: action.payload,
      };

    case GET_CLUB_LEADER_SUB_CLUBS_SUCCESS:
      return {
        ...state,
        clubLeaderSubClubs: action.payload,
      };

    case GET_SUB_CLUBS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_SUB_CLUBS_BY_CLUB_ID:
      return {
        ...state,
        loadingSubclubsByClubId: true,
      };
    case GET_SUB_CLUBS_BY_CLUB_ID_SUCCESS:
      return {
        ...state,
        subclubsByClubId: action.payload,
        loadingSubclubsByClubId: false,
      };
    case GET_SUB_CLUBS_BY_CLUB_ID_FAIL:
      return {
        ...state,
        loadingSubclubsByClubId: false,
      };

    default:
      return state;
  }
};

export default system;