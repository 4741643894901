import { addProduct, updateProduct, getProducts, deleteProduct, getOrders, orderRecieved } from "helpers";
import { ADD_PRODUCT, DELETE_PRODUCT, GET_ORDERS, GET_PRODUCTS, ORDER_RECEIVED, UPDATE_PRODUCT } from "./actionTypes";
import { getImage } from "store/events/selectos";
import { addProductFail, deleteProductFail, getOrdersFail, getOrdersSuccess, getProductsFail, getProductsSuccess, orderRecievedFail, updateProductFail } from "./actions";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { setImageSuccess } from "store/actions";

function* addProductSaga({ payload: { product, history } }) {
    try {
        const token = localStorage.getItem("token");
        const image = yield select(getImage);
        yield call(addProduct, token, { ...product, image });
        yield put(setImageSuccess(null));
        history.push("/store");
    } catch (error) {
        yield put(addProductFail(error));
    }
}

function* orderRecievedSaga({ payload: orderId }) {
    try {
        const token = localStorage.getItem("token");
        yield call(
            orderRecieved,
            token,
            orderId
        );

    } catch (error) {
        yield put(orderRecievedFail(error));
    }
}

function* updateProductSaga({ payload: product }) {
    try {
        const token = localStorage.getItem("token");
        const image = yield select(getImage);
        yield call(
            updateProduct,
            token,
            { ...product, image }
        );
        yield put(setImageSuccess(null));
    } catch (error) {
        yield put(updateProductFail(error));
    }
}

function* getProductsSaga({ payload: clubId }) {
    const token = localStorage.getItem("token");
    try {
        const response = yield call(getProducts, token, clubId);
        yield put(getProductsSuccess(response.data));
    } catch (error) {
        yield put(getProductsFail(error));
    }
}

function* getOrdersSaga({ payload: clubId }) {
    const token = localStorage.getItem("token");
    try {
        const response = yield call(getOrders, token, clubId);
        yield put(getOrdersSuccess(response.data));
    } catch (error) {
        yield put(getOrdersFail(error));
    }
}

export function* deleteProductSaga({ payload: productId }) {
    try {
        const token = localStorage.getItem("token");
        yield call(deleteProduct, token, productId);
    } catch (error) {
        yield put(deleteProductFail(error));
    }
}

function* productsSaga() {
    yield takeEvery(ADD_PRODUCT, addProductSaga);
    yield takeEvery(UPDATE_PRODUCT, updateProductSaga);
    yield takeEvery(GET_PRODUCTS, getProductsSaga);
    yield takeEvery(GET_ORDERS, getOrdersSaga);
    yield takeEvery(ORDER_RECEIVED, orderRecievedSaga);
    yield takeEvery(DELETE_PRODUCT, deleteProductSaga);
}

export default productsSaga;
