import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";


import {
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Card,
    CardBody,
} from "reactstrap";

import { Formik, Field, Form } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { cloneEvent, getSuggestedEvents } from "store/actions";

class SuggestedEventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestedEvents: [],
            pastEvent: "",
            selectedFiles: [],
            guestInvitesAllowed: 0,
            requiresPayment: false,
            price: 0,
        };

        this.handlePastEventClick = this.handlePastEventClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handlePastEventClicks = this.handlePastEventClicks.bind(this);
    }

    componentDidMount() {
        const { onGetSuggestedEvents, onEventClone } = this.props;
        onGetSuggestedEvents();
        onEventClone(null)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.suggestedEvents !== prevProps.suggestedEvents) {
            this.setState({
                suggestedEvents: this.props.suggestedEvents
            })
        }
    }

    handlePastEventClicks = arg => {
        this.setState({ selectedpastEvent: arg });
        this.toggle();
    };

    handlePastEventClick = arg => {
        const pastEvent = arg;
        this.setState({
            pastEvent: {
                id: pastEvent.id,
                firstName: this.props.suggestedEvents.find(item => item.id === pastEvent.id)?.firstName,
                lastName: this.props.suggestedEvents.find(item => item.id === pastEvent.id)?.lastName,
                price: pastEvent.price,
                description: pastEvent.description,
                capacity: pastEvent.capacity,
                guestInvitesAllowed: pastEvent.guestInvitesAllowed,
                dueDate: pastEvent.dueDate,
                location: pastEvent.location,
                requiresPayment: pastEvent.requiresPayment === 1,
                startDate: pastEvent.startDate,
                startTime: pastEvent.startTime,
                title: pastEvent.title,
            },
            isEdit: true,
            guestInvitesAllowed: pastEvent.guestInvitesAllowed,
            requiresPayment: pastEvent.requiresPayment === 1,
            price: pastEvent.price,
        });

        this.toggle();
    };



    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    handleValidDate = date => {
        const date1 = moment(date).utc().format("DD MMM Y");
        return date1;
    };

    handleRquirePayment = () => {
        this.setState({
            requiresPayment: !this.state.requiresPayment,
        });
    };

    cloneEvent = (event) => {
        this.props.onEventClone({
            title: event['Name of the event'],
            description: event['Event description:'],
            startDate: event['Event date'],
            image: event['Event image:'][0].url,
            capacity: event['Max capacity for this event?'],
            category: event,
            host: event['Host Name:'],
            startTime: '',
            endTime: '',
            price: 0,
            guestInvitesAllowed: 0,
            link: '',
            category: '',
            instructorId: '',
        })
        this.props.history.push('/events-create')
    }

    render() {
        const { suggestedEvents, loadingSuggestedEvents } = this.props;
        const { pastEvent } = this.state
        const { SearchBar } = Search;
        const events = [
            {
                dataField: "image",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <>

                        <img
                            onClick={() => this.cloneEvent(event)}
                            src={event['Event image:'][0].url}
                            alt=""
                            className="avatar-sm"
                        />
                    </>
                ),
            },
            {
                text: "Events",
                dataField: "events",
                // eslint-disable-next-line react/display-name
                filterValue: (_, event) => event?.title + event?.description,
                formatter: (_, event) => (
                    <>
                        <h5 onClick={() => this.cloneEvent(event)} className="font-size-14">

                            {event['Name of the event']}

                        </h5>
                        <p onClick={() => this.cloneEvent(event)}
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            className="text-muted mb-0"
                        >

                            {event['Event description:']}

                        </p>
                    </>
                ),
            },
            {
                dataField: "createdBy",
                text: "Host",
                formatter: (_, event) => (
                    <>
                        <p onClick={() => this.cloneEvent(event)} className="text-muted mb-0">

                            {event['Host Name:']}

                        </p>
                        <p
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            onClick={() => this.cloneEvent(event)}
                            className="text-muted mb-0"
                        >

                            {event['Host Email:']}

                        </p>
                    </>
                ),
            },
            {
                text: "Start Date",
                dataField: "startDate",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <div onClick={() => this.cloneEvent(event)}>

                        {this.handleValidDate(event['Event date'])}

                    </div>
                ),
            },
            {
                dataField: "bookingsCount",
                text: "Groundfloor Location",
                formatter: (_, event) => (
                    <>
                        <p onClick={() => this.cloneEvent(event)} className="text-muted mb-0">

                            {event['Groundfloor location']}

                        </p>
                    </>
                )
            },
            {
                dataField: "capacity",
                text: "Capacity",
                formatter: (_, event) => (
                    <>
                        <p onClick={() => this.cloneEvent(event)} className="text-muted mb-0">

                            {event['Max capacity for this event?']}

                        </p>
                    </>
                ),
            },
            // {
            //     dataField: "price",
            //     text: "Price",
            //     formatter: (_, event) => (
            //         <>
            //             <p className="text-muted mb-0">
            //                 <Link
            //                     to={`/events-create`}
            //                     className="text-dark"
            //                 >
            //                     {event.price}$
            //                 </Link>
            //             </p>
            //         </>
            //     )
            // },
            // {
            //     text: "Action",
            //     dataField: '',
            //     formatter: (_, event) => (
            //         <>
            //             <UncontrolledDropdown>
            //                 <DropdownToggle
            //                     href="#"
            //                     className="card-drop"
            //                     tag="a"
            //                 >
            //                     <i className="mdi mdi-dots-horizontal font-size-18" />
            //                 </DropdownToggle>
            //                 <DropdownMenu className="dropdown-menu-end">
            //                     <DropdownItem
            //                         href="#"
            //                         onClick={() =>
            //                             this.handlePastEventClick(event)
            //                         }
            //                     >
            //                         <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            //                         View
            //                     </DropdownItem>
            //                     <DropdownItem
            //                         onClick={() => {
            //                             onEventClone(event)
            //                             history.push('/events-create')
            //                         }}
            //                     >
            //                         <i className="mdi mdi-clipboard-file font-size-16 text-danger me-1" />{" "}
            //                         Clone Event
            //                     </DropdownItem>
            //                 </DropdownMenu>
            //             </UncontrolledDropdown>
            //         </>
            //     )
            // },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: suggestedEvents?.length, // replace later with size(customers),
            custom: true,
        };

        return (
            <React.Fragment>

                <div className="page-content">
                    <MetaTags>
                        <title>Suggested Events List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Suggested Events" breadcrumbItem="Suggested Events List" />

                        <div className="text-sm-end">

                        </div>
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                {suggestedEvents && <PaginationProvider
                                                    pagination={paginationFactory(pageOptions)}
                                                    columns={events}
                                                    data={suggestedEvents}
                                                >
                                                    {({ paginationProps, paginationTableProps }) => (
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            columns={events}
                                                            data={suggestedEvents}
                                                            search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>

                                                                    <Row>
                                                                        <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        // style={{ width: "400px", height: "40px" }}
                                                                        />
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                {loadingSuggestedEvents ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap"
                                                                                    }
                                                                                    headerWrapperClasses={"thead-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                />}
                                                                            </div>
                                                                        </Col>
                                                                        {/* </Link> */}
                                                                    </Row>

                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="inner-custom-pagination d-flex">
                                                                            <div className="d-inline">
                                                                                <SizePerPageDropdownStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                            <div className="text-md-right ms-auto">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                                </PaginationProvider>}
                                            </CardBody>
                                        </Card>


                                        <Modal
                                            isOpen={this.state.modal}
                                            className={this.props.className}
                                        >
                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                {"Event Details"}
                                            </ModalHeader>
                                            <ModalBody>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{
                                                        id: (pastEvent && pastEvent.id) || "",
                                                        title: (pastEvent && pastEvent.title) || "",
                                                        description: (pastEvent && pastEvent.description) || "",
                                                        startDate:
                                                            (pastEvent &&
                                                                moment(pastEvent?.startDate, "DD-MM-YYYY").format(
                                                                    "YYYY-MM-DD"
                                                                )) ||
                                                            "",
                                                        capacity: pastEvent && pastEvent.capacity,
                                                        guestInvitesAllowed:
                                                            pastEvent && pastEvent.guestInvitesAllowed,
                                                        location: (pastEvent && pastEvent.location) || "",
                                                        price: pastEvent && pastEvent.price,
                                                        startTime: (pastEvent && pastEvent.startTime) || "",
                                                    }}
                                                >
                                                    {() => (
                                                        <Form>
                                                            <Row>
                                                                <Col className="col-12">

                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Title</Label>
                                                                        <Field
                                                                            name="title"
                                                                            type="text"
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                            disabled
                                                                        />

                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Description
                                                                        </Label>
                                                                        <Field
                                                                            name="description"
                                                                            type="text"
                                                                            component="textarea"
                                                                            rows="6"
                                                                            disabled
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />

                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Location
                                                                        </Label>
                                                                        <Field
                                                                            name="location"
                                                                            type="text"
                                                                            disabled
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Capacity
                                                                        </Label>
                                                                        <Field
                                                                            name="capacity"
                                                                            type="number"
                                                                            disabled
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <Label
                                                                            htmlFor="guestInvitesAllowed"
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Guest Invites Allowed
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Field
                                                                                name="guestInvitesAllowed"
                                                                                value={this.state.guestInvitesAllowed}
                                                                                disabled
                                                                                onChange={e =>
                                                                                    this.setState({
                                                                                        guestInvitesAllowed: e.target.value,
                                                                                    })
                                                                                }
                                                                                type="number"
                                                                                className={
                                                                                    "form-control"
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label
                                                                            htmlFor="requiresPayment"
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Requires Payment
                                                                        </Label>

                                                                        <Col lg="10">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    disabled
                                                                                    checked={this.state.requiresPayment}
                                                                                    onChange={() =>
                                                                                        this.handleRquirePayment()
                                                                                    }
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    id="requiresPayment"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Start Date and Time
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <Field
                                                                                        name="startDate"
                                                                                        type="date"
                                                                                        disabled
                                                                                        className={
                                                                                            "form-control"
                                                                                        }
                                                                                    />
                                                                                </Col>

                                                                                <Col lg="6">
                                                                                    <Field
                                                                                        name="startTime"
                                                                                        type="time"
                                                                                        disabled
                                                                                        className={
                                                                                            "form-control"
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <Label
                                                                            htmlFor="price"
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Price
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Field
                                                                                name="price"
                                                                                value={this.state.price}
                                                                                disabled
                                                                                onChange={e =>
                                                                                    this.setState({
                                                                                        price: e.target.value,
                                                                                    })
                                                                                }
                                                                                type="number"
                                                                                className={
                                                                                    "form-control"
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </Form>
                                                    )}
                                                </Formik>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

SuggestedEventList.propTypes = {
    suggestedEvents: PropTypes.array,
    className: PropTypes.any,
    token: PropTypes.any,
    onGetSuggestedEvents: PropTypes.func,
    loadingSuggestedEvents: PropTypes.bool,
    onEventClone: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ events, Login }) => ({
    suggestedEvents: events.suggestedEvents,
    loadingSuggestedEvents: events.loadingSuggestedEvents,
    token: Login.token,
});

const mapDispatchToProps = dispatch => ({
    onGetSuggestedEvents: () => dispatch(getSuggestedEvents()),
    onEventClone: (event) => dispatch(cloneEvent(event)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SuggestedEventList));
