import React, { Component } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import {
    getApplicants,
} from "../../store/actions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class ApplicantList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            selectedCategory: null,
            selectedMulti: null,
            membersList: [],
            activeClub: 1,
        };

    }

    componentDidMount() {
        const { onGetApplicants } = this.props;
        onGetApplicants()
    }

    render() {
        const { loadingApplicants, applicants } = this.props;

        const columns = [
            {
                dataField: "id",
                text: "Application ID",
                sort: false,
            },
            {
                dataField: "email",
                text: "Email",
                sort: false,
            },
            {
                dataField: "trial",
                text: "On Trial",
                sort: false,
                formatter: (_, applicant) => (
                    <span>{applicant.trial ? 'Yes' : 'No'}</span>
                ),
            },
            {
                dataField: "club_applied",
                text: "Club Applied",
                sort: false,
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: applicants?.length,
            custom: true,
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title> Applicants | Groundfloor</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title={"Applicants"} breadcrumbItem={"Applicants List"} />

                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <div className="text-sm-end">
                                            <Link to="/add-applicant">
                                                <Button
                                                    style={{ backgroundColor: "#032224" }}
                                                    className="font-16 btn-block btn btn-primary"
                                                >
                                                    Add Applicant
                                                </Button>
                                            </Link>
                                        </div>
                                        {applicants && (
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={columns}
                                                data={applicants}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={applicants}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            {loadingApplicants ? <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Link
                                                                                    to="#"
                                                                                    className="text-success"
                                                                                >
                                                                                    <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                                                    Loading
                                                                                </Link>{" "}
                                                                            </div> : <BootstrapTable
                                                                                keyField={"email"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                            />}
                                                                        </div>
                                                                    </Col>
                                                                    {/* </Link> */}
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ApplicantList.propTypes = {
    applicants: PropTypes.array,
    loadingApplicants: PropTypes.bool,
    onGetApplicants: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ members }) => ({
    applicants: members.applicants,
    loadingApplicants: members.loadingApplicants,
});

const mapDispatchToProps = dispatch => ({
    onGetApplicants: () => dispatch(getApplicants()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ApplicantList));