import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import FileUpload from "../../components/FileUpload/FileUpload";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Button,
} from "reactstrap";

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from 'draftjs-to-html';

import { EditorState, convertToRaw } from "draft-js";


import {
    getClubs,
    sendEmail,
    sendNotifications,
} from "store/actions";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

import { TreeSelect } from "antd";
import SendEmailOrNorificationModal from "components/Common/SendEmailOrNorificationModal";

const { SHOW_PARENT } = TreeSelect;


class SendEmail extends Component {
    constructor() {
        super();
        this.state = {
            selectedFiles: [],
            eventImage: null,
            clubsFiltered: [],
            clubsSelected: [],
            value: [],
            clubError: false,
            sendNotificationModal: false,
            emailOrNotification: { label: "Email", value: 'Email' },
            dataToSend: null,
            editorState: EditorState.createEmpty(),
            body: ''
        };
        this.handleAcceptedFiles.bind(this);

    }

    filterClubs = () => {
        const { clubs } = this.props;
        return clubs.map(item => ({ label: item.name, value: item.id }));
    };

    handleEmailNotificationSelect = value => {
        this.setState({
            emailOrNotification: value,
        })

    }


    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };


    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    componentDidMount() {
        const {
            onGetClubs,
        } = this.props;
        onGetClubs();
        this.setState({
            clubsFiltered: [
                {
                    label: "Clubs",
                    options: this.filterClubs(),
                },
            ],
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.editorState != prevState.editorState) {
            this.setState({
                body: `<div style="color: black">${draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}</div>`
            })
        }
    }


    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );

        this.setState({ selectedFiles: files });
    };

    onChange = (newValue) => {
        this.setState({
            value: newValue,
            clubError: false
        })
    };


    handleSendNotification = () => {
        const { onSendEmail, onSendNotification, clubs } = this.props;
        const { dataToSend } = this.state;
        if (dataToSend.subject !== undefined) {
            const { clubId, subject, text, fromEmail, fromName, replyTo } = dataToSend
            onSendEmail(clubId, fromEmail, fromName, replyTo, subject, text, this.showToast);
        } else {
            const { clubId, title, body } = dataToSend
            onSendNotification(clubId, title, body, this.showToast)
        }
        this.setState({
            sendNotificationModal: false,
            value: []
        });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    showToast(message, autoClose, success) {
        if (success) {
            toast.success(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {

        const { value, sendNotificationModal } = this.state

        const { clubs } = this.props

        const emailOrNotificationSelect = [
            { label: "Email", value: 'Email' },
            { label: "Notification", value: 'Notification' },
        ]

        const treeData = [
            {
                title: 'All Clubs',
                value: clubs.map(item => item.id).join(','),
                key: clubs.map(item => item.id).join(','),
                children: clubs.map(item => ({
                    title: item.name,
                    value: item.id.toString(),
                    key: item.id.toString(),
                }))
            },
        ];

        const tProps = {
            treeData,
            value,
            onChange: this.onChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: 'Please select Club',
            style: {
                width: '30%',
            },
        };



        return (
            <React.Fragment>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <SendEmailOrNorificationModal
                    show={sendNotificationModal}
                    onSendClick={this.handleSendNotification}
                    onCloseClick={() => this.setState({ sendNotificationModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Send Email/Notification | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>

                                        <Row className="mb-4 mt-4">
                                            <h5 className="col-form-label col-lg-2">
                                                Email - Notification
                                            </h5>
                                            <Col lg="2">
                                                <Select
                                                    onChange={
                                                        this.handleEmailNotificationSelect
                                                    }
                                                    options={emailOrNotificationSelect}
                                                    value={this.state.emailOrNotification}
                                                    defaultValue={{ label: "Email", value: 'Email' }}
                                                    classNamePrefix="select2-selection"

                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-4 mt-4">
                                            <Label className="col-form-label col-lg-2">
                                                Select Club
                                            </Label>
                                            <Col lg="10">
                                                <TreeSelect {...tProps} />
                                            </Col>
                                        </Row>
                                        {this.state.clubError && (
                                            <span
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                Please Select Club
                                            </span>
                                        )}
                                        {this.state.emailOrNotification.value === 'Email' ? <Formik
                                            enableReinitialize={true}
                                            initialValues={
                                                {
                                                    subject: "",
                                                    fromEmail: "no-reply",
                                                    fromName: "Groundfloor Club",
                                                    replyTo: ""
                                                }
                                            }
                                            validationSchema={Yup.object().shape({
                                                subject: Yup.string().required("Please Enter subject"),
                                                fromEmail: Yup.string().required("Please Enter from email"),
                                                fromName: Yup.string().required("Please Enter from name"),
                                            })}
                                            onSubmit={values => {

                                                if (this.state.value?.length === 0) {
                                                    this.setState({
                                                        clubError: true
                                                    })
                                                }
                                                if (!this.state.clubError) {
                                                    let clubId
                                                    if (this.state.value[0].length > 1) {
                                                        clubId = this.state.value[0]
                                                    } else {
                                                        clubId = this.state.value.join(',')
                                                    }
                                                    this.setState({
                                                        dataToSend: {
                                                            clubId,
                                                            subject: values.subject,
                                                            text: this.state.body,
                                                            fromEmail: `${values.fromEmail}`,
                                                            fromName: values.fromName,
                                                            replyTo: values.replyTo
                                                        },
                                                        sendNotificationModal: true
                                                    })
                                                }


                                            }}
                                        >
                                            {({ errors, status, touched, values }) => (
                                                <React.Fragment>
                                                    <Form>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="fromName"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                From Name
                                                            </Label>
                                                            <Col lg="3">
                                                                <Field
                                                                    name="fromName"
                                                                    type="text"
                                                                    value={values.fromName}
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.fromName && touched.fromName
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="fromName"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="fromEmail"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                From Email
                                                            </Label>
                                                            <Col lg="2">
                                                                <Field
                                                                    name="fromEmail"
                                                                    value={values.fromEmail}
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.fromEmail && touched.fromEmail
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="fromEmail"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                            <h6
                                                                style={{ marginLeft: -20 }}
                                                                className="col-form-label col-lg-4 ml-0"
                                                            >
                                                                @mail.groundfloorclub.com
                                                            </h6>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="replyTo"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Reply to
                                                            </Label>
                                                            <Col lg="4">
                                                                <Field
                                                                    name="replyTo"
                                                                    type="text"
                                                                    placeholder='E.g. hello@groundfloorclub.com (Optional)'
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.replyTo && touched.replyTo
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="replyTo"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="name"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Subject
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="subject"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.subject && touched.subject
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="subject"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="text"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Text
                                                            </Label>
                                                            <Col lg="10">
                                                                <span style={{ marginBottom: 10 }}>{`Compose your email body here, noting that a 'Hey firstName' will be automatically inserted.`}</span>

                                                                <Editor
                                                                    editorState={this.state.editorState}
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                    editorStyle={{
                                                                        color: 'black'
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>



                                                        <FormGroup className="mb-4" row>
                                                            <Label className="col-form-label col-lg-2">
                                                                Attach Image
                                                            </Label>
                                                            <Col lg="10">
                                                                <FileUpload
                                                                    onFileUploaded={this.onFileUploaded}
                                                                ></FileUpload>

                                                                <div
                                                                    className="dropzone-previews mt-3"
                                                                    id="file-previews"
                                                                >
                                                                    {this.state.selectedFiles.map((f, i) => {
                                                                        return (
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={i + "-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <Row className="align-items-center">
                                                                                        <Col className="col-auto">
                                                                                            <img
                                                                                                data-dz-thumbnail=""
                                                                                                height="80"
                                                                                                className="avatar-sm rounded bg-light"
                                                                                                alt={f.name}
                                                                                                src={f.preview}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="text-muted font-weight-bold"
                                                                                            >
                                                                                                {f.name}
                                                                                            </Link>
                                                                                            <p className="mb-0">
                                                                                                <strong>
                                                                                                    {f.formattedSize}
                                                                                                </strong>
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </Col>
                                                        </FormGroup>

                                                        <Row className="justify-content-end">
                                                            <Col lg="10">
                                                                <Button type="submit" color="primary">
                                                                    Send Email
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                        </Formik> : <Formik
                                            enableReinitialize={true}
                                            initialValues={
                                                {
                                                    title: "",
                                                    body: "",

                                                }
                                            }
                                            validationSchema={Yup.object().shape({
                                                title: Yup.string().required("Please Enter title").test('len', 'Max character limit is 40', val => val?.toString()?.length <= 40),
                                                body: Yup.string().required(
                                                    "Please Enter body"
                                                ).test('len', 'Max character limit is 150', val => val?.toString().length <= 150),

                                            })}
                                            onSubmit={values => {
                                                if (this.state.value?.length === 0) {
                                                    this.setState({
                                                        clubError: true
                                                    })
                                                }
                                                if (!this.state.clubError) {
                                                    let clubId
                                                    if (this.state.value[0].length > 1) {
                                                        clubId = this.state.value[0]
                                                    } else {
                                                        clubId = this.state.value.join(',')
                                                    }
                                                    this.setState({
                                                        dataToSend: {
                                                            clubId,
                                                            title: values.title,
                                                            body: values.body,
                                                        },
                                                        sendNotificationModal: true
                                                    })
                                                }

                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <React.Fragment>
                                                    <Form>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="name"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Title
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="title"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.title && touched.title
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="title"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="body"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Body
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field name="body"
                                                                    component="textarea"
                                                                    rows="6"
                                                                    type="textarea"
                                                                >
                                                                    {({ field, form }) => (
                                                                        <textarea

                                                                            className={
                                                                                "form-control" +
                                                                                (errors.body && touched.body
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                            style={{ whiteSpace: 'pre-line', height: 300, }}
                                                                            {...field}
                                                                            type="textarea"

                                                                            placeholder=""
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="body"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="justify-content-end">
                                                            <Col lg="10">
                                                                <Button type="submit" color="primary">
                                                                    Send Notifications
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                        </Formik>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

SendEmail.propTypes = {
    onSendEmail: PropTypes.func,
    onSendNotification: PropTypes.func,
    onGetClubs: PropTypes.func,
    clubs: PropTypes.array,
    history: PropTypes.shape({
        go: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ system, }) => ({
    clubs: system.clubs,
});

const mapDispatchToProps = dispatch => ({
    onGetClubs: () => dispatch(getClubs()),
    onSendEmail: (clubId, fromEmail, fromName, replyTo, subject, text, showToast) => dispatch(sendEmail(clubId, fromEmail, fromName, replyTo, subject, text, showToast)),
    onSendNotification: (clubId, title, body, showToast) => dispatch(sendNotifications(clubId, title, body, showToast)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SendEmail));