import React, { Component } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import classnames from "classnames"
import Select from "react-select";


// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import { getClubs, getNonAttendees } from "../../store/actions";
import { Link } from "react-router-dom/cjs/react-router-dom";

class InactiveMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            membersList: [],
            activeClub: null,
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            clubSelected: { label: "No Club Selected", value: '' },
            clubsOptions: [],
        };

    }

    handleClubSelect = club => {
        this.setState({
            clubSelected: club,
            activeClub: club.value,
            membersList: this.props.nonAttendees.filter(inactiveMemeber => inactiveMemeber.main_club_id === club.value)
        })
    }

    componentDidMount() {
        const { onGetNonAttendees, clubs, onGetClubs } = this.props;
        const authUser = localStorage.getItem("authUser")
        if (JSON.parse(authUser)?.clubId) {
            this.setState({
                activeClub: JSON.parse(authUser).clubId
            })
        }

        if (clubs.length === 0) {
            onGetClubs();
        } else {
            onGetNonAttendees();
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { nonAttendees, clubs, onGetNonAttendees } = this.props;
        const { activeClub } = this.state
        if (clubs !== prevProps.clubs) {
            onGetNonAttendees();
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        }
        if (nonAttendees !== prevProps.nonAttendees) {
            this.setState({
                membersList: nonAttendees.filter(item => item.main_club_id === activeClub)
            });
        }
        if (activeClub !== prevState.activeClub || nonAttendees !== prevProps.nonAttendees) {
            console.log(nonAttendees)
            if (activeClub && nonAttendees) {
                this.setState({
                    membersList: nonAttendees.filter(item => item.main_club_id === activeClub)
                });
            }
        }
    }

    handleValidDate = date => {
        const date1 = moment(date).utc().format("DD MMM Y");
        return date1;
    };

    render() {
        const { membersList, activeClub, } = this.state;
        const { nonAttendeesLoading, clubs, nonAttendees } = this.props
        const { SearchBar } = Search;
        const columns = [
            {
                dataField: "first_name",
                text: "First Name",
                sort: true,
                filterValue: (_, user) => `${user?.first_name} ${user?.last_name}`,
            },
            {
                dataField: "email",
                text: "Email",
                sort: false,
            },

        ];


        const pageOptions = {
            sizePerPage: 10,
            totalSize: membersList?.length,
            custom: true,
        };

        const tableRowEvents = {
            onClick: (e, row, rowIndex) => {
                e.target
            },
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Inactive Members | Groundfloor</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title={"Members"} breadcrumbItem={"Inactive Members"} />
                        <p>Members who have not engaged with the space for 12+ days</p>

                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        {this.state.isMobile ? <Row><Col>
                                            <Select
                                                styles={{
                                                }}
                                                onChange={
                                                    this.handleClubSelect
                                                }
                                                options={this.state.clubsOptions}
                                                value={this.state.clubSelected}
                                                classNamePrefix="select2-selection"
                                            /></Col></Row> : <div style={{
                                                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10
                                            }}>
                                            <Nav pills className="bg-light rounded" role="tablist">
                                                {clubs?.map((item, index) => (
                                                    <NavItem key={index}>
                                                        <NavLink
                                                            className={classnames({ active: activeClub === item.id })}
                                                            style={{
                                                                backgroundColor: activeClub === item.id ? '#032224' : 'transparent'
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    activeClub: item.id,
                                                                    membersList: nonAttendees.filter(inactiveMemeber => inactiveMemeber.main_club_id === item.id)
                                                                })
                                                            }}
                                                        >
                                                            {item.name}
                                                        </NavLink>
                                                    </NavItem>
                                                ))}
                                            </Nav>
                                        </div>}
                                        {membersList && (
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={columns}
                                                data={membersList}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={membersList}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row>
                                                                    <SearchBar
                                                                        {...toolkitProps.searchProps}
                                                                    // style={{ width: "400px", height: "40px" }}
                                                                    />

                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            {nonAttendeesLoading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                keyField={"email"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                rowEvents={tableRowEvents}
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                            />}
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

InactiveMembers.propTypes = {
    nonAttendees: PropTypes.array,
    clubs: PropTypes.array,
    nonAttendeesLoading: PropTypes.bool,
    onGetNonAttendees: PropTypes.func,
    onGetMemberById: PropTypes.func,
    onGetClubs: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ members, system }) => ({
    nonAttendees: members.nonAttendees,
    nonAttendeesLoading: members.nonAttendeesLoading,
    clubs: system.clubs

});

const mapDispatchToProps = dispatch => ({
    onGetMemberById: id => dispatch(getMemberById(id)),
    onGetNonAttendees: () => dispatch(getNonAttendees()),
    onGetClubs: () => dispatch(getClubs())

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(InactiveMembers));
