import { GET_ANNOUNCMENTS, GET_ANNOUNCMENTS_FAIL, GET_ANNOUNCMENTS_SUCCESS } from "./actionTypes";

const INIT_STATE = {
    error: {},
    announcments: [],
    loadingAnnouncments: false
};

const announcments = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ANNOUNCMENTS:
            return {
                ...state,
                loadingAnnouncments: true
            }
        case GET_ANNOUNCMENTS_SUCCESS:
            return {
                ...state,
                loadingAnnouncments: false,
                announcments: action.payload
            }
        case GET_ANNOUNCMENTS_FAIL:
            return {
                ...state,
                loadingAnnouncments: false,
            }
        default:
            return state;
    }
};

export default announcments;
