import moment from 'moment';
import React, { useEffect, useState } from 'react'
import QRCode from "react-qr-code";
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

function QRcodePage() {
    const [title, setTitle] = useState('')
    const [date, setDate] = useState(moment())
    const [time, setTime] = useState(moment())
    const [id, setId] = useState('')

    const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    const { params } = useRouteMatch()

    useEffect(() => {
        if (params) {
            setTitle(decodeURIComponent(params.eventTitle))
            setDate(moment(params.date).format('ddd, DD MMM Y'))
            setTime(moment(params.time, 'HH:mm:ss').format('hh:mmA'))
            setId(params.id)
        }
    }, [params])

    return (
        <div style={{
            display: 'flex',
            flex: 1,
            backgroundColor: '#032224',
            height: '100vh',
            width: '100vw',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: isMobile ? '0px 16px 0px 16px' : 0
        }}>
            <div>
                <h1 style={{ color: 'white', textAlign: 'center', marginBottom: 40 }}>CHECK-IN USING THE QR CODE</h1>
                <h1 style={{ color: 'white', textAlign: 'center' }}>{title}</h1>
                <h3 style={{ textAlign: 'center', color: 'white' }}>{`${date}, ${time}`}</h3>
            </div>
            <div style={{
                padding: 20,
                backgroundColor: 'white'
            }}>
                <QRCode value={id} size={isMobile ? 300 : 450} />
            </div>
        </div>
    )
}

export default QRcodePage