import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";

import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
    getEventAttendance,
} from "store/actions";
import Switch from "components/Common/Switch";

class EventAttendanceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventAttendance: [],
        };
    }

    componentDidMount() {
        const {
            match: { params },
            eventAttendance,
            onGetEventAttendance,
        } = this.props;
        if (params && params.id) {
            onGetEventAttendance(params.id); // me id
        }
        this.setState({
            eventAttendance
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.eventAttendance !== prevProps.eventAttendance) {
            this.setState({
                eventAttendance: this.props.eventAttendance,
            });
        }
    }

    handleValidDate = date => {
        const date1 = moment(date).utc().format("DD MMM Y");
        return date1;
    };

    render() {
        const { eventAttendance, history, loadingEventAttendance } = this.props;
        const { SearchBar } = Search;
        const role = localStorage.getItem("roleId");
        const userId = localStorage.getItem("userId");
        const eventsColumns = [
            // {
            //     dataField: "image",
            //     text: "#",
            //     // eslint-disable-next-line react/display-name
            //     formatter: (_, event) => (
            //         <>
            //             {/* <Link to={`/bookings/${event.title?.replace('/', '-')}/${event.id}`}> */}
            //             <img src={event.image} alt="" className="avatar-sm" />
            //             {/* </Link> */}
            //         </>
            //     ),
            // },
            {
                text: "Events",
                dataField: "events",
                // eslint-disable-next-line react/display-name
                filterValue: (_, event) => event?.title + event?.description,
                formatter: (_, event) => (
                    <>
                        <h5 className="font-size-14">
                            {/* <Link
                                to={`/bookings/${event.title?.replace('/', '-')}/${event.id}`}
                                className="text-dark"
                            > */}
                            {event.title}
                            {/* </Link> */}
                        </h5>
                        <p
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            className="text-muted mb-0"
                        >
                            {/* <Link
                                to={`/bookings/${event.title?.replace('/', '-')}/${event.id}`}
                                className="text-dark"
                            > */}
                            {event.description}
                            {/* </Link> */}
                        </p>
                    </>
                ),
            },
            // {
            //     dataField: "createdBy",
            //     text: "Created By",
            //     formatter: (_, event) => (
            //         <>
            //             <p className="text-muted mb-0">
            //                 {/* <Link
            //                     to={`/bookings/${event.title?.replace('/', '-')}/${event.id}`}
            //                     className="text-dark"
            //                 > */}
            //                 {event.firstName} {event.lastName}
            //                 {/* </Link> */}
            //             </p>
            //         </>
            //     ),
            // },
            {
                text: "Start Date",
                dataField: "startDate",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <>
                        {/* <Link
                            to={`/bookings/${event.title?.replace('/', '-')}/${event.id}`}
                            className="text-dark"
                        > */}
                        {this.handleValidDate(event.startDate)} from{" "}

                        {moment(event.start_time, "HH:mm:ss").format("hh:mm A")}
                        {/* to{" "} */}
                        {/* {moment(event.endTime, "HH:mm:ss").format("hh:mm A")} */}
                        {/* </Link> */}
                    </>
                ),
            },

        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: eventAttendance?.length, // replace later with size(customers),
            custom: true,
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Event Attendance List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Events" breadcrumbItem="Event Attendance List" />

                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                {eventAttendance && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={eventsColumns}
                                                        data={eventAttendance}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={eventsColumns}
                                                                data={eventAttendance}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                                style={{ marginTop: 10 }}
                                                                            />
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingEventAttendance ? (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="text-success"
                                                                                            >
                                                                                                <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                                                                Loading
                                                                                            </Link>{" "}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <BootstrapTable
                                                                                            responsive
                                                                                            bordered={false}
                                                                                            striped={false}
                                                                                            classes={
                                                                                                "table align-middle table-nowrap"
                                                                                            }
                                                                                            headerWrapperClasses={
                                                                                                "thead-light"
                                                                                            }
                                                                                            {...toolkitProps.baseProps}
                                                                                            {...paginationTableProps}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

EventAttendanceList.propTypes = {
    match: PropTypes.object,
    eventAttendance: PropTypes.array,
    className: PropTypes.any,
    onGetEventAttendance: PropTypes.func,
    loadingEventAttendance: PropTypes.bool,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ events, }) => ({
    eventAttendance: events.eventAttendance,
    loadingEventAttendance: events.loadingEventAttendance,
});

const mapDispatchToProps = dispatch => ({
    onGetEventAttendance: (userId) => dispatch(getEventAttendance(userId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EventAttendanceList));
