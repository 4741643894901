import { getClubLeaderSubClubs, getSubClubs, getSubClubsByClubIdAPI, } from "helpers";
import { call, put, takeEvery } from "redux-saga/effects";
import { getClubLeaderSubClubsSuccess, getSubClubsByClubIdFail, getSubClubsByClubIdSuccess, getSubClubsFail, getSubClubsSuccess, } from "./actions";

import { GET_CLUB_LEADER_SUB_CLUBS, GET_SUB_CLUBS, GET_SUB_CLUBS_BY_CLUB_ID, } from "./actionTypes";

export function* fetchSubClubs() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getSubClubs, token);
    yield put(getSubClubsSuccess(response.data));
  } catch (error) {
    yield put(getSubClubsFail(error));
  }
}

export function* fetchClubLeaderSubClubs() {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  try {
    const response = yield call(getClubLeaderSubClubs, token, userId);
    yield put(getClubLeaderSubClubsSuccess(response.data.response));
  } catch (error) {
    yield put(getSubClubsFail(error));
  }
}

export function* fetchSubClubsByClubId({ payload: clubId }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getSubClubsByClubIdAPI, token, clubId);
    yield put(getSubClubsByClubIdSuccess(response.data));
  } catch (error) {
    yield put(getSubClubsByClubIdFail(error));
  }
}

function* systemSaga() {
  yield takeEvery(GET_SUB_CLUBS, fetchSubClubs);
  yield takeEvery(GET_CLUB_LEADER_SUB_CLUBS, fetchClubLeaderSubClubs);
  yield takeEvery(GET_SUB_CLUBS_BY_CLUB_ID, fetchSubClubsByClubId);
}

export default systemSaga;