import { BOOK_SPACE, BOOK_SPACE_FAIL, BOOK_SPACE_SUCCESS, CANCEL_BOOKING, CANCEL_BOOKING_FAIL, CANCEL_BOOKING_SUCCESS, CANCEL_SPACE_BOOKING, CANCEL_SPACE_BOOKING_FAIL, CANCEL_SPACE_BOOKING_SUCCESS, GET_BOOKINGS, GET_BOOKINGS_FAIL, GET_BOOKINGS_LEADER, GET_BOOKINGS_LEADER_FAIL, GET_BOOKINGS_LEADER_SUCCESS, GET_BOOKINGS_SUCCESS, GET_EVENT_WAITLIST, GET_EVENT_WAITLIST_FAIL, GET_EVENT_WAITLIST_LEADER, GET_EVENT_WAITLIST_LEADER_FAIL, GET_EVENT_WAITLIST_LEADER_SUCCESS, GET_EVENT_WAITLIST_SUCCESS, GET_PUBLIC_BOOKING, GET_PUBLIC_BOOKING_FAIL, GET_PUBLIC_BOOKING_SUCCESS, GET_SPACES, GET_SPACES_FAIL, GET_SPACES_SUCCESS, GET_SPACE_BOOKINGS, GET_SPACE_BOOKINGS_FAIL, GET_SPACE_BOOKINGS_SUCCESS, GET_USER_SPACE_BOOKINGS, GET_USER_SPACE_BOOKINGS_FAIL, GET_USER_SPACE_BOOKINGS_SUCCESS, UPDATE_CHECKIN_MEMBER, UPDATE_CHECKIN_MEMBER_FAIL, UPDATE_CHECKIN_MEMBER_SUCCESS } from "./actionTypes";

export const getBookings = id => ({
  type: GET_BOOKINGS,
  payload: { id },
});

export const getBookingsSuccess = bookings => ({
  type: GET_BOOKINGS_SUCCESS,
  payload: bookings,
});

export const getBookingsFail = error => ({
  type: GET_BOOKINGS_FAIL,
  payload: error,
});

export const getBookingsLeader = id => ({
  type: GET_BOOKINGS_LEADER,
  payload: { id },
});

export const getBookingsLeaderSuccess = bookings => ({
  type: GET_BOOKINGS_LEADER_SUCCESS,
  payload: bookings,
});

export const getBookingsLeaderFail = error => ({
  type: GET_BOOKINGS_LEADER_FAIL,
  payload: error,
});

export const getEventWaitlist = id => ({
  type: GET_EVENT_WAITLIST,
  payload: { id },
});

export const getEventWaitlistSuccess = waitlist => ({
  type: GET_EVENT_WAITLIST_SUCCESS,
  payload: waitlist,
});

export const getEventWaitlistFail = error => ({
  type: GET_EVENT_WAITLIST_FAIL,
  payload: error,
});

export const getEventWaitlistLeader = id => ({
  type: GET_EVENT_WAITLIST_LEADER,
  payload: { id },
});

export const getEventWaitlistLeaderSuccess = waitlist => ({
  type: GET_EVENT_WAITLIST_LEADER_SUCCESS,
  payload: waitlist,
});

export const getEventWaitlistLeaderFail = error => ({
  type: GET_EVENT_WAITLIST_LEADER_FAIL,
  payload: error,
});


export const getPublicBookings = eventId => ({
  type: GET_PUBLIC_BOOKING,
  payload: { eventId },
});

export const getPublicBookingsSuccess = bookings => ({
  type: GET_PUBLIC_BOOKING_SUCCESS,
  payload: bookings,
});

export const getPublicBookingsFail = error => ({
  type: GET_PUBLIC_BOOKING_FAIL,
  payload: error,
});

export const getSpaceBookings = (clubId, date) => ({
  type: GET_SPACE_BOOKINGS,
  payload: { date, clubId },
});

export const getSpaceBookingsSuccess = bookings => ({
  type: GET_SPACE_BOOKINGS_SUCCESS,
  payload: bookings,
});

export const getSpaceBookingsFail = error => ({
  type: GET_SPACE_BOOKINGS_FAIL,
  payload: error,
});

export const getUserSpaceBookings = (userId) => ({
  type: GET_USER_SPACE_BOOKINGS,
  payload: userId,
});

export const getUserSpaceBookingsSuccess = bookings => ({
  type: GET_USER_SPACE_BOOKINGS_SUCCESS,
  payload: bookings,
});

export const getUserSpaceBookingsFail = error => ({
  type: GET_USER_SPACE_BOOKINGS_FAIL,
  payload: error,
});

export const cancelBooking = (bookingId, eventId, userId) => ({
  type: CANCEL_BOOKING,
  payload: { bookingId, eventId, userId },
});

export const cancelBookingSuccess = () => ({
  type: CANCEL_BOOKING_SUCCESS,
});

export const cancelBookingFail = error => ({
  type: CANCEL_BOOKING_FAIL,
  payload: error,
});

export const checkInMemberOnEvent = id => ({
  type: UPDATE_CHECKIN_MEMBER,
  payload: { id },
});

export const checkInMemberOnEventSuccess = booking => ({
  type: UPDATE_CHECKIN_MEMBER_SUCCESS,
  payload: booking,
});

export const checkInMemberOnEventFail = error => ({
  type: UPDATE_CHECKIN_MEMBER_FAIL,
  payload: error,
});

export const bookSpace = (spaceId, type, date, startTime, endTime, showSuccessToast) => ({
  type: BOOK_SPACE,
  payload: { spaceId, type, date, startTime, endTime, showSuccessToast },
});

export const bookSpaceSuccess = booking => ({
  type: BOOK_SPACE_SUCCESS,
  payload: booking,
});

export const bookSpaceFail = error => ({
  type: BOOK_SPACE_FAIL,
  payload: error,
});

export const getSpaces = (clubId) => ({
  type: GET_SPACES,
  payload: { clubId },
});

export const getSpacesSuccess = spaces => ({
  type: GET_SPACES_SUCCESS,
  payload: spaces,
});

export const getSpacesFail = error => ({
  type: GET_SPACES_FAIL,
  payload: error,
});

export const cancelSpaceBooking = bookingId => ({
  type: CANCEL_SPACE_BOOKING,
  payload: { bookingId },
});

export const cancelSpaceBookingSuccess = () => ({
  type: CANCEL_SPACE_BOOKING_SUCCESS,
});

export const cancelSpaceBookingFail = error => ({
  type: CANCEL_SPACE_BOOKING_FAIL,
  payload: error,
});