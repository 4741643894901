export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAIL = "GET_MEMBERS_FAIL";

export const GET_CANCELED_MEMBERS = "GET_CANCELED_MEMBERS";
export const GET_CANCELED_MEMBERS_SUCCESS = "GET_CANCELED_MEMBERS_SUCCESS";
export const GET_CANCELED_MEMBERS_FAIL = "GET_CANCELED_MEMBERS_FAIL";

export const GET_MEMBER_BY_ID = "GET_MEMBER_BY_ID";
export const GET_MEMBER_BY_ID_SUCCESS = "GET_MEMBER_BY_ID_SUCCESS";
export const GET_MEMBER_BY_ID_FAIL = "GET_MEMBER_BY_ID_FAIL";

export const GET_MEMBER_BY_ID_BY_SUBCLUB = "GET_MEMBER_BY_ID_BY_SUBCLUB";
export const GET_MEMBER_BY_ID_BY_SUBCLUB_SUCCESS = "GET_MEMBER_BY_ID_BY_SUBCLUB_SUCCESS";
export const GET_MEMBER_BY_ID_BY_SUBCLUB_FAIL = "GET_MEMBER_BY_ID_BY_SUBCLUB_FAIL";

export const GET_USER_SUBSCRIPTION_BY_ID = "GET_USER_SUBSCRIPTION_BY_ID";
export const GET_USER_SUBSCRIPTION_BY_ID_SUCCESS = "GET_USER_SUBSCRIPTION_BY_ID_SUCCESS";
export const GET_USER_SUBSCRIPTION_BY_ID_FAIL = "GET_USER_SUBSCRIPTION_BY_ID_FAIL";

export const RESET_BILLING_DATE = 'RESET_BILLING_DATE'

export const ADD_NOTE_MEMBER = "ADD_NOTE_MEMBER";
export const ADD_NOTE_MEMBER_SUCCESS = "ADD_NOTE_MEMBER_SUCCESS";
export const ADD_NOTE_MEMBER_FAIL = "ADD_NOTE_MEMBER_FAIL";

export const UPDATE_NOTE_MEMBER = "UPDATE_NOTE_MEMBER";
export const UPDATE_NOTE_MEMBER_SUCCESS = "UPDATE_NOTE_MEMBER_SUCCESS";
export const UPDATE_NOTE_MEMBER_FAIL = "UPDATE_NOTE_MEMBER_FAIL";

export const DELETE_NOTE_MEMBER = "DELETE_NOTE_MEMBER";
export const DELETE_NOTE_MEMBER_SUCCESS = "DELETE_NOTE_MEMBER_SUCCESS";
export const DELETE_NOTE_MEMBER_FAIL = "DELETE_NOTE_MEMBER_FAIL";

export const CANCEL_MEMBER = "CANCEL_MEMBER";
export const CANCEL_MEMBER_SUCCESS = "CANCEL_MEMBER_SUCCESS";
export const CANCEL_MEMBER_FAIL = "CANCEL_MEMBER_FAIL";

export const SET_ONBOARDING_SESSION = "SET_ONBOARDING_SESSION";
export const SET_ONBOARDING_SESSION_SUCCESS = "SET_ONBOARDING_SESSION_SUCCESS";
export const SET_ONBOARDING_SESSION_FAIL = "SET_ONBOARDING_SESSION_FAIL";

export const SET_FIRST_CHECKIN = "SET_FIRST_CHECKIN";
export const SET_FIRST_CHECKIN_SUCCESS = "SET_FIRST_CHECKIN_SUCCESS";
export const SET_FIRST_CHECKIN_FAIL = "SET_FIRST_CHECKIN_FAIL";

export const GET_ONBOARDING_MEMBERS = "GET_ONBOARDING_MEMBERS";
export const GET_ONBOARDING_MEMBERS_SUCCESS = "GET_ONBOARDING_MEMBERS_SUCCESS";
export const GET_ONBOARDING_MEMBERS_FAIL = "GET_ONBOARDING_MEMBERS_FAIL";

export const GET_NON_ATTENDEES = "GET_NON_ATTENDEES";
export const GET_NON_ATTENDEES_SUCCESS = "GET_NON_ATTENDEES_SUCCESS";
export const GET_NON_ATTENDEES_FAIL = "GET_NON_ATTENDEES_FAIL";

export const GET_DOOR_ENTRIES = 'GET_DOOR_ENTRIES'
export const GET_DOOR_ENTRIES_SUCCESS = 'GET_DOOR_ENTRIES_SUCCESS'
export const GET_DOOR_ENTRIES_FAIL = 'GET_DOOR_ENTRIES_FAIL'

export const ONBOARDING_SCHEDULED = 'ONBOARDING_SCHEDULED'
export const ONBOARDING_SCHEDULED_SUCCESS = 'ONBOARDING_SCHEDULED_SUCCESS'
export const ONBOARDING_SCHEDULED_FAIL = 'ONBOARDING_SCHEDULED_FAIL'

export const DOORDECK_ENTRIES_MEMBER = 'DOORDECK_ENTRIES_MEMBER'
export const DOORDECK_ENTRIES_MEMBER_SUCCESS = 'DOORDECK_ENTRIES_MEMBER_SUCCESS'
export const DOORDECK_ENTRIES_MEMBER_FAIL = 'DOORDECK_ENTRIES_MEMBER_FAIL'

export const GET_ONBOARDING_BOOKINGS = 'GET_ONBOARDING_BOOKINGS'
export const GET_ONBOARDING_BOOKINGS_SUCCESS = 'GET_ONBOARDING_BOOKINGS_SUCCESS'
export const GET_ONBOARDING_BOOKINGS_FAIL = 'GET_ONBOARDING_BOOKINGS_FAIL'

export const UPDATE_ONBOARDING_BOOKING = 'UPDATE_ONBOARDING_BOOKING'
export const UPDATE_ONBOARDING_BOOKING_SUCCESS = 'UPDATE_ONBOARDING_BOOKING_SUCCESS'
export const UPDATE_ONBOARDING_BOOKING_FAIL = 'UPDATE_ONBOARDING_BOOKING_FAIL'

export const CANCEL_ONBOARDING_BOOKING = 'CANCEL_ONBOARDING_BOOKING'
export const CANCEL_ONBOARDING_BOOKING_SUCCESS = 'CANCEL_ONBOARDING_BOOKING_SUCCESS'
export const CANCEL_ONBOARDING_BOOKING_FAIL = 'CANCEL_ONBOARDING_BOOKING_FAIL'

export const GET_HIGH_RISK_MEMBERS = 'GET_HIGH_RISK_MEMBERS'
export const GET_HIGH_RISK_MEMBERS_SUCCESS = 'GET_HIGH_RISK_MEMBERS_SUCCESS'
export const GET_HIGH_RISK_MEMBERS_FAIL = 'GET_HIGH_RISK_MEMBERS_FAIL'

export const GET_MEMBER_NOTES = 'GET_MEMBER_NOTES'
export const GET_MEMBER_NOTES_SUCCESS = 'GET_MEMBER_NOTES_SUCCESS'
export const GET_MEMBER_NOTES_FAIL = 'GET_MEMBER_NOTES_FAIL'

export const GET_USER_PAUSES = 'GET_USER_PAUSES'
export const GET_USER_PAUSES_SUCCESS = 'GET_USER_PAUSES_SUCCESS'
export const GET_USER_PAUSES_FAIL = 'GET_USER_PAUSES_FAIL'

export const GET_MEMBER_BY_EMAIL = "GET_MEMBER_BY_EMAIL";
export const GET_MEMBER_BY_EMAIL_SUCCESS = "GET_MEMBER_BY_EMAIL_SUCCESS";
export const GET_MEMBER_BY_EMAIL_FAIL = "GET_MEMBER_BY_EMAIL_FAIL";

export const GET_NEW_MEMBERS = "GET_NEW_MEMBERS";
export const GET_NEW_MEMBERS_SUCCESS = "GET_NEW_MEMBERS_SUCCESS";
export const GET_NEW_MEMBERS_FAIL = "GET_NEW_MEMBERS_FAIL";

export const GET_ALL_COUPONS = "GET_ALL_COUPONS";
export const GET_ALL_COUPONS_SUCCESS = "GET_ALL_COUPONS_SUCCESS";
export const GET_ALL_COUPONS_FAIL = "GET_ALL_COUPONS_FAIL";

export const APPLY_COUPON = "APPLY_COUPON";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAIL = "APPLY_COUPON_FAIL";

export const GET_APPLIED_COUPONS = "GET_APPLIED_COUPONS";
export const GET_APPLIED_COUPONS_SUCCESS = "GET_APPLIED_COUPONS_SUCCESS";
export const GET_APPLIED_COUPONS_FAIL = "GET_APPLIED_COUPONS_FAIL";

export const ADD_APPLICANT = "ADD_APPLICANT";
export const ADD_APPLICANT_SUCCESS = "ADD_APPLICANT_SUCCESS";
export const ADD_APPLICANT_FAIL = "ADD_APPLICANT_FAIL";

export const GET_APPLICANTS = "GET_APPLICANTS";
export const GET_APPLICANTS_SUCCESS = "GET_APPLICANTS_SUCCESS";
export const GET_APPLICANTS_FAIL = "GET_APPLICANTS_FAIL";

export const GET_CLUB_LEADER_MEMBERS = "GET_CLUB_LEADER_MEMBERS";
export const GET_CLUB_LEADER_MEMBERS_SUCCESS = "GET_CLUB_LEADER_MEMBERS_SUCCESS";
export const GET_CLUB_LEADER_MEMBERS_FAIL = "GET__FAIL";

export const SEND_EMAIL_CLUB_LEADER = 'SEND_EMAIL_CLUB_LEADER'

export const UPDATE_MEMBER_AT_RISK = "UPDATE_MEMBER_AT_RISK";
export const UPDATE_MEMBER_AT_RISK_SUCCESS = "UPDATE_MEMBER_AT_RISK_SUCCESS";
export const UPDATE_MEMBER_AT_RISK_FAIL = "UPDATE_MEMBER_AT_RISK_FAIL";
