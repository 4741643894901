import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  AUTO_LOGIN,
  AUTO_LOGIN_SUCCESS,
  USER_LOG_OUT_CHECK,
  CHECK_TOKEN_VALID,
  FORGET_PASSWORD,
} from "./actionTypes";

export const forgotPassword = (email, afterEffect) => {
  return {
    type: FORGET_PASSWORD,
    payload: { email, afterEffect },
  };
};


export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user, token) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { user, token },
  };
};

export const autoLogin = () => {
  return {
    type: AUTO_LOGIN,
  };
};

export const autoLoginSuccess = (user, token) => {
  return {
    type: AUTO_LOGIN_SUCCESS,
    payload: { user, token },
  };
};

export const checkLoggedOut = token => {
  return {
    type: USER_LOG_OUT_CHECK,
    payload: { token },
  };
};

export const checkValidToken = token => {
  return {
    type: CHECK_TOKEN_VALID,
    payload: { token },
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};
