import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import FileUpload from "../../components/FileUpload/FileUpload";
import classnames from "classnames";
import Select from "react-select"


import {
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
    Button,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
    setImage,
    getClubs,
    updateProduct,
    deleteProduct,
    getProducts,
    getAllChildrenCategories,
} from "store/actions";

class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            product: "",
            deleteModal: false,
            modal: false,
            selectedFiles: [],
            dropdownClubOpen: false,
            clubId: null,
            club: "No Club Selected",
            clubError: false,
            dropdownOpen: false,
            activeClub: null,
            productTypes: [],
            productType: '0',
            productLabel: 'No Type Selected',
            availableSizes: [],
            availableSizesFiltered: [],
            availableSizesError: false,
            vendor: [],
            vendorFiltered: [],
            typeError: false,
            hidden: false,
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            clubSelected: { label: "No Club Selected", value: '' },
            clubsOptions: [],
        };

        this.handleProductClick = this.handleProductClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.toggleClubs = this.toggleClubs.bind(this);
        this.toggleProductType = this.toggleProductType.bind(this);
        this.handleAvailableSizesSelect = this.handleAvailableSizesSelect.bind(this)
        this.handleVendorSelect = this.handleVendorSelect.bind(this)

    }

    handleClubSelect = club => {
        this.setState({
            clubSelected: club,
            activeClub: club.value
        })
    }

    toggleClubs() {
        this.setState(prevState => ({
            dropdownClubOpen: !prevState.dropdownClubOpen,
        }));
    }

    toggleProductType() {
        this.setState(prevState => ({
            dropdownProductTypeOpen: !prevState.dropdownProductTypeOpen,
        }));
    }

    handleAvailableSizesSelect = availableSizes => {
        this.setState({ availableSizes, availableSizesError: false })
    }

    productTypeItemSelected(name, id) {
        this.setState({
            productType: id,
            productLabel: name,
            typeError: false
        });
    }

    clubItemSelected(name, id) {
        this.setState({
            club: name,
            clubId: id,
            clubError: false,
        });
    }

    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    componentDidMount() {
        const {
            products,
            onGetProducts,
            onGetClubs,
            onGetAllChildrenCategories,
            clubs
        } = this.props;
        const authUser = localStorage.getItem("authUser")
        if (JSON.parse(authUser)?.clubId) {
            this.setState({
                activeClub: JSON.parse(authUser).clubId
            })
        }
        if (clubs.length !== 0) {
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        } else {
            onGetClubs();
        }
        onGetAllChildrenCategories()
        this.setState({
            products,
            availableSizesFiltered: [
                {
                    label: 'Available Sizes',
                    options: [
                        { label: 'S', value: 'S' },
                        { label: 'M', value: 'M' },
                        { label: 'L', value: 'L' },
                        { label: 'XL', value: 'XL' },
                        { label: 'XXL', value: 'XXL' },
                        { label: 'XXXL', value: 'XXXL' },
                    ]
                }
            ],
            vendorFiltered: [
                {
                    label: 'Vendors',
                    options: [
                        { label: 'Obet&Dels', value: 'Obet&Dels' },
                        { label: 'Coaster Cookies', value: 'Coaster Cookies' },
                    ]
                }
            ]
        });
    }

    handleVendorSelect = vendor => {
        this.setState({ vendor })
    }

    filterCategories = (id) => {
        const { childrenCategories } = this.props;
        return childrenCategories
            ?.filter(item => item.system_category_id === id)
            .map(item => ({ label: item.name, value: item.id }));
    }

    componentDidUpdate(prevProps, prevState) {
        const { childrenCategories, onGetProducts } = this.props
        const { activeClub } = this.state;
        if (this.props.clubs !== prevProps.clubs) {
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        }
        if (activeClub !== prevState.activeClub) {
            if (activeClub) {
                onGetProducts(activeClub);
            }
        }
        if (this.props.products !== prevProps.products) {
            this.setState({
                products: this.props.products,
            });
        }
        if (childrenCategories !== prevProps.childrenCategories) {
            this.setState({
                productTypes: [...this.filterCategories("15"), { label: 'Wellness', value: 1000 }]
            })
        }
    }

    onClickDelete = product => {
        this.setState({ product });
        this.setState({ deleteModal: true });
    };

    handleDeleteProduct = () => {
        const { onDeleteProduct, onGetProducts } = this.props;
        const { product, activeClub } = this.state;
        if (product.id !== undefined) {
            onDeleteProduct(product.id);
            this.setState({ deleteModal: false });
        }
        setTimeout(() => {
            onGetProducts(activeClub)
        }, 500);
    };

    handleHiddenCheckbox = () => {
        this.setState({
            hidden: !this.state.hidden,
        });
    };

    handleProductClick = arg => {
        const product = arg;
        this.props.onSetImage(product.image);
        if (product.vendor) {
            this.setState({
                vendor: { label: product.vendor, value: product.vendor }
            })
        }
        this.setState({
            product,
            isEdit: true,
            clubId: product.club_id,
            club: this.props.clubs.find(item => item.id === product.club_id)?.name,
            productTypes: [...this.filterCategories('15'), { label: 'Wellness', value: 1000 }],
            productType: product.type_id,
            productLabel: [...this.filterCategories('15'), { label: 'Wellness', value: 1000 }].find(item => item.value === product.type_id).label,
            typeError: false,
            hidden: product.hidden,
            availableSizes: product?.size?.split(',').map(size => ({ label: size, value: size }))
        });
        this.toggle();
    };

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    handleValidDate = date => {
        const date1 = moment(date).utc().format("DD MMM Y");
        return date1;
    };

    render() {
        const { products, clubs, onUpdateProduct, loadingProducts, onGetProducts } = this.props;
        const { deleteModal, activeClub, product, productType, availableSizesFiltered, availableSizes, vendor, vendorFiltered } = this.state;
        const { SearchBar } = Search;
        const role = localStorage.getItem("roleId");
        const productsColumns = [
            {
                dataField: "image",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, product) => (
                    <>
                        <Link>
                            <img src={product.image?.split(',')[0]} alt="" className="avatar-sm" />
                        </Link>
                    </>
                ),
            },
            {
                text: "Product",
                dataField: "title",
                // eslint-disable-next-line react/display-name
                filterValue: (_, product) => product?.title + product?.description,
                formatter: (_, product) => (
                    <>
                        <h5 className="font-size-14">
                            <Link
                                className="text-dark"
                            >
                                {product.title}
                            </Link>
                        </h5>
                        <p
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            className="text-muted mb-0"
                        >
                            <Link
                                className="text-dark"
                            >
                                {product.description}
                            </Link>
                        </p>
                    </>
                ),
            },
            {
                dataField: "hidden",
                text: "In Store",
                formatter: (_, product) => (
                    <>
                        <p className="text-muted mb-0">
                            <Link
                                className="text-dark"
                            >
                                {product.hidden === 0 ? 'Yes' : 'No'}
                            </Link>
                        </p>
                    </>
                ),
            },
            {
                dataField: "price",
                text: "Price",
                formatter: (_, product) => (
                    <>
                        <p className="text-muted mb-0">
                            <Link
                                className="text-dark"
                            >
                                {product.price}$
                            </Link>
                        </p>
                    </>
                ),
            },
            {
                text: "Action",
                dataField: "",
                formatter: (_, product) => (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle className="card-drop" tag="a">
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                    onClick={() => this.handleProductClick(product)}
                                >
                                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                    Edit
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => this.onClickDelete(product)}
                                >
                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                ),
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: products?.length, // replace later with size(customers),
            custom: true,
        };

        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteProduct}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Products List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Products" breadcrumbItem="Products List" />
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                {this.state.isMobile ? <Row>
                                                    <Col>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <Link to="/add-product">
                                                                <Button
                                                                    style={{ backgroundColor: "#032224" }}
                                                                    className="font-16 btn-block btn btn-primary"

                                                                >
                                                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                                                    Add Product
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                        <Select
                                                            styles={{
                                                            }}
                                                            onChange={
                                                                this.handleClubSelect
                                                            }
                                                            options={this.state.clubsOptions}
                                                            value={this.state.clubSelected}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </Col>
                                                </Row> : <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Nav
                                                        pills
                                                        className="bg-light rounded"
                                                        role="tablist"
                                                    >
                                                        <NavItem>
                                                        </NavItem>
                                                        {clubs?.map((item, index) => (
                                                            <NavItem key={index}>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeClub === item.id,
                                                                    })}
                                                                    style={{
                                                                        backgroundColor:
                                                                            activeClub === item.id
                                                                                ? "#032224"
                                                                                : "transparent",
                                                                    }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            activeClub: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                    </Nav>
                                                    <div className="text-sm-end">
                                                        <Link to="/add-product">
                                                            <Button
                                                                // color="#032224"
                                                                style={{ backgroundColor: "#032224" }}
                                                                className="font-16 btn-block btn btn-primary"

                                                            >
                                                                <i className="mdi mdi-plus-circle-outline me-1" />
                                                                Add Product
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </div>}
                                                {products && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={productsColumns}
                                                        data={products}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={productsColumns}
                                                                data={products}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                                style={{ marginTop: 10 }}
                                                                            />
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingProducts ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={"thead-light"}
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                        <Modal
                                            size="xl"
                                            isOpen={this.state.modal}
                                            className={this.props.className}
                                        >
                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                {"Edit Product"}
                                            </ModalHeader>
                                            <ModalBody>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{
                                                        id: (product && product.id) || "",
                                                        title: (product && product.title) || "",
                                                        description: (product && product.description) || "",
                                                        clubId: (product && product.clubId) || "",
                                                        club:
                                                            product && this.props.clubs
                                                                ? this.props.clubs.find(
                                                                    item => item.id === product.clubId
                                                                )?.name
                                                                : "",
                                                        price: product && product.price,
                                                        type_id: product && product.type_id

                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        title: Yup.string().required("Please Enter Title"),
                                                        price: Yup.number().min(0, 'Can not be lower than 0!').required("Please Enter Price"),
                                                    })}
                                                    onSubmit={values => {
                                                        if (this.state.clubId === null) {
                                                            this.setState({
                                                                clubError: true,
                                                            });
                                                        } else if (this.state.productType === '0') {
                                                            this.setState({
                                                                typeError: true
                                                            })
                                                        } else if (productType === 488 && availableSizes.length === 0) {
                                                            this.setState({
                                                                availableSizesError: true
                                                            })
                                                        } else {
                                                            const updateProduct = {
                                                                title: values.title,
                                                                description: values.description,
                                                                price: values.price,
                                                                clubId: parseInt(this.state.clubId),
                                                                hidden: this.state.hidden ? 1 : 0,
                                                                id: product.id,
                                                                image: this.state?.selectedFiles[0],
                                                                typeId: this.state.productType,
                                                                size: this.state.availableSizes ? this.state.availableSizes?.map(item => item.label)?.join(',') : '',
                                                                vendor: vendor.length === 0 ? null : vendor.value
                                                            };
                                                            onUpdateProduct(updateProduct, this.state.clubId);
                                                            this.toggle();
                                                            setTimeout(() => {
                                                                onGetProducts(activeClub)
                                                            }, 500);
                                                        }
                                                    }}
                                                >
                                                    {({ errors, status, touched }) => (
                                                        <Form>
                                                            <Row>
                                                                <Col className="col-12">
                                                                    <div className="mb-3">
                                                                        <Field name="img" type="hidden" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Field name="team" type="hidden" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Title</Label>
                                                                        <Field
                                                                            name="title"
                                                                            type="text"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.title && touched.title
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="title"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Description
                                                                        </Label>
                                                                        <Field
                                                                            name="description"
                                                                            type="text"
                                                                            component="textarea"
                                                                            rows="6"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.description &&
                                                                                    touched.description
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <Label
                                                                            htmlFor="price"
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Price
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Field
                                                                                name="price"
                                                                                type="number"
                                                                                className={
                                                                                    "form-control" +
                                                                                    (errors.price && touched.price
                                                                                        ? " is-invalid"
                                                                                        : "")
                                                                                }
                                                                            />
                                                                            <ErrorMessage
                                                                                name="price"
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </Col>
                                                                    </div>
                                                                    <Row className="mb-4">
                                                                        <Label
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Hide Product
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <div className="form-check">
                                                                                <input

                                                                                    checked={this.state.hidden}
                                                                                    onChange={() =>
                                                                                        this.handleHiddenCheckbox()
                                                                                    }
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                />
                                                                            </div>
                                                                            <ErrorMessage
                                                                                name="hidden"
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-4">

                                                                        <Label
                                                                            htmlFor="clubs"
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Clubs
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Dropdown
                                                                                isOpen={this.state.dropdownClubOpen}
                                                                                toggle={this.toggleClubs}
                                                                            >
                                                                                <DropdownToggle caret>
                                                                                    {this.state.club}
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    {this.props.clubs?.map((club, index) => {
                                                                                        return (
                                                                                            <DropdownItem
                                                                                                onClick={() =>
                                                                                                    this.clubItemSelected(
                                                                                                        club.name,
                                                                                                        club.id
                                                                                                    )
                                                                                                }
                                                                                                key={index}
                                                                                            >
                                                                                                {club.name}
                                                                                            </DropdownItem>
                                                                                        );
                                                                                    })}
                                                                                </DropdownMenu>
                                                                            </Dropdown>
                                                                            {this.state.clubError && (
                                                                                <p
                                                                                    style={{
                                                                                        color: "red",
                                                                                    }}
                                                                                >
                                                                                    Please Select a Club
                                                                                </p>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-4">
                                                                        <Label
                                                                            htmlFor="instructor"
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Select Product Type
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Dropdown
                                                                                isOpen={this.state.dropdownProductTypeOpen}
                                                                                toggle={this.toggleProductType}
                                                                            >
                                                                                <DropdownToggle caret>
                                                                                    {this.state.productLabel}
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    {this.state.productTypes.map(
                                                                                        (item, index) => {
                                                                                            return (
                                                                                                <DropdownItem
                                                                                                    onClick={() =>
                                                                                                        this.productTypeItemSelected(item.label, item.value)
                                                                                                    }
                                                                                                    key={index}
                                                                                                >
                                                                                                    {item.label}
                                                                                                </DropdownItem>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </DropdownMenu>
                                                                            </Dropdown>
                                                                        </Col>
                                                                        {this.state.typeError && (
                                                                            <p
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                Please Select a type
                                                                            </p>
                                                                        )}
                                                                    </Row>
                                                                    {productType === 488 && <Row className="mb-4">
                                                                        <Label
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Available Sizes
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Select
                                                                                value={availableSizes}
                                                                                isMulti={true}
                                                                                onChange={this.handleAvailableSizesSelect}
                                                                                options={availableSizesFiltered}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                            {this.state.availableSizesError && (
                                                                                <p
                                                                                    style={{
                                                                                        color: "red",
                                                                                    }}
                                                                                >
                                                                                    Please Select Available Sizes
                                                                                </p>
                                                                            )}
                                                                        </Col>
                                                                    </Row>}
                                                                    <Row className="mb-4">
                                                                        <Label
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Vendor
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <span>This field is optional.</span>

                                                                            <Select
                                                                                value={vendor}
                                                                                onChange={this.handleVendorSelect}
                                                                                options={vendorFiltered}
                                                                                classNamePrefix="select2-selection"
                                                                            />

                                                                        </Col>
                                                                    </Row>
                                                                    {this.state.product.image && <Row className="mb-4">
                                                                        <Label className="col-form-label col-lg-2">
                                                                            Image
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <div
                                                                                className="dropzone-previews mt-3 d-flex mr-1"
                                                                                id="file-previews"
                                                                            >
                                                                                {this.state.product.image?.split(',').map(image => {
                                                                                    return (<Card
                                                                                        key={image}
                                                                                        className="mt-1 mb-0 shadow-none "
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="100"
                                                                                                        className="rounded bg-light"
                                                                                                        src={image}
                                                                                                    />
                                                                                                </Col>

                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>)
                                                                                }

                                                                                )}

                                                                            </div>
                                                                        </Col>
                                                                    </Row>}

                                                                    <FormGroup className="mb-3" row>
                                                                        <Label className="col-form-label col-lg-2">
                                                                            Attach Image
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <FileUpload
                                                                                maxFiles={productType === 488 ? 4 : 1}
                                                                                onFileUploaded={this.onFileUploaded}
                                                                            ></FileUpload>

                                                                            <div
                                                                                className="dropzone-previews mt-3"
                                                                                id="file-previews"
                                                                            >
                                                                                {this.state.selectedFiles.map(
                                                                                    (f, i) => {
                                                                                        return (
                                                                                            <Card
                                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                                key={i + "-file"}
                                                                                            >
                                                                                                <div className="p-2">
                                                                                                    <Row className="align-items-center">
                                                                                                        <Col className="col-auto">
                                                                                                            <img
                                                                                                                data-dz-thumbnail=""
                                                                                                                height="80"
                                                                                                                className="avatar-sm rounded bg-light"
                                                                                                                alt={f.name}
                                                                                                                src={f.preview}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Link
                                                                                                                to="#"
                                                                                                                className="text-muted font-weight-bold"
                                                                                                            >
                                                                                                                {f.name}
                                                                                                            </Link>
                                                                                                            <p className="mb-0">
                                                                                                                <strong>
                                                                                                                    {f.formattedSize}
                                                                                                                </strong>
                                                                                                            </p>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            </Card>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>

                                                                    <div className="text-end">
                                                                        {(() => {
                                                                            if (role == 1 || role == 666) {
                                                                                return (
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-success save-user"
                                                                                    >
                                                                                        Save
                                                                                    </button>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ProductList.propTypes = {
    products: PropTypes.array,
    className: PropTypes.any,
    onUpdateProduct: PropTypes.func,
    onDeleteProduct: PropTypes.func,
    onSetImage: PropTypes.func,
    onGetProducts: PropTypes.func,
    onGetClubs: PropTypes.func,
    loadingProducts: PropTypes.bool,
    clubs: PropTypes.array,
    onGetAllChildrenCategories: PropTypes.func,
    childrenCategories: PropTypes.array,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ products, system, members }) => ({
    products: products.products,
    loadingProducts: products.loadingProducts,
    clubs: system.clubs,
    childrenCategories: system.childrenCategories,

});

const mapDispatchToProps = dispatch => ({
    onGetClubs: () => dispatch(getClubs()),
    onUpdateProduct: (product) => dispatch(updateProduct(product)),
    onDeleteProduct: (productId) => dispatch(deleteProduct(productId)),
    onGetProducts: clubId => dispatch(getProducts(clubId)),
    onSetImage: image => dispatch(setImage(image)),
    onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ProductList));
