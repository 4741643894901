import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import FileUpload from "../../components/FileUpload/FileUpload";
import Select from "react-select";
import Switch from "components/Common/Switch";



import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Card,
  CardBody,
  Button,
  UncontrolledDropdown,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import { map } from "lodash";

import {
  cancelEvent,
  cloneEvent,
  getAllChildrenCategories,
  setImage,
  updateInstructorEvent,
} from "../../store/actions";
import { deleteEvent, updateEvent, getEventsByInstructor, getEventInstructors } from "store/actions";
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";


class EventListInstructor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructors: [],
      events: [],
      event: "",
      deleteModal: false,
      modal: false,
      selectedFiles: [],
      guestInvitesAllowed: 0,
      requiresPayment: false,
      price: 0,
      dropdownOpen: false,
      cancelEventModal: false,
      showBookClosingTimes: false,
      interestsSelected: [],
      interestsFiltered: [],
      interestsError: false,
      bookingConfirmation: { label: "", value: "" },
      locationSelected: { label: "Select Location", value: '' },
      location: '',
      eventImage: null,
      host: "",
      salesEndBeforeEvent: { label: "2 hours before the event", value: 2 },
    };

    this.handleEventClick = this.handleEventClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onClickCancelEvent = this.onClickCancelEvent.bind(this);
    this.handleEventClicks = this.handleEventClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleCategory = this.toggleCategory.bind(this);
    this.toggleInstructor = this.toggleInstructor.bind(this);
    this.handleMulti = this.handleMulti.bind(this);
    this.onQrDownload = this.onQrDownload.bind(this);


  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.token !== this.props.token) {
      this.props.onGetEventsByInstructor();
    }
  };

  toggleCategory() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleBookingConfirmationSelect = bookingConfirmation => {
    this.setState({ bookingConfirmation });
  };

  onClickCancelEvent = event => {
    this.setState({ event: event });
    this.setState({ cancelEventModal: true });
  };

  toggleInstructor() {
    this.setState(prevState => ({
      dropdownInstructorOpen: !prevState.dropdownInstructorOpen,
    }));
  }

  filterCategories = id => {
    const { childrenCategories } = this.props;
    return childrenCategories
      ?.filter(item => item.system_category_id === id)
      .map(item => ({ label: item.name, value: item.name }));
  };

  handleMulti = interestsSelected => {
    this.setState({ interestsSelected, interestsError: false });
  };

  categoryItemSelected(value) {
    this.setState({
      category: value,
    });
  }

  instructorItemSelected(name, id) {
    this.setState({
      instructorName: name,
      instructorId: id,
    });
  }

  onFileUploaded(file) {
    this.setState({ eventImage: file.url });
  }

  componentDidMount() {
    const { onGetEventsByInstructor, onGetEventInstructors, onEventClone, onGetAllChildrenCategories } = this.props;
    onGetEventsByInstructor();
    onGetEventInstructors();
    onGetAllChildrenCategories();
    onEventClone(null);
  }
  componentDidUpdate(prevProps) {
    if (this.props.instructors !== prevProps.instructors) {
      this.setState({ instructors: this.props.instructors });
    }
    if (this.props.childrenCategories !== prevProps.childrenCategories) {
      this.setState({
        interestsFiltered: [
          {
            label: "Interests",
            options: [...this.filterCategories("2"), ...this.filterCategories("11")],
          },
        ],
      });
    }
  }


  handleEventClicks = arg => {
    this.setState({ selectedEvent: arg });
    this.toggle();
  };

  //Delete event list
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = event => {
    this.setState({ event: event });
    this.setState({ deleteModal: true });
  };

  handleDeleteEvent = () => {
    const { onDeleteEvent } = this.props;
    const { event } = this.state;
    if (event.id !== undefined) {
      onDeleteEvent(event?.id);
      this.setState({ deleteModal: false });
    }
  };

  handleLocationSelect = location => {
    if (location.value === 'Other') {
      this.setState({
        locationSelected: location,
        location: ''
      })
    } else {
      this.setState({
        location: location.value,
        locationSelected: location,
        locationError: false
      })
    }
  }

  handleSalesEndBeforeEventSelect = salesEndBeforeEvent => {
    this.setState({ salesEndBeforeEvent });
  };


  handleCancelEvent = () => {
    const { onCancelEvent } = this.props;
    const { event } = this.state;
    if (event.id !== undefined) {
      onCancelEvent(event?.id);
      this.setState({ cancelEventModal: false });
    }
  };

  handleEventClick = arg => {
    const event = arg;
    this.props.onSetImage(event.image);

    const startDate = moment.utc(event.startDate).format('YYYY-MM-DD')

    let difference = moment(`${startDate} ${event.startTime}`, 'YYYY-MM-DD HH:mm:ss').diff(moment(moment.utc(event.salesEnd).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss'), 'hours', true)

    let salesEndBeforeEvent = { label: `${difference} hours before the event`, value: difference }

    if (![0, 2, 12, 24, 48].includes(difference)) {
      salesEndBeforeEvent = { label: "12 hours before the event", value: 12 }
    } else if (difference === 0) {
      salesEndBeforeEvent = { label: "2 hours before the event", value: 2 }
    }

    this.setState({
      event,
      isEdit: true,
      guestInvitesAllowed: event.guestInvitesAllowed,
      requiresPayment: event.requiresPayment === 1,
      price: event.price,
      showBookClosingTimes: difference !== 0,
      interestsSelected: event.interests?.split(';')?.map(item => ({ label: item, value: item })),
      bookingConfirmation: { label: event.bookingConfirmation, value: event.bookingConfirmation },
      locationSelected: { label: event.location, value: event.location },
      location: event.location,
      eventImage: event.image,
      host: event.host,
      salesEndBeforeEvent,
      category:
        event.category.charAt(0).toUpperCase() + event.category.slice(1),
    });

    this.toggle();
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleValidDate = date => {
    const date1 = moment(date, "YYYY-MM-DD").format("DD MMM Y");
    return date1;
  };
  handleValidTime = time => {
    const date1 = moment(new Date(time)).format("HH mm ss");
  };

  handleRquirePayment = () => {
    this.setState({
      requiresPayment: !this.state.requiresPayment,
    });
  };

  handleShowBookClosingTimes = () => {
    this.setState({
      showBookClosingTimes: !this.state.showBookClosingTimes,
    });
  };

  viewEditFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => this.handleEventClick(row)}
        color="info"
      >
        View/Edit
      </Button>
    );
  };

  onQrDownload = event => {
    const title = encodeURIComponent(event.title)
    window.open(`https://dashboard.groundfloorapp.com/qr/${event.id}/${title}/${event.startDate}/${event.startTime}`, '_blank', 'noopener noreferrer');
  };

  viewBookingsFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => this.props.history.push(`/bookings/${row.title?.replace(/[?/]/g, '-')}/${row.id}`)}
        color="info"
      >
        Bookings
      </Button>
    );
  };

  render() {
    const { onEventClone, history } = this.props;
    const { event, interestsSelected, interestsFiltered } = this.state;

    const bookingConfirmationSelect = [
      { label: "No link", value: "No link" },
      { label: "Learn more", value: "Learn more" },
      { label: "Purchase tickets", value: "Purchase tickets" },
      { label: "Book a time slot", value: "Book a time slot" },
    ];

    const columns = [
      {
        dataField: "image",
        text: "Title",
        formatter: (value, event) => {
          return (
            <img
              src={event.image}
              alt=""
              className="avatar-sm"
            />
          )
        },
      },
      {
        dataField: "title",
        text: "Title",
      },
      {
        text: "View/Edit",
        dataField: "",
        formatter: this.viewEditFormater
      },
      {
        text: "Bookings",
        dataField: "",
        formatter: this.viewBookingsFormater
      },
      {
        text: "Action",
        dataField: "",
        formatter: (_, event) => (
          <>
            <UncontrolledDropdown>
              <DropdownToggle className="card-drop" tag="a">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => this.onQrDownload(event)}
                  value={this.state.qrCodeText}
                >
                  <i className="mdi mdi-qrcode font-size-16 text-danger me-1" />{" "}
                  View Event QR
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    onEventClone(event);
                    history.push("/instructor-event-create");
                  }}
                >
                  <i className="mdi mdi-clipboard-file font-size-16 text-danger me-1" />{" "}
                  Clone Event
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => this.onClickCancelEvent(event)}
                >
                  <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                  Cancel Event
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ),
      },
      {
        dataField: "startDate",
        text: "Date",
        sort: false,
        formatter: (value, event) => {
          return (
            <span className="text-muted mb-0">
              {this.handleValidDate(event.startDate)}
            </span>
          )
        },
      },
      {
        text: "Time",
        dataField: "startTime",
        formatter: (_, event) => (
          <span className="text-muted mb-0">
            {moment(event.startTime, "HH:mm:ss").format("hh:mmA")} -{' '}
            {moment(event.endTime, "HH:mm:ss").format("hh:mmA")}
          </span>

        ),
      },
      {
        dataField: "bookingsCount",
        text: "Total Bookings",
        sort: false,
        formatter: (_, event) => (
          <span className="text-muted mb-0">
            {event.bookingsCount ? event.bookingsCount : 0}
          </span>
        ),
      },
      {
        dataField: "guestInvitesAllowed",
        text: "Guest Invites",
      },
      {
        dataField: "price",
        text: "Price",
        sort: false,
        formatter: (_, event) => (
          <>
            <span className="text-muted mb-0">
              <Link
                to={`/bookings/${event.title?.replace('/', '-')}/${event.id}`}
                className="text-dark"
              >
                ${event.price}
              </Link>
            </span>
          </>)
      },

    ];

    const locationSelect = [
      { label: "Lounge", value: 'Lounge' },
      { label: "Library", value: 'Library' },
      { label: "Kitchen", value: 'Kitchen' },
      { label: "Wellness Studio", value: 'Wellness Studio' },
      { label: "Patio", value: 'Patio' },
      { label: "Banana Yoga - 376 40th Street, Oakland", value: 'Banana Yoga - 376 40th Street, Oakland' },
      { label: "835 4th St Suite B, San Rafael", value: '835 4th St Suite B, San Rafael' },
      { label: "239 3rd St, San Rafael, CA 94901", value: '239 3rd St, San Rafael, CA 94901' },
      { label: "Other", value: 'Other' },
    ]

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.events?.length,
      custom: true,
    };

    const user = JSON.parse(localStorage.getItem("authUser"))

    const salesEndSelect = [
      { label: "2 hours before the event", value: 2 },
      { label: "12 hours before the event", value: 12 },
      { label: "24 hours before the event", value: 24 },
      { label: "48 hours before the event", value: 48 },
    ]

    return (
      <React.Fragment>
        <DeleteModal
          cancel
          show={this.state.cancelEventModal}
          onDeleteClick={this.handleCancelEvent}
          onCloseClick={() => this.setState({ cancelEventModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Events List | Groundfloor</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Events" breadcrumbItem="Events List" />

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div className="text-sm-end">
                            <Link to="/instructor-event-create">
                              <Button
                                // color="#032224"
                                style={{ backgroundColor: "#032224" }}
                                className="font-16 btn-block btn btn-primary"
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create Event
                              </Button>
                            </Link>
                          </div>
                        </div>
                        {this.props.events && <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          columns={columns}
                          data={this.props.events}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              columns={columns}
                              data={this.props.events}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>}
                      </CardBody>
                    </Card>
                    <Modal
                      size="xl"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {"Edit Event"}
                      </ModalHeader>
                      <ModalBody>
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            id: (event && event.id) || "",
                            title: (event && event.title) || "",
                            description: (event && event.description) || "",
                            startDate:
                              (event &&
                                moment(event?.startDate)
                                  .format("YYYY-MM-DD")) ||
                              "",
                            capacity: event && event.capacity,
                            guestInvitesAllowed:
                              event && event.guestInvitesAllowed,
                            link: (event && event.link) || "",
                            bookingConfirmation:
                              (event && event.bookingConfirmation) || "",
                            category: (event && event.category) || "",
                            price: event && event.price,
                            startTime: (event && event.startTime) || "",
                            endTime: (event && event.endTime) || "",
                          }}
                          validationSchema={Yup.object().shape({
                            title: Yup.string().trim('Please Enter Title').required("Please Enter Title"),
                            description: Yup.string().trim('Please Enter Description').required(
                              "Please Enter Description"
                            ),
                            startDate: Yup.string().required(
                              "Please Enter Start Date"
                            ),
                            startTime: Yup.string().required(
                              "Please Enter Start Time"
                            ),
                            endTime: Yup.string().required(
                              "Please Enter End Time"
                            ),
                            capacity: Yup.number()
                              .min(1, "Capacity can not be 0")
                              .typeError("The amount invalid")
                              .required("The amount is required"),
                            guestInvitesAllowed: Yup.number().min(0, 'Can not be lower than 0!').required(
                              "Please Enter Guest Invite Allowed"
                            ),
                            price: Yup.number().min(0, 'Can not be lower than 0!').required("Please Enter Price"),
                          })}
                          onSubmit={values => {
                            let startDate = values.startDate
                            let startTime = values.startTime

                            const startDateAndTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm')
                            let salesEndDateAndTime = startDateAndTime

                            if (this.state.showBookClosingTimes) {
                              salesEndDateAndTime = startDateAndTime.subtract(this.state.salesEndBeforeEvent.value, 'hours')
                            }



                            if (this.state.interestsSelected?.length === 0 || this.state.interestsSelected === undefined) {
                              this.setState({
                                interestsError: true
                              })
                            }
                            if (!this.state.interestsError) {
                              const updateEvent = {
                                title: values.title,
                                description: values.description,
                                startTime,
                                endTime: values.endTime,
                                salesEnd: moment(
                                  salesEndDateAndTime
                                ).format("YYYY-MM-DD HH:mm:ss"),
                                capacity: values.capacity,
                                guestInvitesAllowed:
                                  this.state.guestInvitesAllowed,
                                price: this.state.price,
                                link: this.state.bookingConfirmation.value === 'No link' ? "" : values.link,
                                location: this.state.location,
                                bookingConfirmation:
                                  this.state.bookingConfirmation.label,
                                host: this.state.host.trim() !== '' ? this.state.host : `${user.firstName} ${user.lastName}`,
                                category: this.state.category,
                                instructorId: user.id,
                                clubId: user.clubId,
                                checkinRequired: 1,
                                instructorName: `${user.firstName} ${user.lastName}`,
                                requiresPayment: this.state.price > 0 ? 1 : 0,
                                id: event.id,
                                hostId: "",
                                interests: this.state.interestsSelected
                                  .map(item => item.value)
                                  .join(";"),
                                image: this.state?.selectedFiles[0],
                                startDate: moment(values.startDate).format(
                                  "YYYY-MM-DD"
                                ),
                              };
                              this.props.onUpdateEvent(updateEvent);
                              this.setState({ selectedEvent: null });
                              this.toggle();
                            }
                          }}
                        >
                          {({ errors, status, touched }) => (
                            <Form>
                              <Row>
                                <h4 className="mb-4">Basic Info</h4>
                                <Row className="mb-4">
                                  <Col lg='10'>
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Title
                                    </Label>
                                    <Field
                                      name="title"
                                      placeholder="Title"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.title && touched.title
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="title"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg='10'>
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Description
                                    </Label>
                                    <Field
                                      name="description"
                                      placeholder="Description"
                                      type="text"
                                      component="textarea"
                                      rows="6"
                                      className={
                                        "form-control" +
                                        (errors.description &&
                                          touched.description
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="description"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg="10">
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Host (Optional)
                                    </Label>

                                    <Field
                                      type="text"
                                      value={this.state.host}
                                      style={{ marginTop: 10 }}
                                      placeholder='Enter Host'
                                      onChange={e =>
                                        this.setState({
                                          host: e.target.value,
                                        })}
                                      className={
                                        "form-control"
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg='10'>
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Capacity
                                    </Label>
                                    <Field
                                      name="capacity"
                                      type="number"
                                      placeholder="Capacity"
                                      className={
                                        "form-control" +
                                        (errors.capacity && touched.capacity
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />

                                    <ErrorMessage
                                      name="capacity"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg="10">
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Guest Invites Allowed
                                    </Label>
                                    <Field
                                      name="guestInvitesAllowed"
                                      value={this.state.guestInvitesAllowed}
                                      placeholder='Guest Invites Allowed'
                                      onChange={e =>
                                        this.setState({
                                          guestInvitesAllowed: e.target.value,
                                        })
                                      }
                                      type="number"
                                      className={
                                        "form-control" +
                                        (errors.guestInvitesAllowed &&
                                          touched.guestInvitesAllowed
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="guestInvitesAllowed"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg="10">
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Price
                                    </Label>
                                    <Field
                                      name="price"
                                      value={this.state.price}
                                      placeholder="Price"
                                      onChange={e =>
                                        this.setState({
                                          price: e.target.value,
                                        })
                                      }
                                      type="number"
                                      className={
                                        "form-control" +
                                        (errors.price && touched.price
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="price"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <hr />
                                <h4 className="mt-4 mb-4">Location</h4>
                                <Row className="mb-4">
                                  <Label
                                    className="col-form-label col-lg-2"
                                  >
                                    Location
                                  </Label>
                                  <Col lg="10">
                                    <Select
                                      onChange={
                                        this.handleLocationSelect
                                      }
                                      options={locationSelect}
                                      value={this.state.locationSelected}
                                      classNamePrefix="select2-selection"
                                    />
                                    {this.state.locationSelected.value === 'Other' && <Field
                                      type="text"
                                      value={this.state.location}
                                      style={{ marginTop: 10 }}
                                      onChange={e =>
                                        this.setState({
                                          location: e.target.value,
                                          locationError: false
                                        })}
                                      className={
                                        "form-control"
                                      }
                                    />}
                                    {this.state.locationError && (
                                      <p
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        Please Select a Location
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <hr />
                                <h4 className="mt-4 mb-5">Date and time</h4>
                                <Row className="mb-4">
                                  <Label
                                    htmlFor="startDate"
                                    className="col-form-label col-lg-2"
                                  >
                                    Start Date and Time
                                  </Label>
                                  <Col lg="10">
                                    <Row className="mb-2">
                                      <Col lg="6">
                                        <Field
                                          name="startDate"
                                          type="date"
                                          className={
                                            "form-control" +
                                            (errors.startDate && touched.startDate
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="startDate"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <Field
                                          name="startTime"
                                          type="time"
                                          format="HH:mm"
                                          className={
                                            "form-control" +
                                            (errors.startTime && touched.startTime
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="startTime"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Label className="col-form-label col-lg-2">
                                    End Time
                                  </Label>
                                  <Col lg="10">
                                    <Col lg="6">
                                      <Field
                                        name="endTime"
                                        type="time"
                                        className={
                                          "form-control" +
                                          (errors.endTime && touched.endTime
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="endTime"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Label
                                    className="col-form-label col-lg-2"
                                  >
                                    Final Attendee Update Deadline
                                  </Label>
                                  <Col lg="10">
                                    <div style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: 10
                                    }}>
                                      <span style={{ marginRight: 10 }}>OFF</span>
                                      <Switch active={this.state.showBookClosingTimes} parentFunc={this.handleShowBookClosingTimes} />
                                      <span style={{ marginLeft: 10 }}>ON</span>
                                    </div>
                                    <span>{`Set this as the cutoff for all attendee list changes, including the waitlist. No bookings or waitlist updates will occur after this time.`}</span>
                                  </Col>

                                </Row>
                                {this.state.showBookClosingTimes && <Row className="mt-5 mb-4">
                                  <Label
                                    htmlFor="bookingConfirmation"
                                    className="col-form-label col-lg-2"
                                  >
                                    Select sales end
                                  </Label>
                                  <Col lg="8">
                                    <Select
                                      onChange={
                                        this.handleSalesEndBeforeEventSelect
                                      }
                                      value={this.state.salesEndBeforeEvent}
                                      options={salesEndSelect}
                                      classNamePrefix="select2-selection"
                                    />
                                  </Col>
                                </Row>}
                                <hr />
                                <h4 className="mt-4 mb-5">Details</h4>
                                <Row className="mb-4">
                                  <Label
                                    htmlFor="bookingConfirmation"
                                    className="col-form-label col-lg-2"
                                  >
                                    Booking Confirmation
                                  </Label>
                                  <Col lg="10">
                                    <Select
                                      onChange={
                                        this.handleBookingConfirmationSelect
                                      }
                                      options={bookingConfirmationSelect}
                                      defaultValue={this.state.bookingConfirmation}
                                      classNamePrefix="select2-selection"
                                    />
                                    <ErrorMessage
                                      name="bookingConfirmation"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>

                                  {this.state.bookingConfirmation.value !== 'No link' && <div className="mb-3">
                                    <Label className="form-label">Link</Label>
                                    <Field
                                      name="link"
                                      type="text"
                                      className={
                                        "form-control" +
                                        (errors.link && touched.link
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="link"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>}
                                </Row>
                                <Row className="mb-4">
                                  <Label
                                    htmlFor="bookingConfirmation"
                                    className="col-form-label col-lg-2"
                                  >
                                    Select Interests
                                  </Label>
                                  <Col lg="10">
                                    <p style={{ fontSize: 12, fontWeight: '400' }}>Pick all the relevant and related themes to tag the event, this will help us automate suggestions in future!</p>
                                    <Select
                                      value={interestsSelected}
                                      isMulti={true}
                                      onChange={this.handleMulti}
                                      options={interestsFiltered}
                                      classNamePrefix="select2-selection"
                                    />

                                    {this.state.interestsError && (
                                      <p
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        Please Select Interests
                                      </p>
                                    )}
                                  </Col>

                                </Row>

                                <FormGroup className="mb-3" row>
                                  <Label className="col-form-label col-lg-2">
                                    Attach Image
                                  </Label>
                                  <Col lg="10">
                                    <FileUpload
                                      onFileUploaded={this.onFileUploaded}
                                      image={this.state.eventImage}
                                    ></FileUpload>
                                  </Col>
                                </FormGroup>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-end">

                                    <button
                                      type="submit"
                                      className="btn btn-success save-user"
                                    >
                                      Save
                                    </button>


                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </Col>
            </Row>


          </Container>
        </div>
      </React.Fragment>
    );
  }
}

EventListInstructor.propTypes = {
  events: PropTypes.array,
  className: PropTypes.any,
  token: PropTypes.any,
  onGetEventsByInstructor: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onSetImage: PropTypes.func,
  instructors: PropTypes.array,
  clubs: PropTypes.array,
  onCancelEvent: PropTypes.func,
  onGetEventInstructors: PropTypes.func,
  onGetAllChildrenCategories: PropTypes.func,
  childrenCategories: PropTypes.array,
  onEventClone: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = ({ events, Login, system }) => ({
  events: events.events,
  token: Login.token,
  instructors: events.instructors,
  clubs: system.clubs,
  childrenCategories: system.childrenCategories,

});

const mapDispatchToProps = dispatch => ({
  onGetEventsByInstructor: id => dispatch(getEventsByInstructor()),
  onGetEventInstructors: () => dispatch(getEventInstructors()),
  onUpdateEvent: event => dispatch(updateInstructorEvent(event)),
  onDeleteEvent: id => dispatch(deleteEvent(id)),
  onSetImage: image => dispatch(setImage(image)),
  onEventClone: event => dispatch(cloneEvent(event)),
  onCancelEvent: (id, clubId) => dispatch(cancelEvent(id, clubId)),
  onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventListInstructor));
