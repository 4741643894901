import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Config from "API/Config";
import { setImage } from "../../store/actions";
import Resizer from "react-image-file-resizer";
import { axiosApi as axios } from "helpers/api_helper";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedFiles: [], isUploading: false, uploadedFile: null };
  }

  componentDidMount() {
    const { image } = this.props
    if (image) {
      this.setState({
        selectedFiles: [
          { name: "", preview: image, url: image },
        ],
      })
    }
  }

  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  handleAcceptedFiles = async files => {
    this.setState({ isUploading: true });
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
    files.forEach(file => {
      this.handleFileUpload(file)
    })
  };

  handleFileUpload = async file => {
    const fileResized = await this.resizeFile(file)

    Object.assign(fileResized, {
      preview: URL.createObjectURL(fileResized),
      formattedSize: this.formatBytes(fileResized.size),
    })

    const token = (axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("token"));

    const formData = new FormData();

    formData.append("file", fileResized);
    axios({
      crossdomain: true,
      method: "post",
      url: `${Config.Server.API_BASE_URL}/upload`,
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then(response => {
        if (response.data.result) {
          const reponseData = {
            url: response.data.data.imageUrl,
          };
          let fileToSave
          if (this.state.uploadedFile?.length > 1) {
            fileToSave = `${this.state.uploadedFile},${response.data.data.fileUrl}`
          } else {
            fileToSave = response.data.data.fileUrl
          }
          this.props.setImage(fileToSave);

          this.setState({
            uploadedFile: fileToSave,
            isUploading: false,
          });
          return reponseData;
        } else {
        }
      })
      .catch(error => {
      });
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  render() {
    const { isUploading } = this.state;
    return (
      <div>
        <Dropzone
          maxFiles={this.props.maxFiles ? this.props.maxFiles : 1}
          multiple={this.props.maxFiles ? this.props.maxFiles !== 1 : false}
          accept="image/jpeg,image/png,image/webp"
          onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick mt-2" {...getRootProps()}>
                <input {...getInputProps()} />
                {/* <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                </div> */}
                <h4>Drop files here or click to upload.</h4>
              </div>
            </div>
          )}
        </Dropzone>
        {!isUploading && (
          <div className="dropzone-previews mt-3" id="file-previews">
            {this.state.selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        {f.preview && f.name.includes("mp4") ? (
                          <video controls src={f.preview} width="100%"></video>
                        ) : (
                          <img
                            data-dz-thumbnail=""
                            height="400"
                            className="avatar-xxl rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        )}
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
          </div>
        )}
        {isUploading && (
          <div className="spinner-border text-primary m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
    );
  }
}

FileUpload.propTypes = {
  token: PropTypes.any,
  setImage: PropTypes.func,
  maxFiles: PropTypes.number,
  image: PropTypes.string
};

const mapStateToProps = ({ Login }) => ({
  token: Login.token,
});

export default withRouter(connect(mapStateToProps, { setImage })(FileUpload));
