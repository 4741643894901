import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  Label,
} from "reactstrap";
import { Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import classnames from "classnames";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import { getClubs, getOnboardingMembers, onboardingScheduled, setOnboardingSession, } from "../../store/actions";

class OnboardingMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      activeClub: 1,
      onboardingSession: false,
      membersList: [],
    };

    this.handleOnboarding = this.handleOnboarding.bind(this);
  }

  componentDidMount() {
    const { clubs, onGetClubs, membersNotOnBoarded, onGetOnBoardingMembers } = this.props;
    const { activeClub } = this.state;
    if (clubs.length === 0) {
      onGetClubs();
    } else {
      onGetOnBoardingMembers(activeClub);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { clubs, onGetOnBoardingMembers, membersNotOnBoarded } = this.props;
    const { activeClub } = this.state;
    if (clubs !== prevProps.clubs) {
      onGetOnBoardingMembers(activeClub);
    }
    if (membersNotOnBoarded !== prevProps.membersNotOnBoarded) {
      this.setState({
        membersList: membersNotOnBoarded,
      });
    }
  }



  handleOnboarding = (row) => {
    const { onSetOnboardingSession } = this.props;
    const member = {
      userId: row.id,
      onboardingSession: 1,
    };
    onSetOnboardingSession(member);

    this.setState({
      membersList: this.state.membersList.filter(function (member) {
        return member.id !== row.id
      })
    });
  }

  handleOnboardingScheduled = (row) => {
    const { onboardingScheduled, onGetOnBoardingMembers } = this.props
    const { activeClub } = this.state
    onboardingScheduled(row.email)
    setTimeout(() => {
      onGetOnBoardingMembers(activeClub);
    }, 500);
  }

  handleValidDate = date => {
    const date1 = moment(date).utc().format("DD MMM Y");
    return date1;
  };

  checkinFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Popconfirm

        description="Are you sure you want to onboard?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => this.handleOnboarding(row)}
        icon={<QuestionCircleOutlined style={{ color: 'green' }} />}
      >
        <Button color="primary" >Complete Onboarding</Button>
      </Popconfirm>
    );
  };

  onboardingScheduledFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      row.first_chaser !== null ? <Popconfirm

        description="Are you sure you want to schedule?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => this.handleOnboardingScheduled(row)}
        icon={<QuestionCircleOutlined style={{ color: 'green' }} />}
      >
        <Button color="primary" >Onboarding scheduled</Button>
      </Popconfirm> : null
    );
  };

  render() {
    const { clubs } = this.props;
    const { activeClub, membersList } = this.state;
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "first_name",
        text: "First Name",
        sort: true,
        filterValue: (_, user) => `${user?.first_name} ${user?.last_name}`,
      },
      {
        dataField: "last_name",
        text: "Last Name",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: false,
      },
      {
        text: "Start Date",
        dataField: "start_date",
        formatter: (_, user) => (
          <>
            {this.handleValidDate(user.start_date)}
          </>
        ),
      },
      {
        dataField: "onboarding_session",
        text: "Has not been Onboarded yet",
        formatter: this.checkinFormatter,
      },
      // {
      //   dataField: "id",
      //   text: "Onboarding scheduled",
      //   formatter: this.onboardingScheduledFormatter,
      // },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: membersList?.length,
      custom: true,
    };

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        e.target
      },
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Onboarding Checklist | Groundfloor</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={"Onboarding"} breadcrumbItem={"Onboarding Checklist"} />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <Nav pills className="bg-light rounded" role="tablist">
                        <NavItem>
                        </NavItem>
                        {clubs?.map((item, index) => (
                          <div key={index}>
                            <NavItem >
                              <NavLink
                                className={classnames({
                                  active: activeClub === item.id,
                                })}
                                style={{
                                  backgroundColor:
                                    activeClub === item.id
                                      ? "#032224"
                                      : "transparent",
                                }}
                                onClick={() => {
                                  this.props.onGetOnBoardingMembers(item.id);
                                  this.setState({
                                    activeClub: item.id,
                                  });
                                }}
                              >
                                {item.name}
                              </NavLink>
                            </NavItem>
                            {activeClub === item.id && membersList && <span style={{ position: 'absolute', top: 15 }} className="badge bg-danger rounded-pill">{membersList.length}</span>}
                          </div>
                        ))}
                      </Nav>
                    </div>
                    {membersList && (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={membersList}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={membersList}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  // style={{ width: "400px", height: "40px" }}
                                  />
                                  {/* <Col lg="6"  style={{width: '5%', marginTop: '30px'}}>
                                      <Switch 
                                       checkedChildren="Or" unCheckedChildren="And" defaultChecked onClick={() => this.setState({orAnd: !this.state.orAnd})}
                                      />
                                  </Col> */}
                                </Row>
                                <Row>
                                  {/* <Link
                                  to="/profile"
                                  className="text-muted font-weight-bold"
                                > */}
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"email"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        rowEvents={tableRowEvents}
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                  {/* </Link> */}
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }

}

OnboardingMembers.propTypes = {
  membersNotOnBoarded: PropTypes.array,
  onGetOnBoardingMembers: PropTypes.func,
  onGetClubs: PropTypes.func,
  onGetMemberById: PropTypes.func,
  onSetOnboardingSession: PropTypes.func,
  onboardingScheduled: PropTypes.func,
  clubs: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = ({ members, system }) => ({
  membersNotOnBoarded: members.membersNotOnBoarded,
  clubs: system.clubs,
});

const mapDispatchToProps = dispatch => ({
  onGetMemberById: id => dispatch(getMemberById(id)),
  onSetOnboardingSession: onboarding => dispatch(setOnboardingSession(onboarding)),
  onGetOnBoardingMembers: clubId => dispatch(getOnboardingMembers(clubId)),
  onboardingScheduled: email => dispatch(onboardingScheduled(email)),
  onGetClubs: () => dispatch(getClubs()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OnboardingMembers));
