import {
  GET_EVENTS,
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  ADD_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  GET_PAST_EVENTS,
  GET_PAST_EVENTS_FAIL,
  GET_PAST_EVENTS_SUCCESS,
  GET_EVENT_INSTRUCTOR,
  GET_EVENT_INSTRUCTOR_SUCCESS,
  GET_EVENT_INSTRUCTOR_FAIL,
  GET_EVENTS_BY_INSTRUCTOR,
  GET_EVENTS_BY_INSTRUCTOR_SUCCESS,
  GET_EVENTS_BY_INSTRUCTOR_FAIL,
  SET_IMAGE,
  SET_IMAGE_SUCCESS,
  GET_EVENTS_BY_CLUB,
  SEND_NEW_EVENT_NOTIFICATION,
  GET_CALENDLY_SCHEDULED_EVENTS_SUCCESS,
  GET_CALENDLY_SCHEDULED_EVENTS_FAIL,
  GET_CALENDLY_SCHEDULED_EVENTS,
  GET_CALENDLY_EVENT,
  GET_CALENDLY_EVENT_SUCCESS,
  GET_CALENDLY_EVENT_FAIL,
  GET_CALENDLY_EVENT_INVITEES,
  GET_CALENDLY_EVENT_INVITEES_SUCCESS,
  GET_CALENDLY_EVENT_INVITEES_FAIL,
  CLONE_EVENT,
  GET_SUGGESTED_EVENTS,
  GET_SUGGESTED_EVENTS_SUCCESS,
  GET_SUGGESTED_EVENTS_FAIL,
  UPDATE_SPOTLIGHT,
  UPDATE_SPOTLIGHT_SUCCESS,
  UPDATE_SPOTLIGHT_FAIL,
  GET_EVENT_ATTENDANCE,
  GET_EVENT_ATTENDANCE_SUCCESS,
  GET_EVENT_ATTENDANCE_FAIL,
  CANCEL_EVENT,
  CANCEL_EVENT_SUCCESS,
  CANCEL_EVENT_FAIL,
  GET_EVENT_POSSIBLE_MATCHES,
  GET_EVENT_POSSIBLE_MATCHES_SUCCESS,
  GET_EVENT_POSSIBLE_MATCHES_FAIL,
  GET_PAST_EVENTS_BY_INSTRUCTOR,
  GET_PAST_EVENTS_BY_INSTRUCTOR_SUCCESS,
  GET_PAST_EVENTS_BY_INSTRUCTOR_FAIL,
  ADD_EVENT_INSTRUCTOR,
  UPDATE_INSTRUCTOR_EVENT,
  UPDATE_INSTRUCTOR_EVENT_SUCCESS,
  UPDATE_INSTRUCTOR_EVENT_FAIL,
  GET_SUGGESTED_EVENTS_BY_CLUB_ID,
  GET_SUGGESTED_EVENTS_BY_CLUB_ID_SUCCESS,
  GET_SUGGESTED_EVENTS_BY_CLUB_ID_FAIL,
  GET_CLUB_LEADER_UPCOMING_EVENTS,
  GET_CLUB_LEADER_UPCOMING_EVENTS_SUCCESS,
  GET_CLUB_LEADER_UPCOMING_EVENTS_FAIL,
  GET_CLUB_LEADER_PAST_EVENTS,
  GET_CLUB_LEADER_PAST_EVENTS_SUCCESS,
  GET_CLUB_LEADER_PAST_EVENTS_FAIL,
  SUGGEST_EVENT,
  SUGGEST_EVENT_SUCCESS,
  SUGGEST_EVENT_FAIL,
  GET_SUGGESTED_EVENTS_BY_USER_ID,
  GET_SUGGESTED_EVENTS_BY_USER_ID_SUCCESS,
  GET_SUGGESTED_EVENTS_BY_USER_ID_FAIL,
  GET_DRAFT_EVENTS_BY_USER_ID,
  GET_DRAFT_EVENTS_BY_USER_ID_SUCCESS,
  GET_DRAFT_EVENTS_BY_USER_ID_FAIL,
  CREATE_DRAFT_SUCCESS,
  CREATE_DRAFT_FAIL,
  CREATE_DRAFT,
  DELETE_DRAFT,
  UPDATE_DRAFT,
  UPDATE_SUGGESTED_EVENT,
  SEND_EMAIL_TO_ATTENDEES,
  DELETE_SUGGESTED_EVENT,
  RESET_LOADING_STATE,
  UPDATE_EVENT_CLUB_LEADER,

} from "./actionTypes";

export const getEvents = () => ({
  type: GET_EVENTS,
});

export const getEventsSuccess = events => ({
  type: GET_EVENTS_SUCCESS,
  payload: events,
});

export const getEventsFail = error => ({
  type: GET_EVENTS_FAIL,
  payload: error,
});

export const addEvent = (event, history) => ({
  type: ADD_EVENT,
  payload: { event, history },
});

export const addEventSuccess = event => ({
  type: ADD_EVENT_SUCCESS,
  payload: event,
});

export const addEventFail = error => ({
  type: ADD_EVENT_FAIL,
  payload: error,
});

export const addEventInstructor = (event, history) => ({
  type: ADD_EVENT_INSTRUCTOR,
  payload: { event, history },
});

export const sendNewEventNotification = (data) => ({
  type: SEND_NEW_EVENT_NOTIFICATION,
  payload: data,
});

export const updateEvent = (event, clubId) => ({
  type: UPDATE_EVENT,
  payload: { event, clubId },
});

export const updateEventClubLeader = (event) => ({
  type: UPDATE_EVENT_CLUB_LEADER,
  payload: { event },
});


export const updateEventSuccess = event => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: event,
});

export const updateEventFail = error => ({
  type: UPDATE_EVENT_FAIL,
  payload: error,
});

export const updateInstructorEvent = (event) => ({
  type: UPDATE_INSTRUCTOR_EVENT,
  payload: { event },
});

export const updateInstructorEventSuccess = event => ({
  type: UPDATE_INSTRUCTOR_EVENT_SUCCESS,
  payload: event,
});

export const updateInstructorEventFail = error => ({
  type: UPDATE_INSTRUCTOR_EVENT_FAIL,
  payload: error,
});

export const updateSpotlight = (eventId, spotlight) => ({
  type: UPDATE_SPOTLIGHT,
  payload: { eventId, spotlight },
});

export const updateSpotlightSuccess = event => ({
  type: UPDATE_SPOTLIGHT_SUCCESS,
  payload: event,
});

export const updateSpotlightFail = error => ({
  type: UPDATE_SPOTLIGHT_FAIL,
  payload: error,
});


export const deleteEvent = (id, clubId) => ({
  type: DELETE_EVENT,
  payload: { id, clubId },
});

export const deleteEventSuccess = event => ({
  type: DELETE_EVENT_SUCCESS,
  payload: event,
});

export const deleteEventFail = error => ({
  type: DELETE_EVENT_FAIL,
  payload: error,
});

export const cancelEvent = (id, clubId) => ({
  type: CANCEL_EVENT,
  payload: { id, clubId },
});

export const cancelEventSuccess = event => ({
  type: CANCEL_EVENT_SUCCESS,
  payload: event,
});

export const cancelEventFail = error => ({
  type: CANCEL_EVENT_FAIL,
  payload: error,
});

export const getPastEvents = (clubId) => ({
  type: GET_PAST_EVENTS,
  payload: clubId
});

export const getPastEventsSuccess = events => ({
  type: GET_PAST_EVENTS_SUCCESS,
  payload: events,
});

export const getPastEventsFail = error => ({
  type: GET_PAST_EVENTS_FAIL,
  payload: error,
});

export const getEventAttendance = (userId) => ({
  type: GET_EVENT_ATTENDANCE,
  payload: userId
});

export const getEventAttendanceSuccess = events => ({
  type: GET_EVENT_ATTENDANCE_SUCCESS,
  payload: events,
});

export const getEventAttendanceFail = error => ({
  type: GET_EVENT_ATTENDANCE_FAIL,
  payload: error,
});

export const getSuggestedEventsByUserId = () => ({
  type: GET_SUGGESTED_EVENTS_BY_USER_ID,
});

export const getSuggestedEventsByUserIdSuccess = events => ({
  type: GET_SUGGESTED_EVENTS_BY_USER_ID_SUCCESS,
  payload: events,
});

export const getSuggestedEventsByUserIdFail = error => ({
  type: GET_SUGGESTED_EVENTS_BY_USER_ID_FAIL,
  payload: error,
});

export const getDraftEventsByUserId = () => ({
  type: GET_DRAFT_EVENTS_BY_USER_ID,
});

export const getDraftEventsByUserIdSuccess = events => ({
  type: GET_DRAFT_EVENTS_BY_USER_ID_SUCCESS,
  payload: events,
});

export const getDraftEventsByUserIdFail = error => ({
  type: GET_DRAFT_EVENTS_BY_USER_ID_FAIL,
  payload: error,
});

export const getEventPossibleMatches = (eventId) => ({
  type: GET_EVENT_POSSIBLE_MATCHES,
  payload: eventId
});

export const getEventPossibleMatchesSuccess = matches => ({
  type: GET_EVENT_POSSIBLE_MATCHES_SUCCESS,
  payload: matches,
});

export const getEventPossibleMatchesFail = error => ({
  type: GET_EVENT_POSSIBLE_MATCHES_FAIL,
  payload: error,
});

export const getCalendlyEvents = () => ({
  type: GET_CALENDLY_SCHEDULED_EVENTS,
});

export const getCalendlyEventsSuccess = events => ({
  type: GET_CALENDLY_SCHEDULED_EVENTS_SUCCESS,
  payload: events,
});

export const getCalendlyEventsFail = error => ({
  type: GET_CALENDLY_SCHEDULED_EVENTS_FAIL,
  payload: error,
});

export const getEventInstructors = id => ({
  type: GET_EVENT_INSTRUCTOR,
  payload: { id },
});

export const getEventInstructorsSuccess = instructors => ({
  type: GET_EVENT_INSTRUCTOR_SUCCESS,
  payload: instructors,
});

export const getEventInstructorsFail = error => ({
  type: GET_EVENT_INSTRUCTOR_FAIL,
  payload: error,
});

export const getEventsByInstructor = () => ({
  type: GET_EVENTS_BY_INSTRUCTOR,
});

export const getEventsByInstructorSuccess = events => ({
  type: GET_EVENTS_BY_INSTRUCTOR_SUCCESS,
  payload: events,
});

export const getEventsByInstructorFail = error => ({
  type: GET_EVENTS_BY_INSTRUCTOR_FAIL,
  payload: error,
});

export const getPastEventsByInstructor = () => ({
  type: GET_PAST_EVENTS_BY_INSTRUCTOR,
});

export const getPastEventsByInstructorSuccess = events => ({
  type: GET_PAST_EVENTS_BY_INSTRUCTOR_SUCCESS,
  payload: events,
});

export const getPastEventsByInstructorFail = error => ({
  type: GET_PAST_EVENTS_BY_INSTRUCTOR_FAIL,
  payload: error,
});

export const getCalendlyEvent = (eventId) => ({
  type: GET_CALENDLY_EVENT,
  payload: { eventId }
});

export const getCalendlyEventSuccess = event => ({
  type: GET_CALENDLY_EVENT_SUCCESS,
  payload: event,
});

export const getCalendlyEventFail = error => ({
  type: GET_CALENDLY_EVENT_FAIL,
  payload: error,
});

export const getCalendlyEventInvitees = (eventId) => ({
  type: GET_CALENDLY_EVENT_INVITEES,
  payload: { eventId }

});

export const getCalendlyEventInviteesSuccess = invitees => ({
  type: GET_CALENDLY_EVENT_INVITEES_SUCCESS,
  payload: invitees,
});

export const getCalendlyEventInviteesFail = error => ({
  type: GET_CALENDLY_EVENT_INVITEES_FAIL,
  payload: error,
});

export const getSuggestedEvents = () => ({
  type: GET_SUGGESTED_EVENTS,
});

export const getSuggestedEventsSuccess = events => ({
  type: GET_SUGGESTED_EVENTS_SUCCESS,
  payload: events,
});

export const getSuggestedEventsFail = error => ({
  type: GET_SUGGESTED_EVENTS_FAIL,
  payload: error,
});

export const getSuggestedEventsByClubId = (clubId) => ({
  type: GET_SUGGESTED_EVENTS_BY_CLUB_ID,
  payload: clubId
});

export const getSuggestedEventsByClubIdSuccess = events => ({
  type: GET_SUGGESTED_EVENTS_BY_CLUB_ID_SUCCESS,
  payload: events,
});

export const getSuggestedEventsByClubIdFail = error => ({
  type: GET_SUGGESTED_EVENTS_BY_CLUB_ID_FAIL,
  payload: error,
});

export const setImage = image => {
  return {
    type: SET_IMAGE,
    payload: { image },
  };
};

export const setImageSuccess = image => ({
  type: SET_IMAGE_SUCCESS,
  payload: image,
});

export const getEventsByClub = id => ({
  type: GET_EVENTS_BY_CLUB,
  payload: id,
});

export const cloneEvent = event => ({
  type: CLONE_EVENT,
  payload: event
})

export const getClubLeaderUpcomingEvents = (subClubId) => ({
  type: GET_CLUB_LEADER_UPCOMING_EVENTS,
  payload: subClubId
});

export const getClubLeaderUpcomingEventsSuccess = events => ({
  type: GET_CLUB_LEADER_UPCOMING_EVENTS_SUCCESS,
  payload: events,
});

export const getClubLeaderUpcomingEventsFail = error => ({
  type: GET_CLUB_LEADER_UPCOMING_EVENTS_FAIL,
  payload: error,
});

export const getClubLeaderPastEvents = (subClubId) => ({
  type: GET_CLUB_LEADER_PAST_EVENTS,
  payload: subClubId
});

export const getClubLeaderPastEventsSuccess = events => ({
  type: GET_CLUB_LEADER_PAST_EVENTS_SUCCESS,
  payload: events,
});

export const getClubLeaderPastEventsFail = error => ({
  type: GET_CLUB_LEADER_PAST_EVENTS_FAIL,
  payload: error,
});


export const suggestEvent = (event, history) => ({
  type: SUGGEST_EVENT,
  payload: { event, history },
});

export const suggestEventSuccess = event => ({
  type: SUGGEST_EVENT_SUCCESS,
  payload: event,
});

export const suggestEventFail = error => ({
  type: SUGGEST_EVENT_FAIL,
  payload: error,
});

export const createDraft = (event, history) => ({
  type: CREATE_DRAFT,
  payload: { event, history },
});

export const createDraftSuccess = event => ({
  type: CREATE_DRAFT_SUCCESS,
  payload: event,
});

export const createDraftFail = error => ({
  type: CREATE_DRAFT_FAIL,
  payload: error,
});

export const deleteDraft = (id) => ({
  type: DELETE_DRAFT,
  payload: id
});

export const updateDraft = (event, history) => ({
  type: UPDATE_DRAFT,
  payload: { event, history },
});

export const updateSuggestedEvent = (event, history) => ({
  type: UPDATE_SUGGESTED_EVENT,
  payload: { event, history },
});

export const sendEmailToEventAttendees = (data, showToast) => ({
  type: SEND_EMAIL_TO_ATTENDEES,
  payload: { data, showToast },
});

export const deleteSuggestedEvent = (id) => ({
  type: DELETE_SUGGESTED_EVENT,
  payload: id
});

export const resetLoadingState = () => ({
  type: RESET_LOADING_STATE,
});
