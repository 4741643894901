import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"

//events
import events from "./events/reducer"
import system from "./system/reducer"

import bookings from "./bookings/reducer"
import members from "./members/reducer"
import guests from "./guests/reducer"
import products from "./products/reducer"
import announcments from "./announcments/reducer"
import subclubs from "./subclubs/reducer"

const rootReducer = combineReducers({
  Layout,
  Login,
  Profile,
  events,
  bookings,
  members,
  guests,
  system,
  products,
  announcments,
  subclubs
})

export default rootReducer
