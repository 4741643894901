import React, { Component } from "react";

import {
    Col,
    Row,
    Card,
    CardBody,
} from "reactstrap";

import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom/cjs/react-router-dom";


import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import {
    getClubLeaderMembers,
} from "../../store/actions";

class ClubLeaderMembersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clubLeaderSubClubs: [],
        };
    }

    componentDidMount() {
        const {
            match: { params },
        } = this.props;
        this.props.onGetClubLeaderMembers(params.id)
    }

    render() {
        const { clubLeaderMembers, loadingClubLeaderMembers, history } = this.props
        const {
            match: { params },
        } = this.props;

        const { SearchBar } = Search;
        const columns = [
            {
                dataField: "firstName",
                text: "Member",
                // eslint-disable-next-line react/display-name
                formatter: (_, member) => (
                    <img src={member.profilePhoto} alt="" className="avatar-sm" />
                ),
            },
            {
                dataField: "firstName",
                text: "Name",
                sort: true,
                filterValue: (_, user) => `${user?.firstName} ${user?.lastName}`,
                formatter: (value, row) => {
                    return <span>{row.firstName} {row.lastName}
                    </span>
                },
            },
            {
                dataField: "",
                text: "",
                formatter: (value, row) => {
                    return <span>{row.leader ? 'Leader' : ''}
                    </span>
                },
            }
        ];

        const tableRowEvents = {
            onClick: (e, row, rowIndex) => {
                history.push(`/profile/${row.id}/${params.id}`)
            },
        };

        const pageOptions = {
            sizePerPage: 10,
            totalSize: clubLeaderMembers?.length,
            custom: true,
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title> Members List | Groundfloor</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title={"Members"} breadcrumbItem={"Members List"} />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        {(
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={columns}
                                                data={clubLeaderMembers}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={clubLeaderMembers}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row>
                                                                    <SearchBar
                                                                        {...toolkitProps.searchProps}
                                                                    />
                                                                </Row>
                                                                <Row>

                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            {loadingClubLeaderMembers ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                keyField={"email"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                rowEvents={tableRowEvents}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                            />}
                                                                        </div>
                                                                    </Col>
                                                                    {/* </Link> */}
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

ClubLeaderMembersList.propTypes = {
    match: PropTypes.object,
    className: PropTypes.any,
    clubLeaderMembers: PropTypes.array,
    loadingClubLeaderMembers: PropTypes.bool,
    onGetClubLeaderMembers: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ members }) => ({
    clubLeaderMembers: members.clubLeaderMembers,
    loadingClubLeaderMembers: members.loadingClubLeaderMembers
});

const mapDispatchToProps = dispatch => ({
    onGetClubLeaderMembers: (subClubId) => dispatch(getClubLeaderMembers(subClubId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ClubLeaderMembersList));
