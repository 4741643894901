import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Modal, ModalBody, Row } from "reactstrap";

class DefaultModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.show} toggle={this.props.onCloseClick} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <i
                                        className="mdi mdi-alert-circle-outline"
                                        style={{ fontSize: "9em", color: "orange" }}
                                    />
                                    <h2>{this.props.title}</h2>
                                    <h4>{this.props.description}</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-center mt-3">
                                    {this.props.hideYes ? null : <button
                                        type="button"
                                        className="btn btn-success btn-lg me-2"
                                        onClick={this.props.onSendClick}
                                    >
                                        {this.props.yesLabel ? this.props.yesLabel : 'Yes'}
                                    </button>}
                                    {this.props.hideNo ? null : <button
                                        type="button"
                                        className="btn btn-danger btn-lg me-2"
                                        onClick={this.props.onCloseClick}
                                    >
                                        {this.props.noLabel ? this.props.noLabel : 'No'}
                                    </button>}
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

DefaultModal.propTypes = {
    onCloseClick: PropTypes.func,
    onSendClick: PropTypes.func,
    show: PropTypes.any,
    cancel: PropTypes.bool,
    hideYes: PropTypes.bool,
    hideNo: PropTypes.bool,
    yesLabel: PropTypes.string,
    noLabel: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default DefaultModal;
