export const GET_SPACE_BOOKINGS = "GET_SPACE_BOOKINGS";
export const GET_SPACE_BOOKINGS_SUCCESS = "GET_SPACE_BOOKINGS_SUCCESS";
export const GET_SPACE_BOOKINGS_FAIL = "GET_SPACE_BOOKINGS_FAIL";

export const UPDATE_CHECKIN_MEMBER = "UPDATE_CHECKIN_MEMBER";
export const UPDATE_CHECKIN_MEMBER_SUCCESS = "UPDATE_CHECKIN_MEMBER_SUCCESS";
export const UPDATE_CHECKIN_MEMBER_FAIL = "UPDATE_CHECKIN_MEMBER_FAIL";

export const GET_BOOKINGS = "GET_BOOKINGS";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAIL = "GET_BOOKINGS_FAIL";

export const GET_BOOKINGS_LEADER = "GET_BOOKINGS_LEADER";
export const GET_BOOKINGS_LEADER_SUCCESS = "GET_BOOKINGS_LEADER_SUCCESS";
export const GET_BOOKINGS_LEADER_FAIL = "GET_BOOKINGS_LEADER_FAIL";

export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";
export const CANCEL_BOOKING_FAIL = "CANCEL_BOOKING_FAIL";

export const CANCEL_SPACE_BOOKING = 'CANCEL_SPACE_BOOKING'
export const CANCEL_SPACE_BOOKING_SUCCESS = 'CANCEL_SPACE_BOOKING_SUCCESS'
export const CANCEL_SPACE_BOOKING_FAIL = 'CANCEL_SPACE_BOOKING_FAIL'

export const GET_PUBLIC_BOOKING = 'GET_PUBLIC_BOOKING'
export const GET_PUBLIC_BOOKING_SUCCESS = 'GET_PUBLIC_BOOKING_SUCCESS'
export const GET_PUBLIC_BOOKING_FAIL = 'GET_PUBLIC_BOOKING_FAIL'

export const GET_USER_SPACE_BOOKINGS = 'GET_USER_SPACE_BOOKINGS'
export const GET_USER_SPACE_BOOKINGS_SUCCESS = 'GET_USER_SPACE_BOOKINGS_SUCCESS'
export const GET_USER_SPACE_BOOKINGS_FAIL = 'GET_USER_SPACE_BOOKINGS_FAIL'

export const GET_EVENT_WAITLIST = "GET_EVENT_WAITLIST";
export const GET_EVENT_WAITLIST_SUCCESS = "GET_EVENT_WAITLIST_SUCCESS";
export const GET_EVENT_WAITLIST_FAIL = "GET_EVENT_WAITLIST_FAIL";

export const GET_EVENT_WAITLIST_LEADER = "GET_EVENT_WAITLIST_LEADER";
export const GET_EVENT_WAITLIST_LEADER_SUCCESS = "GET_EVENT_WAITLIST_LEADER_SUCCESS";
export const GET_EVENT_WAITLIST_LEADER_FAIL = "GET_EVENT_WAITLIST_LEADER_FAIL";

export const BOOK_SPACE = "BOOK_SPACE";
export const BOOK_SPACE_SUCCESS = "BOOK_SPACE_SUCCESS";
export const BOOK_SPACE_FAIL = "BOOK_SPACE_FAIL";

export const GET_SPACES = "GET_SPACES";
export const GET_SPACES_SUCCESS = "GET_SPACES_SUCCESS";
export const GET_SPACES_FAIL = "GET_SPACES_FAIL";
