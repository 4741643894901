import { DOORDECK_ACCESS_STATUS, DOORDECK_ACCESS_STATUS_FAIL, DOORDECK_ACCESS_STATUS_SUCCESS, GET_ALL_CHILDREN_CATEGORIES_FAIL, GET_ALL_CHILDREN_CATEGORIES_SUCCESS, GET_CLUBS_FAIL, GET_CLUBS_SUCCESS, SEND_EMAILS, SEND_EMAILS_FAIL, SEND_EMAILS_SUCCESS, SEND_NOTIFICATIONS, SEND_NOTIFICATIONS_FAIL, SEND_NOTIFICATIONS_SUCCESS } from "./actionTypes";


const INIT_STATE = {
  clubs: [],
  subClubs: [],
  childrenCategories: [],
  error: {},
  loadingSendEmail: false,
  loadingSendNotification: false,

  clubLeaderSubClubs: [],
  loadinClubLeaderSubClubs: false,

  loadingDoorAccess: false
};

const system = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CLUBS_SUCCESS:
      return {
        ...state,
        clubs: action.payload,
      };

    case GET_CLUBS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SEND_EMAILS:
      return {
        ...state,
        loadingSendEmail: true,
      };

    case SEND_EMAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSendEmail: false
      };
    case SEND_EMAILS_SUCCESS:
      return {
        ...state,
        loadingSendEmail: false
      };

    case DOORDECK_ACCESS_STATUS:
      return {
        ...state,
        loadingDoorAccess: true,
      };

    case DOORDECK_ACCESS_STATUS_FAIL:
      return {
        ...state,
        loadingDoorAccess: false
      };
    case DOORDECK_ACCESS_STATUS_SUCCESS:
      return {
        ...state,
        loadingDoorAccess: false
      };

    case SEND_NOTIFICATIONS:
      return {
        ...state,
        loadingSendNotification: true,
      };

    case SEND_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSendNotification: false
      };
    case SEND_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingSendNotification: false
      };
    case GET_ALL_CHILDREN_CATEGORIES_SUCCESS:
      return {
        ...state,
        childrenCategories: action.payload,
      };

    case GET_ALL_CHILDREN_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default system;