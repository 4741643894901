import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Nav, NavItem, NavLink } from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import classnames from "classnames"
import Select from "react-select";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import { getClubs, getGuests } from "../../store/actions";

class Guests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      selectedDate: moment().format("YYYY-MM-DD"),
      activeClub: null,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      clubSelected: { label: "No Club Selected", value: '' },
      clubsOptions: [],
    };
  }

  handleClubSelect = club => {
    this.setState({
      clubSelected: club,
      activeClub: club.value
    })
  }

  componentDidMount() {
    const { onGetClubs, clubs } = this.props;

    const authUser = localStorage.getItem("authUser")
    if (JSON.parse(authUser)?.clubId) {
      this.setState({
        activeClub: JSON.parse(authUser).clubId
      })
    }
    if (clubs.length !== 0) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    } else {
      onGetClubs();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { clubs } = this.props;
    const { activeClub } = this.state
    if (clubs !== prevProps.clubs) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    }
    if (activeClub !== prevState.activeClub) {
      if (activeClub) {
        this.props.onGetGuests(activeClub, this.state.selectedDate)
      }
    }
  }


  render() {

    const { activeClub } = this.state
    const { clubs } = this.props;

    const columns = [
      {
        dataField: "guest_name",
        text: "Guest Name",
        sort: true,
      },
      {
        dataField: "guest_email",
        text: "Guest Email",
        sort: false,
      },
      {
        dataField: "invited_by",
        text: "Invited By",
        sort: false,
      },
      {
        dataField: "dateFormated",
        text: "Date",
        sort: false,
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.guests?.length, // replace later with size(customers),
      custom: true,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> Guests List | Groundfloor</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={"Guests"} breadcrumbItem={"Guests List"} />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {this.state.isMobile ? <Row><Col>
                      <Select
                        styles={{
                        }}
                        onChange={
                          this.handleClubSelect
                        }
                        options={this.state.clubsOptions}
                        value={this.state.clubSelected}
                        classNamePrefix="select2-selection"
                      />

                    </Col></Row> : <div style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10
                    }}>
                      <Nav pills className="bg-light rounded" role="tablist">
                        {clubs?.map((item, index) => (
                          <NavItem key={index}>
                            <NavLink
                              className={classnames({ active: activeClub === item.id })}
                              style={{
                                backgroundColor: activeClub === item.id ? '#032224' : 'transparent'
                              }}
                              onClick={() => {
                                this.setState({
                                  activeClub: item.id
                                })
                              }}
                            >
                              {item.name}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </div>}
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        startDate: this.state.selectedDate,
                      }}
                      validationSchema={Yup.object().shape({
                        startDate: Yup.string().required(
                          "Please Enter Start Date"
                        ),
                      })}
                      onSubmit={values => {
                        this.props.onGetGuests(activeClub,
                          moment(values.startDate).format("YYYY-MM-DD")
                        );
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <React.Fragment>
                          <Form>
                            <Row className="mb-4">
                              <Col lg="10">
                                <Row>
                                  <Col lg="6">
                                    <Field
                                      name="startDate"
                                      type="date"
                                      onChange={value => this.setState({
                                        selectedDate: value.target.value
                                      })}
                                      className={
                                        "form-control" +
                                        (errors.startDate && touched.startDate
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="startDate"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <Button type="submit" color="primary">
                                      Find Guests
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </React.Fragment>
                      )}
                    </Formik>
                    {this.props.guests && <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.props.guests}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.props.guests}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"email"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Guests.propTypes = {
  guests: PropTypes.array,
  onGetGuests: PropTypes.func,
  onGetClubs: PropTypes.func,
  clubs: PropTypes.array,
};

const mapStateToProps = ({ guests, system }) => ({
  guests: guests.guests,
  clubs: system.clubs

});

const mapDispatchToProps = dispatch => ({
  onGetGuests: (clubId, date) => dispatch(getGuests(clubId, date)),
  onGetClubs: () => dispatch(getClubs())

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Guests));
