import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

const getProducts = (token, clubId) => {
    return get(url.GET_PRODUCTS(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

const getOrders = (token, clubId) => {
    return get(url.GET_ORDERS(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

const orderRecieved = (token, orderId) => {
    return put(url.ORDER_RECEIVED(orderId), {
    }, {
        headers: {
            Authorization: token,
        },
    })
}

const deleteProduct = (token, productId) => {
    return del(url.DELETE_PRODUCT(productId), {
        headers: {
            Authorization: token,
        },
    });
};

const updateProduct = (token, data) => {
    return put(url.PRODUCT, {
        id: data.id,
        clubId: data.clubId,
        title: data.title,
        description: data.description,
        image: data.image,
        price: data.price,
        typeId: data.typeId,
        hidden: data.hidden,
        size: data.size,
        vendor: data.vendor
    }, {
        headers: {
            Authorization: token,
        },
    })
}

const addProduct = (token, data) =>
    post(
        url.PRODUCT,
        {
            clubId: data.clubId,
            title: data.title,
            description: data.description,
            image: data.image,
            price: data.price,
            typeId: data.typeId,
            size: data.size,
            vendor: data.vendor
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );

export {
    getProducts,
    deleteProduct,
    updateProduct,
    addProduct,
    getOrders,
    orderRecieved
};
