import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'

function Switch(props) {
    const [active, setActive] = useState(false)
    useEffect(() => {
        if (props.active)
            setActive(true)
    }, [props.active])

    const handleSwitchClick = () => {
        if (!props.disabled) {
            setActive(prevState => {
                if (props.parentFunc)
                    props.parentFunc(!prevState)
                return !prevState
            })
        }
    }

    return (
        <div onClick={handleSwitchClick} style={{
            display: 'flex',
            flexDirection: 'row',
            width: 50,
            height: 30,
            borderRadius: 20,
            backgroundColor: '#F0F2F6',
            alignItems: 'center',
            padding: 3,
            justifyContent: active ? 'flex-end' : 'flex-start',
            border: 'solid 1px',
            borderColor: '#79829C'
        }}>
            <div style={{
                width: 25,
                height: 25,
                borderRadius: 12.5,
                background: active ? '#042225' : '#79829C',
                display: 'flex',
            }}></div>
        </div>
    )
}

Switch.propTypes = {
    active: PropTypes.bool,
    parentFunc: PropTypes.func,
    disabled: PropTypes.bool
}

export default Switch
