import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";

import {
    Col,
    Container,
    Row,
    Button,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { cloneEvent, deleteDraft, getDraftEventsByUserId, getSuggestedEventsByUserId, resetLoadingState } from "store/actions";
import DefaultModal from "components/Common/DefaultModal";
import { Field, Form, Formik } from "formik";

class ClubLeaderSuggestedAndDrafts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            deleteModal: false,
            eventIdToDelete: null,
            eventToView: null,
            viewEvent: false
        };
        this.toggle = this.toggle.bind(this);
    }

    handleValidDate = date => {
        const date1 = moment.utc(date).format("ddd, DD MMM Y");
        return date1;
    };

    toggle() {
        this.setState(prevState => ({
            viewEvent: !prevState.viewEvent,
        }));
    }

    componentDidMount() {
        const {
            onGetDrafts,
            onGetSuggestedEvents,
            onResetEventsLoadingState
        } = this.props;
        onGetDrafts();
        onGetSuggestedEvents();
        onResetEventsLoadingState()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.draftsByUserId !== prevProps.draftsByUserId) {
            this.setState({
                events: [...this.props.suggestedEventsByUserId, ...this.props.draftsByUserId].sort((a, b) => new moment(a.updated).format('YYYYMMDD') - new moment(b.updated).format('YYYYMMDD'))
            });
        }
        if (this.props.suggestedEventsByUserId !== prevProps.suggestedEventsByUserId) {
            this.setState({
                events: [...this.props.draftsByUserId, ...this.props.suggestedEventsByUserId].sort((a, b) => new moment(a.updated).format('YYYYMMDD') - new moment(b.updated).format('YYYYMMDD')),
            });
        }
    }

    handleViewEventClick = arg => {
        this.setState({
            eventToView: arg,
            viewEvent: true,
        });
    };

    deleteDraftHanlder = (draftId) => {
        this.setState({
            deleteModal: true,
            eventIdToDelete: draftId
        })
    }

    deleteDraft = () => {
        setTimeout(() => {
            this.props.onGetDrafts()
        }, 800)
        this.props.onDeleteDraft(this.state.eventIdToDelete)
        this.setState({
            deleteModal: false
        })
    }

    handleRowClick = (row) => {
        this.props.onEventClone({ ...row, type: row.title.includes('Draft - ') ? 'draft' : 'suggest' })
        this.props.history.push('/suggest-event')
    }

    render() {
        const { loadingDraftsByUserId, loadingSuggestedEventsByUserId, history } = this.props;
        const { events, eventToView } = this.state
        const { SearchBar } = Search;

        const eventsColumns = [
            {
                dataField: "image",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <>
                        <img src={event.image} alt="" className="avatar-sm" />
                    </>
                ),
            },
            {
                text: "Event",
                dataField: "events",
                // eslint-disable-next-line react/display-name
                filterValue: (_, event) => event?.title + event?.description,
                formatter: (_, event) => (
                    <div onClick={() => this.handleRowClick(event)}>
                        <h5 className="font-size-14">
                            {event.title}
                        </h5>
                        <p
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            className="text-muted mb-0"
                        >

                            {event.description}
                        </p>
                    </div>
                ),
            },
            {
                text: "Start date and time",
                dataField: "startDate",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <div onClick={() => this.handleRowClick(event)}>

                        {this.handleValidDate(event.start_date)}
                        <p style={{ marginTop: 10 }}>
                            {moment(event.start_time, "HH:mm:ss").format("hh:mmA")} -{' '}
                            {moment(event.end_time, "HH:mm:ss").format("hh:mmA")}
                        </p>
                    </div>
                ),
            },
            {
                dataField: "capacity",
                text: "Capacity",
                formatter: (_, event) => (
                    <div onClick={() => this.handleRowClick(event)}>

                        <span>
                            {event.capacity}
                        </span>
                    </div>

                ),
            },
            {
                dataField: "price",
                text: "Price",
                formatter: (_, event) => (
                    <div onClick={() => this.handleRowClick(event)}>

                        <span>
                            ${event.price}
                        </span>
                    </div>
                ),
            },
            {
                text: "Action",
                dataField: '',
                formatter: (_, event) => (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="card-drop"
                                tag="a"
                            >
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                {event.title.includes('Draft - ') ? <DropdownItem
                                    href="#"
                                    onClick={() =>
                                        this.deleteDraftHanlder(event.id)
                                    }
                                >
                                    <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                                    Delete Draft
                                </DropdownItem> : <DropdownItem
                                    href="#"
                                    onClick={() => this.handleViewEventClick(event)}
                                >
                                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                    View Event
                                </DropdownItem>}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>

                )
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: events.length,
            custom: true,
        };

        return (
            <React.Fragment>
                <DefaultModal
                    title={'Are you sure you want to delete this draft?'}
                    description={'You will not be able to revert this!'}
                    show={this.state.deleteModal}
                    onSendClick={this.deleteDraft}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Events List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Events" breadcrumbItem="Events List" />
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                <div className="text-sm-end">
                                                    <Link to="/suggest-event">
                                                        <Button
                                                            style={{ backgroundColor: "#032224" }}
                                                            className="font-16 btn-block btn btn-primary"
                                                        >
                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                            Suggest Event
                                                        </Button>
                                                    </Link>
                                                </div>
                                                {events && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={eventsColumns}
                                                        data={events}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={eventsColumns}
                                                                data={events}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                                style={{ marginTop: 10 }}
                                                                            />
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingDraftsByUserId || loadingSuggestedEventsByUserId ? (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="text-success"
                                                                                            >
                                                                                                <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                                                                Loading
                                                                                            </Link>{" "}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <BootstrapTable
                                                                                            responsive
                                                                                            bordered={false}
                                                                                            striped={false}
                                                                                            classes={
                                                                                                "table align-middle table-nowrap"
                                                                                            }
                                                                                            headerWrapperClasses={
                                                                                                "thead-light"
                                                                                            }
                                                                                            {...toolkitProps.baseProps}
                                                                                            {...paginationTableProps}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>
                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                        <Modal
                                            isOpen={this.state.viewEvent}
                                            className={this.props.className}
                                            size="xl"
                                        >
                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                {"Event Details"}
                                            </ModalHeader>
                                            <ModalBody>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{
                                                        id: (eventToView && eventToView.id) || "",
                                                        title: (eventToView && eventToView.title) || "",
                                                        description: (eventToView && eventToView.description) || "",
                                                        startDate:
                                                            (eventToView &&
                                                                eventToView.start_date) ||
                                                            "",
                                                        capacity: eventToView && eventToView.capacity,
                                                        guestInvitesAllowed:
                                                            eventToView && eventToView.guest_invites_allowed,
                                                        location: (eventToView && eventToView.location) || "",
                                                        price: eventToView && eventToView.price,
                                                        startTime: (eventToView && eventToView.start_time) || "",
                                                    }}
                                                >
                                                    {() => (
                                                        <Form>
                                                            <Row>
                                                                <Col className="col-12">

                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Title</Label>
                                                                        <Field
                                                                            name="title"
                                                                            type="text"
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                            disabled
                                                                        />

                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Description
                                                                        </Label>
                                                                        <Field
                                                                            name="description"
                                                                            type="text"
                                                                            component="textarea"
                                                                            rows="6"
                                                                            disabled
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />

                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Location
                                                                        </Label>
                                                                        <Field
                                                                            name="location"
                                                                            type="text"
                                                                            disabled
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Capacity
                                                                        </Label>
                                                                        <Field
                                                                            name="capacity"
                                                                            type="number"
                                                                            disabled
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <Label
                                                                            htmlFor="guestInvitesAllowed"
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Guest Invites Allowed
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Field
                                                                                name="guestInvitesAllowed"
                                                                                disabled

                                                                                className={
                                                                                    "form-control"
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </div>


                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Start Date and Time
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <Field
                                                                                        name="startDate"
                                                                                        type="date"
                                                                                        disabled
                                                                                        className={
                                                                                            "form-control"
                                                                                        }
                                                                                    />
                                                                                </Col>

                                                                                <Col lg="6">
                                                                                    <Field
                                                                                        name="startTime"
                                                                                        type="time"
                                                                                        disabled
                                                                                        className={
                                                                                            "form-control"
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <Label
                                                                            htmlFor="price"
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Price
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Field
                                                                                name="price"
                                                                                disabled

                                                                                className={
                                                                                    "form-control"
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="col-form-label col-lg-2">
                                                                            Image
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <div
                                                                                className="dropzone-previews mt-3"
                                                                                id="file-previews"
                                                                            >

                                                                                <Card
                                                                                    className="mt-1 mb-0 shadow-none "
                                                                                >
                                                                                    <div className="p-2">
                                                                                        <Row className="align-items-center">
                                                                                            <Col className="col-auto">
                                                                                                <img
                                                                                                    data-dz-thumbnail=""
                                                                                                    height="400"
                                                                                                    className="rounded bg-light"
                                                                                                    src={eventToView.image}
                                                                                                />
                                                                                            </Col>

                                                                                        </Row>
                                                                                    </div>
                                                                                </Card>

                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </Form>
                                                    )}
                                                </Formik>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ClubLeaderSuggestedAndDrafts.propTypes = {
    className: PropTypes.any,
    suggestedEventsByUserId: PropTypes.array,
    loadingSuggestedEventsByUserId: PropTypes.bool,
    draftsByUserId: PropTypes.array,
    loadingDraftsByUserId: PropTypes.bool,
    onGetSuggestedEvents: PropTypes.func,
    onResetEventsLoadingState: PropTypes.func,
    onDeleteDraft: PropTypes.func,
    onEventClone: PropTypes.func,
    onGetDrafts: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ events }) => ({
    suggestedEventsByUserId: events.suggestedEventsByUserId,
    loadingSuggestedEventsByUserId: events.loadingSuggestedEventsByUserId,
    draftsByUserId: events.draftsByUserId,
    loadingDraftsByUserId: events.loadingDraftsByUserId,
});

const mapDispatchToProps = dispatch => ({
    onGetSuggestedEvents: () => dispatch(getSuggestedEventsByUserId()),
    onGetDrafts: () => dispatch(getDraftEventsByUserId()),
    onDeleteDraft: (id) => dispatch(deleteDraft(id)),
    onEventClone: (event) => dispatch(cloneEvent(event)),
    onResetEventsLoadingState: (event) => dispatch(resetLoadingState(event)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ClubLeaderSuggestedAndDrafts));