export const GET_CLUBS = "GET_CLUBS";
export const GET_CLUBS_SUCCESS = "GET_CLUBS_SUCCESS";
export const GET_CLUBS_FAIL = "GET_CLUBS_FAIL";

export const GET_ALL_CHILDREN_CATEGORIES = "GET_ALL_CHILDREN_CATEGORIES";
export const GET_ALL_CHILDREN_CATEGORIES_SUCCESS = "GET_ALL_CHILDREN_CATEGORIES_SUCCESS";
export const GET_ALL_CHILDREN_CATEGORIES_FAIL = "GET_ALL_CHILDREN_CATEGORIES_FAIL";

export const SEND_EMAILS = "SEND_EMAILS";
export const SEND_EMAILS_SUCCESS = "SEND_EMAILS_SUCCESS";
export const SEND_EMAILS_FAIL = "SEND_EMAILS_FAIL";

export const SEND_NOTIFICATIONS = "SEND_NOTIFICATIONS";
export const SEND_NOTIFICATIONS_SUCCESS = "SEND_NOTIFICATIONS_SUCCESS";
export const SEND_NOTIFICATIONS_FAIL = "SEND_NOTIFICATIONS_FAIL";

export const DOORDECK_ACCESS_STATUS = 'DOORDECK_ACCESS_STATUS'
export const DOORDECK_ACCESS_STATUS_SUCCESS = "DOORDECK_ACCESS_STATUS_SUCCESS";
export const DOORDECK_ACCESS_STATUS_FAIL = "DOORDECK_ACCESS_STATUS_FAIL";