import {
  UPDATE_LOG_OUT_FROM_APP,
  UPDATE_LOG_OUT_FROM_APP_SUCCESS,
  UPDATE_LOG_OUT_FROM_APP_FAIL,
  SEND_CANCELTION_EMAIL,
  SEND_CANCELTION_EMAIL_SUCCESS,
  SEND_CANCELTION_EMAIL_FAIL,
} from "./actionTypes";

export const logOutFromApp = data => ({
  type: UPDATE_LOG_OUT_FROM_APP,
  payload: { data },
});

export const logOutFromAppSuccess = member => ({
  type: UPDATE_LOG_OUT_FROM_APP_SUCCESS,
  payload: member,
});

export const logOutFromAppFail = error => ({
  type: UPDATE_LOG_OUT_FROM_APP_FAIL,
  payload: error,
});

export const sendCancelationEmail = data => ({
  type: SEND_CANCELTION_EMAIL,
  payload: { data },
});

export const sendCancelationEmailSuccess = member => ({
  type: SEND_CANCELTION_EMAIL_SUCCESS,
  payload: member,
});

export const sendCancelationEmailFail = error => ({
  type: SEND_CANCELTION_EMAIL_FAIL,
  payload: error,
});

