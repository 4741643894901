import PropTypes from "prop-types";
import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";


import {
    getMemberByIdBySubclub,
} from "../../store/actions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class MemberProfileSubClub extends Component {
    constructor(props) {
        super(props);
        const {
            match: { params },
        } = this.props;

        this.state = {
            member: null,
            isNew: null
        };

    }

    componentDidMount() {
        const {
            match: { params },
            onGetMemberById,
        } = this.props;
        if (params && params.id) {
            onGetMemberById(params.id, params.subclubId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.member !== prevProps.member) {
            if (this.props.member?.profile[0]) {
                const isNew = moment().subtract(1, 'month').isBefore(this.props.member?.profile[0]?.sub_club_joined_date)
                this.setState({
                    isNew
                })
            }

        }
    }


    handleValidDate = date => {
        const date1 = moment(date).utc().format("DD MMM Y");
        return date1;
    };


    render() {

        const {
            match: { params },
            history
        } = this.props;

        const { isNew } = this.state

        const memberProfileHeadings = {
            industry: "Industry",
            job_title: "Job Title",
            offerings: "Offerings",
            wants: "Wants",
            community_contribute: "Community Contribution",
            personal_interests: "Personal Interests",
            looking_for: "Looking For",
            contact_reference: "Contact Reference",
            bio: "Bio",
            my_interests: "My Interests",
            where_are_you_in_life: "Where Are You In Life",
            words_best_describe_you: "Words Describe You",
            perfect_day_description: "Describe your perfect day",
            groundfloor_goals: "What are you looking to get out of Gf?",

        };

        const eventsColumns = [
            {
                text: "Events Attended",
                dataField: "title",
            },
            {
                text: "Start Date and Time",
                dataField: "start_date",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <span>
                        {this.handleValidDate(event.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z')} from{" "}
                        {moment(event.start_time, "HH:mm:ss").format("hh:mm A")}
                    </span>
                ),
            },

        ];


        const pageOptions = {
            sizePerPage: 5,
            totalSize: this.props.member?.events?.length,
            custom: true,
        };

        const role = localStorage.getItem("roleId")

        return (
            this.props.member?.profile[0] ? <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        {this.props.member?.profile[0] && <Breadcrumb
                            title={this.props.member?.profile[0].first_name}
                            breadcrumbItem="Profile"
                        />}
                        <Row>
                            <Col lg="12">
                                <Card>
                                    {this.props.member?.profile[0] && <CardBody>
                                        <div className="d-flex">
                                            <div className="me-3">
                                                <img
                                                    src={this.props.member?.profile[0].profile_photo}
                                                    alt=""
                                                    className="avatar-xl"
                                                    style={{
                                                        width: 300,
                                                        height: 300,
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </div>
                                            <div className="align-self-center flex-1">
                                                <div className="text-muted">
                                                    <h5>
                                                        {this.props.member?.profile[0].first_name}{" "}
                                                        {this.props.member?.profile[0].last_name}
                                                        {isNew && <span style={{ color: 'green' }}> (New)</span>}
                                                    </h5>
                                                    <p className="mb-0 font-size-14">
                                                        <b>{this.props.member?.profile[0].pronouns}</b>
                                                    </p>
                                                    <p className="mb-0 font-size-14">
                                                        <b> {this.props.member?.profile[0].other_pronoun}</b>
                                                    </p>

                                                    <p className="mb-0 font-size-14">
                                                        Industry: <b>{this.props.member?.profile[0].industry}</b>
                                                    </p>
                                                    <p className="mb-0 font-size-14">
                                                        Job title: <b>{this.props.member?.profile[0].job_title}</b>
                                                    </p>
                                                    <p className="mb-0 font-size-14">
                                                        Joined club at: <b>{moment(this.props.member?.profile[0].sub_club_joined_date).format('DD MMM Y')}</b>
                                                    </p>
                                                    {/* {role != 5 && <Link to={`/profile/${params.id}/`} ><h6>View full profile</h6></Link>} */}
                                                </div>
                                            </div>


                                        </div>
                                    </CardBody>}
                                </Card>
                            </Col>
                        </Row>

                        <Card>
                            {this.props.member?.profile[0] && <CardBody>
                                {[...new Set(Object.keys(this.props.member?.profile[0])
                                    .filter(item =>
                                        [
                                            "industry",
                                            "job_title",
                                            "offerings",
                                            "wants",
                                            "community_contribute",
                                            "personal_interests",
                                            "looking_for",
                                            "contact_reference",
                                            "where_are_you_in_life",
                                            "bio",
                                            "my_interests",
                                            "words_best_describe_you",
                                            "perfect_day_description",
                                            "groundfloor_goals",
                                        ].includes(item)
                                    ))]
                                    .map(
                                        (item, index) => {
                                            return this.props.member?.profile[0][item] && (
                                                <Row key={index}>
                                                    <div>
                                                        <div className="d-flex">
                                                            <div className="align-self-center flex-1">
                                                                <div className="text-muted">
                                                                    <h5>{memberProfileHeadings[item]}</h5>
                                                                    <p className="mb-1">
                                                                        {this.props.member?.profile[0][item]}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br></br>
                                                    </div>
                                                </Row>
                                            )
                                        }
                                    )}
                            </CardBody>}
                        </Card>


                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        // paddingLeft: 10,
                                                        borderBottom: '1px solid #F0F2F6'
                                                    }}
                                                >
                                                    <h4 style={{
                                                        marginLeft: 10
                                                    }}>Event booking history</h4>

                                                </div>
                                                {this.props.member?.events?.length > 0 && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={eventsColumns}
                                                        data={this.props.member?.events}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={eventsColumns}
                                                                data={this.props.member?.events}

                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>

                                                                            <Col xl="12">
                                                                                <div className="table-responsive">

                                                                                    <BootstrapTable
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={
                                                                                            "thead-light"
                                                                                        }
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />

                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Col>


                        </Row>


                    </Container>

                </div>
            </React.Fragment> : null
        );
    }
}

MemberProfileSubClub.propTypes = {
    match: PropTypes.object,
    error: PropTypes.any,
    className: PropTypes.any,

    member: PropTypes.object,
    onGetMemberById: PropTypes.func,

    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ members, }) => ({
    member: members.member,
});

const mapDispatchToProps = dispatch => ({
    onGetMemberById: (userId, subclubId) => dispatch(getMemberByIdBySubclub(userId, subclubId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MemberProfileSubClub));
