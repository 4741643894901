import {
  GET_GUESTS,
  GET_GUESTS_SUCCESS,
  GET_GUESTS_FAIL,
} from "./actionTypes";

export const getGuests = (clubId, date) => ({
  type: GET_GUESTS,
  payload: { date, clubId },
});

export const getGuestsSuccess = guests => ({
  type: GET_GUESTS_SUCCESS,
  payload: guests,
});

export const getGuestsFail = error => ({
  type: GET_GUESTS_FAIL,
  payload: error,
});