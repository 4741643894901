import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes, { number } from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TreeSelect, Select, Switch } from "antd";
import classnames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "reactstrap";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ExportToCsv } from "export-to-csv";
import SelectR from "react-select"


import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import {
  getAllChildrenCategories,
  getClubs,
  getHighRiskMembers,
  getMembers,
  getNewMembers,
} from "../../store/actions";
import moment from "moment";

const CategoriesEnum = {
  looking_for: "looking_for",
  pronouns: "pronouns",
  my_interests: "my_interests",
  industry: "industry",
  job_title: "job_title",
  community_contribute: "community_contribute",
  where_are_you_in_life: "where_are_you_in_life",
  words_best_describe_you: "words_best_describe_you",
};



class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      selectedCategory: null,
      membersList: [],
      orAnd: true,
      activeClub: null,
      operatorSelected: 1,
      categoriesSelected: [],
      lookingForFiltered: [],
      pronounsFiltered: [],
      myInterestsFiltered: [],
      industryFiltered: [],
      jobTitleFiltered: [],
      communityContributeFiltered: [],
      whereAreYouInLifeFiltered: [],
      wordsDescribeYouFiltered: [],
      showNewMembers: false,
      trialButtonText: 'Show new members',
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      clubSelected: { label: "No Club Selected", value: '' },
      clubsOptions: [],
    };
    this.handleTreeSelectCategories = this.handleTreeSelectCategories.bind(this)
    this.getChildrenIntoArrays = this.getChildrenIntoArrays.bind(this);
    this.copyEmailToClipBoard = this.copyEmailToClipBoard.bind(this);
    this.showSuccessToast = this.showSuccessToast.bind(this);

    this.downloadCsv = this.downloadCsv.bind(this);


  }

  componentDidMount() {
    const {
      clubs,
      onGetClubs,
      onGetHighRiskMembers,
      onGetAllChildrenCategories,
    } = this.props;
    const authUser = localStorage.getItem("authUser")
    if (JSON.parse(authUser)?.clubId) {
      this.setState({
        activeClub: JSON.parse(authUser).clubId
      })
    }
    if (clubs.length !== 0) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    } else {
      onGetClubs();
    }
    onGetAllChildrenCategories();
    onGetHighRiskMembers()
  }

  handleClubSelect = club => {
    this.setState({
      clubSelected: club,
      activeClub: club.value,
      showNewMembers: false
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGetMembers, clubs, members, childrenCategories, highRiskMemers, onGetNewMembers, newMembers } = this.props;
    const { activeClub } = this.state;
    if (clubs !== prevProps.clubs) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    }
    if (activeClub !== prevState.activeClub) {
      if (activeClub) {
        onGetMembers(activeClub);
        onGetNewMembers(activeClub)
      }
    }
    if (members !== prevProps.members) {
      const membersWithHighRiskProp = members.map(item => {
        const userFound = this.props.highRiskMemers?.find(item2 => item2.email === item.email)
        const highRisk = userFound ? 'Yes' : 'No'
        return { ...item, highRisk }
      })
      this.setState({
        membersList: membersWithHighRiskProp,
      });
    }

    if (highRiskMemers !== prevProps.highRiskMemers) {
      const membersWithHighRiskProp = members.map(item => {
        const userFound = this.props.highRiskMemers?.find(item2 => item2.email === item.email)
        const highRisk = userFound ? 'Yes' : 'No'
        return { ...item, highRisk }
      })
      this.setState({
        membersList: membersWithHighRiskProp,
      });
    }

    if (childrenCategories !== prevProps.childrenCategories) {
      this.getChildrenIntoArrays();
    }

    if (this.state.showNewMembers !== prevState.showNewMembers) {
      const membersWithHighRiskProp = members.map(item => {
        const userFound = this.props.highRiskMemers?.find(item2 => item2.email === item.email)
        const highRisk = userFound ? 'Yes' : 'No'
        return { ...item, highRisk }
      })
      if (this.state.showNewMembers) {
        this.setState({
          membersList: membersWithHighRiskProp.filter(item => {
            return moment().diff(moment(item?.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z'), 'days') <= 30
            console.log()
          }),
          trialButtonText: 'Show all'
        })
      } else {
        this.setState({
          membersList: membersWithHighRiskProp,
          trialButtonText: 'Show new members'
        })
      }
    }

  }

  showSuccessToast(message, autoClose) {
    toast.success(message, {
      position: "top-right",
      autoClose: autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  onHandleChange = (value, label, extra) => {

    let filteredArray;

    const {
      operatorSelected,
      lookingForFiltered,
      pronounsFiltered,
      myInterestsFiltered,
      industryFiltered,
      jobTitleFiltered,
      communityContributeFiltered,
      whereAreYouInLifeFiltered,
      wordsDescribeYouFiltered,
    } = this.state;


    filteredArray = this.props.members.filter(function (itm) {

      const lookingFor = itm?.looking_for != null ? itm?.looking_for.split(";") : [];
      const pronouns = itm?.pronouns != null ? itm?.pronouns.split(";") : [];
      const myInterests = itm?.my_interests != null ? itm?.my_interests.split(";") : [];
      const communityContribute = itm?.community_contribute != null ? itm?.community_contribute.split(";") : [];
      const industry = itm?.industry != null ? itm?.industry.split(";") : [];
      const jobTitle = itm?.job_title != null ? itm?.job_title.split(";") : [];
      const whereAreYouInLife = itm?.where_are_you_in_life != null ? itm?.where_are_you_in_life.split(";") : [];
      const wordsDescribeYou = itm?.words_best_describe_you != null ? itm?.words_best_describe_you.split(";") : [];

      if (operatorSelected == 1) {
        return (
          label.some(r => lookingFor.indexOf(r) >= 0) ||
          label.some(r => pronouns.indexOf(r) >= 0) ||
          label.some(r => myInterests.indexOf(r) >= 0) ||
          label.some(r => communityContribute.indexOf(r) >= 0) ||
          label.some(r => industry.indexOf(r) >= 0) ||
          label.some(r => jobTitle.indexOf(r) >= 0) ||
          label.some(r => whereAreYouInLife.indexOf(r) >= 0) ||
          label.some(r => wordsDescribeYou.indexOf(r) >= 0)
        );
      } else if (operatorSelected == 2) {
        //TODO: find better solution

        var lookingForArray = []
        var pronounsArray = []
        var myInterestsArray = []
        var industryArray = []
        var jobTitleArray = []
        var communityContributeArray = []
        var whereAreYouInLifeArray = []
        var wordsDescribeYouArray = []

        //put selected values into their categories
        for (let index = 0; index < label.length; index++) {
          if ([label[index]].some(r => lookingForFiltered.indexOf(r) >= 0)) {
            lookingForArray.push(label[index]);
          } else if ([label[index]].some(r => pronounsFiltered.indexOf(r) >= 0)) {
            pronounsArray.push(label[index]);
          } else if ([label[index]].some(r => myInterestsFiltered.indexOf(r) >= 0)) {
            myInterestsArray.push(label[index]);
          } else if ([label[index]].some(r => industryFiltered.indexOf(r) >= 0)) {
            industryArray.push(label[index]);
          } else if ([label[index]].some(r => jobTitleFiltered.indexOf(r) >= 0)) {
            jobTitleArray.push(label[index]);
          } else if ([label[index]].some(r => communityContributeFiltered.indexOf(r) >= 0)) {
            communityContributeArray.push(label[index]);
          } else if ([label[index]].some(r => whereAreYouInLifeFiltered.indexOf(r) >= 0)) {
            whereAreYouInLifeArray.push(label[index]);
          } else if ([label[index]].some(r => wordsDescribeYouFiltered.indexOf(r) >= 0)) {
            wordsDescribeYouArray.push(label[index]);
          }
        }

        const categoryArray = [
          lookingForArray,
          pronounsArray,
          myInterestsArray,
          industryArray,
          jobTitleArray,
          communityContributeArray,
          whereAreYouInLifeArray,
          wordsDescribeYouArray
        ];

        // check results for each category
        const lookingForResult = lookingForArray.every(i => lookingFor.includes(i));
        const pronounsResult = pronounsArray.every(i => pronouns.includes(i));
        const myInterestsResult = myInterestsArray.every(i => myInterests.includes(i));
        const industryResult = industryArray.every(i => industry.includes(i));
        const jobTitleResult = jobTitleArray.every(i => jobTitle.includes(i));
        const communityContrubtionResult = communityContributeArray.every(i => communityContribute.includes(i));
        const whereAreYouInLifeResult = whereAreYouInLifeArray.every(i => whereAreYouInLife.includes(i));
        const wordsDescribeYouResult = wordsDescribeYouArray.every(i => wordsDescribeYou.includes(i));

        //results array needs to be in the same order as category array
        const results = [
          lookingForResult,
          pronounsResult,
          myInterestsResult,
          industryResult,
          jobTitleResult,
          communityContrubtionResult,
          whereAreYouInLifeResult,
          wordsDescribeYouResult
        ]

        // get the results only for the categories selected
        //if order of categoryArray is not the same as results array the below won't give an accurate result
        const resultsToCheck = []
        for (let index = 0; index < categoryArray.length; index++) {
          if (categoryArray[index].length > 0) {
            resultsToCheck.push(results[index])
          }
        }

        // perform AND operator for the categories selected
        return resultsToCheck.length > 0 && resultsToCheck.indexOf(false) == -1;
      }
    });

    if (filteredArray.length > 0) {
      const membersWithHighRiskProp = filteredArray.map(item => {
        const userFound = this.props.highRiskMemers?.find(item2 => item2.email === item.email)
        const highRisk = userFound ? 'Yes' : 'No'
        return { ...item, highRisk }
      })
      this.setState({
        membersList: membersWithHighRiskProp,
      });
    } else if (filteredArray.length == 0 && label.length > 0) {
      this.setState({
        membersList: [],
      });
    } else {
      const membersWithHighRiskProp = this.props.members.map(item => {
        const userFound = this.props.highRiskMemers?.find(item2 => item2.email === item.email)
        const highRisk = userFound ? 'Yes' : 'No'
        return { ...item, highRisk }
      })
      this.setState({
        membersList: membersWithHighRiskProp,
      });
    }

    this.setState({
      categoriesSelected: label,
    });

  };

  downloadCsv() {
    const { membersList } = this.state

    const membersToDownload = membersList.map(item => {
      return ({
        FirstName: item.first_name,
        LastName: item.last_name,
        Email: item.email,
        PhoneNumber: item.phone_number
      })
    })

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `Members`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `Members`
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(membersToDownload);
  }


  filterCategories = (id) => {
    const { childrenCategories } = this.props;
    return childrenCategories
      .filter(item => item.system_category_id === id)
      .map(item => (item.name));
  }

  getChildrenIntoArrays = () => {
    this.setState({
      lookingForFiltered: this.filterCategories("0"),
      pronounsFiltered: this.filterCategories("1"),
      myInterestsFiltered: this.filterCategories("2"),
      industryFiltered: this.filterCategories("3"),
      jobTitleFiltered: this.filterCategories("4"),
      communityContributeFiltered: this.filterCategories("5"),
      whereAreYouInLifeFiltered: this.filterCategories("11"),
      wordsDescribeYouFiltered: this.filterCategories("12"),
    });

  }

  copyEmailToClipBoard(email) {
    this.showSuccessToast("Email copied to clipboard", 500)
    navigator.clipboard.writeText(
      "" + email
    );
  }


  handleTreeSelectCategories = (id) => {
    const { childrenCategories } = this.props;
    return childrenCategories
      .filter(item => item.system_category_id === id)
      .map(item => ({ label: item.name, value: item.id }));
  }

  handleSelectOperator = (value) => {
    this.setState({ operatorSelected: value }, () => {
      if (this.state.categoriesSelected.length > 0) {
        this.onHandleChange('', this.state.categoriesSelected)
      }
    });
  }

  render() {
    const { loadingActiveMembers, members, clubs } = this.props;
    const { membersList, activeClub } =
      this.state;

    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "first_name",
        text: "First Name",
        sort: true,
        filterValue: (_, user) => `${user?.first_name} ${user?.last_name}`,
        formatter: (value, row) => {
          const isWithin15DaysOfStartDate = moment().isBetween(moment(row.subscription_date, 'YYYY-MM-DDTHH:mm:ss.000Z'), moment(row.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z'))
          return <div>
            <span>{row.first_name}
            </span>
            {row.trial === 1 && isWithin15DaysOfStartDate && <span style={{ color: 'red' }}>{' (On trial)'}</span>}
          </div>


        },
      },
      {
        dataField: "last_name",
        text: "Last Name",
        sort: true,
      },
      {
        dataField: "phone_number",
        text: "Phone number",
        sort: false,
      },
      {
        dataField: "email",
        text: "Email",
        sort: false,
        addEventListener: () => { },
        formatter: (value, row) => {

          return <span>{row.email}
            <i style={{ marginLeft: 15, position: 'absolute' }} onClick={() => this.copyEmailToClipBoard(row.email)} className="mdi mdi-clipboard-file font-size-16 clipboard-copy me-1" />
          </span>

        },

      },
      {
        dataField: "highRisk",
        text: "High risk",
        sort: true,
      },
      {
        dataField: "subscription_canceled",
        text: "Canceled",
        sort: false,
        formatter: (value, row) => (value == 1 ? "Canceled" : ""),
      },
      {
        dataField: "on_pause",
        text: "On Pause",
        sort: false,
        formatter: (value, row) => (value == 1 ? "On Pause" : ""),
      },
    ];

    const categoriesTreeSelect = [
      {
        title: "Looking For",
        key: "0",
        value: CategoriesEnum.looking_for,
        children: this.handleTreeSelectCategories("0"),
      },
      {
        title: "Pronouns",
        key: "1",
        value: CategoriesEnum.pronouns,
        children: this.handleTreeSelectCategories("1"),
      },
      {
        title: "My Interests",
        key: "2",
        value: CategoriesEnum.my_interests,
        children: this.handleTreeSelectCategories("2"),
      },
      {
        title: "Industry",
        key: "3",
        value: CategoriesEnum.industry,
        children: this.handleTreeSelectCategories("3"),
      },
      {
        title: "Job Title",
        key: "4",
        value: CategoriesEnum.job_title,
        children: this.handleTreeSelectCategories("4"),
      },
      {
        title: "Community Contributions",
        key: "5",
        value: CategoriesEnum.community_contribute,
        children: this.handleTreeSelectCategories("5"),
      },
      {
        title: "Where Are You In Life",
        key: "11",
        value: CategoriesEnum.where_are_you_in_life,
        children: this.handleTreeSelectCategories("11"),
      },
      {
        title: "Words Describe You",
        key: "12",
        value: CategoriesEnum.words_best_describe_you,
        children: this.handleTreeSelectCategories("12"),
      },
    ];


    const operators = [
      {
        label: "OR",
        value: "1"
      },
      {
        label: "AND",
        value: "2"
      }
    ]

    const pageOptions = {
      sizePerPage: 10,
      totalSize: membersList?.length,
      custom: true,
    };

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        // history.push(`/profile/${row.memberId}/`);
        window.open(`https://dashboard.groundfloorapp.com/profile/${row.memberId}/`, '_blank', 'noopener noreferrer');
      },
    };
    const payingMembers = members.filter(
      member => member.subscription_type > 0
    );
    const freeMembers = members.filter(member => member.subscription_type == 0);

    return (
      <React.Fragment>
        <div className="page-content">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <MetaTags>
            <title> Members List | Groundfloor</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={"Members"} breadcrumbItem={"Members List"} />
            <p>Paying members: {payingMembers.length} </p>
            <p>Free members: {freeMembers.length} </p>
            <p>Total active members: {members.length} </p>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {this.state.isMobile ? <Row>
                      <Col>
                        <Button
                          style={{ backgroundColor: this.state.showNewMembers ? "#032224" : '#F0F2F6', color: this.state.showNewMembers ? "#fff" : "#042225", marginBottom: 10 }}
                          className="font-16 btn-block btn btn-primary"
                          color={'#032224'}
                          onClick={() => this.setState({
                            showNewMembers: !this.state.showNewMembers
                          })}
                        >
                          {this.state.trialButtonText}
                        </Button>
                        <SelectR
                          styles={{
                          }}
                          onChange={
                            this.handleClubSelect
                          }
                          options={this.state.clubsOptions}
                          value={this.state.clubSelected}
                          classNamePrefix="select2-selection"
                        />

                      </Col>
                    </Row> : <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <Nav pills className="bg-light rounded" role="tablist">
                        {clubs?.map((item, index) => (
                          <NavItem key={index}>
                            <NavLink
                              className={classnames({
                                active: activeClub === item.id,
                              })}
                              style={{
                                backgroundColor:
                                  activeClub === item.id
                                    ? "#032224"
                                    : "transparent",
                              }}
                              onClick={() => {
                                this.setState({
                                  activeClub: item.id,
                                  showNewMembers: false
                                });
                              }}
                            >
                              {item.name}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                      {<div className="text-sm-end" >
                        <Button
                          style={{ backgroundColor: this.state.showNewMembers ? "#032224" : '#F0F2F6', color: this.state.showNewMembers ? "#fff" : "#042225", marginRight: 10 }}
                          className="font-16 btn-block btn btn-primary"
                          color={'#032224'}
                          onClick={() => this.setState({
                            showNewMembers: !this.state.showNewMembers
                          })}
                        >
                          {this.state.trialButtonText}
                        </Button>
                        <Button
                          style={{ backgroundColor: "#032224", marginRight: 10 }}
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.downloadCsv}
                        >
                          Download CSV
                        </Button>
                      </div>}
                    </div>}
                    {(
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={membersList}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={membersList}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>

                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  // style={{ width: "400px", height: "40px" }}
                                  />
                                  {/* <Col lg="6"  style={{width: '5%', marginTop: '30px'}}>
                                      <Switch 
                                       checkedChildren="Or" unCheckedChildren="And" defaultChecked onClick={() => this.setState({orAnd: !this.state.orAnd})}
                                      />
                                  </Col> */}
                                  <Label>
                                    Select Category
                                  </Label>
                                  <Col lg="6" style={{ width: "100%" }}>
                                    <div className="mb-3" style={{ display: "flex" }}>
                                      <TreeSelect
                                        treeData={categoriesTreeSelect}
                                        showdropdown="always"
                                        treeCheckable={true}
                                        placeholder="Please select"
                                        style={{ width: "100%" }}
                                        onChange={this.onHandleChange}
                                        showSearch
                                        treeNodeFilterProp='label'
                                        allowClear
                                      />
                                      <Select
                                        onChange={this.handleSelectOperator}
                                        options={operators}
                                        defaultValue={operators[0]}
                                        placeholder="Please select"
                                        style={{ width: "15%" }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  {/* <Link
                                  to="/profile"
                                  className="text-muted font-weight-bold"
                                > */}
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      {loadingActiveMembers ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                        keyField={"email"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        rowEvents={tableRowEvents}
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />}
                                    </div>
                                  </Col>
                                  {/* </Link> */}
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Members.propTypes = {
  members: PropTypes.array,
  onGetMembers: PropTypes.func,
  onGetAllChildrenCategories: PropTypes.func,
  onGetHighRiskMembers: PropTypes.func,
  loadingActiveMembers: PropTypes.bool,
  onHandleChange: PropTypes.func,
  onOperatorFilterchange: PropTypes.func,
  childrenCategories: PropTypes.array,
  highRiskMemers: PropTypes.array,
  onGetClubs: PropTypes.func,
  newMembers: PropTypes.array,
  onGetNewMembers: PropTypes.func,
  clubs: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = ({ members, system }) => ({
  members: members.members,
  loadingActiveMembers: members.loadingActiveMembers,
  highRiskMemers: members.highRiskMemers,
  childrenCategories: system.childrenCategories,
  clubs: system.clubs,
  newMembers: members.newMembers,
});

const mapDispatchToProps = dispatch => ({
  onGetMembers: clubId => dispatch(getMembers(clubId)),
  onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),
  onGetClubs: () => dispatch(getClubs()),
  onGetHighRiskMembers: () => dispatch(getHighRiskMembers()),
  onGetNewMembers: (clubId) => dispatch(getNewMembers(clubId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Members));
