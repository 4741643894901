import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";
import moment from "moment";

const getEvents = token => {
    return get(url.EVENTS, {
        headers: {
            Authorization: token,
        },
    });
};

const getEventsByClub = (token, id) => {
    return get(url.GET_EVENTS_BY_CLUB(id), {
        headers: {
            Authorization: token,
        },
    });
};

const getEventAttendanceAPI = (token, userId) => {
    return get(url.GET_EVENT_ATTENDANCE(userId), {
        headers: {
            Authorization: token,
        },
    });
};

const getEventPossibleMatchesAPI = (token, eventId) => {
    return get(url.GET_EVENT_POSSIBLE_MATCHES(eventId), {
        headers: {
            Authorization: token,
        },
    });
};

const updateSpotlight = (token, eventId, spotlight) => {
    return put(
        url.UPDATE_SPOTLIGHT(eventId),
        {
            spotlight
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );
}

const deleteEvent = (token, id) => {
    return del(url.MUTATE_EVENT(id), {
        headers: {
            Authorization: token,
        },
    });
};

const cancelEvent = (token, eventId) => {
    return put(url.CANCEL_EVENT(eventId), {
        headers: {
            Authorization: token,
        },
    });
};

const updateEvent = (token, data) => {
    return put(
        url.MUTATE_EVENT,
        {
            id: data.id,
            title: data.title,
            description: data.description,
            startDate: data.startDate,
            startTime: data.startTime,
            endTime: data.endTime,
            image: data.image,
            capacity: data.capacity,
            guestInvitesAllowed: data.guestInvitesAllowed,
            price: data.price,
            requiresPayment: data.requiresPayment,
            location: data.location,
            link: data.link,
            bookingConfirmation: data.bookingConfirmation,
            clubId: data.clubId,
            category: data.category,
            instructorId: data.instructorId,
            instructorName: data.instructorName,
            host: data.host,
            checkinRequired: data.checkinRequired,
            interests: data.interests,
            spotlight: data.spotlight,
            allClubs: data.allClubs,
            hostId: data.hostId,
            salesEnd: data.salesEnd,
        },
        {
            headers: {
                Authorization: token,
                Accept: "multipart/form-data",
            },
        }
    );
};

const updateEventClubLeaderAPI = (token, data) => {
    return put(
        url.MUTATE_EVENT_CLUB_LEADER,
        {
            ...data
        },
        {
            headers: {
                Authorization: token,
                Accept: "multipart/form-data",
            },
        }
    );
};

const updateInstructorEvent = (token, data) => {
    return put(
        url.MUTATE_INSTRUCTOR_EVENT,
        {
            id: data.id,
            title: data.title,
            description: data.description,
            startDate: data.startDate,
            startTime: data.startTime,
            endTime: data.endTime,
            image: data.image,
            capacity: data.capacity,
            guestInvitesAllowed: data.guestInvitesAllowed,
            price: data.price,
            requiresPayment: data.requiresPayment,
            location: data.location,
            link: data.link,
            bookingConfirmation: data.bookingConfirmation,
            clubId: data.clubId,
            category: data.category,
            instructorId: data.instructorId,
            instructorName: data.instructorName,
            host: data.host,
            checkinRequired: data.checkinRequired,
            interests: data.interests,
            spotlight: data.spotlight,
            hostId: data.hostId,
            salesEnd: data.salesEnd,
        },
        {
            headers: {
                Authorization: token,
                Accept: "multipart/form-data",
            },
        }
    );
};

const getPastEvents = (token, clubId) => {
    return get(url.PAST_EVENTS(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

const getSuggestedEvents = () => {
    return get(url.AIRTABLE_RECORDS, {
        headers: {
            Authorization: 'Bearer pat8B7TF34TJzv0OT.be1e5774cf99682fd9f29ed5a05fc4fd44dd15007a7bf1345aad2d325e2a5219',
        },
    });
};

const getSuggestedEventsByClubIdAPI = (token, clubId) => {
    return get(url.SUGGESTED_EVENTS_BY_CLUB_ID(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

const getCalendlyScheduledEvents = () => {
    return get(`${url.CALENDLY_SCHEDULED_EVENTS}?organization=https%3A%2F%2Fapi.calendly.com%2Forganizations%2FCHAG6KFOYR7FANTV&count=100&sort=start_time:asc&status=active&min_start_time=${moment()}`, {
        headers: {
            Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjgwNjAyODg1LCJqdGkiOiJmNzBhYTNkYS00NTdmLTRiNDQtOTk3ZS0zMGFhMjc2Mjg0NTAiLCJ1c2VyX3V1aWQiOiJhODliMjBhMS0wZWQ1LTRiMTAtYTg3ZS1iNDBkMDdmZmMwYzEifQ.tRfWo0r4aDrRd9AVrumBynJUUNCHJrVfwT-XNLTeYfqDEEAyhb1k71FvHKEUMbjsP846SAgWgeNNZHbW9WLTmg`,
        }
    });
};

const getCalendlyEvent = (eventId) => {
    return get(`${url.CALENDLY_SCHEDULED_EVENTS}/${eventId}`, {
        headers: {
            Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjgwNjAyODg1LCJqdGkiOiJmNzBhYTNkYS00NTdmLTRiNDQtOTk3ZS0zMGFhMjc2Mjg0NTAiLCJ1c2VyX3V1aWQiOiJhODliMjBhMS0wZWQ1LTRiMTAtYTg3ZS1iNDBkMDdmZmMwYzEifQ.tRfWo0r4aDrRd9AVrumBynJUUNCHJrVfwT-XNLTeYfqDEEAyhb1k71FvHKEUMbjsP846SAgWgeNNZHbW9WLTmg`,
        }
    });
}

const getCalendlyEventInvitees = (eventId) => {
    return get(`${url.CALENDLY_SCHEDULED_EVENTS}/${eventId}/invitees?count=100`, {
        headers: {
            Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjgwNjAyODg1LCJqdGkiOiJmNzBhYTNkYS00NTdmLTRiNDQtOTk3ZS0zMGFhMjc2Mjg0NTAiLCJ1c2VyX3V1aWQiOiJhODliMjBhMS0wZWQ1LTRiMTAtYTg3ZS1iNDBkMDdmZmMwYzEifQ.tRfWo0r4aDrRd9AVrumBynJUUNCHJrVfwT-XNLTeYfqDEEAyhb1k71FvHKEUMbjsP846SAgWgeNNZHbW9WLTmg`,
        }
    });
}

const createNewEvent = (token, data) => {
    let draftId = null
    if (data?.draftId) {
        draftId = data.draftId
    }
    let suggestEventId = null
    if (data?.suggestEventId) {
        suggestEventId = data.suggestEventId
    }
    let hostId = null
    if (data?.hostId) {
        hostId = data.hostId
    }
    return post(
        url.CREATE_EVENT,
        {
            userId: data.userId,
            title: data.title,
            description: data.description,
            startDate: data.startDate,
            startTime: data.startTime,
            endTime: data.endTime,
            image: data.image,
            capacity: data.capacity,
            guestInvitesAllowed: data.guestInvitesAllowed,
            price: data.price,
            requiresPayment: data.requiresPayment,
            sendPushNotification: data.sendPushNotification,
            checkinRequired: data.checkinRequired,
            bookingConfirmation: data.bookingConfirmation,
            location: data.location,
            link: data.link,
            category: data.category,
            instructorId: data.instructorId,
            clubId: data.clubId,
            instructorName: data.instructorName,
            host: data.host,
            allClubs: data.allClubs,
            interests: data.interests,
            spotlight: data.spotlight,
            hostId: hostId,
            salesEnd: data.salesEnd,
            suggestEventId,
            draftId
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );
}

const suggestNewEvent = (token, data) => {
    let draftId = null
    if (data?.draftId) {
        draftId = data.draftId
    }
    return post(
        url.SUGGEST_EVENT,
        { ...data, draftId },
        {
            headers: {
                Authorization: token,
            },
        }
    );
}

const updateSuggestedEventAPI = (token, data) =>
    put(
        url.SUGGEST_EVENT,
        data,
        {
            headers: {
                Authorization: token,
            },
        }
    );


const createDraftAPI = (token, data) =>
    post(
        url.DRAFT_EVENT,
        data,
        {
            headers: {
                Authorization: token,
            },
        }
    );

const updateDraftAPI = (token, data) =>
    put(
        url.DRAFT_EVENT,
        data,
        {
            headers: {
                Authorization: token,
            },
        }
    );

const createNewEventInstructor = (token, data) =>
    post(
        url.CREATE_EVENT_INSTRUCTOR,
        {
            userId: data.userId,
            title: data.title,
            description: data.description,
            startDate: data.startDate,
            startTime: data.startTime,
            endTime: data.endTime,
            image: data.image,
            capacity: data.capacity,
            guestInvitesAllowed: data.guestInvitesAllowed,
            price: data.price,
            requiresPayment: data.requiresPayment,
            sendPushNotification: data.sendPushNotification,
            checkinRequired: data.checkinRequired,
            bookingConfirmation: data.bookingConfirmation,
            location: data.location,
            link: data.link,
            category: data.category,
            instructorId: data.instructorId,
            clubId: data.clubId,
            instructorName: data.instructorName,
            host: data.host,
            interests: data.interests,
            spotlight: data.spotlight,
            hostId: data.hostId,
            salesEnd: data.salesEnd,
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );

const sendNewEventNotification = (token, data) =>
    post(
        url.NEW_EVENT_NOTIFICATION,
        {
            id: data.id,
            title: data.title,
            description: data.description
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );

const getEventsByInstructorId = (token, id) => {
    return get(url.EVENTS_BY_INSTRUCTOR(id), {
        headers: {
            Authorization: token,
        },
    });
};

const getPastEventsByInstructorIdAPI = (token, id) => {
    return get(url.PAST_EVENTS_BY_INSTRUCTOR(id), {
        headers: {
            Authorization: token,
        },
    });
};

const getInstructors = (token, roleId) => {
    return get(url.INSTRUCTOR(roleId), {
        headers: {
            Authorization: token,
        },
    });
};
const getClubLeaderUpcomingEvents = (token, subclubId) => {
    return get(url.CLUB_LEADER_UPCOMING_EVENTS(subclubId), {
        headers: {
            Authorization: token,
        },
    });
};
const getClubLeaderPastEvents = (token, subclubId) => {
    return get(url.CLUB_LEADER_PAST_EVENTS(subclubId), {
        headers: {
            Authorization: token,
        },
    });
};

const getDraftsByUserIdAPI = (token, userId) => {
    return get(url.DRAFTS_BY_USER_ID(userId), {
        headers: {
            Authorization: token,
        },
    });
};

const getSuggestedEventsByUserIdAPI = (token, userId) => {
    return get(url.SUGGESTED_EVENTS_BY_USER_ID(userId), {
        headers: {
            Authorization: token,
        },
    });
};

const deleteDraftAPI = (token, id) => {
    return del(url.DELETE_DRAFT(id), {
        headers: {
            Authorization: token,
        },
    });
};

const sendEmailToEventAttendeesAPI = (token, data) =>
    post(
        url.SEND_EMAIL_TO_ATTENDEES,
        data,
        {
            headers: {
                Authorization: token,
            },
        }
    );

const deleteSuggestedEventAPI = (token, id) => {
    return del(url.DELETE_SUGGESTED_EVENT(id), {
        headers: {
            Authorization: token,
        },
    });
};

export {
    createNewEvent, deleteEvent, getEvents, updateEvent, getPastEvents, getEventsByInstructorId, getInstructors, getEventsByClub, sendNewEventNotification, getCalendlyScheduledEvents, getCalendlyEvent,
    getCalendlyEventInvitees, getSuggestedEvents, cancelEvent, getClubLeaderPastEvents,
    updateSpotlight, getEventPossibleMatchesAPI, suggestNewEvent, getClubLeaderUpcomingEvents,
    getEventAttendanceAPI, getPastEventsByInstructorIdAPI, createNewEventInstructor, updateInstructorEvent, getSuggestedEventsByClubIdAPI,
    getDraftsByUserIdAPI, getSuggestedEventsByUserIdAPI, createDraftAPI, deleteDraftAPI, updateSuggestedEventAPI, updateDraftAPI,
    sendEmailToEventAttendeesAPI, deleteSuggestedEventAPI, updateEventClubLeaderAPI
}