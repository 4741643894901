import { call, put, takeEvery } from "redux-saga/effects";

import {
  UPDATE_LOG_OUT_FROM_APP,
  SEND_CANCELTION_EMAIL,
} from "./actionTypes";
import {
  logOutFromAppSuccess,
  logOutFromAppFail,
  sendCancelationEmailSuccess,
  sendCancelationEmailFail,
} from "./actions";

import {
  updateLogOutFromApp,
  sendCancelationEmail,
} from "helpers/backend_helper";

export function* logOutFromApp({ payload: { data } }) {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(updateLogOutFromApp, token, data);

    yield put(logOutFromAppSuccess(response));
  } catch (error) {
    yield put(logOutFromAppFail(error));
  }
}

export function* cancelationEmail({ payload: { data } }) {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(sendCancelationEmail, token, data);

    yield put(sendCancelationEmailSuccess(response));
  } catch (error) {
    yield put(sendCancelationEmailFail(error));
  }
}

function* projectsSaga() {
  yield takeEvery(UPDATE_LOG_OUT_FROM_APP, logOutFromApp);
  yield takeEvery(SEND_CANCELTION_EMAIL, cancelationEmail)
}

export default projectsSaga;
