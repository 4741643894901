import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";

import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    Button,
} from "reactstrap";
import Select from "react-select";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
    getClubs,
    getAllChildrenCategories,
    getOrders,
    orderRecieved,
} from "store/actions";
import DefaultModal from "components/Common/DefaultModal";

class OrdersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            clubId: null,
            club: "No Club Selected",
            activeClub: null,
            productTypes: [],
            productType: '0',
            productLabel: 'No Type Selected',
            typeError: false,
            hidden: false,
            recievedModal: false,
            selectedOrder: null,
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            clubSelected: { label: "No Club Selected", value: '' },
            clubsOptions: [],
        };

    }

    componentDidMount() {
        const {
            orders,
            onGetOrders,
            onGetClubs,
            onGetAllChildrenCategories,
            clubs
        } = this.props;
        const authUser = localStorage.getItem("authUser")
        if (JSON.parse(authUser)?.clubId) {
            this.setState({
                activeClub: JSON.parse(authUser).clubId
            })
        }
        if (clubs.length !== 0) {
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        } else {
            onGetClubs();
        }
        onGetAllChildrenCategories()
        this.setState({
            orders,
        });
    }

    filterCategories = (id) => {
        const { childrenCategories } = this.props;
        return childrenCategories
            ?.filter(item => item.system_category_id === id)
            .map(item => ({ label: item.name, value: item.id }));
    }

    handleClubSelect = club => {
        this.setState({
            clubSelected: club,
            activeClub: club.value
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { childrenCategories, onGetOrders } = this.props
        const { activeClub } = this.state;
        if (this.props.clubs !== prevProps.clubs) {
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        }
        if (this.props.orders !== prevProps.orders) {
            this.setState({
                orders: this.props.orders,
            });
        }
        if (activeClub !== prevState.activeClub) {
            if (activeClub) {
                onGetOrders(activeClub)
            }
        }
        if (childrenCategories !== prevProps.childrenCategories) {
            this.setState({
                productTypes: [...this.filterCategories("15"), { label: 'Wellness', value: 1000 }]
            })
        }
    }

    handleOrderRecieved = () => {
        this.props.onOrderRecieved(this.state.selectedOrder.id)
        this.setState({ recievedModal: false, selectedOrder: null });
        setTimeout(() => {
            this.props.onGetOrders(this.state.activeClub)
        }, 1000);
    }

    onClickRecieved = (order) => {
        this.setState({
            recievedModal: true,
            selectedOrder: order
        });
    };


    orderRecievedFormater = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => this.onClickRecieved(row)}
                color="info"
            >
                Order Received
            </Button>
        );
    };


    render() {
        const { orders, clubs, loadingOrders } = this.props;
        const { activeClub } = this.state;
        const { SearchBar } = Search;
        const ordersColumns = [
            {
                text: 'Product',
                dataField: "title",
            },
            {
                text: 'User',
                dataField: "first_name",
                formatter: (_, order) => (
                    <span>{`${order.first_name} ${order.last_name}`}</span>
                ),
            },
            {
                text: 'Email',
                dataField: "email",
            },
            {
                text: 'Size',
                dataField: "size",
            },
            {
                text: 'Quantity',
                dataField: "quantity",
            },
            {
                text: "Action",
                dataField: "",
                formatter: this.orderRecievedFormater
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: orders?.length, // replace later with size(customers),
            custom: true,
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Orders List | Groundfloor</title>
                    </MetaTags>
                    <DefaultModal
                        title={'Please confirm order has been received?'}
                        description={'You will not be able to revert this!'}
                        show={this.state.recievedModal}
                        onSendClick={this.handleOrderRecieved}
                        onCloseClick={() => this.setState({ recievedModal: false })}
                    />
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Orders" breadcrumbItem="Orders List" />
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                {this.state.isMobile ? <Row><Col>        <Select
                                                    styles={{
                                                    }}
                                                    onChange={
                                                        this.handleClubSelect
                                                    }
                                                    options={this.state.clubsOptions}
                                                    value={this.state.clubSelected}
                                                    classNamePrefix="select2-selection"
                                                />
                                                </Col></Row> : <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Nav
                                                        pills
                                                        className="bg-light rounded"
                                                        role="tablist"
                                                    >
                                                        <NavItem>
                                                        </NavItem>
                                                        {clubs?.map((item, index) => (
                                                            <NavItem key={index}>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeClub === item.id,
                                                                    })}
                                                                    style={{
                                                                        backgroundColor:
                                                                            activeClub === item.id
                                                                                ? "#032224"
                                                                                : "transparent",
                                                                    }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            activeClub: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                    </Nav>

                                                </div>}
                                                {orders && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={ordersColumns}
                                                        data={orders}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={ordersColumns}
                                                                data={orders}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                                style={{ marginTop: 10 }}
                                                                            />
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingOrders ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={"thead-light"}
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

OrdersList.propTypes = {
    orders: PropTypes.array,
    className: PropTypes.any,
    onGetOrders: PropTypes.func,
    onGetClubs: PropTypes.func,
    loadingOrders: PropTypes.bool,
    clubs: PropTypes.array,
    onGetAllChildrenCategories: PropTypes.func,
    onOrderRecieved: PropTypes.func,
    childrenCategories: PropTypes.array,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ products, system, members }) => ({
    orders: products.orders,
    loadingOrders: products.loadingOrders,
    clubs: system.clubs,
    childrenCategories: system.childrenCategories,
});

const mapDispatchToProps = dispatch => ({
    onGetClubs: () => dispatch(getClubs()),
    onGetOrders: clubId => dispatch(getOrders(clubId)),
    onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),
    onOrderRecieved: (id) => dispatch(orderRecieved(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OrdersList));
