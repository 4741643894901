import { get, post } from "./api_helper";
import * as url from "./url_helper";

const getClubs = token => {
    return get(url.CLUBS, {
        headers: {
            Authorization: token,
        },
    });
};

const getAllChildrenCategories = (token, date) => {
    return get(url.CHILDREN_CATEGORIES, {
        headers: {
            Authorization: token,
        },
    });
};

const sendCustomEmails = (token, clubId, fromEmail, fromName, replyTo, text, subject, imageUrl) => {
    return post(
        url.SEND_EMAIL,
        {
            clubId, text, subject, imageUrl, fromEmail, fromName, replyTo,
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );
}

const sendCustomNotification = (token, clubId, title, body) => post(
    url.SEND_NOTIFICATION,
    {
        clubId, title, body
    },
    {
        headers: {
            Authorization: token,
        },
    }
);

const doorDeckAccessAPI = (token,
    id,
    firstName,
    lastName,
    email,
    status,
    doordeckId,
) => post(
    url.DOORDECK_ACCESS,
    {
        id,
        firstName,
        lastName,
        email,
        status,
        doordeckId,
    },
    {
        headers: {
            Authorization: token,
        },
    }
);



export {
    getClubs,
    getAllChildrenCategories,
    sendCustomEmails,
    sendCustomNotification,
    doorDeckAccessAPI
}