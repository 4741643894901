import React, { Component } from "react";
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, Button } from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import moment from "moment";
import Select from "react-select";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import { getCanceledMembers, getClubs } from "../../store/actions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ExportToCsv } from "export-to-csv";

class CanceledMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      canceledMembersList: [],
      activeClub: null,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      clubSelected: { label: "No Club Selected", value: '' },
      clubsOptions: [],
    };

    this.downloadCsv = this.downloadCsv.bind(this);

  }

  handleClubSelect = club => {
    this.setState({
      clubSelected: club,
      activeClub: club.value
    })
  }

  componentDidMount() {
    const { onGetClubs, clubs } = this.props;
    const authUser = localStorage.getItem("authUser")
    if (JSON.parse(authUser)?.clubId) {
      this.setState({
        activeClub: JSON.parse(authUser).clubId
      })
    }
    if (clubs.length !== 0) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    } else {
      onGetClubs();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { clubs, canceledMembers, onGetCanceledMembers } = this.props;
    const { activeClub } = this.state

    if (clubs !== prevProps.clubs) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    }
    if (activeClub !== prevState.activeClub) {
      if (activeClub) {
        onGetCanceledMembers(activeClub)
      }
    }
    if (canceledMembers !== prevProps.canceledMembers) {
      this.setState({
        canceledMembersList: canceledMembers
      })
    }
  }


  downloadCsv() {
    const { canceledMembers } = this.props

    const membersToDownload = canceledMembers.map(item => {
      return ({
        FirstName: item.first_name,
        LastName: item.last_name,
        Email: item.email,
      })
    })

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `Canceled Members`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `Canceled Members`
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(membersToDownload);
  }

  render() {
    const { history, clubs } = this.props;
    const { SearchBar } = Search;
    const { activeClub, canceledMembersList } = this.state

    const columns = [
      {
        dataField: "first_name",
        text: "First Name",
        sort: true,
        filterValue: (_, user) => `${user?.first_name} ${user?.last_name}`
      },
      {
        dataField: "last_name",
        text: "Last Name",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: false,
      },
      {
        dataField: "subscription_date",
        text: "Start Date",
        sort: false,
        formatter: (cell, row, rowIndex, extraData) => (
          <div>{moment(row.start_date).format("D MMM YYYY")}</div>
        ),
      },
      {
        dataField: "subscription_canceled_date",
        text: "Last Active",
        sort: false,
        formatter: (cell, row, rowIndex, extraData) => (
          <div>{moment(row.membership_canceled_date).format("D MMM YYYY")}</div>
        ),
      },
      {
        dataField: "subscription_type",
        text: "Type",
        sort: false,
      },
      {
        dataField: "logged_out",
        text: "Logged out",
        sort: false,
        formatter: (cell, row, rowIndex, extraData) => (
          <div>
            {cell == 1 && "True"}
            <div>{cell == 0 && "False"}</div>
          </div>
        ),
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: canceledMembersList?.length, // replace later with size(customers),
      custom: true,
    };

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        history.push(
          `/profile/${canceledMembersList[row.id].memberId}/`
        );
      },
    };

    const payingMembers = canceledMembersList.filter(
      member => member.subscription_type > 0
    );
    const freeMembers = this.state.canceledMembersList.filter(
      member => member.subscription_type == 0
    );

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> Members List | Groundfloor</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={"Members"} breadcrumbItem={"Members List"} />
            <p>Paying canceled members: {payingMembers.length} </p>
            <p>Free canceled members: {freeMembers.length} </p>
            <p>Total canceled members: {canceledMembersList.length} </p>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {this.state.isMobile ? <Row><Col>
                      <Select
                        styles={{
                        }}
                        onChange={
                          this.handleClubSelect
                        }
                        options={this.state.clubsOptions}
                        value={this.state.clubSelected}
                        classNamePrefix="select2-selection"
                      />
                    </Col></Row> : <div style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10
                    }}>
                      <Nav pills className="bg-light rounded" role="tablist">
                        {clubs?.map((item, index) => (
                          <NavItem key={index}>
                            <NavLink
                              className={classnames({ active: activeClub === item.id })}
                              style={{
                                backgroundColor: activeClub === item.id ? '#032224' : 'transparent'
                              }}
                              onClick={() => {
                                this.setState({
                                  activeClub: item.id
                                })
                              }}
                            >
                              {item.name}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                      {this.props.canceledMembers.length > 0 && <div className="text-sm-end" >
                        <Button
                          style={{ backgroundColor: "#032224", marginRight: 10 }}
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.downloadCsv}
                        >
                          Download CSV
                        </Button>
                      </div>}
                    </div>}
                    {canceledMembersList && <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={canceledMembersList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={canceledMembersList}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                />
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    {this.props.loadingCanceledMembers ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                      keyField={"email"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      rowEvents={tableRowEvents}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CanceledMembers.propTypes = {
  canceledMembers: PropTypes.array,
  onGetCanceledMembers: PropTypes.func,
  onGetClubs: PropTypes.func,
  clubs: PropTypes.array,
  loadingCanceledMembers: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = ({ members, system }) => ({
  canceledMembers: members.canceledMembers,
  clubs: system.clubs,
  loadingCanceledMembers: members.loadingCanceledMembers

});

const mapDispatchToProps = dispatch => ({
  onGetCanceledMembers: (clubId) => dispatch(getCanceledMembers(clubId)),
  onGetClubs: () => dispatch(getClubs())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CanceledMembers));
