/* eslint-disable react/prop-types */
import { decryptMessage } from 'helpers/encryptionService'
import React, { useEffect, useState } from 'react'
import {
    ChannelPreviewMessenger
} from 'stream-chat-react'

const CustomChannelPreview = (props) => {
    const [latestMessage, setLatestMessage] = useState('')

    useEffect(() => {
        if (props.channel.type === 'dm') {
            if (['system', 'deleted'].includes(props.lastMessage?.type)) {
                setLatestMessage(props.latestMessage)
            } else {
                setLatestMessage({ ...props.latestMessage, props: { ...props.latestMessage.props, children: decryptMessage(props.latestMessage.props?.children) } })
            }
        } else {
            setLatestMessage(props.latestMessage)
        }
    }, [props.channel])

    return (
        <ChannelPreviewMessenger  {...props} latestMessage={latestMessage} />
    )
}

export default CustomChannelPreview
