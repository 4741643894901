/* PROJECTS */
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const ADD_EVENT = "ADD_EVENT";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const ADD_EVENT_INSTRUCTOR = "ADD_EVENT_INSTRUCTOR";
export const ADD_EVENT_INSTRUCTOR_SUCCESS = "ADD_EVENT_INSTRUCTOR_SUCCESS";
export const ADD_EVENT_INSTRUCTOR_FAIL = "ADD_EVENT_INSTRUCTOR_FAIL";

export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL";

export const UPDATE_EVENT_CLUB_LEADER = "UPDATE_EVENT_CLUB_LEADER";


export const UPDATE_INSTRUCTOR_EVENT = "UPDATE_INSTRUCTOR_EVENT";
export const UPDATE_INSTRUCTOR_EVENT_SUCCESS = "UPDATE_INSTRUCTOR_EVENT_SUCCESS";
export const UPDATE_INSTRUCTOR_EVENT_FAIL = "UPDATE_INSTRUCTOR_EVENT_FAIL";

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

export const GET_PAST_EVENTS = "GET_PAST_EVENTS";
export const GET_PAST_EVENTS_SUCCESS = "GET_PAST_EVENTS_SUCCESS";
export const GET_PAST_EVENTS_FAIL = "GET_PAST_EVENTS_FAIL";

export const GET_EVENT_INSTRUCTOR = "GET_EVENT_INSTRUCTOR";
export const GET_EVENT_INSTRUCTOR_SUCCESS = "GET_EVENT_INSTRUCTOR_SUCCESS";
export const GET_EVENT_INSTRUCTOR_FAIL = "GET_EVENT_INSTRUCTOR_FAIL";

export const GET_EVENTS_BY_INSTRUCTOR = "GET_EVENTS_BY_INSTRUCTOR";
export const GET_EVENTS_BY_INSTRUCTOR_SUCCESS = "GET_EVENTS_BY_INSTRUCTOR_SUCCESS";
export const GET_EVENTS_BY_INSTRUCTOR_FAIL = "GET_EVENTS_BY_INSTRUCTOR_FAIL";

export const GET_PAST_EVENTS_BY_INSTRUCTOR = "GET_PAST_EVENTS_BY_INSTRUCTOR";
export const GET_PAST_EVENTS_BY_INSTRUCTOR_SUCCESS = "GET_PAST_EVENTS_BY_INSTRUCTOR_SUCCESS";
export const GET_PAST_EVENTS_BY_INSTRUCTOR_FAIL = "GET_PAST_EVENTS_BY_INSTRUCTOR_FAIL";

export const SET_IMAGE = "SET_IMAGE";
export const SET_IMAGE_SUCCESS = "SET_IMAGE_SUCCESS";

export const GET_EVENTS_BY_CLUB = 'GET_EVENTS_BY_CLUB'

export const SEND_NEW_EVENT_NOTIFICATION = 'SEND_NEW_EVENT_NOTIFICATION'

export const GET_CALENDLY_SCHEDULED_EVENTS = 'GET_CALENDLY_SCHEDULED_EVENTS_START'
export const GET_CALENDLY_SCHEDULED_EVENTS_SUCCESS = 'GET_CALENDLY_SCHEDULED_EVENTS_SUCCESS'
export const GET_CALENDLY_SCHEDULED_EVENTS_FAIL = 'GET_CALENDLY_SCHEDULED_EVENTS_FAIL'

export const GET_CALENDLY_EVENT = 'GET_CALENDLY_EVENT'
export const GET_CALENDLY_EVENT_SUCCESS = 'GET_CALENDLY_EVENT_SUCCESS'
export const GET_CALENDLY_EVENT_FAIL = 'GET_CALENDLY_EVENT_FAIL'

export const GET_CALENDLY_EVENT_INVITEES = 'GET_CALENDLY_EVENT_INVITEES'
export const GET_CALENDLY_EVENT_INVITEES_SUCCESS = 'GET_CALENDLY_EVENT_INVITEES_SUCCESS'
export const GET_CALENDLY_EVENT_INVITEES_FAIL = 'GET_CALENDLY_EVENT_INVITEES_FAIL'

export const GET_SUGGESTED_EVENTS = 'GET_SUGGESTED_EVENTS'
export const GET_SUGGESTED_EVENTS_SUCCESS = 'GET_SUGGESTED_EVENTS_SUCCESS'
export const GET_SUGGESTED_EVENTS_FAIL = 'GET_SUGGESTED_EVENTS_FAIL'

export const GET_SUGGESTED_EVENTS_BY_CLUB_ID = 'GET_SUGGESTED_EVENTS_BY_CLUB_ID'
export const GET_SUGGESTED_EVENTS_BY_CLUB_ID_SUCCESS = 'GET_SUGGESTED_EVENTS_BY_CLUB_ID_SUCCESS'
export const GET_SUGGESTED_EVENTS_BY_CLUB_ID_FAIL = 'GET_SUGGESTED_EVENTS_BY_CLUB_ID_FAIL'

export const CLONE_EVENT = 'CLONE_EVENT'

export const UPDATE_SPOTLIGHT = 'UPDATE_SPOTLIGHT'
export const UPDATE_SPOTLIGHT_SUCCESS = 'UPDATE_SPOTLIGHT_SUCCESS'
export const UPDATE_SPOTLIGHT_FAIL = 'UPDATE_SPOTLIGHT_FAIL'

export const GET_EVENT_ATTENDANCE = 'GET_EVENT_ATTENDANCE'
export const GET_EVENT_ATTENDANCE_SUCCESS = 'GET_EVENT_ATTENDANCE_SUCCESS'
export const GET_EVENT_ATTENDANCE_FAIL = 'GET_EVENT_ATTENDANCE_FAIL'

export const GET_EVENT_POSSIBLE_MATCHES = 'GET_EVENT_POSSIBLE_MATCHES'
export const GET_EVENT_POSSIBLE_MATCHES_SUCCESS = 'GET_EVENT_POSSIBLE_MATCHES_SUCCESS'
export const GET_EVENT_POSSIBLE_MATCHES_FAIL = 'GET_EVENT_POSSIBLE_MATCHES_FAIL'

export const CANCEL_EVENT = 'CANCEL_EVENT'
export const CANCEL_EVENT_SUCCESS = 'CANCEL_EVENT_SUCCESS'
export const CANCEL_EVENT_FAIL = 'CANCEL_EVENT_FAIL'

export const GET_CLUB_LEADER_UPCOMING_EVENTS = 'GET_CLUB_LEADER_UPCOMING_EVENTS'
export const GET_CLUB_LEADER_UPCOMING_EVENTS_SUCCESS = 'GET_CLUB_LEADER_UPCOMING_EVENTS_SUCCESS'
export const GET_CLUB_LEADER_UPCOMING_EVENTS_FAIL = 'GET_CLUB_LEADER_UPCOMING_EVENTS_FAIL'

export const GET_CLUB_LEADER_PAST_EVENTS = 'GET_CLUB_LEADER_PAST_EVENTS'
export const GET_CLUB_LEADER_PAST_EVENTS_SUCCESS = 'GET_CLUB_LEADER_PAST_EVENTS_SUCCESS'
export const GET_CLUB_LEADER_PAST_EVENTS_FAIL = 'GET_CLUB_LEADER_PAST_EVENTS_FAIL'

export const SUGGEST_EVENT = "SUGGEST_EVENT";
export const SUGGEST_EVENT_SUCCESS = "SUGGEST_EVENT_SUCCESS";
export const SUGGEST_EVENT_FAIL = "SUGGEST_EVENT_FAIL";

export const GET_SUGGESTED_EVENTS_BY_USER_ID = 'GET_SUGGESTED_EVENTS_BY_USER_ID'
export const GET_SUGGESTED_EVENTS_BY_USER_ID_SUCCESS = 'GET_SUGGESTED_EVENTS_BY_USER_ID_SUCCESS'
export const GET_SUGGESTED_EVENTS_BY_USER_ID_FAIL = 'GET_SUGGESTED_EVENTS_BY_USER_ID_FAIL'

export const GET_DRAFT_EVENTS_BY_USER_ID = 'GET_DRAFT_EVENTS_BY_USER_ID'
export const GET_DRAFT_EVENTS_BY_USER_ID_SUCCESS = 'GET_DRAFT_EVENTS_BY_USER_ID_SUCCESS'
export const GET_DRAFT_EVENTS_BY_USER_ID_FAIL = 'GET_DRAFT_EVENTS_BY_USER_ID_FAIL'

export const CREATE_DRAFT = "CREATE_DRAFT";
export const CREATE_DRAFT_SUCCESS = "CREATE_DRAFT_SUCCESS";
export const CREATE_DRAFT_FAIL = "CREATE_DRAFT_FAIL";

export const DELETE_DRAFT = 'DELETE_DRAFT'

export const UPDATE_DRAFT = 'UPDATE_DRAFT'

export const UPDATE_SUGGESTED_EVENT = 'UPDATE_SUGGESTED_EVENT'

export const SEND_EMAIL_TO_ATTENDEES = 'SEND_EMAIL_TO_ATTENDEES'

export const DELETE_SUGGESTED_EVENT = 'DELETE_SUGGESTED_EVENT'

export const RESET_LOADING_STATE = "RESET_LOADING_STATE"