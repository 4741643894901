import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";


// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import { getPublicBookings } from "store/actions";
import moment from "moment";
import DeleteModal from "components/Common/DeleteModal";


class PublicBookings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            productData: [],
            eventBookings: [],
            propsReceivedOnce: false,
        };
    }

    dateFormater = (cell, row, rowIndex, formatExtraData) => {

        const startDate = moment()
        let isNew = startDate.diff(moment(row.start_date), "day") <= 30

        return (
            <span>{cell} {isNew ? <span style={{
                color: "red"
            }}> (New)  </span> : null} </span>
        );
    };

    componentDidMount() {
        const {
            match: { params },
            onGetBookings,
        } = this.props;
        if (params && params.id) {
            onGetBookings(params.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.bookings !== prevProps.bookings) {
            this.setState({
                eventBookings: this.props.bookings
            })
        }

    }

    UNSAFE_componentWillReceiveProps(props) {
        if (this.state.eventBookings.length == 0) {
            this.setState({
                eventBookings: props.bookings,
                propsReceivedOnce: true
            })
        }

    }


    render() {
        const { deleteModal } = this.state

        const columns = [
            {
                dataField: "number",
                text: "Nr",
                sort: true,
            },
            {
                dataField: "first_name",
                text: "First Name",
                sort: true,
                formatter: this.dateFormater,
            },
            {
                dataField: "last_name",
                text: "Last Name",
                sort: true,
            },
            {
                dataField: "number_guests",
                text: "Number of Guests",
                sort: true,
            },
            {
                dataField: "status_text",
                text: "Status",
                sort: true,
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.eventBookings?.length,
            custom: true,
        };
        const {
            match: { params },
        } = this.props;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{`${params.title}`} Bookings | Groundfloor</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title={params.title} breadcrumbItem={"Bookings"} />

                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.eventBookings}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.eventBookings}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"email"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PublicBookings.propTypes = {
    match: PropTypes.object,
    loadingBookings: PropTypes.bool,
    bookings: PropTypes.array,
    onGetBookings: PropTypes.func,
};

const mapStateToProps = ({ bookings }) => ({
    bookings: bookings.publicBookings,
    loadingBookings: bookings.loadingPublicBookings,
});

const mapDispatchToProps = dispatch => ({
    onGetBookings: id => dispatch(getPublicBookings(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PublicBookings));
