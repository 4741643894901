import { ADD_ANNOUNCMENT, ARCHIVE_ANNOUNCMENT, DELETE_ANNOUNCMENT, GET_ANNOUNCMENTS, UPDATE_ANNOUNCMENT, } from "./actionTypes";
import { getImage } from "store/events/selectos";
import { addAnnouncmentFail, archiveAnnouncmentFail, deleteAnnouncmentFail, getAnnouncmentsFail, getAnnouncmentsSuccess, updateAnnouncmentFail } from "./actions";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { setImageSuccess } from "store/actions";
import { addAnnouncment, archiveAnnouncment, deleteAnnouncment, getAnnouncments, updateAnnouncment } from "helpers";


function* addAnnouncementSaga({ payload: { announcment } }) {
    try {
        const token = localStorage.getItem("token");
        const image = yield select(getImage);

        yield call(addAnnouncment, token, { ...announcment, image });
        yield put(setImageSuccess(null));
    } catch (error) {
        yield put(addAnnouncmentFail(error));
    }
}

function* updateAnnouncmentSaga({ payload: announcment }) {
    try {
        const token = localStorage.getItem("token");
        const image = yield select(getImage);
        yield call(
            updateAnnouncment,
            token,
            { ...announcment, image }
        );
        yield put(setImageSuccess(null));
    } catch (error) {
        yield put(updateAnnouncmentFail(error));
    }
}

function* archiveAnnouncmentSaga({ payload: data }) {
    try {
        const token = localStorage.getItem("token");
        yield call(
            archiveAnnouncment,
            token,
            data
        );
    } catch (error) {
        yield put(archiveAnnouncmentFail(error));
    }
}

function* getAnnouncmentsSaga({ payload: clubId }) {
    const token = localStorage.getItem("token");
    try {
        const response = yield call(getAnnouncments, token, clubId);
        yield put(getAnnouncmentsSuccess(response.data));
    } catch (error) {
        yield put(getAnnouncmentsFail(error));
    }
}

export function* deleteAnnouncemntSaga({ payload: id }) {
    try {
        const token = localStorage.getItem("token");
        yield call(deleteAnnouncment, token, id);
    } catch (error) {
        yield put(deleteAnnouncmentFail(error));
    }
}

function* announcemntsSaga() {
    yield takeEvery(ADD_ANNOUNCMENT, addAnnouncementSaga);
    yield takeEvery(UPDATE_ANNOUNCMENT, updateAnnouncmentSaga);
    yield takeEvery(ARCHIVE_ANNOUNCMENT, archiveAnnouncmentSaga);
    yield takeEvery(GET_ANNOUNCMENTS, getAnnouncmentsSaga);
    yield takeEvery(DELETE_ANNOUNCMENT, deleteAnnouncemntSaga);
}

export default announcemntsSaga;
