import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import FileUpload from "../../components/FileUpload/FileUpload";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  addEvent,
  createDraft,
  getAllChildrenCategories,
  getClubs,
  getEventInstructors,
  getMembers,
  getSubClubs,
  setImage,
} from "store/actions";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import Switch from "components/Common/Switch";
import { TreeSelect } from "antd";
import DefaultModal from "components/Common/DefaultModal";

class EventCreate extends Component {
  constructor() {
    super();
    this.formikRef = React.createRef();
    this.state = {
      treeData: [],
      startDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      selectedFiles: [],
      events: [],
      instructors: [],
      event: "",
      eventImage: null,
      requiresPayment: false,
      sendPushNotification: false,
      checkinRequired: false,
      spotlightEvent: false,
      showBookClosingTimes: false,
      allClubsEvent: false,
      location: "",
      link: "",
      dropdownOpen: false,
      dropdownInstructorOpen: false,
      category: "Event",
      instructor: "No Instructor",
      instructorId: "0",
      host: null,
      hostId: null,
      clubError: false,
      locationError: false,
      interestsError: false,
      categoryError: false,
      hostError: false,
      interestsFiltered: [],
      interestsSelected: [],
      bookingConfirmation: { label: "No link", value: 0 },
      scheduleDate: { label: "No date", value: null },
      clubsFiltered: [],
      clubsSelected: [],
      locationSelected: { label: "Select Location", value: 0 },
      hostSelected: null,
      draftId: null,
      fieldsError: false,
      loadingSubmitEvent: false,
      scheduleDateError: false,
      submitAsDraft: false,
      salesEndBeforeEvent: { label: "2 hours before the event", value: 2 },
    };
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);
    this.handleAcceptedFiles.bind(this);
    this.toggleCategory = this.toggleCategory.bind(this);
    this.toggleInstructor = this.toggleInstructor.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.handleMulti = this.handleMulti.bind(this);
    this.handleMultiClub = this.handleMultiClub.bind(this);
  }

  toggleCategory() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  filterCategories = id => {
    const { childrenCategories } = this.props;
    return childrenCategories
      ?.filter(item => item.system_category_id === id)
      .map(item => ({ label: item.name, value: item.name }));
  };

  filterClubs = () => {
    const { clubs } = this.props;
    return clubs.map(item => ({ label: item.name, value: item.id }));
  };

  handleMulti = interestsSelected => {
    this.setState({ interestsSelected, interestsError: false });
  };

  handleMultiClub = clubsSelected => {
    this.setState({ clubsSelected, clubError: false });
  };

  handleBookingConfirmationSelect = bookingConfirmation => {
    this.setState({ bookingConfirmation });
  };

  handleScheduleDateSelect = scheduleDate => {
    this.setState({ scheduleDate });
  };

  handleSalesEndBeforeEventSelect = salesEndBeforeEvent => {
    this.setState({ salesEndBeforeEvent });
  };

  handleLocationSelect = location => {
    if (location.value === 'Other') {
      this.setState({
        locationSelected: location,
        location: ''
      })
    } else {
      this.setState({
        location: location.value,
        locationSelected: location,
        locationError: false
      })
    }
  }

  handleHostSelect = host => {
    const nameValue = host.split(';')
    if (['subclub', 'member'].includes(nameValue[0])) {
      this.setState({
        hostSelected: null,
        host: null,
      })
    } else if (nameValue[0] === 'Other') {
      this.setState({
        hostSelected: nameValue[0],
        host: '',
        hostId: null
      })
    } else if (nameValue[0] === 'Groundfloor') {
      this.setState({
        hostSelected: nameValue[0],
        host: nameValue[0],
        hostId: null,
        hostError: false
      })
    } else {
      this.setState({
        hostSelected: nameValue[0],
        host: nameValue[0],
        hostId: nameValue[1],
        hostError: false
      })
    }
  }

  toggleInstructor() {
    this.setState(prevState => ({
      dropdownInstructorOpen: !prevState.dropdownInstructorOpen,
    }));
  }

  categoryItemSelected(value) {
    this.setState({
      category: value,
      categoryError: false
    });
  }

  instructorItemSelected(name, id) {
    this.setState({
      instructor: name,
      instructorId: id,
    });
  }

  startDateChange = date => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = date => {
    this.setState({
      endDate: date,
    });
  };

  onFileUploaded(file) {
    this.setState({ eventImage: file.url });
  }

  componentWillUnmount() {
    this.props.onSetImage(null)
  }

  saveDraft = (values, submit) => {
    let price = values.price
    if (typeof values.price === 'string') {
      price = 0
    }
    const { clubsSelected } = this.state
    let clubId = ''

    if (clubsSelected.length > 0) {
      clubId = parseInt(clubsSelected[0].value)
    }

    let startDate = values.startDate
    let startTime = values.startTime

    const startDateAndTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm')
    let salesEndDateAndTime = startDateAndTime

    if (this.state.showBookClosingTimes) {
      salesEndDateAndTime = startDateAndTime.subtract(this.state.salesEndBeforeEvent.value, 'hours')
    }

    const startTimeToSave = values.startTime !== '' ? moment(
      values.startTime,
      "hh:mm A"
    ).format("HH:mm:ss") : moment().format("hh:mm A")

    const endTime = values.endTime !== '' ? moment(
      values.endTime,
      "hh:mm A"
    ).format("HH:mm:ss") : moment().format("hh:mm A")

    const startDateToSave = values.startDate !== '' ? moment(values.startDate).format(
      "YYYY-MM-DD"
    ) : moment().format("YYYY-MM-DD")

    if (this.state.scheduleDate.value) {
      submit()
    } else {
      this.props.onSaveDraft({
        title: values.title,
        description: values.description,
        startTime: startTimeToSave,
        endTime,
        capacity: values.capacity,
        guestInvitesAllowed:
          values.guestInvitesAllowed === '' ? 0 : values.guestInvitesAllowed,
        price,
        location: this.state.location,
        host: this.state.host,
        clubId,
        hostId: this.state.hostId === null || this.state === undefined ? null : this.state.hostId,
        interests: this.state.interestsSelected
          .map(item => item.value)
          .join(";"),
        startDate: startDateToSave,
        requiresPayment: price > 0 ? 1 : 0,
        link: this.state.bookingConfirmation.value === 0 ? "" : values.link,
        bookingConfirmation:
          this.state.bookingConfirmation.label,
        allClubs: this.state.allClubsEvent ? 1 : 0,
        category: this.state.category,
        instructorId: this.state.instructorId,
        instructorName: this.state.instructor,
        checkinRequired: this.state.checkinRequired,
        spotlight: this.state.spotlightEvent ? 1 : 0,
        salesEnd: moment(
          salesEndDateAndTime
        ).format("YYYY-MM-DD HH:mm:ss"),
        suggestEventId: null,
        publishDate: this.state.scheduleDate.value
      }, this.props.history)
    }

  }

  componentDidMount() {
    const {
      instructors,
      onGetClubs,
      eventToClone,
      clubs,
      onSetImage,
      onGetAllChildrenCategories,
      onGetSubClubs
    } = this.props;
    onGetClubs();
    this.props.onGetEventInstructors();
    onGetAllChildrenCategories();
    onGetSubClubs()
    this.setState({
      instructors,
      interestsFiltered: [
        {
          label: "Interests",
          options: [...this.filterCategories("2"), ...this.filterCategories("11")],
        },
      ],
      clubsFiltered: [
        {
          label: "Clubs",
          options: this.filterClubs(),
        },
      ],
    });
    if (eventToClone) {
      if (eventToClone?.publishDate) {
        this.setState({
          scheduleDate: { label: eventToClone.publishDate, value: eventToClone.publishDate }
        })
      }
      if (eventToClone.image) {
        onSetImage(eventToClone.image);
        this.setState({
          selectedFiles: [
            { name: "", preview: eventToClone.image, url: eventToClone.image },
          ],
        })
      }
      this.setState({
        category: eventToClone.category,
        host: eventToClone.host,
        hostSelected: eventToClone.host,
        hostId: eventToClone.hostId,
        instructorId: eventToClone.instructorId,
        instructor: eventToClone.instructorName,
      });
      if (eventToClone?.clubId > 0) {
        this.setState({
          clubsSelected: [
            {
              label: clubs.find(item => item.id === eventToClone.clubId)?.name,
              value: eventToClone.clubId,
            },
          ],
        })
      }
      if (eventToClone?.interests?.length > 0) {
        this.setState({
          interestsSelected: eventToClone?.interests?.split(';').map(item => ({ label: item, value: item })),
        })
      }
      if (eventToClone?.location?.length > 0) {
        this.setState({
          locationSelected: { label: eventToClone.location, value: eventToClone.location },
          location: eventToClone.location,
        })
      }
      if (eventToClone?.isDraft) {
        this.setState({
          draftId: eventToClone.id
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { instructors, childrenCategories, clubs, onGetMembers, members } = this.props;
    if (instructors !== prevProps.instructors) {
      this.setState({
        instructors,
      });
    }
    if (childrenCategories !== prevProps.childrenCategories) {
      this.setState({
        interestsFiltered: [
          {
            label: "Interests",
            options: [...this.filterCategories("2"), ...this.filterCategories("11")],
          },
        ],
      });
    }
    if (clubs !== prevProps.clubs) {
      onGetMembers(clubs.map(item => item.id).join(','))
      this.setState({
        clubsFiltered: [
          {
            label: "Clubs",
            options: this.filterClubs(),
          },
        ],
      });
    }
    if (members !== prevProps.members) {
      const membersSelect = this.props.clubs.map(club => ({ title: `Members ${club.name}`, value: `member;${club.id}`, children: this.props.members.filter(member => member.main_club_id === club.id).map(item => ({ label: `${item.first_name} ${item.last_name}`, value: `${item.first_name} ${item.last_name};${item.memberId}` })) }))
      const subClubsSelect = this.props.clubs.map(club => ({ title: `Sub clubs ${club.name}`, value: `subclub;${club.id}`, children: this.props.subClubs.filter(item => parseInt(item.club_id) === club.id).map(subClub => ({ title: subClub.name, value: `${subClub.name};${subClub.id}` })) }))
      const treeData = [
        {
          title: "Groundfloor",
          value: "Groundfloor;Groundfloor",
        },
        ...subClubsSelect,
        ...membersSelect,
        {
          title: "Other",
          value: "Other;Other",
        },
      ];

      this.setState({
        treeData
      });
    }
  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleRquirePayment = () => {
    this.setState({
      requiresPayment: !this.state.requiresPayment,
    });
  };
  handleSendNotification = () => {
    this.setState({
      sendPushNotification: !this.state.sendPushNotification,
    });
  };

  handleCheckinRequried = () => {
    this.setState({
      checkinRequired: !this.state.checkinRequired,
    });
  };
  handleSpotlightEvent = () => {
    this.setState({
      spotlightEvent: !this.state.spotlightEvent,
    });
  };
  handleShowBookClosingTimes = () => {
    this.setState({
      showBookClosingTimes: !this.state.showBookClosingTimes,
    });
  };
  handleAllClubsEvent = () => {
    this.setState({
      allClubsEvent: !this.state.allClubsEvent,
    });
  };

  render() {
    const bookingConfirmationSelect = [
      { label: "No link", value: 0 },
      { label: "Learn more", value: 1 },
      { label: "Purchase tickets", value: 2 },
      { label: "Book a time slot", value: 3 },
    ];


    const todayDate = moment().date()

    let thisMonthScheduleDates = []

    if (todayDate >= 1 && todayDate < 15) {
      thisMonthScheduleDates = [
        { label: moment().date(15).format('ddd, DD MMM Y'), value: moment().date(15).format('YYYY-MM-DD') },
      ]
    }

    const scheduleDateSelect = [
      { label: "No date", value: null },
      ...thisMonthScheduleDates,
      { label: moment().add(1, 'month').date(1).format('ddd, DD MMM Y'), value: moment().add(1, 'month').date(1).format('YYYY-MM-DD') },
      { label: moment().add(1, 'month').date(15).format('ddd, DD MMM Y'), value: moment().add(1, 'month').date(15).format('YYYY-MM-DD') },
      { label: moment().add(2, 'month').date(1).format('ddd, DD MMM Y'), value: moment().add(2, 'month').date(1).format('YYYY-MM-DD') },
      { label: moment().add(2, 'month').date(15).format('ddd, DD MMM Y'), value: moment().add(2, 'month').date(15).format('YYYY-MM-DD') },
      { label: moment().add(3, 'month').date(1).format('ddd, DD MMM Y'), value: moment().add(3, 'month').date(1).format('YYYY-MM-DD') },
      { label: moment().add(3, 'month').date(15).format('ddd, DD MMM Y'), value: moment().add(3, 'month').date(15).format('YYYY-MM-DD') },
    ]

    const salesEndSelect = [
      { label: "2 hours before the event", value: 2 },
      { label: "12 hours before the event", value: 12 },
      { label: "24 hours before the event", value: 24 },
      { label: "48 hours before the event", value: 48 },
    ]

    const locationSelect = [
      { label: "Lounge", value: 'Lounge' },
      { label: "Library", value: 'Library' },
      { label: "Kitchen", value: 'Kitchen' },
      { label: "Wellness Studio", value: 'Wellness Studio' },
      { label: "Patio", value: 'Patio' },
      { label: "Banana Yoga - 376 40th Street, Oakland", value: 'Banana Yoga - 376 40th Street, Oakland' },
      { label: "835 4th St Suite B, San Rafael", value: '835 4th St Suite B, San Rafael' },
      { label: "3976 Piedmont Ave", value: "3976 Piedmont Ave" },
      { label: "2973 16th St 5th Floor", value: '2973 16th St 5th Floor' },
      { label: "239 3rd St, San Rafael, CA 94901", value: '239 3rd St, San Rafael, CA 94901' },
      { label: "Other", value: 'Other' },
    ]

    const { eventToClone, } = this.props;
    const {
      interestsFiltered,
      interestsSelected,
      clubsFiltered,
      clubsSelected,
      draftId
    } = this.state;
    const role = localStorage.getItem("roleId");

    return (
      <React.Fragment>
        <DefaultModal
          hideYes
          noLabel={'Ok'}
          title={'Please make sure to fill up all required fields!'}
          description={`Fields missing: ${this.state.clubError ? 'Club ' : ''}${this.state.interestsError ? 'Interests ' : ''}${this.state.locationError ? 'Location ' : ''}${this.state.hostError ? 'Host ' : ''}${this.state.categoryError ? 'Category ' : ''}${this.props.eventImage === null ? 'Image ' : ''}`}
          show={this.state.fieldsError}
          onCloseClick={() => this.setState({ fieldsError: false })}
        />
        <DefaultModal
          hideYes
          noLabel={'Ok'}
          title={'Error'}
          description={`You have selected a date for the event to be published, you have to save it as draft or remove the scheduled date!`}
          show={this.state.scheduleDateError}
          onCloseClick={() => this.setState({ scheduleDateError: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Create New Event | Groundfloor</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Events" breadcrumbItem="Create New" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      innerRef={this.formikRef}
                      initialValues={
                        eventToClone
                          ? {
                            title: eventToClone.title,
                            description: eventToClone.description,
                            startDate: moment.utc(eventToClone.startDate).format(
                              "YYYY-MM-DD"
                            ),
                            startTime: eventToClone.startTime,
                            endTime: eventToClone.endTime,
                            capacity: eventToClone.capacity,
                            price: eventToClone.price,
                            guestInvitesAllowed:
                              eventToClone.guestInvitesAllowed,
                            link: eventToClone.link,
                            category: eventToClone.category,
                            instructorId: eventToClone.instructorId,
                          }
                          : {
                            title: "",
                            description: "",
                            startDate: "",
                            startTime: "",
                            endTime: "",
                            capacity: "",
                            price: "",
                            guestInvitesAllowed: "",
                            link: "",
                            category: "",
                            instructorId: "0",
                          }
                      }
                      validationSchema={Yup.object().shape({
                        title: Yup.string().trim('Please Enter Title').required("Please Enter Title"),
                        description: Yup.string().trim('Please Enter Description').required(
                          "Please Enter Description"
                        ),
                        startDate: Yup.string().required(
                          "Please Enter Start Date"
                        ),
                        startTime: Yup.string().required(
                          "Please Enter Start Time"
                        ),
                        endTime: Yup.string().required("Please Enter End Time"),
                        capacity: Yup.number()
                          .min(1, "Capacity can not be 0")
                          .typeError("The amount invalid")
                          .required("The amount is required"),
                        guestInvitesAllowed: Yup.number()
                          .min(0, 'Can not be lower than 0!').typeError("It must be a number"),
                        price: Yup.number()
                          .min(0, 'Can not be lower than 0!').typeError("It must be a number")
                      })}
                      onSubmit={(values) => {
                        let price = values.price
                        if (typeof values.price === 'string') {
                          price = 0
                        }

                        let startDate = values.startDate
                        let startTime = values.startTime

                        const startDateAndTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm')
                        let salesEndDateAndTime = startDateAndTime

                        if (this.state.showBookClosingTimes) {
                          salesEndDateAndTime = startDateAndTime.subtract(this.state.salesEndBeforeEvent.value, 'hours')
                        }

                        if (clubsSelected.length === 0) {
                          this.setState({
                            clubError: true,
                          });
                        }
                        if (this.state.interestsSelected.length === 0) {
                          this.setState({
                            interestsError: true,
                          })
                        }
                        if (this.state.location.trim().length === 0) {
                          this.setState({
                            locationError: true,
                          })
                        }
                        if (this.state.host === null || this.state.host?.length === 0) {
                          this.setState({
                            hostError: true,
                          })
                        }
                        if (this.state.category === null || this.state.category?.length === 0) {
                          this.setState({
                            categoryError: true,
                          })
                        }

                        if (this.state.submitAsDraft) {
                          this.props.onSaveDraft({
                            title: values.title,
                            description: values.description,
                            startTime: moment(
                              startTime,
                              "hh:mm A"
                            ).format("HH:mm:ss"),
                            endTime: moment(
                              values.endTime,
                              "hh:mm A"
                            ).format("HH:mm:ss"),
                            capacity: values.capacity,
                            guestInvitesAllowed:
                              values.guestInvitesAllowed === '' ? 0 : values.guestInvitesAllowed,
                            price,
                            location: this.state.location,
                            host: this.state.host,
                            clubId: parseInt(clubsSelected[0].value),
                            hostId: this.state.hostId === null || this.state === undefined ? null : this.state.hostId,
                            interests: this.state.interestsSelected
                              .map(item => item.value)
                              .join(";"),
                            startDate: moment(values.startDate).format(
                              "YYYY-MM-DD"
                            ),
                            requiresPayment: price > 0 ? 1 : 0,
                            link: this.state.bookingConfirmation.value === 0 ? "" : values.link,
                            bookingConfirmation:
                              this.state.bookingConfirmation.label,
                            allClubs: this.state.allClubsEvent ? 1 : 0,
                            category: this.state.category,
                            instructorId: this.state.instructorId,
                            instructorName: this.state.instructor,
                            checkinRequired: this.state.checkinRequired,
                            spotlight: this.state.spotlightEvent ? 1 : 0,
                            salesEnd: moment(
                              salesEndDateAndTime
                            ).format("YYYY-MM-DD HH:mm:ss"),
                            suggestEventId: null,
                            publishDate: this.state.scheduleDate.value
                          }, this.props.history)

                        } else {
                          if (this.state.scheduleDate.value) {
                            this.setState({
                              scheduleDateError: true
                            })
                          }
                          if (!(clubsSelected.length === 0) && !this.state.scheduleDate.value && !this.state.scheduleDateError && !(this.state.interestsSelected.length === 0) && !(this.state.location.trim().length === 0) && !(this.state.host === null || this.state.host?.length === 0) && !(this.state.category === null || this.state.category?.length === 0) && this.props.eventImage !== null) {
                            this.setState({
                              loadingSubmitEvent: true
                            })
                            clubsSelected.map(item => {
                              this.props.onAddEvent(
                                {
                                  title: values.title,
                                  description: values.description,
                                  startTime: moment(
                                    startTime,
                                    "hh:mm A"
                                  ).format("HH:mm:ss"),
                                  endTime: moment(
                                    values.endTime,
                                    "hh:mm A"
                                  ).format("HH:mm:ss"),
                                  salesEnd: moment(
                                    salesEndDateAndTime
                                  ).format("YYYY-MM-DD HH:mm:ss"),
                                  capacity: values.capacity,
                                  guestInvitesAllowed:
                                    values.guestInvitesAllowed === '' ? 0 : values.guestInvitesAllowed,
                                  price,
                                  location: this.state.location,
                                  link: this.state.bookingConfirmation.value === 0 ? "" : values.link,
                                  host: this.state.host,
                                  category: this.state.category,
                                  instructorId: this.state.instructorId,
                                  clubId: parseInt(item.value),
                                  instructorName: this.state.instructor,
                                  requiresPayment: price > 0 ? 1 : 0,
                                  sendPushNotification:
                                    this.state.sendPushNotification,
                                  checkinRequired: this.state.checkinRequired,
                                  spotlight: this.state.spotlightEvent ? 1 : 0,
                                  allClubs: this.state.allClubsEvent ? 1 : 0,
                                  bookingConfirmation:
                                    this.state.bookingConfirmation.label,
                                  image: this.state?.selectedFiles[0],
                                  hostId: this.state.hostId === null || this.state.hostId === undefined || this.state.hostId === 'undefined' ? null : this.state.hostId,
                                  interests: this.state.interestsSelected
                                    .map(item => item.value)
                                    .join(";"),
                                  startDate: moment(values.startDate).format(
                                    "YYYY-MM-DD"
                                  ),
                                  suggestEventId: null,
                                  draftId
                                },
                                this.props.history
                              );
                            });

                          } else {
                            this.setState({
                              fieldsError: true
                            })
                          }
                        }
                      }}
                    >
                      {({ errors, status, touched }) => (<React.Fragment>
                        <Form>
                          <h4 className="mb-5">Basic Info</h4>
                          <Row className="mb-4">
                            <Label className="form-label">Event title</Label>
                            <span className="mb-2">Be clear and descriptive with a title that tells people what your event is about.</span>
                            <Col lg="10">
                              <Field
                                name="title"
                                placeholder="Title"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.title && touched.title
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="title"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label className="form-label">Description</Label>

                            <Col lg="10">
                              <span className="mb-4">Use this section to provide more details about your event. You can include things to know, venue information, parking, accessbility options—anything that will help people know what to expect.</span>

                              <Field
                                name="description"
                                component="textarea"
                                placeholder="Description"
                                rows="6"
                                type="textarea"
                                className={
                                  "form-control" +
                                  (errors.description && touched.description
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="description"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label className="form-label">Event Host</Label>

                            <Col lg="10">
                              <p style={{ fontSize: 12, fontWeight: '400' }}>{`For members and clubs, please use the search function to find and select from the list. Non-members, please choose 'Other' and manually insert the name.`}</p>
                              <TreeSelect
                                showSearch
                                style={{ width: "100%" }}
                                value={this.state.hostSelected}
                                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                placeholder="Please select host"
                                allowClear
                                treeDefaultExpandAll
                                onChange={this.handleHostSelect}
                                treeData={this.state.treeData}
                              />
                              {this.state.hostSelected === 'Other' && <>
                                <p style={{ fontSize: 12, fontWeight: '400', marginTop: 10 }}>{`Insert host manually!`}</p>
                                <Field
                                  type="text"
                                  value={this.state.host}
                                  onChange={e =>
                                    this.setState({
                                      host: e.target.value,
                                      hostError: false
                                    })}
                                  className={
                                    "form-control"
                                  }
                                /></>}
                              {this.state.hostError && (
                                <p
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  Please Select Host
                                </p>
                              )}
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label className="form-label">Event Capacity</Label>

                            <Col lg="10">
                              <span className="mb-2">Enter capacity for the event</span>

                              <Field
                                name="capacity"
                                placeholder="Capacity"
                                type="number"
                                className={
                                  "form-control" +
                                  (errors.capacity && touched.capacity
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="capacity"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label className="form-label">Guest Invites Allowed</Label>

                            <Col lg="10">

                              <Field
                                placeholder="Guest Invites Allowed"
                                name="guestInvitesAllowed"
                                type="number"
                                className={
                                  "form-control" +
                                  (errors.guestInvitesAllowed &&
                                    touched.guestInvitesAllowed
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="guestInvitesAllowed"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>

                          <Row className="mb-5">
                            <Label className="form-label">Event Price</Label>

                            <Col lg="10">
                              <span className="mb-2">Input the  price for the event if member participation involves a fee.</span>

                              <Field
                                name="price"
                                placeholder="Price"
                                type="number"
                                className={
                                  "form-control" +
                                  (errors.price && touched.price
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="price"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>

                          <hr />

                          <h4 className="mt-5 mb-4">Location</h4>

                          <Row className="mb-4">
                            <Label
                              htmlFor="duration"
                              className="col-form-label col-lg-2"
                            >
                              Select Club
                            </Label>
                            <Col lg="8">
                              <Select
                                value={clubsSelected}
                                isMulti={true}
                                onChange={this.handleMultiClub}
                                options={clubsFiltered}
                                classNamePrefix="select2-selection"
                              />
                              {this.state.clubError && (
                                <p
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  Please Select a Club
                                </p>
                              )}
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              className="col-form-label col-lg-2"
                            >
                              All Clubs Event
                            </Label>

                            <Col lg="10">
                              <Switch active={this.state.allClubsEvent} parentFunc={this.handleAllClubsEvent} />
                              <p style={{ fontSize: 12, fontWeight: '400', marginTop: 10 }}>Turn this on only if you want this event to appear for all locations!</p>
                            </Col>
                          </Row>

                          <Row className="mb-5">
                            <Col lg="10">
                              <p style={{ fontSize: 12, fontWeight: '400' }}>Please ensure if an event is happening within the Groundfloor space, the exact location is selected from the drop-down list!</p>
                              <Select
                                onChange={
                                  this.handleLocationSelect
                                }
                                options={locationSelect}
                                value={this.state.locationSelected}
                                defaultValue={{ label: "Select Location", value: 0 }}
                                classNamePrefix="select2-selection"
                              />
                              {this.state.locationSelected?.value === 'Other' && <Field
                                type="text"
                                value={this.state.location}
                                style={{ marginTop: 10 }}
                                onChange={e =>
                                  this.setState({
                                    location: e.target.value,
                                    locationError: false
                                  })}
                                className={
                                  "form-control"

                                }
                              />}
                              {this.state.locationError && (
                                <p
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  Please Select a Location
                                </p>
                              )}
                            </Col>
                          </Row>
                          <hr />
                          <h4 className="mt-5 mb-5">Date and time</h4>
                          <Row className="mb-4">
                            <Label
                              htmlFor="startDate"
                              className="col-form-label col-lg-2"
                            >
                              Start Date
                            </Label>
                            <Col lg="10">
                              <Row>
                                <Col lg="6">
                                  <Field
                                    name="startDate"
                                    placeholder="Start Date"
                                    type="date"
                                    className={
                                      "form-control" +
                                      (errors.startDate && touched.startDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="startDate"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </Row>

                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Label
                              htmlFor="startDate"
                              className="col-form-label col-lg-2"
                            >
                              Start Time
                            </Label>
                            <Col lg="10">
                              <Row className="mt-2">
                                <Col lg="6">
                                  <Field
                                    name="startTime"
                                    type="time"
                                    format="HH:mm"
                                    className={
                                      "form-control" +
                                      (errors.startTime && touched.startTime
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="startTime"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Label
                              htmlFor="endTime"
                              className="col-form-label col-lg-2"
                            >
                              End Time
                            </Label>
                            <Col lg="10">
                              <Row>
                                <Col lg="6">
                                  <Field
                                    name="endTime"
                                    type="time"
                                    format="HH:mm"
                                    className={
                                      "form-control" +
                                      (errors.endTime && touched.endTime
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="endTime"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Label
                              className="col-form-label col-lg-2"
                            >
                              Final Attendee Update Deadline
                            </Label>
                            <Col lg="10">
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 10
                              }}>
                                <span style={{ marginRight: 10 }}>OFF</span>
                                <Switch active={this.state.showBookClosingTimes} parentFunc={this.handleShowBookClosingTimes} />
                                <span style={{ marginLeft: 10 }}>ON</span>
                              </div>
                              <span>{`Set this as the cutoff for all attendee list changes, including the waitlist. No bookings or waitlist updates will occur after this time.`}</span>
                            </Col>

                          </Row>
                          {this.state.showBookClosingTimes && <Row className="mt-5 mb-4">
                            <Label
                              htmlFor="bookingConfirmation"
                              className="col-form-label col-lg-2"
                            >
                              Select sales end
                            </Label>
                            <Col lg="8">
                              <Select
                                onChange={
                                  this.handleSalesEndBeforeEventSelect
                                }
                                options={salesEndSelect}
                                defaultValue={{ label: "2 hours before the event", value: 2 }}
                                classNamePrefix="select2-selection"
                              />
                            </Col>
                          </Row>}
                          <hr />
                          <h4 className="mt-5 mb-1">Schedule publish date</h4>
                          <span>Please select a date for the event to be published automatically. This applies only to drafts.</span>
                          <Row className="mt-5 mb-4">
                            <Label
                              htmlFor="bookingConfirmation"
                              className="col-form-label col-lg-2"
                            >
                              Schedule date
                            </Label>
                            <Col lg="8">
                              <Select
                                onChange={
                                  this.handleScheduleDateSelect
                                }
                                options={scheduleDateSelect}
                                defaultValue={{ label: "No date", value: null }}
                                classNamePrefix="select2-selection"
                              />
                            </Col>
                          </Row>

                          <hr />

                          <h4 className="mt-5 mb-5">Details</h4>

                          <Row className="mb-4">
                            <Label
                              htmlFor="bookingConfirmation"
                              className="col-form-label col-lg-2"
                            >
                              Booking Confirmation
                            </Label>
                            <Col lg="8">
                              <Select
                                onChange={
                                  this.handleBookingConfirmationSelect
                                }
                                options={bookingConfirmationSelect}
                                defaultValue={{ label: "No link", value: 0 }}
                                classNamePrefix="select2-selection"
                              />
                              <ErrorMessage
                                name="bookingConfirmation"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>
                          {this.state.bookingConfirmation.value !== 0 && <Row className="mb-4">
                            <Label
                              htmlFor="link"
                              className="col-form-label col-lg-2"
                            >
                              Link
                            </Label>
                            <Col lg="8">
                              <Field
                                name="link"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.link && touched.link
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="link"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>}

                          <Row className="mb-4">
                            <Label
                              htmlFor="duration"
                              className="col-form-label col-lg-2"
                            >
                              Select Interests
                            </Label>
                            <Col lg="8">
                              <p style={{ fontSize: 12, fontWeight: '400' }}>Pick all the relevant and related themes to tag the event, this will help us automate suggestions in future!</p>
                              <Select
                                value={interestsSelected}
                                isMulti={true}
                                onChange={this.handleMulti}
                                options={interestsFiltered}
                                classNamePrefix="select2-selection"
                              />
                              {this.state.interestsError && (
                                <p
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  Please Select Interests
                                </p>
                              )}
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              htmlFor="category"
                              className="col-form-label col-lg-2"
                            >
                              Category
                            </Label>
                            <Col lg="10">
                              <Dropdown
                                isOpen={this.state.dropdownOpen}
                                toggle={this.toggleCategory}
                              >
                                <DropdownToggle caret>
                                  {this.state.category}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() =>
                                      this.categoryItemSelected("Event")
                                    }
                                    category="Event"
                                  >
                                    Event
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={() =>
                                      this.categoryItemSelected("Wellness")
                                    }
                                    category="Wellness"
                                  >
                                    Wellnes
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                              {this.state.categoryError && (
                                <p
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  Please Select a Category
                                </p>
                              )}
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              htmlFor="instructor"
                              className="col-form-label col-lg-2"
                            >
                              Instructor
                            </Label>
                            <Col lg="10">
                              <Dropdown
                                isOpen={this.state.dropdownInstructorOpen}
                                toggle={this.toggleInstructor}
                              >
                                <DropdownToggle caret>
                                  {this.state.instructor}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {this.state.instructors.map(
                                    (instructor, index) => {
                                      return (
                                        <DropdownItem
                                          onClick={() =>
                                            this.instructorItemSelected(
                                              `${instructor.first_name} ${instructor.last_name}`,
                                              `${instructor.id}`
                                            )
                                          }
                                          key={index}
                                        >
                                          {instructor.first_name}{" "}
                                          {instructor.last_name}
                                        </DropdownItem>
                                      );
                                    }
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Label
                              className="col-form-label col-lg-2"
                            >
                              Spotlight Event
                            </Label>
                            <Col lg="10">
                              <Switch active={this.state.spotlightEvent} parentFunc={this.handleSpotlightEvent} />
                            </Col>
                          </Row>

                          {/* <Row className="mb-4">
                            <Label
                              htmlFor="checkinRequired"
                              className="col-form-label col-lg-2"
                            >
                              Checkin Required
                            </Label>
                            <Col lg="10">
                              <Switch active={this.state.checkinRequired} parentFunc={this.handleCheckinRequried} />
                              <ErrorMessage
                                name="checkinRequired"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row> */}

                          {(() => {
                            if (role == 1 || role == 666 || role === 3) {
                              return (
                                <Row className="mb-4">
                                  <Label
                                    htmlFor="sendPushNotification"
                                    className="col-form-label col-lg-2"
                                  >
                                    Send Push Notification
                                  </Label>
                                  <Col lg="10">
                                    <Switch active={this.state.sendPushNotification} parentFunc={this.handleSendNotification} />
                                    <ErrorMessage
                                      name="sendPushNotification"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                              );
                            }
                          })()}

                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-2">
                              Image
                            </Label>
                            <Col lg="8">
                              <span className="mb-4">Add a photo to show what your event will be about. You can upload only 1 image.</span>

                              <FileUpload
                                onFileUploaded={this.onFileUploaded}
                              ></FileUpload>

                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {this.state.selectedFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {f.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  );
                                })}
                              </div>
                            </Col>
                          </FormGroup>
                          <Row>
                            <Col lg="10">
                              <Row className="justify-content-end">

                                <Col lg="2">
                                  <Button color="secondary" onClick={() => {
                                    this.saveDraft(this.formikRef.current.values, this.formikRef.current.submitForm)
                                    this.setState({ submitAsDraft: true })
                                  }}>
                                    {this.props.eventToClone?.isDraft ? "Save as a new draft" : "Save as Draft"}
                                  </Button>
                                </Col>
                                <Col lg="2">
                                  {<Button type="submit" color="primary" disabled={this.state.loadingSubmitEvent}>
                                    {!this.state.loadingSubmitEvent ? this.props.eventToClone?.isDraft ? 'Publish' : 'Create Event' : 'Loading'}
                                  </Button>}
                                </Col>
                              </Row>

                            </Col>
                          </Row>

                        </Form>
                      </React.Fragment>)

                      }
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

EventCreate.propTypes = {
  events: PropTypes.array,
  onAddEvent: PropTypes.func,
  onGetMembers: PropTypes.func,
  onGetAllChildrenCategories: PropTypes.func,
  history: PropTypes.object,
  eventToClone: PropTypes.object,
  eventImage: PropTypes.object,
  instructors: PropTypes.array,
  onGetEventInstructors: PropTypes.func,
  onGetClubs: PropTypes.func,
  clubs: PropTypes.array,
  subClubs: PropTypes.array,
  onSetImage: PropTypes.func,
  onSaveDraft: PropTypes.func,
  onGetSubClubs: PropTypes.func,
  childrenCategories: PropTypes.array,
  members: PropTypes.array,
};

const mapStateToProps = ({ events, system, members, subclubs }) => ({
  events: events.events,
  eventToClone: events.eventToClone,
  instructors: events.instructors,
  childrenCategories: system.childrenCategories,
  clubs: system.clubs,
  subClubs: subclubs.subClubs,
  members: members.members,
  eventImage: events.image
});

const mapDispatchToProps = dispatch => ({
  onGetEventInstructors: () => dispatch(getEventInstructors()),
  onGetClubs: () => dispatch(getClubs()),
  onAddEvent: (event, history) => dispatch(addEvent(event, history)),
  onSetImage: image => dispatch(setImage(image)),
  onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),
  onGetMembers: clubId => dispatch(getMembers(clubId)),
  onGetSubClubs: () => dispatch(getSubClubs()),
  onSaveDraft: (event, history) => dispatch(createDraft(event, history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventCreate));