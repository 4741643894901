import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import FileUpload from "../../components/FileUpload/FileUpload";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
} from "reactstrap";

import {
    cloneEvent,
    createDraft,
    getAllChildrenCategories,
    getClubLeaderSubClubs,
    setImage,
    suggestEvent,
    updateDraft,
    updateSuggestedEvent,
} from "store/actions";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import { TreeSelect } from "antd";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";

class SuggestEvent extends Component {
    constructor() {
        super();
        this.formikRef = React.createRef();
        this.state = {
            treeData: [],
            startDate: new Date(),
            startTime: new Date(),
            endTime: new Date(),
            selectedFiles: [],
            instructors: [],
            event: "",
            eventImage: null,
            requiresPayment: false,
            sendPushNotification: false,
            salesEndDateError: false,
            checkinRequired: false,
            spotlightEvent: false,
            showBookClosingTimes: false,
            allClubsEvent: false,
            location: "",
            link: "",
            dropdownOpen: false,
            dropdownInstructorOpen: false,
            category: "Event",
            instructor: "No Instructor",
            instructorId: "0",
            host: null,
            hostId: null,
            clubError: false,
            locationError: false,
            interestsError: false,
            categoryError: false,
            hostError: false,
            interestsFiltered: [],
            interestsSelected: [],
            locationSelected: { label: "Select Location", value: 0 },
            hostSelected: null,
        };
        this.startDateChange.bind(this);
        this.endDateChange.bind(this);
        this.handleAcceptedFiles.bind(this);
        this.onFileUploaded = this.onFileUploaded.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
    }

    toggleCategory() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    filterCategories = id => {
        const { childrenCategories } = this.props;
        return childrenCategories
            ?.filter(item => item.system_category_id === id)
            .map(item => ({ label: item.name, value: item.name }));
    };

    handleMulti = interestsSelected => {
        this.setState({ interestsSelected, interestsError: false });
    };

    handleLocationSelect = location => {
        if (location.value === 'Other') {
            this.setState({
                locationSelected: location,
                location: ''
            })
        } else {
            this.setState({
                location: location.value,
                locationSelected: location,
                locationError: false
            })
        }
    }

    startDateChange = date => {
        this.setState({
            startDate: date,
        });
    };

    endDateChange = date => {
        this.setState({
            endDate: date,
        });
    };

    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    componentWillUnmount() {
        this.props.onEventClone()
    }

    componentDidMount() {
        const { onGetSubClubs, onGetAllChildrenCategories } = this.props
        onGetSubClubs()
        onGetAllChildrenCategories()
    }

    componentDidUpdate(prevProps, prevState) {
        const { clubLeaderSubClubs, childrenCategories, eventToClone, onSetImage } = this.props
        if (clubLeaderSubClubs !== prevProps.clubLeaderSubClubs) {
            const subClubsSelect = clubLeaderSubClubs.map(item => ({ title: item.name, value: `${item.name};${item.sub_club_id}` }))

            this.setState({
                treeData: subClubsSelect
            });

            if (eventToClone) {
                onSetImage(eventToClone.image);
                if (eventToClone.host && eventToClone.host_id && eventToClone.host !== 'undefined') {
                    this.setState({
                        hostSelected: eventToClone.host,
                        host: eventToClone.host,
                        hostId: eventToClone.host_id,
                        hostError: false,
                    })
                }
                if (eventToClone.location) {
                    this.setState({
                        locationSelected: { label: eventToClone.location, value: eventToClone.location },
                        location: eventToClone.location,
                    })
                }
                if (eventToClone.interests?.length > 0) {
                    this.setState({
                        interestsSelected: eventToClone?.interests?.split(';').map(item => ({ label: item, value: item })),
                    })
                }
                this.setState({
                    selectedFiles: [
                        { name: "", preview: eventToClone.image, url: eventToClone.image },
                    ],
                })
            } else {
                if (clubLeaderSubClubs.length === 1) {
                    this.setState({
                        hostSelected: clubLeaderSubClubs[0].name,
                        host: clubLeaderSubClubs[0].name,
                        hostId: clubLeaderSubClubs[0].id,
                        hostError: false
                    })
                }
            }

        }
        if (childrenCategories !== prevProps.childrenCategories) {
            this.setState({
                interestsFiltered: [
                    {
                        label: "Interests",
                        options: [...this.filterCategories("2"), ...this.filterCategories("11")],
                    },
                ],
            });
        }
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );

        this.setState({ selectedFiles: files });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    handleRquirePayment = () => {
        this.setState({
            requiresPayment: !this.state.requiresPayment,
        });
    };

    handleHostSelect = host => {
        const nameValue = host.split(';')
        this.setState({
            hostSelected: nameValue[0],
            host: nameValue[0],
            hostId: nameValue[1],
            hostError: false
        })
    }

    saveDraft = values => {
        const { eventToClone } = this.props
        const startTime = values.startTime !== '' ? moment(
            values.startTime,
            "hh:mm A"
        ).format("HH:mm:ss") : moment().format("hh:mm A")

        const endTime = values.endTime !== '' ? moment(
            values.endTime,
            "hh:mm A"
        ).format("HH:mm:ss") : moment().format("hh:mm A")

        const startDate = values.startDate !== '' ? moment(values.startDate).format(
            "YYYY-MM-DD"
        ) : moment().format("YYYY-MM-DD")

        const user = localStorage.getItem('authUser')

        if (eventToClone?.type === 'draft') {
            this.props.onUpdateDraft({
                id: eventToClone.id,
                title: values.title,
                description: values.description,
                startTime,
                endTime,
                capacity: values.capacity,
                guestInvitesAllowed:
                    values.guestInvitesAllowed === '' ? 0 : values.guestInvitesAllowed,
                price: values.price === '' ? 0 : values.price,
                location: this.state.location,
                host: this.state.host,
                clubId: JSON.parse(user).clubId,
                hostId: this.state.hostId === null ? '' : this.state.hostId,
                interests: this.state.interestsSelected
                    .map(item => item.value)
                    .join(";"),
                startDate,

                requiresPayment: '',
                link: '',
                bookingConfirmation: '',
                allClubs: '',
                category: "event",
                instructorId: '',
                instructorName: '',
                checkinRequired: '',
                spotlight: '',
                salesEnd: '',
                suggestEventId: ''
            }, this.props.history)
        } else {
            this.props.onSaveDraft({
                title: values.title,
                description: values.description,
                startTime,
                endTime,
                capacity: values.capacity,
                guestInvitesAllowed:
                    values.guestInvitesAllowed === '' ? 0 : values.guestInvitesAllowed,
                price: values.price === '' ? 0 : values.price,
                location: this.state.location,
                host: this.state.host,
                clubId: JSON.parse(user).clubId,
                hostId: this.state.hostId === null ? '' : this.state.hostId,
                interests: this.state.interestsSelected
                    .map(item => item.value)
                    .join(";"),
                startDate,
                requiresPayment: '',
                link: '',
                bookingConfirmation: '',
                allClubs: '',
                category: "event",
                instructorId: '',
                instructorName: '',
                checkinRequired: '',
                spotlight: '',
                salesEnd: '',
                suggestEventId: ''
            }, this.props.history)
        }


    }

    render() {
        const locationSelect = [
            { label: "Lounge", value: 'Lounge' },
            { label: "Library", value: 'Library' },
            { label: "Kitchen", value: 'Kitchen' },
            { label: "Wellness Studio", value: 'Wellness Studio' },
            { label: "Patio", value: 'Patio' },
            { label: "Other", value: 'Other' },
        ]
        const {
            interestsFiltered,
            interestsSelected,
        } = this.state;

        const { eventToClone } = this.props;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Suggest New Event | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Events" breadcrumbItem="Suggest New" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <Formik
                                            innerRef={this.formikRef}
                                            enableReinitialize={true}
                                            initialValues={
                                                eventToClone ?
                                                    {
                                                        title: eventToClone.title.replace('Draft - ', ''),
                                                        description: eventToClone.description,
                                                        startDate: moment.utc(eventToClone.start_date).format(
                                                            "YYYY-MM-DD"
                                                        ),
                                                        startTime: eventToClone.start_time,
                                                        endTime: eventToClone.end_time,
                                                        capacity: parseInt(eventToClone.capacity),
                                                        price: parseInt(eventToClone.price),
                                                        guestInvitesAllowed: parseInt(eventToClone.guest_invites_allowed),
                                                    } :
                                                    {
                                                        title: "",
                                                        description: "",
                                                        startDate: "",
                                                        startTime: "",
                                                        endTime: "",
                                                        capacity: "",
                                                        price: 0,
                                                        guestInvitesAllowed: 0,
                                                    }
                                            }
                                            validationSchema={Yup.object().shape({
                                                title: Yup.string().trim('Please Enter Title').required("Please Enter Title"),
                                                description: Yup.string().trim('Please Enter Description').required(
                                                    "Please Enter Description"
                                                ),
                                                startDate: Yup.string().required(
                                                    "Please Enter Start Date"
                                                ),
                                                startTime: Yup.string().required(
                                                    "Please Enter Start Time"
                                                ),
                                                endTime: Yup.string().required("Please Enter End Time"),
                                                capacity: Yup.number()
                                                    .min(1, "Capacity can not be 0")
                                                    .typeError("The amount invalid")
                                                    .required("The amount is required"),
                                                guestInvitesAllowed: Yup.number()
                                                    .min(0, 'Can not be lower than 0!')
                                                    .max(3, 'Up to 3 guests per member allowed!').typeError("It must be a number"),
                                                price: Yup.number().min(0, 'Can not be lower than 0!').typeError("It must be a number")
                                            })}
                                            onSubmit={values => {
                                                const user = localStorage.getItem('authUser')


                                                let startTime = values.startTime

                                                if (this.state.interestsSelected.length === 0) {
                                                    this.setState({
                                                        interestsError: true
                                                    })
                                                }
                                                if (this.state.location.length === 0) {
                                                    this.setState({
                                                        locationError: true
                                                    })
                                                }
                                                if (this.state.host === null || this.state.host?.length === 0) {
                                                    this.setState({
                                                        hostError: true
                                                    })
                                                }

                                                if (!this.state.interestsError && !this.state.locationError && !this.state.hostError) {
                                                    if (eventToClone?.type === 'suggest') {
                                                        console.log('updating suggested event...')
                                                        this.props.onUpdateSuggestedEvent({
                                                            id: eventToClone.id,
                                                            title: values.title,
                                                            description: values.description,
                                                            startTime: moment(
                                                                startTime,
                                                                "hh:mm A"
                                                            ).format("HH:mm:ss"),
                                                            endTime: moment(
                                                                values.endTime,
                                                                "hh:mm A"
                                                            ).format("HH:mm:ss"),
                                                            capacity: values.capacity,
                                                            guestInvitesAllowed:
                                                                values.guestInvitesAllowed === '' ? 0 : values.guestInvitesAllowed,
                                                            price: values.price === '' ? 0 : values.price,
                                                            location: this.state.location,
                                                            host: this.state.host,
                                                            clubId: JSON.parse(user)?.clubId,
                                                            hostId: this.state.hostId === null ? '' : this.state.hostId,
                                                            interests: this.state.interestsSelected
                                                                .map(item => item.value)
                                                                .join(";"),
                                                            startDate: moment(values.startDate).format(
                                                                "YYYY-MM-DD"
                                                            ),
                                                        }, this.props.history)
                                                    } else {
                                                        console.log('suggesting event...')
                                                        this.props.onSuggestEvent(
                                                            {
                                                                title: values.title,
                                                                description: values.description,
                                                                startTime: moment(
                                                                    startTime,
                                                                    "hh:mm A"
                                                                ).format("HH:mm:ss"),
                                                                endTime: moment(
                                                                    values.endTime,
                                                                    "hh:mm A"
                                                                ).format("HH:mm:ss"),
                                                                capacity: values.capacity,
                                                                guestInvitesAllowed:
                                                                    values.guestInvitesAllowed === '' ? 0 : values.guestInvitesAllowed,
                                                                price: values.price === '' ? 0 : values.price,
                                                                location: this.state.location,
                                                                host: this.state.host,
                                                                clubId: JSON.parse(user).clubId,
                                                                hostId: this.state.hostId === null ? '' : this.state.hostId,
                                                                interests: this.state.interestsSelected
                                                                    .map(item => item.value)
                                                                    .join(";"),
                                                                startDate: moment(values.startDate).format(
                                                                    "YYYY-MM-DD"
                                                                ),
                                                                draftId: eventToClone?.id ? eventToClone?.id : null
                                                            },
                                                            this.props.history
                                                        );
                                                    }

                                                }

                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <React.Fragment>
                                                    <Form>
                                                        <h4 className="mb-5">Basic Info</h4>
                                                        <Row className="mb-4">
                                                            <Label className="form-label">Event title</Label>
                                                            <span className="mb-2">Be clear and descriptive with a title that tells people what your event is about.</span>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="title"
                                                                    placeholder="Title"
                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.title && touched.title
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="title"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label className="form-label">Description</Label>
                                                            <Col lg="10">
                                                                <span className="mb-4">Use this section to provide more details about your event. You can include things to know, venue information, parking, accessbility options—anything that will help people know what to expect.</span>
                                                                <Field
                                                                    name="description"
                                                                    component="textarea"
                                                                    placeholder="Description"
                                                                    rows="6"
                                                                    type="textarea"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.description && touched.description
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="description"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label className="form-label">Event Host</Label>

                                                            <Col lg="10">
                                                                <span className="mb-2">Please select the sub club that is hosting the event</span>
                                                                <TreeSelect
                                                                    showSearch
                                                                    style={{ width: "100%" }}
                                                                    value={this.state.hostSelected}
                                                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                                                    placeholder="Please select host"
                                                                    allowClear
                                                                    treeDefaultExpandAll
                                                                    onChange={this.handleHostSelect}
                                                                    treeData={this.state.treeData}
                                                                />

                                                                {this.state.hostError && (
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Please Select Host
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label className="form-label">Event Capacity</Label>
                                                            <Col lg="10">
                                                                <span className="mb-2">Enter capacity for the event</span>
                                                                <Field
                                                                    name="capacity"
                                                                    placeholder="Capacity"
                                                                    type="number"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.capacity && touched.capacity
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="capacity"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label className="form-label">Guest Invites Allowed</Label>

                                                            <Col lg="10">
                                                                <span className="mb-2">Up to 3 guests per member allowed</span>
                                                                <Field
                                                                    placeholder="Guest Invites Allowed"
                                                                    name="guestInvitesAllowed"
                                                                    type="number"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.guestInvitesAllowed &&
                                                                            touched.guestInvitesAllowed
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="guestInvitesAllowed"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-5">
                                                            <Label className="form-label">Event Price</Label>
                                                            <Col lg="10">
                                                                <span className="mb-2">Input the  price for the event if member participation involves a fee.</span>
                                                                <Field
                                                                    name="price"
                                                                    placeholder="Price"
                                                                    type="number"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.price && touched.price
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="price"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <hr />

                                                        <h4 className="mt-5 mb-4">Location</h4>

                                                        <Row className="mb-5">
                                                            <Col lg="10">
                                                                <p style={{ fontSize: 12, fontWeight: '400' }}>Please ensure if an event is happening within the Groundfloor space, the exact location is selected from the drop-down list!</p>
                                                                <Select
                                                                    onChange={
                                                                        this.handleLocationSelect
                                                                    }
                                                                    options={locationSelect}
                                                                    value={this.state.locationSelected}
                                                                    defaultValue={{ label: "Select Location", value: 0 }}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                {this.state.locationSelected?.value === 'Other' && <Field
                                                                    type="text"
                                                                    value={this.state.location}
                                                                    style={{ marginTop: 10 }}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            location: e.target.value,
                                                                            locationError: false
                                                                        })}
                                                                    className={
                                                                        "form-control"
                                                                    }
                                                                />}
                                                                {this.state.locationError && (
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Please Select a Location
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <h4 className="mt-5 mb-5">Date and time</h4>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="startDate"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Start Date
                                                            </Label>
                                                            <Col lg="10">
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <Field
                                                                            name="startDate"
                                                                            placeholder="Start Date"
                                                                            type="date"
                                                                            format="YYYY-MM-DD"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.startDate && touched.startDate
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="startDate"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="startTime"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Start Time
                                                            </Label>
                                                            <Col lg="10">
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <Field
                                                                            name="startTime"
                                                                            type="time"
                                                                            format="hh:mm A"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.startTime && touched.startTime
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="startTime"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="endTime"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                End Time
                                                            </Label>
                                                            <Col lg="10">
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <span className="mb-2">Enter the anticipated end time for the event.</span>

                                                                        <Field
                                                                            name="endTime"
                                                                            type="time"
                                                                            format="hh:mm A"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.endTime && touched.endTime
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="endTime"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                        <hr />

                                                        <h4 className="mt-5 mb-5">Details</h4>



                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="duration"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Select Interests
                                                            </Label>
                                                            <Col lg="8">
                                                                <p style={{ fontSize: 12, fontWeight: '400' }}>Pick all the relevant and related themes to tag the event, this will help us automate suggestions in future!</p>
                                                                <Select
                                                                    value={interestsSelected}
                                                                    isMulti={true}
                                                                    onChange={this.handleMulti}
                                                                    options={interestsFiltered}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                {this.state.interestsError && (
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Please Select Interests
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <FormGroup className="mb-4" row>
                                                            <Label className="col-form-label col-lg-2">
                                                                Image
                                                            </Label>
                                                            <Col lg="8">
                                                                <span className="mb-4">Add a photo to show what your event will be about. You can upload only 1 image.</span>
                                                                <FileUpload
                                                                    onFileUploaded={this.onFileUploaded}
                                                                ></FileUpload>

                                                                <div
                                                                    className="dropzone-previews mt-3"
                                                                    id="file-previews"
                                                                >
                                                                    {this.state.selectedFiles.map((f, i) => {
                                                                        return (
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={i + "-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <Row className="align-items-center">
                                                                                        <Col className="col-auto">
                                                                                            <img
                                                                                                data-dz-thumbnail=""
                                                                                                height="400"
                                                                                                className="rounded bg-light"
                                                                                                alt={f.name}
                                                                                                src={f.preview}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="text-muted font-weight-bold"
                                                                                            >
                                                                                                {f.name}
                                                                                            </Link>
                                                                                            <p className="mb-0">
                                                                                                <strong>
                                                                                                    {f.formattedSize}
                                                                                                </strong>
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </Col>
                                                        </FormGroup>

                                                        <Row >
                                                            <Col lg='10'>
                                                                <Row className="justify-content-end">
                                                                    {eventToClone?.type === 'suggest' ? null : <Col lg="2">
                                                                        <Button disabled={this.props.loadingSaveDraft} color="secondary" onClick={() => this.saveDraft(this.formikRef.current.values)}>
                                                                            {this.props.loadingSaveDraft ? 'Loading' : eventToClone?.type === 'draft' ? 'Update draft' : 'Save draft'}
                                                                        </Button>
                                                                    </Col>}
                                                                    <Col style={{ marginLeft: -20 }} lg="2">
                                                                        <Button type="submit" color="primary" disabled={this.props.loadingSuggestEvent}>
                                                                            {this.props.loadingSuggestEvent ? 'Loading' : eventToClone?.type === 'suggest' ? 'Update' : 'Suggest Event'}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                        </Row>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

SuggestEvent.propTypes = {
    onSuggestEvent: PropTypes.func,
    onSaveDraft: PropTypes.func,
    onGetAllChildrenCategories: PropTypes.func,
    history: PropTypes.object,
    instructors: PropTypes.array,
    clubLeaderSubClubs: PropTypes.array,
    onSetImage: PropTypes.func,
    onGetSubClubs: PropTypes.func,
    onEventClone: PropTypes.func,
    onUpdateSuggestedEvent: PropTypes.func,
    onUpdateDraft: PropTypes.func,
    eventToClone: PropTypes.object,
    loadingSuggestEvent: PropTypes.bool,
    loadingSaveDraft: PropTypes.bool,
    childrenCategories: PropTypes.array,
};

const mapStateToProps = ({ system, events, Login, subclubs }) => ({
    clubLeaderSubClubs: subclubs.clubLeaderSubClubs,
    childrenCategories: system.childrenCategories,
    eventToClone: events.eventToClone,
    loadingSuggestEvent: events.loadingSuggestEvent,
    loadingSaveDraft: events.loadingSaveDraft,
    subClubs: subclubs.subClubs,
});

const mapDispatchToProps = dispatch => ({
    onSuggestEvent: (event, history) => dispatch(suggestEvent(event, history)),
    onSaveDraft: (event, history) => dispatch(createDraft(event, history)),
    onUpdateSuggestedEvent: (event, history) => dispatch(updateSuggestedEvent(event, history)),
    onUpdateDraft: (event, history) => dispatch(updateDraft(event, history)),
    onSetImage: image => dispatch(setImage(image)),
    onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),
    onGetSubClubs: () => dispatch(getClubLeaderSubClubs()),
    onEventClone: (event) => dispatch(cloneEvent(event)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SuggestEvent));