import { get, } from "./api_helper";
import * as url from "./url_helper";

const getGuests = (token, clubId, date) => {
    return get(url.GET_GUESTS(clubId, date), {
        headers: {
            Authorization: token,
        },
    });
};

export {
    getGuests,
};
