import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";

import {
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
    getOnboardingBookings,
    updateOnboardingBooking,
    cancelOnboardingBooking,
    getClubs,
} from "store/actions";

class OnboardingBookingsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelEventModal: false,
            modal: false,
            selectedBooking: null,
            activeClub: 1,
            filteredBookings: []
        };

        this.toggle = this.toggle.bind(this);
        this.onClickCancelEvent = this.onClickCancelEvent.bind(this);
    }

    componentDidMount() {
        const {
            onGetOnboardingBookings,
            onGetClubs
        } = this.props;
        onGetOnboardingBookings();
        onGetClubs()
    }

    componentDidUpdate(prevProps, prevState) {
        const { onboardingBookings } = this.props;
        const { activeClub } = this.state
        if (onboardingBookings !== prevProps.onboardingBookings) {
            this.setState({
                filteredBookings: onboardingBookings.filter(item => item.club_id === activeClub)
            })
        }
        if (activeClub !== prevState.activeClub) {
            this.setState({
                filteredBookings: onboardingBookings.filter(item => item.club_id === activeClub)
            })
        }
    }


    onClickCancelEvent = (selectedBooking) => {
        this.setState({ cancelEventModal: true, selectedBooking });
    };

    handleCancelEvent = () => {
        this.props.onCancelOnboardingBooking(this.state.selectedBooking.id)
        this.setState({
            cancelEventModal: false,
            selectedBooking: null
        });
    };

    toggle(selectedBooking) {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedBooking
        }));
    }

    handleValidDate = date => {
        const date1 = moment(date).utc().format("ddd, DD MMM Y");
        return date1;
    };

    render() {
        const { loadingOnboardingBookings, onUpdateOnboardingBooking, onCancelOnboardingBooking } = this.props
        const { activeClub, filteredBookings } = this.state

        const { cancelEventModal, selectedBooking } = this.state

        const bookingClumns = [
            {
                text: "Member",
                dataField: "first_name",
                formatter: (_, booking) => (
                    <span>
                        {`${booking.first_name} ${booking.last_name}`}
                    </span>
                ),
            },
            {
                text: "Email",
                dataField: "email",
            },
            {
                text: "Booking date",
                dataField: "start_date",
                // eslint-disable-next-line react/display-name
                formatter: (_, booking) => (
                    <span>
                        {this.handleValidDate(booking.start_date)}
                    </span>
                ),
            },
            {
                text: "Booking Time",
                dataField: "startTime",
                // eslint-disable-next-line react/display-name
                formatter: (_, booking) => (
                    <span>
                        {moment(booking.start_time, "HH:mm:ss").format("hh:mmA")}
                    </span>
                ),
            },

            {
                text: "Status",
                dataField: "canceled",
                // eslint-disable-next-line react/display-name
                formatter: (_, booking) => (
                    <span>
                        {booking.canceled === 1 ? 'Canceled' : 'Active'}
                    </span>
                ),
            },

            {
                text: "Action",
                dataField: "",
                formatter: (_, booking) => (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle className="card-drop" tag="a">
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                    href="#"
                                    onClick={() => this.toggle(booking)}
                                >
                                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                    Edit Booking
                                </DropdownItem>
                                <DropdownItem
                                    href="#"
                                    onClick={() => this.onClickCancelEvent(booking)}
                                >
                                    <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                                    Cancel Booking
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                ),
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: filteredBookings?.length, // replace later with size(customers),
            custom: true,
        };

        return (
            <React.Fragment>

                <DeleteModal
                    cancel
                    show={cancelEventModal}
                    onDeleteClick={this.handleCancelEvent}
                    onCloseClick={() => this.setState({ cancelEventModal: false })}
                />

                <div className="page-content">
                    <MetaTags>
                        <title>Onboard Bookings List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Onboard Bookings" breadcrumbItem="Onboard Bookings List" />

                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10
                                                }}>
                                                    <Nav pills className="bg-light rounded" role="tablist">
                                                        {this.props?.clubs?.map((item, index) => (
                                                            <NavItem key={index}>
                                                                <NavLink
                                                                    className={classnames({ active: activeClub === item.id })}
                                                                    style={{
                                                                        backgroundColor: activeClub === item.id ? '#032224' : 'transparent'
                                                                    }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            activeClub: item.id,
                                                                        })
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                    </Nav>
                                                </div>
                                                {filteredBookings && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={bookingClumns}
                                                        data={filteredBookings}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={bookingClumns}
                                                                data={filteredBookings}

                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>

                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingOnboardingBookings ? (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="text-success"
                                                                                            >
                                                                                                <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                                                                Loading
                                                                                            </Link>{" "}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <BootstrapTable
                                                                                            responsive
                                                                                            bordered={false}
                                                                                            striped={false}
                                                                                            classes={
                                                                                                "table align-middle table-nowrap"
                                                                                            }
                                                                                            headerWrapperClasses={
                                                                                                "thead-light"
                                                                                            }
                                                                                            {...toolkitProps.baseProps}
                                                                                            {...paginationTableProps}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                        <Modal
                                            isOpen={this.state.modal}
                                            className={this.props.className}
                                        >
                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                {"Edit Booking"}
                                            </ModalHeader>
                                            <ModalBody>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{

                                                        startDate: "",
                                                        startTime: "",
                                                    }}
                                                    validationSchema={Yup.object().shape({

                                                        startDate: Yup.string().required(
                                                            "Please Enter Start Date"
                                                        ),
                                                        startTime: Yup.string().required(
                                                            "Please Enter Start Time"
                                                        ),

                                                    })}
                                                    onSubmit={values => {
                                                        const updateBooking = {
                                                            startTime: values.startTime,
                                                            startDate: moment(values.startDate).format(
                                                                "YYYY-MM-DD"
                                                            ),
                                                        };
                                                        onUpdateOnboardingBooking(selectedBooking.id, updateBooking.startDate, updateBooking.startTime);
                                                        this.setState({ selectedBooking: null });
                                                        this.toggle();
                                                    }}
                                                >
                                                    {({ errors, status, touched }) => (
                                                        <Form>
                                                            <Row>
                                                                <Col className="col-12">
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Change Date and Time
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Row>
                                                                                <Col lg="6">
                                                                                    <Field
                                                                                        name="startDate"
                                                                                        type="date"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.startDate &&
                                                                                                touched.startDate
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="startDate"
                                                                                        component="div"
                                                                                        className="invalid-feedback"
                                                                                    />
                                                                                </Col>

                                                                                <Col lg="6">
                                                                                    <Field
                                                                                        name="startTime"
                                                                                        type="time"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.startTime &&
                                                                                                touched.startTime
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="startTime"
                                                                                        component="div"
                                                                                        className="invalid-feedback"
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <div className="text-end">
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-success save-user"
                                                                        >
                                                                            Save
                                                                        </button>

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

OnboardingBookingsList.propTypes = {
    loadingOnboardingBookings: PropTypes.bool,
    onGetOnboardingBookings: PropTypes.func,
    onUpdateOnboardingBooking: PropTypes.func,
    onCancelOnboardingBooking: PropTypes.func,
    onboardingBookings: PropTypes.array,
    onGetClubs: PropTypes.func,
    className: PropTypes.any,
    clubs: PropTypes.array,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ members, system }) => ({
    loadingOnboardingBookings: members.loadingOnboardingBookings,
    onboardingBookings: members.onboardingBookings,
    clubs: system.clubs

});

const mapDispatchToProps = dispatch => ({
    onGetOnboardingBookings: () => dispatch(getOnboardingBookings()),
    onUpdateOnboardingBooking: (id, startDate, startTime) => dispatch(updateOnboardingBooking(id, startDate, startTime)),
    onCancelOnboardingBooking: (id) => dispatch(cancelOnboardingBooking(id)),
    onGetClubs: () => dispatch(getClubs())

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OnboardingBookingsList));
