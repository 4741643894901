import moment from "moment";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

const getMembers = (token, clubId) => {
    return get(url.MEMBERS(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

const getCanceledMembers = (token, clubId) => {
    return get(url.CANCELED_MEMBERS(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

const getMemberById = (token, id) => {
    return get(url.MEMBER_BY_ID(id), {
        headers: {
            Authorization: token,
        },
    });
};

const getMemberByIdBySubclubAPI = (token, subclubId, userId) => {
    return get(url.MEMBER_BY_ID_BY_SUBCLUB(subclubId, userId), {
        headers: {
            Authorization: token,
        },
    });
};

const getAllCouponsAPI = (token) => {
    return get(url.GET_COUPONS, {
        headers: {
            Authorization: token,
        },
    });
};

const applyCouponAPI = (token, email, couponId, couponTitle, subscriptionType) => {
    return post(
        url.APPLY_COUPON,
        {
            email, couponId, couponTitle, subscriptionType
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const getAppliedCouponsAPI = (token) => {
    return get(url.GET_COUPONS_APPLIED, {
        headers: {
            Authorization: token,
        },
    });
};

const getMemberByEmailAPI = (token, email) => {
    return get(url.MEMBER_BY_EMAIL(email), {
        headers: {
            Authorization: token,
        },
    });
};

const getNewMembersAPI = (token, clubId) => {
    return get(url.NEW_MEMBERS_BY_CLUB_ID(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

const getUserSubscriptionByIdAPI = (token, id) => {
    return get(url.USER_SUBSCRIPTION_BY_ID(id), {
        headers: {
            Authorization: token,
        },
    });
};


const getUserPausesAPI = (token, id) => {
    return get(url.USER_PAUSES(id), {
        headers: {
            Authorization: token,
        },
    });
};


const getDoordeckEntriesForUser = (token, email) => {
    return get(url.DOORDECK_ENTRIES_MEMBER(email), {
        headers: {
            Authorization: token,
        },
    });
};

const getMemberNotesAPI = (token, userId) => {
    return get(url.GET_NOTES_FOR_MEMBER(userId), {
        headers: {
            Authorization: token,
        },
    });
};


const addNotesForMemberAPI = (token, memberId, notes) => {
    return post(
        url.NOTES_FOR_MEMBER,
        {
            memberId,
            notes
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const updateNoteAPI = (token, id, notes) => {
    return put(
        url.UPDATE_NOTE,
        {
            id,
            notes
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const updateUserAtRiskAPI = (token, userId, atRisk) => {
    return put(
        url.UPDATE_USER_AT_RISK,
        {
            userId,
            atRisk
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const deleteNoteAPI = (token, id) => {
    return put(
        url.DELETE_NOTE(id),
        {
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const cancelMembership = (token, data) => {
    return put(
        url.CANCEL_MEMBERSHIP,
        {
            userId: data.userId,
            customerId: data.customerId,
            cancelationReason: data.cancelationReason,
            logOut: data.logOut,
            cancelEmail: data.cancelEmail
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const updateOnboardingSession = (token, data) => {
    return put(
        url.UPDATE_ONBOARDING_SESSION,
        {
            userId: data.userId,
            onboardingSession: data.onboardingSession,
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const updateFirstMonthCheckIn = (token, data) => {
    return put(
        url.UPDATE_FIRST_MONTH_CHECHIN,
        {
            userId: data.userId,
            checkIn: data.checkIn,
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};


const updateLogOutFromApp = (token, data) => {
    return put(
        url.UPDATE_LOGOUT_FROM_APP,
        {
            userId: data.userId,
            loggedOut: data.loggedOut,
            cancelEmail: data.cancelEmail
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const getApplicantsAPI = (token) => {
    return get(url.LATEST_APPLICANTS, {
        headers: {
            Authorization: token,
        },
    });
};

const addApplicantAPI = (token, data) => {
    return post(
        url.APPLICANTS,
        {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            trial: data.trial,
            mainClubId: data.mainClubId,
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const getOnboardingMembers = (token, clubId) => {
    return get(url.GET_ONBOARDING_MEMBERS(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

const getNonAttendees = (token) => {
    return get(url.NON_ATTENDEES, {
        headers: {
            Authorization: token,
        },
    });
};

const getOnboardingBookingsAPI = (token) => {
    return get(url.GET_ONBOARDING_BOOKINGS, {
        headers: {
            Authorization: token,
        },
    });
};
const getHighRiskMembersAPI = (token) => {
    return get(url.GET_HIGH_RISK_MEMBERS, {
        headers: {
            Authorization: token,
        },
    });
};
const getClubLeaderMembersBySubclub = (token, subclubId) => {
    return get(url.CLUB_LEADER_MEMBERS_BY_SUBCLUB(subclubId), {
        headers: {
            Authorization: token,
        },
    });
};

const updateOnboardingBookingAPI = (token, bookingId, startDate, startTime) => {
    return put(
        url.UPDATE_ONBOARDING_BOOKING(bookingId),
        {
            startDate,
            startTime
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const cancelOnboardingBookingAPI = (token, bookingId) => {
    return put(
        url.CANCEL_ONBOARDING_BOOKING(bookingId),
        {}
        , {
            headers: {
                Authorization: token,
            },
        });
};

const onboardingScheduledReq = (token, email) => {
    return del(url.ONBOARDING_SCHEDULED(email), {
        headers: {
            Authorization: token,
        },
    });
};

const getDoorEntries = (token, doorId) => {
    const startDate = moment()
        .tz('America/Los_Angeles')
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .unix();
    const endDate = moment()
        .tz('America/Los_Angeles')
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 0)
        .unix();

    return get(url.DOORDECK_API(doorId, startDate, endDate), {
        headers: {
            Authorization: token
        }
    })
}

const sendEmailAsClubLeaderAPI = (token, data) =>
    post(
        url.SEND_EMAIL_LEADER,
        data,
        {
            headers: {
                Authorization: token,
            },
        }
    );


export {
    getMembers,
    getMemberById,
    addNotesForMemberAPI,
    getMemberNotesAPI,
    cancelMembership,
    updateOnboardingSession,
    updateFirstMonthCheckIn,
    getCanceledMembers,
    updateLogOutFromApp,
    getOnboardingMembers,
    getNonAttendees,
    getDoorEntries, getAppliedCouponsAPI,
    onboardingScheduledReq,
    getDoordeckEntriesForUser,
    getOnboardingBookingsAPI,
    updateOnboardingBookingAPI,
    cancelOnboardingBookingAPI,
    getHighRiskMembersAPI,
    getUserSubscriptionByIdAPI,
    getUserPausesAPI,
    getMemberByEmailAPI,
    getNewMembersAPI,
    getAllCouponsAPI,
    applyCouponAPI,
    addApplicantAPI, getMemberByIdBySubclubAPI,
    getApplicantsAPI, getClubLeaderMembersBySubclub,
    sendEmailAsClubLeaderAPI,
    updateNoteAPI,
    deleteNoteAPI,
    updateUserAtRiskAPI
};
