export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const AUTO_LOGIN = "AUTO_LOGIN";
export const AUTO_LOGIN_SUCCESS = "AUTO_LOGIN_SUCCESS";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";

export const USER_LOG_OUT_CHECK = "USER_LOG_OUT_CHECK";
export const CHECK_TOKEN_VALID = "CHECK_TOKEN_VALID";

export const FORGET_PASSWORD = 'FORGET_PASSWORD'