import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Nav, NavItem, NavLink, ModalHeader, ModalBody, Modal, Label } from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from "draft-js";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import { cancelBooking, checkInMemberOnEvent, getBookings, getBookingsLeader, getEventWaitlist, getEventWaitlistLeader, sendEmailToEventAttendees } from "store/actions";
import moment from "moment";
import DeleteModal from "components/Common/DeleteModal";
import { ExportToCsv } from "export-to-csv";
import { ErrorMessage, Field, Form, Formik } from "formik";
import DefaultModal from "components/Common/DefaultModal";


const CategoryEnum = {
    Bookings: 'bookings',
    Waitlist: 'waitlist'
}
class ClubLeaderBookings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            productData: [],
            eventBookings: [],
            propsReceivedOnce: false,
            deleteModal: false,
            bookingToDelete: null,
            selectedCategory: CategoryEnum.Bookings,
            showModal: false,
            sendEmailModal: false,
            subject: "",
            body: "",
            editorState: EditorState.createEmpty()
        };


        this.onCheckInChanged.bind(this);
        this.onCancelHandler.bind(this)
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
        this.toggle = this.toggle.bind(this);


    }

    onCancelHandler(bookingToDelete) {
        this.setState({
            bookingToDelete,
            deleteModal: true
        })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleValidDate = date => {
        const date1 = moment.utc(date).format("ddd, DD MMM Y");
        return date1;
    };

    handleDeleteEvent() {
        const { onCancelBooking, match: { params } } = this.props
        const { bookingToDelete } = this.state
        onCancelBooking(bookingToDelete.booking_id, params.id, bookingToDelete.user_id)
        const upadtedRowId = bookingToDelete.booking_id
        const newEventBookings = this.state.eventBookings.map(row => {
            if (row.booking_id === upadtedRowId) {
                return { ...row, status_text: "Canceled" };
            }
            return row;
        });
        this.setState({
            deleteModal: false,
            eventBookings: newEventBookings
        })
    }

    onCheckInChanged(row, rowIndex) {
        this.props.onCheckInMemberOnEvent(
            row.booking_id
        );

        const upadtedRowId = row.booking_id
        const newEventBookings = this.state.eventBookings.map(row => {
            if (row.booking_id === upadtedRowId) {
                return { ...row, checked_in: "Attended" };
            }
            return row;
        });
        this.setState({ eventBookings: newEventBookings });
    }

    checkinFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => {
                    this.onCheckInChanged(row, rowIndex);
                }}
                color="primary"
                disabled={row.checked_in !== "Absent"}
            >
                {row.checked_in !== "Absent" ? 'Checked In' : 'Check In'}
            </Button>
        );
    };

    cancelFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => {
                    this.onCancelHandler(row);
                }}
                color="danger"
            >
                Cancel
            </Button>
        );
    };

    dateFormater = (cell, row, rowIndex, formatExtraData) => {

        const startDate = moment()
        let isNew = startDate.diff(moment(row.start_date), "day") <= 30

        return (
            <span>{cell} {isNew ? <span style={{
                color: "red"
            }}> (New)  </span> : null} </span>
        );
    };

    componentDidMount() {
        const {
            match: { params },
            onGetBookings,
            onGetEventWaitlist
        } = this.props;
        if (params && params.id) {
            onGetBookings(params.id);
            const role = localStorage.getItem("roleId");
            if (role != 4) {
                onGetEventWaitlist(params.id)
            }
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.bookings !== prevProps.bookings) {
            this.setState({
                eventBookings: this.props.bookings
            })
        }

        if (this.state.editorState != prevState.editorState) {
            this.setState({
                body: `<div style="color: black">${draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}</div>`
            })
        }

    }

    UNSAFE_componentWillReceiveProps(props) {
        if (this.state.eventBookings.length == 0) {
            this.setState({
                eventBookings: props.bookings,
                propsReceivedOnce: true
            })
        }

    }

    toggle() {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    }

    sendEmail = () => {
        const {
            match: { params },
        } = this.props;

        this.props.onSendEmailToAttendees({
            eventId: params.id,
            subject: this.state.subject,
            body: this.state.body
        }, this.showToast)

        this.toggle();
        this.setState({
            sendEmailModal: false,
            subject: "",
            body: "",
        })
    }

    showToast(message, autoClose, success) {
        if (success) {
            toast.success(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        const { history } = this.props;
        const { deleteModal } = this.state

        const role = parseInt(localStorage.getItem("roleId"))

        let columns = this.state.selectedCategory === CategoryEnum.Bookings ? [
            {
                dataField: "number",
                text: "Nr",
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        history.push(`/profile/${row.user_id}/`);
                    },
                },
                formatter: (_, member, rowIndex) =>
                (
                    <span>{rowIndex + 1}</span>
                )
            },
            {
                dataField: "profile_photo",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, user) => (
                    <>
                        <img src={user.profile_photo} alt="" className="avatar-sm" />
                    </>
                ),
            },
            {
                dataField: "first_name",
                text: "First Name",
                sort: true,
                formatter: this.dateFormater,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        history.push(`/profile/${row.user_id}/`);
                    },
                }
            },
            {
                dataField: "last_name",
                text: "Last Name",
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        history.push(`/profile/${row.user_id}/`);
                    },
                }
            },
            {
                dataField: "number_guests",
                text: "Number of Guests",
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        history.push(`/profile/${row.user_id}/`);
                    },
                }
            },
            {
                dataField: "created_at",
                text: "Created",
                sort: true,
                formatter: (_, row) => (
                    <>
                        {this.handleValidDate(row.created_at)}
                        <p style={{ marginTop: 10 }}>
                            {moment(row.created_at).format('hh:mm A')}
                        </p>
                    </>
                ),
            },
            {
                dataField: "updated_at",
                text: "Updated",
                sort: true,
                formatter: (_, row) => (
                    <>
                        {this.handleValidDate(row.updated_at)}
                        <p style={{ marginTop: 10 }}>
                            {moment(row.updated_at).format('hh:mm A')}
                        </p>
                    </>
                ),
            },
            {
                dataField: "status_text",
                text: "Status",
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        history.push(`/profile/${row.user_id}/`);
                    },
                }
            },
            {
                dataField: "follow",
                text: "Check In",
                formatter: this.checkinFormatter,
                sort: true,
            },
            // {
            //     dataField: "canceled",
            //     text: "Cancel Booking",
            //     formatter: this.cancelFormatter,
            //     sort: true,
            // }

        ] : [
            {
                dataField: "number",
                text: "Nr",
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        history.push(`/profile/${row.user_id}/`);
                    },
                },
                formatter: (_, member, rowIndex) =>
                (
                    <span>{rowIndex + 1}</span>
                )
            },
            {
                dataField: "profile_photo",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, user) => (
                    <>
                        <img src={user.profile_photo} alt="" className="avatar-sm" />
                    </>
                ),
            },
            {
                dataField: "first_name",
                text: "First Name",
                sort: true,
                formatter: this.dateFormater,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        history.push(`/profile/${row.user_id}/`);
                    },
                }
            },
            {
                dataField: "last_name",
                text: "Last Name",
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        history.push(`/profile/${row.user_id}/`);
                    },
                }
            },
            {
                dataField: "created",
                text: "Created",
                sort: true,
                formatter: (_, row) => (
                    <>
                        {this.handleValidDate(row.created)}
                        <p style={{ marginTop: 10 }}>
                            {moment(row.created).format('hh:mm A')}
                        </p>
                    </>
                ),
            },
            {
                dataField: "updated",
                text: "Updated",
                sort: true,
                formatter: (_, row) => (
                    row.updated && <>
                        {this.handleValidDate(row.updated)}
                        <p style={{ marginTop: 10 }}>
                            {moment(row.updated).format('hh:mm A')}
                        </p>
                    </>
                ),
            },
            {
                dataField: "number_guests",
                text: "Number of Guests",
                sort: true,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        history.push(`/profile/${row.user_id}/`);
                    },
                }
            },
        ]
        if (role === 4) {
            columns.pop()
        }

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.selectedCategory === CategoryEnum.Bookings ? this.state.eventBookings?.length : this.props.eventWaitlist.length,
            custom: true,
        };
        const {
            match: { params },
        } = this.props;

        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteEvent}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                    cancel
                />
                <DefaultModal
                    title={'Are you sure you want to send this email?'}
                    description={'You will not be able to revert this!'}
                    show={this.state.sendEmailModal}
                    onSendClick={this.sendEmail}
                    onCloseClick={() => this.setState({ sendEmailModal: false })}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-content">
                    <MetaTags>
                        <title>{`${params.title}`} {`${this.state.selectedCategory}`} | Groundfloor</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title={params.title} breadcrumbItem={this.state.selectedCategory} />

                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', flexDirection: this.state.isMobile ? 'column' : 'row' }}>
                                                <Button
                                                    style={{ backgroundColor: "#032224", marginRight: 10 }}
                                                    className="font-16 btn-block btn btn-primary"
                                                    onClick={() => this.setState({
                                                        selectedCategory: CategoryEnum.Bookings
                                                    })}
                                                >
                                                    Bookings
                                                </Button>
                                                {role != 4 && <div  >
                                                    <Button
                                                        style={{ backgroundColor: "#032224", marginRight: 10 }}
                                                        className="font-16 btn-block btn btn-primary"
                                                        onClick={() => this.setState({
                                                            selectedCategory: CategoryEnum.Waitlist
                                                        })}
                                                    >
                                                        Waitlist
                                                    </Button>
                                                </div>}
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: this.state.isMobile ? 'column' : 'row' }}>
                                                <Button
                                                    style={{ backgroundColor: "#032224", marginRight: 10 }}
                                                    className="font-16 btn-block btn btn-primary"
                                                    onClick={() => this.setState({
                                                        showModal: true
                                                    })}
                                                >
                                                    Send email to all attendees
                                                </Button>

                                            </div>
                                        </div>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.selectedCategory === CategoryEnum.Bookings ? this.state.eventBookings : this.props.eventWaitlist}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.selectedCategory === CategoryEnum.Bookings ? this.state.eventBookings : this.props.eventWaitlist}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                                <Modal
                                    isOpen={this.state.showModal}
                                    className={this.props.className}
                                    size="xl"
                                >
                                    <ModalHeader toggle={this.toggle} tag="h4">
                                        {"Send email"}
                                    </ModalHeader>
                                    <span style={{ fontStyle: 'italic', marginLeft: 10 }}>Note that members will be able reply to you directly to your email registered with your groundfloor account.</span>
                                    <ModalBody>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                subject: "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                subject: Yup.string().required("Please Enter Subject"),
                                            })}
                                            onSubmit={(values) => {
                                                this.setState({
                                                    sendEmailModal: true,
                                                    subject: values.subject,
                                                    body: this.state.body
                                                })
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form>
                                                    <Row>
                                                        <Row className="mb-4">
                                                            <Col lg='10'>
                                                                <Label
                                                                    className="col-form-label col-lg-2"
                                                                >
                                                                    Subject
                                                                </Label>
                                                                <Field
                                                                    name="subject"
                                                                    placeholder="Subject"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.subject && touched.subject
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="subject"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col lg='10'>
                                                                <Label
                                                                    className="col-form-label col-lg-2"
                                                                >
                                                                    Body
                                                                </Label>
                                                                <Row>
                                                                    <span style={{ marginBottom: 10 }}>{`Compose your email body here, noting that a 'Hey firstName' will be automatically inserted.`}</span>
                                                                </Row>
                                                                <Editor
                                                                    editorState={this.state.editorState}
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                    editorStyle={{
                                                                        color: 'black'
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="text-end">

                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-success save-user"
                                                                >
                                                                    Send email
                                                                </button>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </ModalBody>
                                </Modal>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ClubLeaderBookings.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    bookings: PropTypes.array,
    eventWaitlist: PropTypes.array,
    onGetBookings: PropTypes.func,
    onGetEventWaitlist: PropTypes.func,
    onCancelBooking: PropTypes.func,
    checkedinBooking: PropTypes.array,
    onCheckInMemberOnEvent: PropTypes.func,
    onSendEmailToAttendees: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ bookings }) => ({
    bookings: bookings.bookings,
    eventWaitlist: bookings.eventWaitlist,
    booking: bookings.booking
});

const mapDispatchToProps = dispatch => ({
    onGetBookings: id => dispatch(getBookingsLeader(id)),
    onGetEventWaitlist: id => dispatch(getEventWaitlistLeader(id)),
    onCheckInMemberOnEvent: id => dispatch(checkInMemberOnEvent(id)),
    onCancelBooking: (bookingId, eventId, userId) => dispatch(cancelBooking(bookingId, eventId, userId)),
    onSendEmailToAttendees: (data, showToast) => dispatch(sendEmailToEventAttendees(data, showToast)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ClubLeaderBookings));
