import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";


// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";


import {
  getMemberById,
  addNotesForMember,
  cancelMember,
  setOnboardingSession,
  setFirstCheckIn,
  getEventAttendance,
  getDoordeckEntriesForMember,
  getUserSpaceBookings,
  getClubs,
  getUserSubById,
  resetBillingDate,
  getMemberNotes,
  getUserPauses,
  deleteNotesForMember,
  updateNotesForMember,
  updateMemberAtRisk,
  doorAccessStatus,
} from "../../store/actions";
import DefaultModal from "components/Common/DefaultModal";
import Switch from "components/Common/Switch";

class MemberProfile extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    this.state = {
      member: null,
      propsReceivedOnce: false,
      cancelationReasonDropdownOpen: false,
      cancelationReason: "Back in office",
      logOut: false,
      cancelEmail: false,
      onboardingSession: false,
      firstCheckIn: false,
      addNoteModal: false,
      deleteModal: false,
      note: null,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      memberAtRisk: false
    };
    this.copyUpdateMethodLinkToClipboard = this.copyUpdateMethodLinkToClipboard.bind(this);
    this.copyDoordeckIdToClipboard = this.copyDoordeckIdToClipboard.bind(this);
    this.copyMemberDeeplink = this.copyMemberDeeplink.bind(this);
    this.upadateLockAccess = this.upadateLockAccess.bind(this);
    this.copyEmailToClipBoard = this.copyEmailToClipBoard.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleAddNoteModal = this.toggleAddNoteModal.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.toggleCancelationReason = this.toggleCancelationReason.bind(this);
    this.logOutChecked = this.logOutChecked.bind(this);
    this.cancelEmail = this.cancelEmail.bind(this);
    this.handleOnboarding = this.handleOnboarding.bind(this);
    this.showSuccessToast = this.showSuccessToast.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this)
    this.handleDeleteNote = this.handleDeleteNote.bind(this)
    this.onClickEdit = this.onClickEdit.bind(this)
  }

  componentDidMount() {
    const {
      match: { params },
      onGetMemberById,
      onGetEventAttendance,
      onGetClubs,
      onResetBillingDate,
      onGetUserSubById,
      onGetMemberNotes,
      onGetUserPauses
    } = this.props;
    onResetBillingDate()
    if (params && params.id) {
      onGetMemberById(params.id);
      onGetEventAttendance(params.id);
      onGetUserSubById(params.id)
      onGetMemberNotes(params.id)
      onGetUserPauses(params.id)
      onGetClubs()
    }
  }

  copyUpdateMethodLinkToClipboard() {

    this.showSuccessToast("Link copied to clipboard", 500)
    navigator.clipboard.writeText(
      "https://api.groundfloorapp.com/update-payment-details/" +
      this.props.member?.user[0].id
    );
  }

  copyDoordeckIdToClipboard() {
    this.showSuccessToast("Id copied to clipboard", 500)
    navigator.clipboard.writeText(
      "" + this.props.member?.user[0].doordeck_id
    );
  }

  copyMemberDeeplink() {
    this.showSuccessToast("Member deeplink copied to clipboard", 500)
    navigator.clipboard.writeText(
      `https://www.groundfloorclub.com/g68zuqkhzwkodjphs3wjo/?user=${this.props.member.user[0].id}`
    );
  }

  upadateLockAccess(status) {
    const memberData = this.props.member?.user[0]
    const { onUpdateDoorDeckStatus } = this.props
    onUpdateDoorDeckStatus(memberData.id, memberData.first_name, memberData.last_name, memberData.email, status, memberData.doordeck_id, this.showToast)
  }

  showToast(message, autoClose, success) {
    if (success) {
      toast.success(message, {
        position: "top-right",
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  copyEmailToClipBoard() {
    this.showSuccessToast("Email copied to clipboard", 500)
    navigator.clipboard.writeText(
      "" + this.props.member?.user[0].email
    );
  }

  showSuccessToast(message, autoClose) {
    toast.success(message, {
      position: "top-right",
      autoClose: autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState,) {
    const { onGetDoordeckEntriesForMember, member, eventAttendance, onGetUserSpaceBookings } = this.props
    if (member !== prevProps.member && member !== null) {
      onGetDoordeckEntriesForMember(member?.user[0].email)
      onGetUserSpaceBookings(member?.user[0].id)
      this.setState({
        member: member?.user[0],
        propsReceivedOnce: true,
        onboardingSession: member?.user[0].onboarding_session == 1 ? true : false,
        firstCheckIn: member?.user[0].first_month_checkin == 1 ? true : false,
        memberAtRisk: !!member?.user[0].at_risk
      });
    }
  }

  handleCancelButtonClick = arg => {
    this.setState({ selectedProject: arg });
    this.toggle();
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  toggleCancelationReason() {
    this.setState(prevState => ({
      cancelationReasonDropdownOpen: !prevState.cancelationReasonDropdownOpen,
    }));
  }

  cancelationReasonSelected(value) {
    this.setState({
      cancelationReason: value,
    });
  }

  toggleCancelModal = () => {
    this.setState(prevState => ({
      cancelModal: !prevState.cancelModal,
    }));
  };

  logOutChecked = value => {
    this.setState({
      logOut: value,
    });
  };

  cancelEmail = value => {
    this.setState({
      cancelEmail: value
    })
  }

  handleOnboarding(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const { onSetOnboardingSession, onSetFirstCheckIn } = this.props;

    this.setState({
      [name]: value,
    });

    if (name == "onboardingSession") {
      const member = {
        userId: this.props.member?.user[0].id,
        onboardingSession: value ? 1 : 0,
      };
      onSetOnboardingSession(member);
      this.showSuccessToast("On boarding session updated", 2000)
    } else if (name == "firstCheckIn") {
      const member = {
        userId: this.props.member?.user[0].id,
        checkIn: value ? 1 : 0,
      };
      this.showSuccessToast("First month check-in updated", 2000)
      onSetFirstCheckIn(member);
    }
  }

  handleValidDate = date => {
    const date1 = moment(date).utc().format("DD MMM Y");
    return date1;
  };

  toggleAddNoteModal() {
    this.setState(prevState => ({
      addNoteModal: !prevState.addNoteModal,
      note: null
    }));
  }

  onClickDelete(note) {
    this.setState({
      note,
      deleteModal: true
    })
  }

  onClickEdit(note) {
    this.setState({
      note,
      addNoteModal: true,
    })
  }


  handleDeleteNote() {
    this.props.onDeleteNote(this.props.match.params.id, this.state.note.id)
    this.setState({
      deleteModal: false
    })
  }

  handleMemberAtRisk = () => {
    this.setState({
      memberAtRisk: !this.state.memberAtRisk,
    });
    this.props.onUpdateMemberAtRisk(this.props.member?.user[0].id, !this.state.memberAtRisk ? 1 : 0)
  };

  render() {

    const { eventAttendance, userSpaceBookings, loadingUserSpaceBookings, loadingDoordeckEntriesMember, loadingEventAttendance, doordeckEntriesMember, userPauses, onCancelMember } = this.props;
    const memberProfileHeadings = {
      industry: "Industry",
      job_title: "Job Title",
      offerings: "Offerings",
      wants: "Wants",
      community_contribute: "Community Contribution",
      personal_interests: "Personal Interests",
      looking_for: "Looking For",
      contact_reference: "Contact Reference",
      bio: "Bio",
      my_interests: "My Interests",
      where_are_you_in_life: "Where Are You In Life",
      words_best_describe_you: "Words Describe You",
      perfect_day_description: "Describe your perfect day",
      groundfloor_goals: "What are you looking to get out of Gf?",

    };
    const role = localStorage.getItem("roleId");

    const eventsColumns = [
      {
        text: "Events Attended",
        dataField: "title",
      },
      {
        text: "Start Date and Time",
        dataField: "start_date",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <span>
            {this.handleValidDate(event.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z')} from{" "}
            {moment(event.start_time, "HH:mm:ss").format("hh:mm A")}
          </span>
        ),
      },
      {
        text: "Attended",
        dataField: "attended",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <span>{event.attended ? 'Yes' : 'No'}</span>
        ),
      },
      {
        text: "Canceled",
        dataField: "canceled",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <span>{event.canceled ? 'Yes' : 'No'}</span>
        ),
      },
      {
        text: "Number Guests",
        dataField: "number_guests",
      },

    ];

    const doordeckColumns = [

      {
        text: "Start Date and Time",
        dataField: "timestamp",
        // eslint-disable-next-line react/display-name
        formatter: (_, entry) => (
          <span>
            {moment.unix(entry.timestamp).format("DD MMM Y hh:mmA")}
          </span>
        ),
      },
      {
        text: "Message",
        dataField: "message",
      },
    ];
    const userSpaceBookingsColumns = [
      {
        text: "Start Date and Time",
        dataField: "start_time",
        // eslint-disable-next-line react/display-name
        formatter: (_, booking) => (
          <span>
            {this.handleValidDate(booking.date, 'YYYY-MM-DDTHH:mm:ss.000Z')} from{" "}
            {moment(booking.start_time, 'HH:mm:ss').format("hh:mmA")}
          </span>
        ),
      },
      {
        text: "Duration",
        dataField: "duration",
      },
      {
        text: "Type",
        dataField: "type",
      },
      {
        text: "Location",
        dataField: "club_id",
        // eslint-disable-next-line react/display-name
        formatter: (_, booking) => (
          <span>
            {this.props.clubs?.find(item => item.id === booking.club_id)?.name}
          </span>
        ),
      },
    ];

    const userNotesColumns = [
      {
        text: "Written By",
        dataField: "clucreated_byb_id",
        // eslint-disable-next-line react/display-name
        formatter: (_, note) => (
          <span>
            {`${note.created_by_first_name} ${note.created_by_last_name}`}
          </span>
        ),
      },

      {
        text: "Note",
        dataField: "notes",
        formatter: (_, note) => (
          <textarea
            rows={5}
            disabled style={{ width: '100%', padding: '8px 16px' }}>
            {note.notes}
          </textarea>
        ),
      },
      {
        text: "Written Date",
        dataField: "created",
        // eslint-disable-next-line react/display-name
        formatter: (_, note) => (
          <span>
            {this.handleValidDate(note.created, 'YYYY-MM-DDTHH:mm:ss.000Z')}
          </span>
        ),
      },
      {
        text: "Action",
        dataField: "",
        formatter: (_, note) => (
          <>
            <UncontrolledDropdown>
              <DropdownToggle className="card-drop" tag="a">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => this.onClickEdit(note)}
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                  Edit
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.onClickDelete(note)}
                >
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ),
      },
    ];

    const referralsColumns = [
      {
        text: "Name",
        dataField: "first_name",
        // eslint-disable-next-line react/display-name
        formatter: (_, member) => (
          <span>
            {member.first_name} {member.last_name}
          </span>
        ),
      },
      {
        text: "Email",
        dataField: "email",
      },
      {
        text: "Start Date",
        dataField: "start_date",
        formatter: (_, member) => (
          <span>
            {this.handleValidDate(member.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z')}
          </span>
        ),
      },
      {
        text: "Subscription active",
        dataField: "subscription_active",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (_, member) => (
          <span style={{ color: member.subscription_active ? 'green' : 'red' }}>
            {member.subscription_active ? 'Active' : 'Canceled'}
          </span>
        ),
      },
      {
        text: "Subscription Type",
        dataField: "subscription_type",
      },
      {
        text: "Canceled Date",
        dataField: "subscription_canceled_date",
        formatter: (_, member) => (
          member.subscription_canceled_date && <span>
            {this.handleValidDate(member.subscription_canceled_date, 'YYYY-MM-DDTHH:mm:ss.000Z')}
          </span>
        ),
      },
    ];

    const subClubsColumns = [
      {
        text: "Name",
        dataField: "name",
      },
    ]

    const userPausesColumns = [
      {
        text: "Start Date",
        dataField: "start_date",
        formatter: (_, pause) => (
          <span>
            {this.handleValidDate(pause.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z')}
          </span>
        ),
      },
      {
        text: "End Date",
        dataField: "end_date",
        formatter: (_, pause) => (
          <span>
            {this.handleValidDate(pause.end_date, 'YYYY-MM-DDTHH:mm:ss.000Z')}
          </span>
        ),
      },
    ];


    const pageOptions = {
      sizePerPage: 5,
      totalSize: eventAttendance?.length,
      custom: true,
    };
    const pagePausesOptions = {
      sizePerPage: 5,
      totalSize: userPauses?.length,
      custom: true,
    };
    const pageDoordeckOptions = {
      sizePerPage: 5,
      totalSize: doordeckEntriesMember?.length,
      custom: true,
    };
    const pageUserSpaceBookingsOptions = {
      sizePerPage: 5,
      totalSize: userSpaceBookings?.length,
      custom: true,
    };
    const pageReferralsOptions = {
      sizePerPage: 5,
      totalSize: this.props.member?.referrals?.length,
      custom: true,
    };

    const pageSubClubsOptions = {
      sizePerPage: 5,
      totalSize: this.props.member?.subClubs?.length,
      custom: true,
    }
    const pageReferredByOptions = {
      sizePerPage: 5,
      totalSize: this.props.member?.referredBy?.length,
      custom: true,
    };

    const pageNotesOptions = {
      sizePerPage: 5,
      totalSize: this.props.notes?.length,
      custom: true,
    };

    const referralsTableRowEvents = {
      onClick: (e, row, rowIndex) => {
        // history.push(`/profile/${row.memberId}/`);
        window.open(`https://dashboard.groundfloorapp.com/profile/${row.id}/`, '_blank', 'noopener noreferrer');
      },
    };

    const isWithin15DaysOfStartDate = moment().isBetween(moment(this.props.member?.user[0]?.subscription_date, 'YYYY-MM-DDTHH:mm:ss.000Z'), moment(this.props.member?.user[0]?.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z'))

    let renewDate

    if (this.props.member?.subscriptionPlan) {
      const subscriptionPlan = this.props.member.subscriptionPlan
      const subscriptionSchedule = subscriptionPlan.subscriptionSchedule

      let membershipPlan

      if (subscriptionPlan?.currentPlan) {
        membershipPlan = subscriptionPlan?.currentPlan[0]
      }

      if (subscriptionSchedule[0]?.start_date) {
        renewDate = subscriptionSchedule[0].start_date
      } else {
        if (membershipPlan?.pause_collection?.resumes_at) {
          renewDate = membershipPlan.pause_collection.resumes_at
        } else {
          if (membershipPlan?.current_period_end) {
            renewDate = membershipPlan?.current_period_end
          } else {
            renewDate = moment().unix()
          }
        }
      }
    }

    console.log(this.props.member?.user[0])

    return (
      <React.Fragment>
        <DefaultModal
          title={'Are you sure you want to delete this note?'}
          description={'You will not be able to revert this!'}
          show={this.state.deleteModal}
          onSendClick={this.handleDeleteNote}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            {this.props.member?.user[0] && <Breadcrumb
              title={this.props.member?.user[0].first_name}
              breadcrumbItem="Profile"
            />}
            <Row>
              <Col lg="12">
                <Card>
                  {this.props.member?.user[0] && <CardBody>
                    <div style={this.state.isMobile ? { display: 'flex', flexDirection: 'column' } : {}} className="d-flex">

                      <div className="me-3">
                        <img
                          src={this.props.member?.user[0].profile_photo}
                          alt=""
                          className="avatar-xl"
                          style={{
                            width: 300,
                            height: 300,
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div style={{ marginTop: 40 }} className="align-self-center flex-1">
                        <div className="text-muted">
                          {this.props.member?.user[0].on_pause == 1 && (
                            <h3
                              style={{
                                color: "red",
                              }}
                            >
                              ON PAUSE
                            </h3>
                          )}
                          <h5>
                            {this.props.member?.user[0].first_name}{" "}
                            {this.props.member?.user[0].last_name}
                          </h5>
                          <p className="mb-0 font-size-14">
                            <b>{this.props.member?.user[0].pronouns}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            <b> {this.props.member?.user[0].other_pronoun}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            <b style={{ marginRight: 10 }}>{this.props.member?.user[0].email}</b>
                            <i onClick={this.copyEmailToClipBoard} className="mdi mdi-clipboard-file font-size-16 clipboard-copy me-1" />
                          </p>
                          <p className="mb-0 font-size-14">
                            <b>{this.props.member?.user[0].phone_number}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Club:{" "}
                            <b>{this.props.clubs.find(item => item.id === this.props.member?.user[0].main_club_id)?.name}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Birthday:{" "}
                            <b>
                              {moment(
                                this.props.member?.user[0].birthday,
                                "YYYY-MM-DD"
                              ).format("MMM DD YYYY")}
                            </b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Discord: <b>{this.props.member?.user[0].discord_handle}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Address: <b>{this.props.member?.user[0].address}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            City: <b>{this.props.member?.user[0].city}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            State: <b>{this.props.member?.user[0].state}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Industry: <b>{this.props.member?.user[0].industry}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Emergency Contact Name: <b>{this.props.member?.user[0].emergency_contact_name}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Emergency Contact Number: <b>{this.props.member?.user[0].emergency_contact_phone_number}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Job title: <b>{this.props.member?.user[0].job_title}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Referral code :{" "}
                            <b>{this.props.member?.user[0].referral_code}</b>
                          </p>
                          <br></br>
                          {this.props.member?.user[0]?.credits && <p className="mb-0 font-size-14">
                            Credits:
                            <b> ${this.props.member?.user[0].credits}</b>
                          </p>}
                          <p className="mb-0 font-size-14">
                            Subscription type:
                            <b> ${this.props.member?.user[0].subscription_type}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Membership Plan:
                            <b> {this.props.member?.user[0].membership_plan}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Member since:{" "}
                            <b>
                              {moment(
                                this.props.member?.user[0].start_date,
                                "YYYY-MM-DDTHH:mm:ss"
                              ).format("MMM DD YYYY")}
                            </b>
                          </p>
                        </div>
                      </div>



                      <div style={{ marginTop: 40, display: 'flex', flexDirection: 'row' }} className={this.state.isMobile ? "" : "ms-auto"}>
                        {(role == 666 || role == 1) && <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, marginRight: 10 }} className={this.state.isMobile ? "" : "ms-auto"}>
                          <button
                            className={`btn btn-light`}
                            disabled={this.props.loadingDoorAccess}

                            onClick={() => this.upadateLockAccess('share_locks')}
                            style={{ width: 160, height: 35, marginBottom: 20 }}
                          >
                            {this.props.loadingDoorAccess ? 'Loading...' : `Share door access`}
                          </button>
                          <button
                            className="btn btn-dark"
                            disabled={this.props.loadingDoorAccess}
                            onClick={() => this.upadateLockAccess('revoke_access')}
                            style={{ width: 160, height: 35, marginBottom: 10 }}
                          >
                            {this.props.loadingDoorAccess ? 'Loading...' : 'Revoke door access'}
                          </button>
                        </div>}
                        <div>
                          <br></br>
                          {role == 666 && <a
                            className="btn btn-primary w-md"
                            href={
                              "https://dashboard.stripe.com/customers/" +
                              this.props.member?.user[0].customer_id
                            }
                            target="_blank"
                            rel="noreferrer"
                            style={{ width: 160, height: 35 }}
                          >
                            Billing Profile
                          </a>}
                          <br></br>
                          <br></br>
                          <div>
                            <button
                              className="btn btn-light"
                              onClick={this.copyMemberDeeplink}
                              style={{ width: 160, height: 35, marginBottom: 10 }}
                            >
                              Copy Deeplink
                            </button>
                          </div>
                          {role == 666 && <button
                            className="btn btn-light "
                            onClick={this.copyUpdateMethodLinkToClipboard}
                            style={{ width: 160, height: 35 }}
                          >
                            Payment method
                          </button>}
                          <br></br>
                          <br></br>
                          {role == 666 && <button
                            className="btn btn-light "
                            onClick={this.copyDoordeckIdToClipboard}
                            style={{ width: 160, height: 35 }}
                          >
                            Doordeck Id
                          </button>}
                          <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                          <br></br>
                          {role == 666 && <button
                            className="btn btn-danger"
                            onClick={this.handleCancelButtonClick}
                            style={{ width: 160, height: 35 }}
                          >
                            Cancel Membership
                          </button>}
                          <br></br>
                          <br></br>
                          <div className="mb-4">
                            <Label
                            >
                              Member at risk
                            </Label>

                            <Col lg="10">
                              <Switch active={this.state.memberAtRisk} parentFunc={this.handleMemberAtRisk} />
                            </Col>
                          </div>
                          {/* Same as */}
                          <ToastContainer />
                        </div>
                      </div>

                    </div>
                  </CardBody>}
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  {this.props.member?.user[0] && <CardBody>
                    <div className="d-flex">
                      <div className="align-self-center flex-1">
                        {this.props.member?.user[0].subscription_canceled === 1 && <h4 style={{ color: 'red' }}>Canceled</h4>}
                        {isWithin15DaysOfStartDate && this.props.member?.user[0].trial === 1 && <h4 style={{ color: 'red' }}>On trial</h4>}
                        <div className="text-muted">
                          {(role == 666 || role === 1) && (
                            <>
                              <p className="mb-0 font-size-14">
                                Subscription type:
                                <b> ${this.props.member?.user[0].subscription_type}</b>
                              </p>
                              <p className="mb-0 font-size-14">
                                Membership Plan:
                                <b> {this.props.member?.user[0].membership_plan}</b>
                              </p>
                            </>

                          )}
                          <p className="mb-0 font-size-14">
                            Activated membership:{" "}
                            <b>
                              {moment(
                                this.props.member?.user[0].subscription_date,
                                "YYYY-MM-DD"
                              ).format("MMM DD YYYY")}
                            </b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Membership start date: <b>{moment(
                              this.props.member?.user[0].start_date,
                              "YYYY-MM-DD"
                            ).format("MMM DD YYYY")}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Next billing date: <b>{this.props.member?.user[0].subscription_canceled === 1 ? 'N/A' : moment.unix(renewDate).format('MMM DD YYYY')}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Request to cancel date: <b>{this.props.member?.user[0].requested_date ? moment(
                              this.props.member?.user[0].requested_date
                            ).format("MMM DD YYYY") : ''}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Final billing date: <b>{moment(this.props.member?.user[0]?.subscription_canceled_date).format('MMM DD YYYY')}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Effective last date as member: <b>{moment(this.props.member?.user[0]?.membership_canceled_date).format('MMM DD YYYY')}</b>
                          </p>
                          <p className="mb-0 font-size-14">
                            Cancelation category: <b>{this.props.member?.user[0].cancelation_reason}</b>
                          </p>
                          {this.props.member?.user[0].subject === 'Account cancelation' && <> <p className="mb-0 font-size-14">
                            Cancelation reason: <b>{this.props.member?.user[0].additional_feedback}</b>
                          </p>
                            <p className="mb-0 font-size-14">
                              Further cancelation notes: <b>{this.props.member?.user[0].message}</b>
                            </p></>}
                          {
                            this.props.member?.user[0].trial === 1 &&
                            <p className="mb-0 font-size-14">
                              Trial period: <b>{moment(
                                this.props.member?.user[0].subscription_date,
                                "YYYY-MM-DD"
                              ).format("MMM DD YYYY")} - {this.handleValidDate(this.props.member?.user[0].start_date, 'YYYY-MM-DDTHH:mm:ss.000Z')}</b>
                            </p>
                          }
                        </div>
                      </div>

                    </div>
                  </CardBody>}
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="4">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // paddingLeft: 10,
                            borderBottom: '1px solid #F0F2F6'
                          }}
                        >
                          <h4 style={{
                            marginLeft: 10,
                            fontSize: 17
                          }}>Event booking history</h4>

                        </div>
                        {eventAttendance && (
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            columns={eventsColumns}
                            data={eventAttendance}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={eventsColumns}
                                data={eventAttendance}

                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>

                                      <Col xl="12">
                                        <div className="table-responsive">
                                          {loadingEventAttendance ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Link
                                                to="#"
                                                className="text-success"
                                              >
                                                <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                Loading
                                              </Link>{" "}
                                            </div>
                                          ) : (
                                            <BootstrapTable
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          )}
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // paddingLeft: 10,
                            borderBottom: '1px solid #F0F2F6'
                          }}
                        >
                          <h4 style={{
                            marginLeft: 10,
                            fontSize: 17,
                          }}>Door access history</h4>

                        </div>
                        {doordeckEntriesMember && (
                          <PaginationProvider
                            pagination={paginationFactory(pageDoordeckOptions)}
                            columns={doordeckColumns}
                            data={doordeckEntriesMember}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={doordeckColumns}
                                data={doordeckEntriesMember}

                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>

                                      <Col xl="12">
                                        <div className="table-responsive">
                                          {loadingDoordeckEntriesMember ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Link
                                                to="#"
                                                className="text-success"
                                              >
                                                <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                Loading
                                              </Link>{" "}
                                            </div>
                                          ) : (
                                            <BootstrapTable
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          )}
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // paddingLeft: 10,
                            borderBottom: '1px solid #F0F2F6'
                          }}
                        >
                          <h4 style={{
                            marginLeft: 10,
                            fontsize: 17
                          }}>Space booking history</h4>

                        </div>
                        {eventAttendance && (
                          <PaginationProvider
                            pagination={paginationFactory(pageUserSpaceBookingsOptions)}
                            columns={userSpaceBookingsColumns}
                            data={userSpaceBookings}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={userSpaceBookingsColumns}
                                data={userSpaceBookings}

                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>

                                      <Col xl="12">
                                        <div className="table-responsive">
                                          {loadingUserSpaceBookings ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Link
                                                to="#"
                                                className="text-success"
                                              >
                                                <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                Loading
                                              </Link>{" "}
                                            </div>
                                          ) : (
                                            <BootstrapTable
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          )}
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>

              <Col lg="4">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // paddingLeft: 10,
                            borderBottom: '1px solid #F0F2F6'
                          }}
                        >
                          <h4 style={{
                            fontSize: 17,
                            marginLeft: 10
                          }}>Pausing history</h4>

                        </div>
                        {userPauses && (
                          <PaginationProvider
                            pagination={paginationFactory(pagePausesOptions)}
                            columns={userPausesColumns}
                            data={userPauses}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={userPausesColumns}
                                data={userPauses}

                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>

                                      <Col xl="12">
                                        <div className="table-responsive">

                                          <BootstrapTable
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={
                                              "thead-light"
                                            }
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />

                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                      </CardBody>
                    </Card>


                  </div>
                </div>
              </Col>
              <Col lg="4">
                {this.props.member?.subClubs.length > 0 && <Row>
                  <Col lg="12">
                    <div className="">
                      <div className="table-responsive">
                        <Card>
                          <CardBody>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                // paddingLeft: 10,
                                borderBottom: '1px solid #F0F2F6'
                              }}
                            >
                              <h4 style={{
                                marginLeft: 10,
                                fontSize: 17
                              }}>Sub clubs</h4>

                            </div>
                            {this.props.member?.subClubs && (
                              <PaginationProvider
                                pagination={paginationFactory(pageSubClubsOptions)}
                                columns={subClubsColumns}
                                data={this.props.member.subClubs}
                              >
                                {({ paginationProps, paginationTableProps }) => (
                                  <ToolkitProvider
                                    keyField="id"
                                    columns={subClubsColumns}
                                    data={this.props.member.subClubs}

                                  >
                                    {toolkitProps => (
                                      <React.Fragment>
                                        <Row>

                                          <Col xl="12">
                                            <div className="table-responsive">

                                              <BootstrapTable
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                classes={
                                                  "table align-middle table-nowrap"
                                                }
                                                headerWrapperClasses={
                                                  "thead-light"
                                                }
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row className="align-items-md-center mt-30">
                                          <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                              <SizePerPageDropdownStandalone
                                                {...paginationProps}
                                              />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                              <PaginationListStandalone
                                                {...paginationProps}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </React.Fragment>
                                    )}
                                  </ToolkitProvider>
                                )}
                              </PaginationProvider>
                            )}
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Col>


                </Row>}
              </Col>
            </Row>

            {this.props.member?.referrals.length > 0 && <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // paddingLeft: 10,
                            borderBottom: '1px solid #F0F2F6'
                          }}
                        >
                          <h4 style={{
                            marginLeft: 10
                          }}>Referrals</h4>

                        </div>
                        {this.props.member?.referrals && (
                          <PaginationProvider
                            pagination={paginationFactory(pageReferralsOptions)}
                            columns={referralsColumns}
                            data={this.props.member.referrals}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={referralsColumns}
                                data={this.props.member.referrals}

                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>

                                      <Col xl="12">
                                        <div className="table-responsive">

                                          <BootstrapTable
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            rowEvents={referralsTableRowEvents}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={
                                              "thead-light"
                                            }
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>


            </Row>}

            {this.props.member?.referredBy.length > 0 && <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // paddingLeft: 10,
                            borderBottom: '1px solid #F0F2F6'
                          }}
                        >
                          <h4 style={{
                            marginLeft: 10
                          }}>Referred by</h4>

                        </div>
                        {this.props.member?.referredBy && (
                          <PaginationProvider
                            pagination={paginationFactory(pageReferredByOptions)}
                            columns={referralsColumns}
                            data={this.props.member.referredBy}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={referralsColumns}
                                data={this.props.member.referredBy}

                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>

                                      <Col xl="12">
                                        <div className="table-responsive">

                                          <BootstrapTable
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            rowEvents={referralsTableRowEvents}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={
                                              "thead-light"
                                            }
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>}



            <Card>
              {this.props.member?.user[0] && <CardBody>
                {[...new Set(Object.keys(this.props.member?.user[0])
                  .filter(item =>
                    [
                      "industry",
                      "job_title",
                      "offerings",
                      "wants",
                      "community_contribute",
                      "personal_interests",
                      "looking_for",
                      "contact_reference",
                      "where_are_you_in_life",
                      "bio",
                      "my_interests",
                      "words_best_describe_you",
                      "perfect_day_description",
                      "groundfloor_goals",
                    ].includes(item)
                  ))]
                  .map(
                    (item, index) => {
                      return this.props.member?.user[0][item] && (
                        <Row key={index}>
                          <div>
                            <div className="d-flex">
                              <div className="align-self-center flex-1">
                                <div className="text-muted">
                                  <h5>{memberProfileHeadings[item]}</h5>
                                  <p className="mb-1">
                                    {this.props.member?.user[0][item]}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <br></br>
                          </div>
                        </Row>
                      )
                    }
                  )}
              </CardBody>}
            </Card>


            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // paddingLeft: 10,
                            borderBottom: '1px solid #F0F2F6'
                          }}
                        >
                          <h4 style={{
                            marginLeft: 10
                          }}>Groundfloor Notes</h4>
                          <div className="text-sm-end">
                            <Link to="#">
                              <Button
                                // color="#032224"
                                style={{ backgroundColor: "#032224" }}
                                className="font-16 btn-block btn btn-primary"
                                onClick={() => this.toggleAddNoteModal()}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Add Note
                              </Button>
                            </Link>
                          </div>
                        </div>
                        {this.props.notes && (
                          <PaginationProvider
                            pagination={paginationFactory(pageNotesOptions)}
                            columns={userNotesColumns}
                            data={this.props.notes}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={userNotesColumns}
                                data={this.props.notes}

                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>

                                      <Col xl="12">
                                        <div className="table-responsive">

                                          <BootstrapTable
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={
                                              "thead-light"
                                            }
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.addNoteModal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleAddNoteModal} tag="h4">
                {"Add Note"}
              </ModalHeader>
              <ModalBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    note: this.state.note ? this.state.note.notes : "",
                  }}
                  validationSchema={Yup.object().shape({
                    note: Yup.string().required(
                      "Please Enter Note"
                    ),
                  })}
                  onSubmit={values => {
                    if (this.state.note) {
                      this.props.onUpdateNote(this.props.match.params.id, this.state.note.id, values.note)
                    } else {
                      this.props.onAddNotesForMember(this.props.match.params.id, values.note)
                    }
                    this.toggleAddNoteModal()
                  }
                  }
                >
                  {({ errors, status, touched }) => (
                    <Form>
                      <Row>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Field name="img" type="hidden" />
                          </div>
                          <div className="mb-3">
                            <Field name="team" type="hidden" />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Note
                            </Label>
                            <Field
                              name="note"
                              type="text"
                              component="textarea"
                              rows="6"
                              className={
                                "form-control" +
                                (errors.note &&
                                  touched.note
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="note"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>


                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
          </Container>
          {this.props.member?.user[0] !== null && <Modal isOpen={this.state.modal}>
            <ModalHeader toggle={this.toggle} tag="h4">
              {"Are you sure you want to cancel "}
              {this.props.member?.user[0].first_name} {"'s"} {" membership?"}
            </ModalHeader>
            <ModalBody>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  logOut: false,
                  cancelEmail: false,
                }}
                onSubmit={values => {
                  const cancelData = {
                    userId: this.props.match.params.id,
                    customerId: this.props.member?.user[0].customer_id,
                    cancelationReason: this.state.cancelationReason,
                    logOut: values.logOut ? 1 : 0,
                    cancelEmail: values.cancelEmail ? 1 : 0,
                  };
                  onCancelMember(cancelData);
                  this.toggle();
                  this.showSuccessToast("Member has been canceled", 1000);

                  setTimeout(() => {
                    this.props.onGetMemberById(this.props.match.params.id)
                  }, 2500);
                }}
              >
                {({ errors, status, touched, values }) => (
                  <Form>
                    <Row>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Field name="img" type="hidden" />
                        </div>
                        <div className="mb-3">
                          <Field name="team" type="hidden" />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">
                            Log out from app
                          </Label>
                          <div>
                            <Field type="checkbox" name="logOut" />
                          </div>

                          <ErrorMessage
                            name="title"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">
                            Send cancelation email
                          </Label>
                          <div>
                            <Field type="checkbox" name="cancelEmail" />
                          </div>

                          <ErrorMessage
                            name="title"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div>
                          <Label
                            htmlFor="category"
                            className="col-form-label "
                          >
                            Cancelation Reason
                          </Label>
                          <Dropdown
                            isOpen={this.state.cancelationReasonDropdownOpen}
                            toggle={this.toggleCancelationReason}
                          >
                            <DropdownToggle caret>
                              {this.state.cancelationReason}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected(
                                    "Back in office"
                                  )
                                }
                                cancelationReason="Back in office"
                              >
                                Back in office
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected(
                                    "Location distance"
                                  )
                                }
                                cancelationReason="Location distance"
                              >
                                Location distance
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected(
                                    "Moved away/Travelling"
                                  )
                                }
                                cancelationReason="Moved away/Travelling"
                              >
                                Moved away/Travelling
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected(
                                    "Under-utilization of space"
                                  )
                                }
                                cancelationReason="Under-utilization of space"
                              >
                                Under-utilization of space
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected(
                                    "Did not meet expectation"
                                  )
                                }
                                cancelationReason="Did not meet expectation"
                              >
                                Did not meet expectation
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected(
                                    "Personal circumstances"
                                  )
                                }
                                cancelationReason="Personal circumstances"
                              >
                                Personal circumstances
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected("Unknown")
                                }
                                cancelationReason="Unknown"
                              >
                                Unknown
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected("NA")
                                }
                                cancelationReason="NA"
                              >
                                NA
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected(
                                    "No Response"
                                  )
                                }
                                cancelationReason="No Response"
                              >
                                No Response
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected(
                                    "Terminated by Gf"
                                  )
                                }
                                cancelationReason="Terminated by Gf"
                              >
                                Terminated by Gf
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() =>
                                  this.cancelationReasonSelected(
                                    "Failed Payments"
                                  )
                                }
                                cancelationReason="Failed Payments"
                              >
                                Failed Payments
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-danger save-user"
                          >
                            Cancel Member
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </Modal>}
        </div>
      </React.Fragment>
    );
  }
}

MemberProfile.propTypes = {
  match: PropTypes.object,
  editProfile: PropTypes.func,
  error: PropTypes.any,
  className: PropTypes.any,
  success: PropTypes.any,
  nextBillingDate: PropTypes.any,
  member: PropTypes.object,
  onGetMemberById: PropTypes.func,
  onAddNotesForMember: PropTypes.func,
  onGetDoordeckEntriesForMember: PropTypes.func,
  onCancelMember: PropTypes.func,
  onSetOnboardingSession: PropTypes.func,
  onSetFirstCheckIn: PropTypes.func,
  onGetUserSubById: PropTypes.func,
  onResetBillingDate: PropTypes.func,
  onGetMemberNotes: PropTypes.func,
  onGetUserPauses: PropTypes.func,
  onDeleteNote: PropTypes.func,
  onUpdateNote: PropTypes.func,
  onUpdateMemberAtRisk: PropTypes.func,
  eventAttendance: PropTypes.array,
  notes: PropTypes.array,
  userSpaceBookings: PropTypes.array,
  doordeckEntriesMember: PropTypes.array,
  userPauses: PropTypes.array,
  onGetEventAttendance: PropTypes.func,
  onGetUserSpaceBookings: PropTypes.func,
  onUpdateDoorDeckStatus: PropTypes.func,
  loadingEventAttendance: PropTypes.bool,
  loadingUserSpaceBookings: PropTypes.bool,
  loadingDoordeckEntriesMember: PropTypes.bool,
  loadingDoorAccess: PropTypes.bool,
  clubs: PropTypes.array,
  onGetClubs: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = ({ members, events, bookings, system }) => ({
  member: members.member,
  notes: members.notes,
  doordeckEntriesMember: members.doordeckEntriesMember,
  nextBillingDate: members.nextBillingDate,
  loadingDoordeckEntriesMember: members.loadingDoordeckEntriesMember,
  eventAttendance: events.eventAttendance,
  loadingEventAttendance: events.loadingEventAttendance,
  loadingUserSpaceBookings: bookings.loadingUserSpaceBookings,
  userSpaceBookings: bookings.userSpaceBookings,
  clubs: system.clubs,
  userPauses: members.userPauses,
  loadingDoorAccess: system.loadingDoorAccess
});

const mapDispatchToProps = dispatch => ({
  onGetMemberById: id => dispatch(getMemberById(id)),
  onGetMemberNotes: id => dispatch(getMemberNotes(id)),
  onGetUserSubById: id => dispatch(getUserSubById(id)),
  onResetBillingDate: () => dispatch(resetBillingDate()),
  onAddNotesForMember: (userId, notes) => dispatch(addNotesForMember(userId, notes)),
  onCancelMember: data => dispatch(cancelMember(data)),
  onGetEventAttendance: (userId) => dispatch(getEventAttendance(userId)),
  onGetDoordeckEntriesForMember: (email) => dispatch(getDoordeckEntriesForMember(email)),
  onGetUserSpaceBookings: (userId) => dispatch(getUserSpaceBookings(userId)),
  onGetClubs: () => dispatch(getClubs()),
  onSetOnboardingSession: onboarding =>
    dispatch(setOnboardingSession(onboarding)),
  onSetFirstCheckIn: checkIn => dispatch(setFirstCheckIn(checkIn)),
  onGetUserPauses: id => dispatch(getUserPauses(id)),
  onDeleteNote: (userId, noteId) => dispatch(deleteNotesForMember(userId, noteId)),
  onUpdateNote: (userId, noteId, note) => dispatch(updateNotesForMember(userId, noteId, note)),
  onUpdateMemberAtRisk: (userId, risk) => dispatch(updateMemberAtRisk(userId, risk)),
  onUpdateDoorDeckStatus: (id,
    firstName,
    lastName,
    email,
    status,
    doordeckId,
    showToast) => dispatch(doorAccessStatus(id,
      firstName,
      lastName,
      email,
      status,
      doordeckId,
      showToast)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MemberProfile));
