import {
  GET_CLUB_LEADER_SUB_CLUBS, GET_CLUB_LEADER_SUB_CLUBS_FAIL, GET_CLUB_LEADER_SUB_CLUBS_SUCCESS, GET_SUB_CLUBS, GET_SUB_CLUBS_BY_CLUB_ID, GET_SUB_CLUBS_BY_CLUB_ID_FAIL, GET_SUB_CLUBS_BY_CLUB_ID_SUCCESS, GET_SUB_CLUBS_FAIL, GET_SUB_CLUBS_SUCCESS,
} from "./actionTypes";

export const getSubClubs = () => ({
  type: GET_SUB_CLUBS,
});

export const getSubClubsSuccess = subClubs => ({
  type: GET_SUB_CLUBS_SUCCESS,
  payload: subClubs,
});

export const getSubClubsFail = error => ({
  type: GET_SUB_CLUBS_FAIL,
  payload: error,
});

export const getClubLeaderSubClubs = () => ({
  type: GET_CLUB_LEADER_SUB_CLUBS,
});

export const getClubLeaderSubClubsSuccess = subClubs => ({
  type: GET_CLUB_LEADER_SUB_CLUBS_SUCCESS,
  payload: subClubs,
});

export const getClubLeaderSubClubsFail = error => ({
  type: GET_CLUB_LEADER_SUB_CLUBS_FAIL,
  payload: error,
});

export const getSubClubsByClubId = (id) => ({
  type: GET_SUB_CLUBS_BY_CLUB_ID,
  payload: id
});

export const getSubClubsByClubIdSuccess = subClubs => ({
  type: GET_SUB_CLUBS_BY_CLUB_ID_SUCCESS,
  payload: subClubs,
});

export const getSubClubsByClubIdFail = error => ({
  type: GET_SUB_CLUBS_BY_CLUB_ID_FAIL,
  payload: error,
});