import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import classnames from "classnames";



import {
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { getCalendlyEvent, getCalendlyEventInvitees, getCalendlyEvents } from "store/actions";
import InviteeDetails from "components/Common/InviteeDetails";

class ScheduledOnboardingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendlyEvents: [],
            modal: false,
            eventTypes: [],
            selectedEventType: '',
        };

        this.handleSelectedEventClick = this.handleSelectedEventClick.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        const { calendlyEvents, onGetCalendlyEvents, } = this.props;
        if (calendlyEvents.length === 0) {
            onGetCalendlyEvents();
        } else {
            const filteredWithEventTypes = calendlyEvents.filter(item => item.name === 'Groundfloor: New member orientation' || item.name === 'Onboarding to Groundfloor')
            this.setState({
                calendlyEvents: filteredWithEventTypes,
                eventTypes: [...new Set(filteredWithEventTypes.map(item => item.name))],
                selectedEventType: [...new Set(filteredWithEventTypes.map(item => item.name))][0]
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.calendlyEvents !== this.props.calendlyEvents) {
            const filteredWithEventTypes = this.props.calendlyEvents.filter(item => item.name === 'Groundfloor: New member orientation' || item.name === 'Onboarding to Groundfloor')
            this.setState({
                calendlyEvents: filteredWithEventTypes.filter(item => item.name === [...new Set(filteredWithEventTypes.map(item => item.name))][0]),
                eventTypes: [...new Set(filteredWithEventTypes.map(item => item.name))],
                selectedEventType: [...new Set(filteredWithEventTypes.map(item => item.name))][0]
            })
        }
    }

    handleSelectedEventClick = (eventId) => {
        const { onGetCalendlyEvent, onGetCalendlyEventInvitees } = this.props
        onGetCalendlyEvent(eventId)
        onGetCalendlyEventInvitees(eventId)
        this.toggle();
    };



    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    render() {
        const { selectedEventInvitees, selectedEvent, loadingCalendlyEvents } = this.props;
        const { eventTypes, selectedEventType, calendlyEvents } = this.state
        const { SearchBar } = Search;
        const tableRowEvents = {
            onClick: (e, row, rowIndex) => {
                const reg = /[https://api\.calendly\.com/scheduled_events/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/g
                this.handleSelectedEventClick(row.uri.match(reg)[0])
            },
        };
        const events = [
            {
                text: "Date",
                dataField: "start_time",
                formatter: (_, event) => {

                    return (
                        <>
                            <h5 className="font-size-14">
                                <span
                                    className="text-dark"
                                >
                                    {moment(event.start_time).tz('America/Los_Angeles').format('dddd, DD MMMM YYYY')}
                                </span>
                            </h5>
                        </>
                    )
                },
            },
            {
                text: "Time",
                dataField: "end_time",
                formatter: (_, event) => {

                    return (
                        <>
                            <h5 className="font-size-14">
                                <span
                                    className="text-dark"
                                >
                                    {moment(event.start_time).tz('America/Los_Angeles').format('hh:mm A')} - {moment(event.end_time).tz('America/Los_Angeles').format('hh:mm A')}
                                </span>
                            </h5>
                        </>
                    )
                },
            },
            {
                text: "Invitees",
                dataField: "invitees_counter",
                filterValue: (_, event) => event.name,
                formatter: (_, event) => {

                    return (
                        <>
                            <h5 className="font-size-14">
                                <span
                                    className="text-dark"
                                >
                                    {event.invitees_counter.active} of {event.invitees_counter.limit}
                                </span>
                            </h5>
                        </>
                    )
                }
            },

        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: calendlyEvents?.length,
            custom: true,
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Onboarding Calendar | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Onboarding" breadcrumbItem="Onboarding Calendar" />

                        <div className="text-sm-end">

                        </div>
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        marginBottom: 10
                                                    }}
                                                >
                                                    <Nav
                                                        pills
                                                        className="bg-light rounded"
                                                        role="tablist"
                                                    >
                                                        <NavItem>
                                                        </NavItem>
                                                        {eventTypes?.map((item) => ({ name: item, value: item === 'Groundfloor: New member orientation' ? 'Oakland, CA' : 'San Francisco, CA' }))?.map((item, index) => (
                                                            <NavItem key={index}>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: item.name === selectedEventType,
                                                                    })}
                                                                    style={{
                                                                        backgroundColor:
                                                                            selectedEventType === item.name
                                                                                ? "#032224"
                                                                                : "transparent",
                                                                    }}
                                                                    onClick={() => {
                                                                        const filteredWithEventTypes = this.props.calendlyEvents.filter(itemCal => itemCal.name === 'Groundfloor: New member orientation' || itemCal.name === 'Onboarding to Groundfloor')

                                                                        this.setState({
                                                                            calendlyEvents: filteredWithEventTypes.filter(itemCal => itemCal.name === item.name),
                                                                            selectedEventType: item.name,
                                                                        });
                                                                    }}
                                                                >
                                                                    {item.value}
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                    </Nav>
                                                </div>
                                                {calendlyEvents && events && <PaginationProvider
                                                    pagination={paginationFactory(pageOptions)}
                                                    columns={events}
                                                    data={calendlyEvents}
                                                >
                                                    {({ paginationProps, paginationTableProps }) => (
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            columns={events}
                                                            data={calendlyEvents}
                                                            search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>

                                                                    <Row>
                                                                        <SearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        // style={{ width: "400px", height: "40px" }}
                                                                        />
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                {loadingCalendlyEvents ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    rowEvents={tableRowEvents}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap"
                                                                                    }
                                                                                    headerWrapperClasses={"thead-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                />}
                                                                            </div>
                                                                        </Col>
                                                                        {/* </Link> */}
                                                                    </Row>

                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="inner-custom-pagination d-flex">
                                                                            <div className="d-inline">
                                                                                <SizePerPageDropdownStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                            <div className="text-md-right ms-auto">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                                </PaginationProvider>}
                                            </CardBody>
                                        </Card>


                                        <Modal
                                            isOpen={this.state?.modal}
                                        >
                                            <ModalHeader toggle={this.toggle} tag='h4' >
                                                Onboarding Details
                                            </ModalHeader>
                                            <ModalBody >
                                                <h5>Location</h5>
                                                <span>{selectedEvent?.location?.location}</span>

                                                <h5 style={{ marginTop: 20 }}>Invitees</h5>
                                                {selectedEventInvitees.map((item, index) => <InviteeDetails key={index} item={item} />)}
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ScheduledOnboardingList.propTypes = {
    calendlyEvents: PropTypes.array,
    selectedEventInvitees: PropTypes.array,
    selectedEvent: PropTypes.object,
    loadingCalendlyEvents: PropTypes.bool,
    className: PropTypes.any,
    token: PropTypes.any,
    onGetCalendlyEvents: PropTypes.func,
    onGetCalendlyEvent: PropTypes.func,
    onGetCalendlyEventInvitees: PropTypes.func,

};

const mapStateToProps = ({ events, Login }) => ({
    calendlyEvents: events.calendlyEvents,
    selectedEvent: events.calendlyEvent,
    loadingCalendlyEvents: events.loadingCalendlyEvents,
    selectedEventInvitees: events.calendlyEventInitees,
    token: Login.token,
});

const mapDispatchToProps = dispatch => ({
    onGetCalendlyEvents: () => dispatch(getCalendlyEvents()),
    onGetCalendlyEvent: (eventId) => dispatch(getCalendlyEvent(eventId)),
    onGetCalendlyEventInvitees: (eventId) => dispatch(getCalendlyEventInvitees(eventId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ScheduledOnboardingList));
