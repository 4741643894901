import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Label,
    Button,
} from "reactstrap";

import {
    applyCoupon,
    getAllCoupons,
    getClubs,
} from "store/actions";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

import DefaultModal from "components/Common/DefaultModal";

class ApplyCoupon extends Component {
    constructor() {
        super();
        this.state = {
            selectedFiles: [],
            eventImage: null,
            clubsFiltered: [],
            clubsSelected: [],
            value: [],
            clubError: false,
            confirmModal: false,
            emailOrNotification: { label: "Email", value: 'Email' },
            dataToSend: null,
            couponSelected: { label: "Select Coupon", value: 0 }
        };
        this.handleAcceptedFiles.bind(this);
        this.showSuccessToast = this.showSuccessToast.bind(this);

    }

    filterClubs = () => {
        const { clubs } = this.props;
        return clubs.map(item => ({ label: item.name, value: item.id }));
    };

    handleEmailNotificationSelect = value => {
        this.setState({
            emailOrNotification: value,
        })

    }


    showSuccessToast(message, autoClose, success) {
        if (success) {
            toast.success(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                this.props.history.push('/applied-coupons')
            }, 2000);
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    componentDidMount() {
        const {
            onGetClubs, onGetAllCoupons
        } = this.props;
        onGetClubs();
        onGetAllCoupons()
        this.setState({
            clubsFiltered: [
                {
                    label: "Clubs",
                    options: this.filterClubs(),
                },
            ],
        });
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );

        this.setState({ selectedFiles: files });
    };

    onChange = (newValue) => {
        this.setState({
            value: newValue,
            clubError: false
        })
    };


    handleConfirm = () => {
        const { onApplyCoupon } = this.props;
        const { dataToSend } = this.state;
        onApplyCoupon({ ...dataToSend, showSuccessToast: this.showSuccessToast })
        this.setState({
            confirmModal: false,
        });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    handleCouponSelect = location => {
        this.setState({
            couponSelected: location,
            couponError: false
        })
    }


    render() {

        const { confirmModal } = this.state

        const couponSelect = this.props.allCoupons.map(item => ({
            label: `${item.coupon_title} - ${item.subscription_type}`,
            value: item.coupon_id
        }))


        return (
            <React.Fragment>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <DefaultModal
                    title={'Are you sure you want to apply this coupon?'}
                    description={'You will not be able to revert this!'}
                    show={confirmModal}
                    onSendClick={this.handleConfirm}
                    onCloseClick={() => this.setState({ confirmModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title> Apply Coupon | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        <Row>
                            <Col lg="12">
                                <Card >
                                    <CardBody style={{ height: '80vh' }}>
                                        <div style={{ display: 'flex', }}>
                                            <CardTitle style={{ marginRight: 43 }} className="mb-4">Apply Coupon</CardTitle>
                                        </div>
                                        <Row className="mb-4 mt-4">
                                            <Label className="col-form-label col-lg-2">
                                                Select Coupon
                                            </Label>
                                            <Col lg="4">
                                                <Select
                                                    onChange={
                                                        this.handleCouponSelect
                                                    }
                                                    options={couponSelect}
                                                    value={this.state.couponSelected}
                                                    defaultValue={{ label: "Select Coupon", value: 0 }}
                                                    classNamePrefix="select2-selection"
                                                />
                                                {this.state.couponError && (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        Please Select Coupon
                                                    </span>
                                                )}
                                            </Col>
                                        </Row>

                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={
                                                {
                                                    email: "",
                                                }
                                            }
                                            validationSchema={Yup.object().shape({
                                                email: Yup.string().required("Please Enter Email"),
                                            })}

                                            onSubmit={values => {
                                                if (this.state.couponSelected.value === 0) {
                                                    this.setState({
                                                        couponError: true
                                                    })
                                                } else {
                                                    const coupon = this.props.allCoupons.find(item => item.coupon_id === this.state.couponSelected.value)
                                                    this.setState({
                                                        dataToSend: {
                                                            email: values.email,
                                                            couponId: coupon.coupon_id,
                                                            couponTitle: coupon.coupon_title,
                                                            subscriptionType: coupon.subscription_type
                                                        },
                                                        confirmModal: true
                                                    })
                                                }
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <React.Fragment>
                                                    <Form>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="email"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Email
                                                            </Label>
                                                            <Col lg="4">
                                                                <Field
                                                                    name="email"
                                                                    type="text"
                                                                    placeholder="Email..."
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.email && touched.email
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="email"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="justify-content-end">
                                                            <Col lg="10">
                                                                <Button type="submit" color="primary">
                                                                    Apply Coupon
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ApplyCoupon.propTypes = {
    onGetClubs: PropTypes.func,
    onGetAllCoupons: PropTypes.func,
    onApplyCoupon: PropTypes.func,
    clubs: PropTypes.array,
    allCoupons: PropTypes.array,
    history: PropTypes.shape({
        go: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ system, members }) => ({
    clubs: system.clubs,
    allCoupons: members.allCoupons,
});

const mapDispatchToProps = dispatch => ({
    onGetClubs: () => dispatch(getClubs()),
    onGetAllCoupons: () => dispatch(getAllCoupons()),
    onApplyCoupon: (data) => dispatch(applyCoupon(data)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ApplyCoupon));