import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_GUESTS,
} from "./actionTypes";


import {
  getGuests,
} from "helpers";
import moment from "moment";

import {
  getBookingsSuccess,
  getGuestsSuccess,
  getGuestsFail,
} from "store/actions";

export function* fetchGuests({ payload: { date, clubId } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getGuests, token, clubId, date);

    const res = response.data.map((data, index) =>
      Object.assign(data, {
        id: index,
        dateFormated: moment(data.date).format("DD MMM Y"),
      })
    );
    yield put(getGuestsSuccess(res));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getGuestsFail(error));
  }
}



function* guestsSaga() {
  yield takeEvery(GET_GUESTS, fetchGuests);
}

export default guestsSaga;
