import { del, get, post, put } from "./api_helper";

const postLogin = data => post("/user/admin/login", data);

const forgotPasswordAPI = email => put(`/user/password/reset/${email}`, {}, {})

const getMembers = token => {
  return get("/user/members", {
    headers: {
      Authorization: token,
    },
  });
};

const getProfile = token => {
  return get("/user/profile", {
    headers: {
      Authorization: token,
    },
  });
};

const tokenValidCheck = token => {
  return get("/user/token", {
    header: {
      Authorization: token,
    },
  });
};

const getCanceledMembers = token => {
  return get("/user/members/canceled", {
    headers: {
      Authorization: token,
    },
  });
};

const getMemberById = (token, id) => {
  return get(`/user/member/${id}`, {
    headers: {
      Authorization: token,
    },
  });
};

const addNotesForMember = (token, id, notes) => {
  return put(
    `/user/member/notes/`,
    {
      memberId: id,
      notes: notes,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

const cancelMembership = (token, data) => {
  return put(
    `/user/member/cancel/`,
    {
      userId: data.userId,
      customerId: data.customerId,
      cancelationReason: data.cancelationReason,
      logOut: data.logOut,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

const updateOnboardingSession = (token, data) => {
  return put(
    `/user/member/onboarding/`,
    {
      userId: data.userId,
      onboardingSession: data.onboardingSession,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

const updateFirstMonthCheckIn = (token, data) => {
  return put(
    `/user/member/checkin/`,
    {
      userId: data.userId,
      checkIn: data.checkIn,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

const updateLogOutFromApp = (token, data) => {
  return put(
    `/user/member/logout/app`,
    {
      userId: data.userId,
      loggedOut: data.loggedOut,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

const getGuests = (token, date) => {
  return get(`/guest/date/${date}`, {
    headers: {
      Authorization: token,
    },
  });
};

const sendCancelationEmail = (token, data) => {
  return post(
    `/user/email/cancel`,
    {
      userId: data.userId,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

const getAllChildrenCategories = (token, date) => {
  return get(`/system/category/children/all`, {
    headers: {
      Authorization: token,
    },
  });
};

const uploadPicture = (token, data) => {
  return post("/upload", data, {
    headers: {
      Authorization: token,
      Accept: "multipart/form-data",
    },
  });
};

export {
  postLogin,
  uploadPicture,
  getMembers,
  getMemberById,
  addNotesForMember,
  getGuests,
  cancelMembership,
  updateOnboardingSession,
  updateFirstMonthCheckIn,
  getCanceledMembers,
  updateLogOutFromApp,
  getAllChildrenCategories,
  getProfile,
  tokenValidCheck,
  sendCancelationEmail,
  forgotPasswordAPI
};
