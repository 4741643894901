import { call, put, select, takeEvery } from "redux-saga/effects";

import { getImage } from "./selectos";

import { createNewEvent, deleteEvent, updateEvent, getPastEvents, getEventsByInstructorId, getInstructors, getEventsByClub, sendNewEventNotification, getCalendlyScheduledEvents, getCalendlyEvent, getCalendlyEventInvitees, getSuggestedEvents, updateSpotlight, getEventAttendanceAPI, cancelEvent, getEventPossibleMatchesAPI, getPastEventsByInstructorIdAPI, createNewEventInstructor, updateInstructorEvent, getSuggestedEventsByClubIdAPI, suggestNewEvent, getClubLeaderUpcomingEvents as getClubLeaderUpcomingEventsAPI, getClubLeaderPastEvents, getSuggestedEventsByUserIdAPI, getDraftsByUserIdAPI, createDraftAPI, deleteDraftAPI, updateDraftAPI, updateSuggestedEventAPI, sendEmailToEventAttendeesAPI, deleteSuggestedEventAPI, updateEventClubLeaderAPI } from "helpers";
import { getBookingsSuccess, getEventInstructorsSuccess, getEventsSuccess, getEventsFail, addEventSuccess, setImageSuccess, addEventFail, updateEventFail, deleteEventSuccess, getPastEventsFail, getPastEventsSuccess, getEventsByInstructorSuccess, deleteEventFail, getEventsByInstructorFail, getEventInstructorsFail, getEventsByClub as getEventsByClubAction, getCalendlyEventsSuccess, getCalendlyEventsFail, getCalendlyEventSuccess, getCalendlyEventFail, getCalendlyEventInviteesFail, getCalendlyEventInviteesSuccess, getSuggestedEventsSuccess, getSuggestedEventsFail, updateSpotlightSuccess, updateSpotlightFail, getEventAttendanceSuccess, getEventAttendanceFail, cancelEventSuccess, cancelEventFail, getEventPossibleMatchesSuccess, getPastEventsByInstructorSuccess, getPastEventsByInstructorFail, getEventsByInstructor, getSuggestedEventsByClubIdSuccess, getSuggestedEventsByClubIdFail, getClubLeaderUpcomingEventsSuccess, getClubLeaderUpcomingEventsFail, getClubLeaderPastEventsSuccess, getClubLeaderPastEventsFail, suggestEventSuccess, suggestEventFail, getSuggestedEventsByUserIdSuccess, getSuggestedEventsByUserIdFail, getDraftEventsByUserIdSuccess, getDraftEventsByUserIdFail, createDraftSuccess, createDraftFail, getClubLeaderUpcomingEvents, } from "store/actions";
import { ADD_EVENT, DELETE_EVENT, UPDATE_EVENT, GET_PAST_EVENTS, GET_EVENTS_BY_INSTRUCTOR, GET_EVENT_INSTRUCTOR, SET_IMAGE, GET_EVENTS_BY_CLUB, SEND_NEW_EVENT_NOTIFICATION, GET_CALENDLY_SCHEDULED_EVENTS, GET_CALENDLY_EVENT, GET_CALENDLY_EVENT_INVITEES, GET_SUGGESTED_EVENTS, UPDATE_SPOTLIGHT, GET_EVENT_ATTENDANCE, CANCEL_EVENT, GET_EVENT_POSSIBLE_MATCHES, GET_PAST_EVENTS_BY_INSTRUCTOR, ADD_EVENT_INSTRUCTOR, UPDATE_INSTRUCTOR_EVENT, GET_SUGGESTED_EVENTS_BY_CLUB_ID, GET_CLUB_LEADER_PAST_EVENTS, GET_CLUB_LEADER_UPCOMING_EVENTS, SUGGEST_EVENT, GET_SUGGESTED_EVENTS_BY_USER_ID, GET_DRAFT_EVENTS_BY_USER_ID, CREATE_DRAFT, DELETE_DRAFT, UPDATE_SUGGESTED_EVENT, UPDATE_DRAFT, SEND_EMAIL_TO_ATTENDEES, DELETE_SUGGESTED_EVENT, UPDATE_EVENT_CLUB_LEADER } from "./actionTypes";

export function* fetchEventsByClub({ payload: id }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getEventsByClub, token, id);
    yield put(getEventsSuccess(response.data));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getEventsFail(error));
  }
}

export function* fetchEventAttendance({ payload: userId }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getEventAttendanceAPI, token, userId);
    yield put(getEventAttendanceSuccess(response.data));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getEventAttendanceFail(error));
  }
}

export function* fetchPossibleMatches({ payload: eventId }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getEventPossibleMatchesAPI, token, eventId);
    yield put(getEventPossibleMatchesSuccess(response.data));
  } catch (error) {
    yield put(getEventAttendanceFail(error));
  }
}

export function* fetchPastEvents({ payload: clubId }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getPastEvents, token, clubId);
    yield put(getPastEventsSuccess(response.data));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getPastEventsFail(error));
  }
}

export function* fetchSuggestedEvents() {
  try {
    const response = yield call(getSuggestedEvents);
    yield put(getSuggestedEventsSuccess(response.records.map(item => ({ id: item.id, ...item.fields })).sort(function (a, b) {
      return new Date(b['Event date']) - new Date(a['Event date']);
    })));
  } catch (error) {
    yield put(getSuggestedEventsFail(error));
  }
}

export function* fetchSuggestedEventsByClubId({ payload: clubId }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getSuggestedEventsByClubIdAPI, token, clubId);
    yield put(getSuggestedEventsByClubIdSuccess(response.data));
  } catch (error) {
    yield put(getSuggestedEventsByClubIdFail(error));
  }
}

export function* fetchCalendlyEvents() {
  try {
    const response = yield call(getCalendlyScheduledEvents);
    yield put(getCalendlyEventsSuccess(response.collection));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getCalendlyEventsFail(error));
  }
}

export function* fetchCalendlyEvent({ payload: { eventId } }) {
  try {
    const response = yield call(getCalendlyEvent, eventId);
    yield put(getCalendlyEventSuccess(response.resource));
  } catch (error) {
    yield put(getCalendlyEventFail(error));
  }
}

export function* fetchCalendlyEventInvitees({ payload: { eventId } }) {
  try {
    const response = yield call(getCalendlyEventInvitees, eventId);
    yield put(getCalendlyEventInviteesSuccess(response.collection));
  } catch (error) {
    yield put(getCalendlyEventInviteesFail(error));
  }
}

function* createEvent({ payload: { event, history } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);

    const response = yield call(createNewEvent, token, { ...event, image });
    yield put(addEventSuccess(response));
    yield put(setImageSuccess(null));
    if (history) {
      history.push("/dashboard");
    }
  } catch (error) {
    yield put(addEventFail(error));
  }
}

function* createEventInstructor({ payload: { event, history } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);

    const response = yield call(createNewEventInstructor, token, { ...event, image });
    yield put(addEventSuccess(response));
    yield put(setImageSuccess(null));
    history.push("/instructor-dashboard");
  } catch (error) {
    yield put(addEventFail(error));
  }
}

function* sendNewEventNotificationSaga({ payload: data }) {
  try {
    const token = localStorage.getItem("token");
    yield call(sendNewEventNotification, token, data);
  } catch (error) {
  }
}

export function* onDeleteEvent({ payload: { id, clubId } }) {
  try {
    const token = localStorage.getItem("token");
    const response = yield call(deleteEvent, token, id);
    yield put(deleteEventSuccess(response));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(deleteEventFail(error));
  }
}

export function* onCancelEvent({ payload: { id } }) {
  try {
    const token = localStorage.getItem("token");
    const response = yield call(cancelEvent, token, id);
    yield put(cancelEventSuccess(response));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(cancelEventFail(error));
  }
}

function* onUpdateEvent({ payload: { event, clubId } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);
    yield call(
      updateEvent,
      token,
      { ...event, image },
      event.id
    );
    yield put(getEventsByClubAction(clubId));
    yield put(setImageSuccess(null));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(updateEventFail(error));
  }
}

function* onUpdateEventClubLeader({ payload: { event } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);
    yield call(
      updateEventClubLeaderAPI,
      token,
      { ...event, image },
    );
    yield put(getClubLeaderUpcomingEvents(event.subClubId));
    yield put(setImageSuccess(null));
    yield put(getBookingsSuccess([]));
  } catch (error) {
  }
}

function* onUpdateInstructorEvent({ payload: { event } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);
    yield call(
      updateInstructorEvent,
      token,
      { ...event, image },
    );
    yield put(getEventsByInstructor());
    yield put(setImageSuccess(null));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(updateEventFail(error));
  }
}

function* onUpdateSpotlight({ payload: { eventId, spotlight } }) {
  try {
    const token = localStorage.getItem("token");
    yield call(
      updateSpotlight,
      token,
      eventId,
      spotlight
    );
    yield put(updateSpotlightSuccess())
  } catch (error) {
    yield put(updateSpotlightFail(error));
  }
}

export function* fetchEventsByInstructor() {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("authUser"));

  try {
    const response = yield call(getEventsByInstructorId, token, user.id);
    yield put(getEventsByInstructorSuccess(response.data));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getEventsByInstructorFail(error));
  }
}

export function* fetchPastEventsByInstructor() {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("authUser"));
  try {
    const response = yield call(getPastEventsByInstructorIdAPI, token, user.id);
    yield put(getPastEventsByInstructorSuccess(response.data));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getPastEventsByInstructorFail(error));
  }
}

export function* fetchEventInstructor() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getInstructors, token, 4);
    var noInstuctor = {
      id: "0",
      first_name: "No",
      last_name: "Instructor",
    };
    const res = response.data;
    res.unshift(noInstuctor);

    yield put(getEventInstructorsSuccess(res));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getEventInstructorsFail(error));
  }
}


export function* setImage({ payload: { image } }) {
  try {
    yield put(setImageSuccess(image));
  } catch (error) {
  }
}

export function* fetchClubLeaderUpcomingEvents({ payload: subClubId }) {
  try {
    const token = localStorage.getItem("token");

    const response = yield call(getClubLeaderUpcomingEventsAPI, token, subClubId);
    yield put(getClubLeaderUpcomingEventsSuccess(response.data));

  } catch (error) {
    yield put(getClubLeaderUpcomingEventsFail(error));
  }
}

export function* fetchClubLeaderPastEvents({ payload: subClubId }) {
  try {
    const token = localStorage.getItem("token");
    const response = yield call(getClubLeaderPastEvents, token, subClubId);

    yield put(getClubLeaderPastEventsSuccess(response.data));

  } catch (error) {
    yield put(getClubLeaderPastEventsFail(error));
  }
}

export function* fetchSuggestedEventsByUserId() {
  try {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const response = yield call(getSuggestedEventsByUserIdAPI, token, userId);
    yield put(getSuggestedEventsByUserIdSuccess(response.data));
  } catch (error) {
    yield put(getSuggestedEventsByUserIdFail(error));
  }
}

export function* fetchDraftsByUserId() {
  try {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const response = yield call(getDraftsByUserIdAPI, token, userId);
    yield put(getDraftEventsByUserIdSuccess(response.data.map(item => ({ ...item, title: `Draft - ${item.title}` }))));
  } catch (error) {
    yield put(getDraftEventsByUserIdFail(error));
  }
}

function* suggestEvent({ payload: { event, history } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);

    const response = yield call(suggestNewEvent, token, { ...event, image });
    yield put(suggestEventSuccess(response));
    yield put(setImageSuccess(null));

    if (history) {
      history.push("/suggested-events");
    }
  } catch (error) {
    yield put(suggestEventFail(error));
  }
}

function* createDraft({ payload: { event, history } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);
    const user = localStorage.getItem('authUser')

    yield call(createDraftAPI, token, { ...event, image });
    yield put(setImageSuccess(null));

    if (history) {
      if (JSON.parse(user).roleId == 5) {
        history.push("/suggested-events");
      } else {
        history.push("/dashboard");
      }
    }
  } catch (error) {
    yield put(createDraftFail(error));
  }
}

function* updateDraft({ payload: { event, history } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);

    yield call(updateDraftAPI, token, { ...event, image });
    yield put(setImageSuccess(null));

    if (history) {
      history.push("/suggested-events");
    }
  } catch (error) {
  }
}

function* updateSuggestedEvent({ payload: { event, history } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);
    yield call(updateSuggestedEventAPI, token, { ...event, image });
    yield put(setImageSuccess(null));
    if (history) {
      history.push("/suggested-events");
    }
  } catch (error) {
  }
}

function* deleteDraft({ payload: draftId }) {
  try {
    const token = localStorage.getItem("token");
    yield call(deleteDraftAPI, token, draftId);
  } catch (error) {
  }
}

export function* sendEmailToEventAttendees({ payload: { data, showToast } }) {
  const token = localStorage.getItem("token");
  try {
    yield call(sendEmailToEventAttendeesAPI, token, data);
    showToast(`Email sent successfully`, 2000, true)
  } catch (error) {
    showToast(`Failed to sent`, 2000, false)
  }
}

function* deleteSuggestedEvent({ payload: suggestedId }) {
  try {
    const token = localStorage.getItem("token");
    yield call(deleteSuggestedEventAPI, token, suggestedId);
  } catch (error) {
  }
}

function* eventsSaga() {
  yield takeEvery(SET_IMAGE, setImage);
  yield takeEvery(GET_SUGGESTED_EVENTS, fetchSuggestedEvents);
  yield takeEvery(GET_SUGGESTED_EVENTS_BY_CLUB_ID, fetchSuggestedEventsByClubId);
  yield takeEvery(ADD_EVENT, createEvent);
  yield takeEvery(ADD_EVENT_INSTRUCTOR, createEventInstructor);
  yield takeEvery(DELETE_EVENT, onDeleteEvent);
  yield takeEvery(UPDATE_EVENT, onUpdateEvent);
  yield takeEvery(UPDATE_INSTRUCTOR_EVENT, onUpdateInstructorEvent);
  yield takeEvery(UPDATE_EVENT_CLUB_LEADER, onUpdateEventClubLeader);
  yield takeEvery(GET_PAST_EVENTS, fetchPastEvents);
  yield takeEvery(GET_CALENDLY_SCHEDULED_EVENTS, fetchCalendlyEvents);
  yield takeEvery(GET_CALENDLY_EVENT, fetchCalendlyEvent);
  yield takeEvery(GET_CALENDLY_EVENT_INVITEES, fetchCalendlyEventInvitees);
  yield takeEvery(GET_PAST_EVENTS_BY_INSTRUCTOR, fetchPastEventsByInstructor);
  yield takeEvery(GET_EVENTS_BY_INSTRUCTOR, fetchEventsByInstructor);
  yield takeEvery(GET_EVENT_INSTRUCTOR, fetchEventInstructor);
  yield takeEvery(GET_EVENTS_BY_CLUB, fetchEventsByClub);
  yield takeEvery(SEND_NEW_EVENT_NOTIFICATION, sendNewEventNotificationSaga);
  yield takeEvery(UPDATE_SPOTLIGHT, onUpdateSpotlight);
  yield takeEvery(GET_EVENT_ATTENDANCE, fetchEventAttendance);
  yield takeEvery(GET_EVENT_POSSIBLE_MATCHES, fetchPossibleMatches);
  yield takeEvery(CANCEL_EVENT, onCancelEvent);
  yield takeEvery(GET_CLUB_LEADER_PAST_EVENTS, fetchClubLeaderPastEvents);
  yield takeEvery(GET_CLUB_LEADER_UPCOMING_EVENTS, fetchClubLeaderUpcomingEvents);
  yield takeEvery(SUGGEST_EVENT, suggestEvent);
  yield takeEvery(CREATE_DRAFT, createDraft);
  yield takeEvery(DELETE_DRAFT, deleteDraft);
  yield takeEvery(UPDATE_SUGGESTED_EVENT, updateSuggestedEvent);
  yield takeEvery(UPDATE_DRAFT, updateDraft);
  yield takeEvery(GET_SUGGESTED_EVENTS_BY_USER_ID, fetchSuggestedEventsByUserId);
  yield takeEvery(GET_DRAFT_EVENTS_BY_USER_ID, fetchDraftsByUserId);
  yield takeEvery(SEND_EMAIL_TO_ATTENDEES, sendEmailToEventAttendees);
  yield takeEvery(DELETE_SUGGESTED_EVENT, deleteSuggestedEvent);

}


export default eventsSaga;
