import React from "react";
import { Redirect } from "react-router-dom";
// User profile


// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import EventCreate from "../pages/Events/EventCreate";

import EventList from "pages/Events/EventList";
import EventListInstructor from "pages/Events/EventListInstructor"
import PastEventList from "pages/Events/PastEventList";
import Bookings from "pages/Bookings/Bookings";
import Members from "pages/Members/Members";
import CanceledMembers from "pages/Members/CanceledMembers";
import Guests from "pages/Guests/Guests";
import SpaceBookings from "pages/Bookings/SpaceBookings";
import MemberProfile from "pages/Members/MemberProfile";
import DoorEntry from "pages/Members/DoorEntry";
import OnboardingMembers from "pages/Members/OnboardingMembers";
import InactiveMembers from "pages/Members/InactiveMembers";
import ScheduledOnboardingList from "pages/Events/ScheduledOnboardingList";
import ProductsList from "pages/Store/ProductsList";
import TourSchedules from 'pages/Events/TourSchedules';
import PublicBookings from "pages/Bookings/PublicBookings";
import AddProduct from "pages/Store/AddProduct";
import PossibleMatches from "pages/Events/PossibleMatches";
import SuggestedEventList from "pages/Events/SuggestedEventList";
import EventAttendanceList from "pages/Events/EventAttendanceList";
import OnboardingBookingsList from "pages/Members/OnboardingBookingsList";
import InstructorPastEventsList from "pages/Events/InstructorPastEventsList";
import EventCreateInstructor from "pages/Events/EventCreateInstructor";
import SendEmail from "pages/SendEmail/SendEmail";
import AnnouncmentList from "pages/Announcment/AnnouncmentList";
import AddAnnouncment from "pages/Announcment/AddAnnouncment";
import ApplyCoupon from "pages/Coupons/ApplyCoupon";
import CouponsList from "pages/Coupons/CouponsList";
import BookSpace from "pages/Bookings/BookSpace";
import ApplicantsList from "pages/Members/ApplicantsList";
import AddApplicant from "pages/Members/AddApplicant";
import ArchivedAnnouncmentList from "pages/Announcment/ArchivedAnnouncmentList";
import SuggestEventListByClub from "pages/Events/SuggestEventListByClub";
import OrdersList from "pages/Store/OrdersList";
import ClubLeaderUpcomingEvents from "pages/Events/ClubLeaderUpcomingEvents";
import ClubLeaderPastEvents from "pages/Events/ClubLeaderPastEvents";
import ClubLeaderMembersList from "pages/Members/ClubLeaderMembersList";
import SuggestEvent from "pages/Events/SuggestEvent";
import ClubLeaderBookings from "pages/Events/ClubLeaderBookings";
import SubClubMember from "pages/Members/SubClubMember";
import ClubLeaderSuggestedAndDrafts from 'pages/Events/ClubLeaderSuggestedAndDrafts';
import SubClubsList from "pages/Subclub/SubClubsList";
import SubClubMembers from "pages/Members/SubClubMembers";
import ForgetPassword from "pages/Authentication/ForgetPassword";
import DashboardChat from "pages/Chat/Chat";
import QRcodePage from "pages/Events/QRcodePage";

const authProtectedRoutes = [
  { path: "/club-upcoming-events", component: ClubLeaderUpcomingEvents },
  { path: "/send-email", component: SendEmail },
  { path: "/dashboard", component: EventList },
  { path: "/instructor-dashboard", component: EventListInstructor },
  { path: "/instructor-past-events", component: InstructorPastEventsList },
  { path: "/events-create", component: EventCreate },
  { path: "/bookings/:title/:id", component: Bookings },
  { path: "/members/canceled", component: CanceledMembers },
  { path: "/members", component: Members },
  { path: "/guests", component: Guests },
  { path: "/past-events", component: PastEventList },
  { path: "/space-bookings", component: SpaceBookings },
  { path: "/profile/:id", component: MemberProfile },
  { path: "/door-entry", component: DoorEntry },
  { path: "/onboarding-members", component: OnboardingMembers },
  { path: "/inactive-members", component: InactiveMembers },
  { path: "/scheduled-onboarding", component: ScheduledOnboardingList },
  { path: "/store", component: ProductsList },
  { path: "/announcements", component: AnnouncmentList },
  { path: "/archived-announcements", component: ArchivedAnnouncmentList },
  { path: "/tours", component: TourSchedules },
  { path: "/add-product", component: AddProduct },
  { path: "/add-announcment", component: AddAnnouncment },
  { path: "/possible-matches/:id", component: PossibleMatches },
  { path: "/suggested-events", component: SuggestedEventList },
  { path: "/suggested-events-by-club", component: SuggestEventListByClub },
  { path: "/user-events/:id", component: EventAttendanceList },
  { path: "/onboarding-bookings", component: OnboardingBookingsList },
  { path: "/apply-coupon", component: ApplyCoupon },
  { path: "/book-space", component: BookSpace },
  { path: "/merch-orders", component: OrdersList },
  { path: "/sub-clubs", component: SubClubsList },
  { path: "/sub-club-members/:id", component: SubClubMembers },
  { path: "/profile/:id/:subclubId", component: SubClubMember },
  { path: "/chat", component: DashboardChat },
  { path: "/qr/:id/:eventTitle/:date/:time", component: QRcodePage },


  // //profile
  // { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const superAdminAuthProtectedRoutes = [
  { path: "/send-email", component: SendEmail },
  { path: "/dashboard", component: EventList },
  { path: "/instructor-dashboard", component: EventListInstructor },
  { path: "/instructor-past-events", component: InstructorPastEventsList },
  { path: "/events-create", component: EventCreate },
  { path: "/bookings/:title/:id", component: Bookings },
  { path: "/members", component: Members },
  { path: "/members/canceled", component: CanceledMembers },
  { path: "/guests", component: Guests },
  { path: "/past-events", component: PastEventList },
  { path: "/space-bookings", component: SpaceBookings },
  { path: "/profile/:id", component: MemberProfile },
  { path: "/door-entry", component: DoorEntry },
  { path: "/onboarding-members", component: OnboardingMembers },
  { path: "/inactive-members", component: InactiveMembers },
  { path: "/scheduled-onboarding", component: ScheduledOnboardingList },
  { path: "/store", component: ProductsList },
  { path: "/announcements", component: AnnouncmentList },
  { path: "/archived-announcements", component: ArchivedAnnouncmentList },
  { path: "/tours", component: TourSchedules },
  { path: "/add-product", component: AddProduct },
  { path: "/add-announcment", component: AddAnnouncment },
  { path: "/possible-matches/:id", component: PossibleMatches },
  { path: "/suggested-events", component: SuggestedEventList },
  { path: "/suggested-events-by-club", component: SuggestEventListByClub },
  { path: "/user-events/:id", component: EventAttendanceList },
  { path: "/onboarding-bookings", component: OnboardingBookingsList },
  { path: "/apply-coupon", component: ApplyCoupon },
  { path: "/applied-coupons", component: CouponsList },
  { path: "/book-space", component: BookSpace },
  { path: "/applicants", component: ApplicantsList },
  { path: "/add-applicant", component: AddApplicant },
  { path: "/merch-orders", component: OrdersList },
  { path: "/sub-clubs", component: SubClubsList },
  { path: "/sub-club-members/:id", component: SubClubMembers },
  { path: "/profile/:id/:subclubId", component: SubClubMember },
  { path: "/chat", component: DashboardChat },
  { path: "/qr/:id/:eventTitle/:date/:time", component: QRcodePage },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];


const instructorAuthProtectedRoutes = [
  { path: "/instructor-dashboard", component: EventListInstructor },
  { path: "/instructor-event-create", component: EventCreateInstructor },
  { path: "/instructor-past-events", component: InstructorPastEventsList },
  { path: "/bookings/:title/:id", component: Bookings },
  { path: "/door-entry", component: DoorEntry },
  { path: "/onboarding-members", component: OnboardingMembers },
  { path: "/possible-matches/:id", component: PossibleMatches },
  { path: "/qr/:id/:eventTitle/:date/:time", component: QRcodePage },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/instructor-dashboard" /> },
];


const clubLeaderAuthProtectedRoutes = [
  { path: "/club-upcoming-events", component: ClubLeaderUpcomingEvents },
  { path: "/past-events", component: ClubLeaderPastEvents },
  { path: "/bookings/:title/:id", component: ClubLeaderBookings },
  { path: '/members', component: ClubLeaderMembersList },
  { path: "/profile/:id/:subclubId", component: SubClubMember },
  { path: '/suggest-event', component: SuggestEvent },
  { path: '/suggested-events', component: ClubLeaderSuggestedAndDrafts },
  { path: "/chat", component: DashboardChat },
  { path: "/qr/:id/:eventTitle/:date/:time", component: QRcodePage },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/club-upcoming-events" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/public/bookings/:title/:id", component: PublicBookings },
];

export { authProtectedRoutes, publicRoutes, instructorAuthProtectedRoutes, superAdminAuthProtectedRoutes, clubLeaderAuthProtectedRoutes };
