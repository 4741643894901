import { BOOK_SPACE, BOOK_SPACE_FAIL, BOOK_SPACE_SUCCESS, GET_BOOKINGS_FAIL, GET_BOOKINGS_SUCCESS, GET_EVENT_WAITLIST_FAIL, GET_EVENT_WAITLIST_SUCCESS, GET_PUBLIC_BOOKING, GET_PUBLIC_BOOKING_FAIL, GET_PUBLIC_BOOKING_SUCCESS, GET_SPACES, GET_SPACES_FAIL, GET_SPACES_SUCCESS, GET_SPACE_BOOKINGS_FAIL, GET_SPACE_BOOKINGS_SUCCESS, GET_USER_SPACE_BOOKINGS, GET_USER_SPACE_BOOKINGS_FAIL, GET_USER_SPACE_BOOKINGS_SUCCESS, UPDATE_CHECKIN_MEMBER_FAIL, UPDATE_CHECKIN_MEMBER_SUCCESS } from "./actionTypes";


const INIT_STATE = {
  spaceBookings: [],
  bookings: [],
  booking: [],
  error: null,
  publicBookings: [],
  loadingPublicBookings: false,

  userSpaceBookings: [],
  loadingUserSpaceBookings: false,

  eventWaitlist: [],

  loadingBookSpace: false,

  loadingGetSpaces: false,
  spaces: []
};

const events = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PUBLIC_BOOKING:
      return {
        ...state,
        loadingPublicBookings: true
      }

    case GET_PUBLIC_BOOKING_SUCCESS:
      return {
        ...state,
        loadingPublicBookings: false,
        publicBookings: action.payload
      }
    case GET_PUBLIC_BOOKING_FAIL:
      return {
        ...state,
        loadingPublicBookings: false,
      }
    case BOOK_SPACE:
      return {
        ...state,
        loadingBookSpace: true
      }

    case BOOK_SPACE_SUCCESS:
      return {
        ...state,
        loadingBookSpace: false,
      }
    case BOOK_SPACE_FAIL:
      return {
        ...state,
        loadingBookSpace: false,
      }
    case GET_SPACES:
      return {
        ...state,
        loadingGetSpaces: true
      }

    case GET_SPACES_SUCCESS:
      return {
        ...state,
        loadingGetSpaces: false,
        spaces: action.payload
      }
    case GET_SPACES_FAIL:
      return {
        ...state,
        loadingGetSpaces: false,
      }
    case GET_SPACE_BOOKINGS_SUCCESS:
      return {
        ...state,
        spaceBookings: action.payload,
      };

    case GET_SPACE_BOOKINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_USER_SPACE_BOOKINGS:
      return {
        ...state,
        loadingUserSpaceBookings: true
      };


    case GET_USER_SPACE_BOOKINGS_SUCCESS:
      return {
        ...state,
        userSpaceBookings: action.payload,
        loadingUserSpaceBookings: false
      };

    case GET_USER_SPACE_BOOKINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingUserSpaceBookings: false
      };

    case GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
      };

    case GET_BOOKINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EVENT_WAITLIST_SUCCESS:
      return {
        ...state,
        eventWaitlist: action.payload,
      };

    case GET_EVENT_WAITLIST_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_CHECKIN_MEMBER_SUCCESS:
      return {
        ...state,
        booking: action.payload,
      };

    case UPDATE_CHECKIN_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default events;
