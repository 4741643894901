import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";


// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import { getBookings, getEventPossibleMatches } from "store/actions";
import { Link } from "react-router-dom/cjs/react-router-dom";
// import { getClubs, getGuests } from "../../store/actions";
// import { users } from "common/data";

class PossibleMatches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      selectedDate: moment().format("YYYY-MM-DD"),
      activeClub: 1,
      possibleMatches: [],
      unmatched: []
    };
  }

  componentDidMount() {
    const {
      match: { params },
      onGetPossibleMatches,
      onGetBookings
    } = this.props;
    if (params && params.id) {
      onGetBookings(params.id)
      onGetPossibleMatches(params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { possibleMatches, bookings } = this.props;
    if (possibleMatches !== prevProps.possibleMatches) {
      let unmatched = bookings?.filter(el => !possibleMatches?.find(item => item.user_id === el.user_id || item.user_id_matched === el.user_id))
      if (unmatched === undefined)
        unmatched = []
      this.setState({
        possibleMatches,
        unmatched
      })
    }
  }

  render() {
    const { possibleMatches, unmatched } = this.state
    const pageOptions = {
      sizePerPage: 10,
      totalSize: possibleMatches?.length, // replace later with size(customers),
      custom: true,
    };

    const unmatchedPageOptions = {
      sizePerPage: 10,
      totalSize: unmatched?.length, // replace later with size(customers),
      custom: true,
    };

    const possibleMatchesColumns = [
      {
        text: "Name",
        dataField: "first_name",
        formatter: (_, match) => (
          <p
          > {`${match.first_name} ${match.last_name}`} </p>
        ),
      },
      {
        text: "Email",
        dataField: "email",
      },
      {
        text: "",
        formatter: () => (

          <p
          > - </p>

        ),
      },
      {
        text: "User Matched Name",
        dataField: "user_matched_first_name",
        formatter: (_, match) => (
          <p
          > {`${match.user_matched_first_name} ${match.user_matched_last_name}`} </p>
        ),
      },
      {
        text: "User Matched Email",
        dataField: "user_matched_email",
      },
      {
        text: "Interests",
        dataField: "interests",
      },
      {
        text: "Where are you in life",
        dataField: "where_are_you_in_life",
        formatter: (_, match) => (
          <p
          >{match.where_are_you_in_life.split(';').join(', ')}</p>
        ),
      },
      {
        text: "Words best describe you",
        dataField: "words_best_describe_you",
        formatter: (_, match) => (
          <p
          >{match.words_best_describe_you.split(';').join(', ')}</p>
        ),
      },
    ];

    const unmatchedColumns = [
      {
        text: "First name",
        dataField: "first_name",
      },
      {
        text: "Last Name",
        dataField: "last_name",
      },
      {
        text: "Email",
        dataField: "email",
      },
    ]



    return (
      <>
        <div className="page-content">
          {possibleMatches?.length > 0 && <>
            <MetaTags>
              <title>Possible Matches | Groundfloor</title>
            </MetaTags>
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Matches" breadcrumbItem="Possible Matches" />

              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Card>
                        <CardBody>
                          {possibleMatches.length > 0 && (
                            <PaginationProvider
                              pagination={paginationFactory(pageOptions)}
                              columns={possibleMatchesColumns}
                              data={possibleMatches}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  columns={possibleMatchesColumns}
                                  data={possibleMatches}
                                  search
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            {this.props.loadingPossibleMatches ? (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="text-success"
                                                >
                                                  <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                  Loading
                                                </Link>{" "}
                                              </div>
                                            ) : (
                                              <BootstrapTable
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                classes={
                                                  "table align-middle table-nowrap"
                                                }
                                                headerWrapperClasses={
                                                  "thead-light"
                                                }
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                              />
                                            )}
                                          </div>
                                        </Col>
                                        {/* </Link> */}
                                      </Row>

                                      <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                          <div className="d-inline">
                                            <SizePerPageDropdownStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                          <div className="text-md-right ms-auto">
                                            <PaginationListStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          )}
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </>}
          {unmatched?.length > 0 && <div>
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title="Matches" breadcrumbItem="Unmatched" />

              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Card>
                        <CardBody>
                          {unmatched.length > 0 && (
                            <PaginationProvider
                              pagination={paginationFactory(unmatchedPageOptions)}
                              columns={unmatchedColumns}
                              data={unmatched}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  columns={unmatchedColumns}
                                  data={unmatched}
                                  search
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          </div>
                                        </Col>
                                        {/* </Link> */}
                                      </Row>

                                      <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                          <div className="d-inline">
                                            <SizePerPageDropdownStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                          <div className="text-md-right ms-auto">
                                            <PaginationListStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          )}
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div >}
        </div >

      </>
    );
  }
}

PossibleMatches.propTypes = {
  loadingPossibleMatches: PropTypes.bool,
  onGetBookings: PropTypes.func,
  onGetPossibleMatches: PropTypes.func,
  possibleMatches: PropTypes.array,
  bookings: PropTypes.array,
  match: PropTypes.object,
};

const mapStateToProps = ({ events, bookings }) => ({
  loadingPossibleMatches: events.loadingPossibleMatches,
  possibleMatches: events.possibleMatches,
  bookings: bookings.bookings,
});

const mapDispatchToProps = dispatch => ({
  onGetPossibleMatches: eventId => dispatch(getEventPossibleMatches(eventId)),
  onGetBookings: eventId => dispatch(getBookings(eventId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PossibleMatches));
