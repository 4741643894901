import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import eventsSaga from "./events/saga"
import projectsSaga from "./projects/saga"
import bookingsSaga from "./bookings/saga"
import membersSaga from "./members/saga"
import guestsSaga from "./guests/saga"
import systemSaga from "./system/saga"
import productsSaga from "./products/saga"
import announcemntsSaga from "./announcments/saga"
import subclubsSaga from "./subclubs/saga"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(eventsSaga),
    fork(projectsSaga),
    fork(bookingsSaga),
    fork(membersSaga),
    fork(guestsSaga),
    fork(systemSaga),
    fork(productsSaga),
    fork(announcemntsSaga),
    fork(subclubsSaga),
  ])
}
