import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_MEMBERS,
  GET_MEMBER_BY_ID,
  ADD_NOTE_MEMBER,
  CANCEL_MEMBER,
  SET_ONBOARDING_SESSION,
  SET_FIRST_CHECKIN,
  GET_CANCELED_MEMBERS,
  GET_ONBOARDING_MEMBERS,
  GET_NON_ATTENDEES,
  GET_DOOR_ENTRIES,
  ONBOARDING_SCHEDULED,
  DOORDECK_ENTRIES_MEMBER,
  GET_ONBOARDING_BOOKINGS,
  UPDATE_ONBOARDING_BOOKING,
  CANCEL_ONBOARDING_BOOKING,
  GET_HIGH_RISK_MEMBERS,
  GET_USER_SUBSCRIPTION_BY_ID,
  GET_MEMBER_NOTES,
  GET_USER_PAUSES,
  GET_NEW_MEMBERS,
  GET_MEMBER_BY_EMAIL,
  GET_ALL_COUPONS,
  GET_APPLIED_COUPONS,
  APPLY_COUPON,
  GET_APPLICANTS,
  ADD_APPLICANT,
  GET_CLUB_LEADER_MEMBERS,
  GET_MEMBER_BY_ID_BY_SUBCLUB,
  SEND_EMAIL_CLUB_LEADER,
  UPDATE_NOTE_MEMBER,
  DELETE_NOTE_MEMBER,
  UPDATE_MEMBER_AT_RISK,
} from "./actionTypes";

import {
  getMembersSuccess,
  getMembersFail,
  getMemberByIdSuccess,
  getMemberByIdFail,
  addNotesForMemberSuccess,
  addNotesForMemberFail,
  cancelMemberSuccess,
  cancelMemberFail,
  setOnboardingSessionSuccess,
  setOnboardingSessionFail,
  setFirstCheckInSuccess,
  setFirstCheckInFail,
  getCanceledMembersSuccess,
  getCanceledMembersFail,
  getBookingsSuccess,
  getOnboardingMembersSuccess,
  getOnboardingMembersFail,
  getNonAttendeesSuccess,
  getNonAttendeesFail,
  getDoorEntriesSuccess,
  getDoorEntriesFail,
  onboardingScheduledSuccess,
  onboardingScheduledFail,
  getDoordeckEntriesForMemberFail,
  getDoordeckEntriesForMemberSuccess,
  getOnboardingBookingsSuccess,
  getOnboardingBookingsFail,
  getOnboardingBookings,
  updateOnboardingBookingSuccess,
  updateOnboardingBookingFail,
  cancelOnboardingBookingSuccess,
  cancelOnboardingBookingFail,
  getHighRiskMembersSuccess,
  getUserSubByIdSuccess,
  getUserSubByIdFail,
  getMemberNotesSuccess,
  getMemberNotesFail,
  getMemberNotes,
  getUserPausesSuccess,
  getUserPausesFail,
  getNewMembersSuccess,
  getAllCouponsSuccess,
  getAllCouponsFail,
  getAppliedCouponsSuccess,
  getAppliedCouponsFail,
  applyCouponSuccess,
  applyCouponFail,
  addApplicantSuccess,
  addApplicantFail,
  getApplicantsSuccess,
  getApplicantsFail,
  getClubLeaderMembersSuccess,
  getClubLeaderMembersFail,
  updateNotesForMemberFail,
  updateNotesForMemberSuccess,
  deleteNotesForMemberSuccess,
  deleteNotesForMemberFail,
} from "store/actions";

import {
  getMembers,
  getMemberById,
  cancelMembership,
  updateOnboardingSession,
  updateFirstMonthCheckIn,
  getCanceledMembers,
  getOnboardingMembers,
  getNonAttendees,
  getDoorEntries,
  onboardingScheduledReq,
  getDoordeckEntriesForUser,
  getOnboardingBookingsAPI,
  updateOnboardingBookingAPI,
  cancelOnboardingBookingAPI,
  getHighRiskMembersAPI,
  getUserSubscriptionByIdAPI,
  getMemberNotesAPI,
  getUserPausesAPI,
  getMemberByEmailAPI,
  getNewMembersAPI,
  getAllCouponsAPI,
  getAppliedCouponsAPI,
  applyCouponAPI,
  addApplicantAPI,
  getApplicantsAPI,
  getClubLeaderMembersBySubclub,
  getMemberByIdBySubclubAPI,
  sendEmailAsClubLeaderAPI,
  addNotesForMemberAPI,
  updateNoteAPI,
  deleteNoteAPI,
  updateUserAtRiskAPI,
} from "helpers";

export function* fetchMembers({ payload: clubId }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getMembers, token, clubId);
    const res = response.data.map((data, index) =>
      Object.assign(data, {
        id: index,
        memberId: data.id,
      })
    );
    yield put(getMembersSuccess(res));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getMembersFail(error));
  }
}

export function* fetchCanceledMembers({ payload: clubId }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getCanceledMembers, token, clubId);

    const res = response.data.map((data, index) =>
      Object.assign(data, {
        id: index,
        memberId: data.id,
      })
    );
    yield put(getCanceledMembersSuccess(res));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getCanceledMembersFail(error));
  }
}

export function* fetchHighRiskMembers() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getHighRiskMembersAPI, token);
    yield put(getHighRiskMembersSuccess(response.data));
  } catch (error) {
  }
}


export function* fetchMemberById({ payload: { id } }) {
  const token = localStorage.getItem("token");
  try {

    const response = yield call(getMemberById, token, id);
    yield put(getMemberByIdSuccess(response.data));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getMemberByIdFail(error));
  }
}

export function* fetchMemberByIdBySubclub({ payload: { id, subclubId } }) {
  const token = localStorage.getItem("token");
  try {

    const response = yield call(getMemberByIdBySubclubAPI, token, subclubId, id);
    yield put(getMemberByIdSuccess(response.data));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getMemberByIdFail(error));
  }
}

export function* fetchMemberByEmail({ payload: { email } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getMemberByEmailAPI, token, email);
    window.open(`https://dashboard.groundfloorapp.com/profile/${response.data.id}/`, '_blank', 'noopener noreferrer');
  } catch (error) {
    yield put(getMemberByIdFail(error));
  }
}

export function* fetchNewMembers({ payload: { clubId } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getNewMembersAPI, token, clubId);
    yield put(getNewMembersSuccess(response.data));
  } catch (error) {
    yield put(getMemberByIdFail(error));
  }
}

export function* fetchAllCoupons() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getAllCouponsAPI, token);
    yield put(getAllCouponsSuccess(response.data));
  } catch (error) {
    yield put(getAllCouponsFail(error));
  }
}

export function* fetchAppliedCoupons() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getAppliedCouponsAPI, token);
    yield put(getAppliedCouponsSuccess(response.data));
  } catch (error) {
    yield put(getAppliedCouponsFail(error));
  }
}

export function* applyCoupon(payload) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(applyCouponAPI, token, payload.payload.email, payload.payload.couponId, payload.payload.couponTitle, payload.payload.subscriptionType);
    yield put(applyCouponSuccess(response.data));
    payload.payload.showSuccessToast(`Coupon Applied`, 2000, true)
  } catch (error) {
    yield put(applyCouponFail(error));
    payload.payload.showSuccessToast(`Failed to apply`, 2000, false)
  }
}

export function* addApplicant({ payload }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(addApplicantAPI, token, payload);
    yield put(addApplicantSuccess(response.data));
    payload.showSuccessToast(`Applicant Added`, 2000, true)
  } catch (error) {
    yield put(addApplicantFail(error));
    payload.showSuccessToast(`Failed to add applicant`, 2000, false)
  }
}

export function* fetchApplicants() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getApplicantsAPI, token);
    yield put(getApplicantsSuccess(response.data));
  } catch (error) {
    yield put(getApplicantsFail(error));
  }
}

export function* getUserSubscriptionById({ payload: { id } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getUserSubscriptionByIdAPI, token, id);
    yield put(getUserSubByIdSuccess(response.data[0].current_period_end));
  } catch (error) {
    yield put(getUserSubByIdFail(error));
  }
}

export function* getUserNotes({ payload: { id } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getMemberNotesAPI, token, id);
    yield put(getMemberNotesSuccess(response.data));
  } catch (error) {
    yield put(getMemberNotesFail(error));
  }
}

export function* addNotesForMember({ payload: { userId, notes } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(addNotesForMemberAPI, token, userId, notes);
    yield put(addNotesForMemberSuccess(response.data));
    yield put(getMemberNotes(userId))
  } catch (error) {
    yield put(addNotesForMemberFail(error));
  }
}

export function* updateNotesForMember({ payload: { id, notes, userId } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(updateNoteAPI, token, id, notes);
    yield put(updateNotesForMemberSuccess(response.data));
    yield put(getMemberNotes(userId))
  } catch (error) {
    yield put(updateNotesForMemberFail(error));
  }
}

export function* updateMemberAtRisk({ payload: { userId, risk } }) {
  const token = localStorage.getItem("token");
  try {
    yield call(updateUserAtRiskAPI, token, userId, risk);
  } catch (error) {
  }
}

export function* deleteNotesForMember({ payload: { id, userId } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(deleteNoteAPI, token, id);
    yield put(deleteNotesForMemberSuccess(response.data));
    yield put(getMemberNotes(userId))
  } catch (error) {
    yield put(deleteNotesForMemberFail(error));
  }
}

export function* cancelMember({ payload: { data } }) {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(cancelMembership, token, data);

    yield put(cancelMemberSuccess(response.data));
  } catch (error) {
    yield put(cancelMemberFail(error));
  }
}


export function* setOnboardingSession({ payload: { data } }) {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(updateOnboardingSession, token, data);

    yield put(setOnboardingSessionSuccess(response));
  } catch (error) {
    yield put(setOnboardingSessionFail(error));
  }
}

export function* setFirstMonthCheckIn({ payload: { data } }) {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(updateFirstMonthCheckIn, token, data);

    yield put(setFirstCheckInSuccess(response));
  } catch (error) {
    yield put(setFirstCheckInFail(error));
  }
}

export function* fetchOnboardingMemberss({ payload: { clubId } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getOnboardingMembers, token, clubId);

    const res = response.data.map((data) =>
      Object.assign(data, {
        id: data.id,
      })
    );
    yield put(getOnboardingMembersSuccess(res));
    yield put(getBookingsSuccess([]));
  } catch (error) {
    yield put(getOnboardingMembersFail(error));
  }
}

export function* fetchNonAttendes() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getNonAttendees, token);
    yield put(getNonAttendeesSuccess(response.data));
  } catch (error) {
    yield put(getNonAttendeesFail(error));
  }
}

export function* onboardingScheduled({ payload: { email } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(onboardingScheduledReq, token, email);
    yield put(onboardingScheduledSuccess(response.data));
  } catch (error) {
    yield put(onboardingScheduledFail(error));
  }
}

export function* fetchDoorEntries({ payload: { doorId } }) {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(getDoorEntries, token, doorId);
    yield put(getDoorEntriesSuccess(response.data));
  } catch (error) {
    yield put(getDoorEntriesFail(error));
  }
}

export function* fetchOnboardingBookings() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getOnboardingBookingsAPI, token);
    yield put(getOnboardingBookingsSuccess(response.data));
  } catch (error) {
    yield put(getOnboardingBookingsFail(error));
  }
}
export function* updateOnboardingBooking({ payload: { bookingId, startDate, startTime } }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(updateOnboardingBookingAPI, token, bookingId, startDate, startTime);
    if (response) {
      yield put(getOnboardingBookings());
      yield put(updateOnboardingBookingSuccess());
    }
  } catch (error) {
    yield put(updateOnboardingBookingFail(error));
  }
}
export function* cancelOnboardingBooking({ payload }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(cancelOnboardingBookingAPI, token, payload);
    if (response) {
      yield put(getOnboardingBookings());
      yield put(cancelOnboardingBookingSuccess());
    }
  } catch (error) {
    yield put(cancelOnboardingBookingFail(error));
  }
}

export function* fetchDoorEntriesForUser({ payload }) {
  try {
    const token = localStorage.getItem("token");
    const response = yield call(getDoordeckEntriesForUser, token, payload);
    yield put(getDoordeckEntriesForMemberSuccess(response.data));
  } catch (error) {
    yield put(getDoordeckEntriesForMemberFail(error));
  }
}

export function* fetchUserPauses({ payload: { id } }) {
  try {
    const token = localStorage.getItem("token");
    const response = yield call(getUserPausesAPI, token, id);
    yield put(getUserPausesSuccess(response.data));
  } catch (error) {
    yield put(getUserPausesFail(error));
  }
}

export function* fetchClubLeaderMembers({ payload: subClubId }) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getClubLeaderMembersBySubclub, token, subClubId);
    yield put(getClubLeaderMembersSuccess(response.data.sort((a, b) => parseFloat(b.leader) - parseFloat(a.leader))));
  } catch (error) {
    yield put(getClubLeaderMembersFail(error));
  }
}

export function* sendEmailToUserByLeader({ payload: { data, showToast } }) {
  const token = localStorage.getItem("token");
  try {
    yield call(sendEmailAsClubLeaderAPI, token, data);
    showToast(`Email sent successfully`, 2000, true)
  } catch (error) {
    showToast(`Failed to sent`, 2000, false)
  }
}


function* membersSaga() {
  yield takeEvery(GET_MEMBERS, fetchMembers);
  yield takeEvery(GET_MEMBER_BY_ID, fetchMemberById);
  yield takeEvery(GET_MEMBER_BY_ID_BY_SUBCLUB, fetchMemberByIdBySubclub);
  yield takeEvery(ADD_NOTE_MEMBER, addNotesForMember);
  yield takeEvery(CANCEL_MEMBER, cancelMember);
  yield takeEvery(SET_ONBOARDING_SESSION, setOnboardingSession);
  yield takeEvery(SET_FIRST_CHECKIN, setFirstMonthCheckIn);
  yield takeEvery(GET_CANCELED_MEMBERS, fetchCanceledMembers);
  yield takeEvery(GET_ONBOARDING_MEMBERS, fetchOnboardingMemberss);
  yield takeEvery(GET_NON_ATTENDEES, fetchNonAttendes);
  yield takeEvery(GET_DOOR_ENTRIES, fetchDoorEntries);
  yield takeEvery(DOORDECK_ENTRIES_MEMBER, fetchDoorEntriesForUser);
  yield takeEvery(ONBOARDING_SCHEDULED, onboardingScheduled);
  yield takeEvery(GET_ONBOARDING_BOOKINGS, fetchOnboardingBookings);
  yield takeEvery(UPDATE_ONBOARDING_BOOKING, updateOnboardingBooking);
  yield takeEvery(CANCEL_ONBOARDING_BOOKING, cancelOnboardingBooking);
  yield takeEvery(GET_HIGH_RISK_MEMBERS, fetchHighRiskMembers);
  yield takeEvery(GET_USER_SUBSCRIPTION_BY_ID, getUserSubscriptionById);
  yield takeEvery(GET_MEMBER_NOTES, getUserNotes);
  yield takeEvery(GET_USER_PAUSES, fetchUserPauses);
  yield takeEvery(GET_NEW_MEMBERS, fetchNewMembers);
  yield takeEvery(GET_MEMBER_BY_EMAIL, fetchMemberByEmail);
  yield takeEvery(GET_ALL_COUPONS, fetchAllCoupons);
  yield takeEvery(GET_APPLIED_COUPONS, fetchAppliedCoupons);
  yield takeEvery(APPLY_COUPON, applyCoupon);
  yield takeEvery(GET_APPLICANTS, fetchApplicants);
  yield takeEvery(GET_CLUB_LEADER_MEMBERS, fetchClubLeaderMembers);
  yield takeEvery(ADD_APPLICANT, addApplicant);
  yield takeEvery(SEND_EMAIL_CLUB_LEADER, sendEmailToUserByLeader);
  yield takeEvery(UPDATE_NOTE_MEMBER, updateNotesForMember);
  yield takeEvery(DELETE_NOTE_MEMBER, deleteNotesForMember);
  yield takeEvery(UPDATE_MEMBER_AT_RISK, updateMemberAtRisk);

}

export default membersSaga;
