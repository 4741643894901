import { doorDeckAccessAPI, getAllChildrenCategories, getClubs, sendCustomEmails, sendCustomNotification } from "helpers";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { doorAccessStatusFail, doorAccessStatusSuccess, getAllChildrenCategoriesFail, getAllChildrenCategoriesSuccess, getClubsFail, getClubsSuccess, sendEmailFail, sendEmailSuccess, sendNotificationsFail, sendNotificationsSuccess } from "./actions";

import { DOORDECK_ACCESS_STATUS, GET_ALL_CHILDREN_CATEGORIES, GET_CLUBS, SEND_EMAILS, SEND_NOTIFICATIONS } from "./actionTypes";
import { getImage } from "./selectors";
import { setImageSuccess } from "store/actions";

export function* fetchClubs() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getClubs, token);
    yield put(getClubsSuccess(response.data));
  } catch (error) {
    yield put(getClubsFail(error));
  }
}

export function* fetchAllChildrenCategories() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(getAllChildrenCategories, token);
    yield put(getAllChildrenCategoriesSuccess(response.data));
  } catch (error) {
    yield put(getAllChildrenCategoriesFail(error));
  }
}

function* sendEmail({ payload: { clubId, fromEmail, fromName, replyTo, subject, text, showToast } }) {
  try {
    const token = localStorage.getItem("token");
    const image = yield select(getImage);

    yield call(sendCustomEmails, token, clubId, fromEmail, fromName, replyTo, text, subject, image);
    yield put(setImageSuccess(null));
    yield put(sendEmailSuccess());
    showToast(`Email sent successfully`, 2000, true)
  } catch (error) {
    console.log(error)
    showToast(`Failed to sent email!`, 2000, false)
    yield put(sendEmailFail(error));
  }
}

function* doorDeckAccess({ payload: {
  id,
  firstName,
  lastName,
  email,
  status,
  doordeckId,
  showToast
} }) {
  try {
    const token = localStorage.getItem("token");
    yield call(doorDeckAccessAPI, token,
      id,
      firstName,
      lastName,
      email,
      status,
      doordeckId,
    );
    if (status === 'share_locks') {
      showToast(`Locks shared successfully`, 2000, true)
    } else {
      showToast(`Access revoked successfully`, 2000, true)
    }
    yield put(doorAccessStatusSuccess());

  } catch (error) {
    yield put(doorAccessStatusFail());

    if (status === 'share_locks') {
      showToast(`Failed to share locks!`, 2000, false)
    } else {
      showToast(`Failed to revoke access!`, 2000, false)
    }
  }
}

function* sendNotifications({ payload: { clubId, title, body, showToast } }) {
  try {
    const token = localStorage.getItem("token");

    yield call(sendCustomNotification, token, clubId, title, body);
    yield put(sendNotificationsSuccess());
    showToast(`Notification sent successfully`, 2000, true)
  } catch (error) {
    showToast(`Failed to sent notification!`, 2000, false)
    yield put(sendNotificationsFail(error));
  }
}

function* systemSaga() {
  yield takeEvery(GET_CLUBS, fetchClubs);
  yield takeEvery(GET_ALL_CHILDREN_CATEGORIES, fetchAllChildrenCategories);
  yield takeEvery(SEND_EMAILS, sendEmail);
  yield takeEvery(SEND_NOTIFICATIONS, sendNotifications);
  yield takeEvery(DOORDECK_ACCESS_STATUS, doorDeckAccess);
}

export default systemSaga;