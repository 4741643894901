import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  GET_MEMBER_BY_ID,
  GET_MEMBER_BY_ID_SUCCESS,
  GET_MEMBER_BY_ID_FAIL,
  ADD_NOTE_MEMBER,
  ADD_NOTE_MEMBER_SUCCESS,
  ADD_NOTE_MEMBER_FAIL,
  CANCEL_MEMBER,
  CANCEL_MEMBER_SUCCESS,
  CANCEL_MEMBER_FAIL,
  SET_ONBOARDING_SESSION,
  SET_ONBOARDING_SESSION_SUCCESS,
  SET_ONBOARDING_SESSION_FAIL,
  SET_FIRST_CHECKIN,
  SET_FIRST_CHECKIN_SUCCESS,
  SET_FIRST_CHECKIN_FAIL,
  GET_CANCELED_MEMBERS,
  GET_CANCELED_MEMBERS_SUCCESS,
  GET_CANCELED_MEMBERS_FAIL,
  GET_ONBOARDING_MEMBERS,
  GET_ONBOARDING_MEMBERS_SUCCESS,
  GET_ONBOARDING_MEMBERS_FAIL,
  GET_NON_ATTENDEES,
  GET_NON_ATTENDEES_SUCCESS,
  GET_NON_ATTENDEES_FAIL,
  GET_DOOR_ENTRIES,
  GET_DOOR_ENTRIES_SUCCESS,
  GET_DOOR_ENTRIES_FAIL,
  ONBOARDING_SCHEDULED,
  ONBOARDING_SCHEDULED_SUCCESS,
  ONBOARDING_SCHEDULED_FAIL,
  DOORDECK_ENTRIES_MEMBER,
  DOORDECK_ENTRIES_MEMBER_SUCCESS,
  DOORDECK_ENTRIES_MEMBER_FAIL,
  GET_ONBOARDING_BOOKINGS,
  GET_ONBOARDING_BOOKINGS_SUCCESS,
  GET_ONBOARDING_BOOKINGS_FAIL,
  UPDATE_ONBOARDING_BOOKING,
  UPDATE_ONBOARDING_BOOKING_SUCCESS,
  UPDATE_ONBOARDING_BOOKING_FAIL,
  CANCEL_ONBOARDING_BOOKING,
  CANCEL_ONBOARDING_BOOKING_SUCCESS,
  CANCEL_ONBOARDING_BOOKING_FAIL,
  GET_HIGH_RISK_MEMBERS,
  GET_HIGH_RISK_MEMBERS_SUCCESS,
  GET_USER_SUBSCRIPTION_BY_ID,
  GET_USER_SUBSCRIPTION_BY_ID_SUCCESS,
  GET_USER_SUBSCRIPTION_BY_ID_FAIL,
  RESET_BILLING_DATE,
  GET_MEMBER_NOTES,
  GET_MEMBER_NOTES_SUCCESS,
  GET_MEMBER_NOTES_FAIL,
  GET_USER_PAUSES,
  GET_USER_PAUSES_SUCCESS,
  GET_USER_PAUSES_FAIL,
  GET_MEMBER_BY_EMAIL,
  GET_NEW_MEMBERS,
  GET_NEW_MEMBERS_SUCCESS,
  GET_NEW_MEMBERS_FAIL,
  GET_ALL_COUPONS,
  GET_ALL_COUPONS_SUCCESS,
  GET_ALL_COUPONS_FAIL,
  APPLY_COUPON,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAIL,
  GET_APPLIED_COUPONS,
  GET_APPLIED_COUPONS_SUCCESS,
  GET_APPLIED_COUPONS_FAIL,
  ADD_APPLICANT,
  ADD_APPLICANT_SUCCESS,
  ADD_APPLICANT_FAIL,
  GET_APPLICANTS,
  GET_APPLICANTS_SUCCESS,
  GET_APPLICANTS_FAIL,
  GET_CLUB_LEADER_MEMBERS,
  GET_CLUB_LEADER_MEMBERS_SUCCESS,
  GET_CLUB_LEADER_MEMBERS_FAIL,
  GET_MEMBER_BY_ID_BY_SUBCLUB,
  GET_MEMBER_BY_ID_BY_SUBCLUB_SUCCESS,
  GET_MEMBER_BY_ID_BY_SUBCLUB_FAIL,
  SEND_EMAIL_CLUB_LEADER,
  UPDATE_NOTE_MEMBER,
  UPDATE_NOTE_MEMBER_SUCCESS,
  UPDATE_NOTE_MEMBER_FAIL,
  DELETE_NOTE_MEMBER,
  DELETE_NOTE_MEMBER_SUCCESS,
  DELETE_NOTE_MEMBER_FAIL,
  UPDATE_MEMBER_AT_RISK,
  UPDATE_MEMBER_AT_RISK_SUCCESS,
  UPDATE_MEMBER_AT_RISK_FAIL,
} from "./actionTypes";

export const getMembers = (clubId) => ({
  type: GET_MEMBERS,
  payload: clubId
});

export const getMembersSuccess = members => ({
  type: GET_MEMBERS_SUCCESS,
  payload: members,
});

export const getMembersFail = error => ({
  type: GET_MEMBERS_FAIL,
  payload: error,
});
export const getDoordeckEntriesForMember = (email) => ({
  type: DOORDECK_ENTRIES_MEMBER,
  payload: email
});

export const getDoordeckEntriesForMemberSuccess = entries => ({
  type: DOORDECK_ENTRIES_MEMBER_SUCCESS,
  payload: entries,
});

export const getDoordeckEntriesForMemberFail = error => ({
  type: DOORDECK_ENTRIES_MEMBER_FAIL,
  payload: error,
});

export const getCanceledMembers = (clubId) => ({
  type: GET_CANCELED_MEMBERS,
  payload: clubId
});

export const getCanceledMembersSuccess = members => ({
  type: GET_CANCELED_MEMBERS_SUCCESS,
  payload: members,
});

export const getHighRiskMembers = () => ({
  type: GET_HIGH_RISK_MEMBERS,
});

export const getHighRiskMembersSuccess = members => ({
  type: GET_HIGH_RISK_MEMBERS_SUCCESS,
  payload: members,
});

export const getCanceledMembersFail = error => ({
  type: GET_CANCELED_MEMBERS_FAIL,
  payload: error,
});

export const getOnboardingBookings = () => ({
  type: GET_ONBOARDING_BOOKINGS,
});

export const getOnboardingBookingsSuccess = onboardingBookings => ({
  type: GET_ONBOARDING_BOOKINGS_SUCCESS,
  payload: onboardingBookings,
});

export const getOnboardingBookingsFail = error => ({
  type: GET_ONBOARDING_BOOKINGS_FAIL,
  payload: error,
});

export const getAllCoupons = () => ({
  type: GET_ALL_COUPONS,
});

export const getAllCouponsSuccess = coupons => ({
  type: GET_ALL_COUPONS_SUCCESS,
  payload: coupons,
});

export const getAllCouponsFail = error => ({
  type: GET_ALL_COUPONS_FAIL,
  payload: error,
});

export const getAppliedCoupons = () => ({
  type: GET_APPLIED_COUPONS,
});

export const getAppliedCouponsSuccess = coupons => ({
  type: GET_APPLIED_COUPONS_SUCCESS,
  payload: coupons,
});

export const getAppliedCouponsFail = error => ({
  type: GET_APPLIED_COUPONS_FAIL,
  payload: error,
});


export const applyCoupon = (data) => ({
  type: APPLY_COUPON,
  payload: data
});

export const applyCouponSuccess = (data) => ({
  type: APPLY_COUPON_SUCCESS,
  payload: data
});

export const applyCouponFail = error => ({
  type: APPLY_COUPON_FAIL,
  payload: error,
});

export const getApplicants = () => ({
  type: GET_APPLICANTS,
});

export const getApplicantsSuccess = coupons => ({
  type: GET_APPLICANTS_SUCCESS,
  payload: coupons,
});

export const getApplicantsFail = error => ({
  type: GET_APPLICANTS_FAIL,
  payload: error,
});

export const addApplicant = (data) => ({
  type: ADD_APPLICANT,
  payload: data
});

export const addApplicantSuccess = (data) => ({
  type: ADD_APPLICANT_SUCCESS,
  payload: data
});

export const addApplicantFail = error => ({
  type: ADD_APPLICANT_FAIL,
  payload: error,
});

export const updateOnboardingBooking = (bookingId, startDate, startTime) => ({
  type: UPDATE_ONBOARDING_BOOKING,
  payload: { bookingId, startDate, startTime }
});

export const updateOnboardingBookingSuccess = () => ({
  type: UPDATE_ONBOARDING_BOOKING_SUCCESS,
});

export const updateOnboardingBookingFail = error => ({
  type: UPDATE_ONBOARDING_BOOKING_FAIL,
  payload: error,
});

export const cancelOnboardingBooking = (bookingId) => ({
  type: CANCEL_ONBOARDING_BOOKING,
  payload: bookingId
});

export const cancelOnboardingBookingSuccess = () => ({
  type: CANCEL_ONBOARDING_BOOKING_SUCCESS,
});

export const cancelOnboardingBookingFail = error => ({
  type: CANCEL_ONBOARDING_BOOKING_FAIL,
  payload: error,
});

export const getMemberById = id => ({
  type: GET_MEMBER_BY_ID,
  payload: { id },
});

export const getMemberByIdSuccess = member => ({
  type: GET_MEMBER_BY_ID_SUCCESS,
  payload: member,
});

export const getMemberByIdFail = error => ({
  type: GET_MEMBER_BY_ID_FAIL,
  payload: error,
});

export const getMemberByIdBySubclub = (id, subclubId) => ({
  type: GET_MEMBER_BY_ID_BY_SUBCLUB,
  payload: { id, subclubId },
});

export const getMemberByIdBySubclubSuccess = member => ({
  type: GET_MEMBER_BY_ID_BY_SUBCLUB_SUCCESS,
  payload: member,
});

export const getMemberByIdBySubclubFail = error => ({
  type: GET_MEMBER_BY_ID_BY_SUBCLUB_FAIL,
  payload: error,
});

export const getMemberByEmail = (email) => ({
  type: GET_MEMBER_BY_EMAIL,
  payload: { email },
});

export const getNewMembers = (clubId) => ({
  type: GET_NEW_MEMBERS,
  payload: { clubId },
});

export const getNewMembersSuccess = members => ({
  type: GET_NEW_MEMBERS_SUCCESS,
  payload: members,
});

export const getNewMembersFail = error => ({
  type: GET_NEW_MEMBERS_FAIL,
  payload: error,
});


export const resetBillingDate = () => ({
  type: RESET_BILLING_DATE,
});

export const getUserSubById = id => ({
  type: GET_USER_SUBSCRIPTION_BY_ID,
  payload: { id },
});

export const getUserSubByIdSuccess = date => ({
  type: GET_USER_SUBSCRIPTION_BY_ID_SUCCESS,
  payload: date,
});

export const getUserSubByIdFail = error => ({
  type: GET_USER_SUBSCRIPTION_BY_ID_FAIL,
  payload: error,
});

export const getMemberNotes = id => ({
  type: GET_MEMBER_NOTES,
  payload: { id },
});

export const getMemberNotesSuccess = notes => ({
  type: GET_MEMBER_NOTES_SUCCESS,
  payload: notes,
});

export const getMemberNotesFail = error => ({
  type: GET_MEMBER_NOTES_FAIL,
  payload: error,
});

export const addNotesForMember = (userId, notes) => ({
  type: ADD_NOTE_MEMBER,
  payload: { userId, notes },
});

export const addNotesForMemberSuccess = member => ({
  type: ADD_NOTE_MEMBER_SUCCESS,
  payload: member,
});

export const addNotesForMemberFail = error => ({
  type: ADD_NOTE_MEMBER_FAIL,
  payload: error,
});

export const updateNotesForMember = (userId, id, notes) => ({
  type: UPDATE_NOTE_MEMBER,
  payload: { userId, id, notes },
});

export const updateNotesForMemberSuccess = member => ({
  type: UPDATE_NOTE_MEMBER_SUCCESS,
  payload: member,
});

export const updateNotesForMemberFail = error => ({
  type: UPDATE_NOTE_MEMBER_FAIL,
  payload: error,
});

export const updateMemberAtRisk = (userId, risk) => ({
  type: UPDATE_MEMBER_AT_RISK,
  payload: { userId, risk },
});

export const updateMemberAtRiskSuccess = member => ({
  type: UPDATE_MEMBER_AT_RISK_SUCCESS,
  payload: member,
});

export const updateMemberAtRiskFail = error => ({
  type: UPDATE_MEMBER_AT_RISK_FAIL,
  payload: error,
});
export const deleteNotesForMember = (userId, id) => ({
  type: DELETE_NOTE_MEMBER,
  payload: { userId, id },
});

export const deleteNotesForMemberSuccess = member => ({
  type: DELETE_NOTE_MEMBER_SUCCESS,
  payload: member,
});

export const deleteNotesForMemberFail = error => ({
  type: DELETE_NOTE_MEMBER_FAIL,
  payload: error,
});

export const cancelMember = data => ({
  type: CANCEL_MEMBER,
  payload: { data },
});

export const cancelMemberSuccess = member => ({
  type: CANCEL_MEMBER_SUCCESS,
  payload: member,
});

export const cancelMemberFail = error => ({
  type: CANCEL_MEMBER_FAIL,
  payload: error,
});

export const setOnboardingSession = data => ({
  type: SET_ONBOARDING_SESSION,
  payload: { data },
});

export const setOnboardingSessionSuccess = member => ({
  type: SET_ONBOARDING_SESSION_SUCCESS,
  payload: member,
});

export const setOnboardingSessionFail = error => ({
  type: SET_ONBOARDING_SESSION_FAIL,
  payload: error,
});

export const setFirstCheckIn = data => ({
  type: SET_FIRST_CHECKIN,
  payload: { data },
});

export const setFirstCheckInSuccess = member => ({
  type: SET_FIRST_CHECKIN_SUCCESS,
  payload: member,
});

export const setFirstCheckInFail = error => ({
  type: SET_FIRST_CHECKIN_FAIL,
  payload: error,
});

export const getOnboardingMembers = (clubId) => ({
  type: GET_ONBOARDING_MEMBERS,
  payload: { clubId },
});

export const getOnboardingMembersSuccess = membersNotOnBoarded => ({
  type: GET_ONBOARDING_MEMBERS_SUCCESS,
  payload: membersNotOnBoarded,
});

export const getOnboardingMembersFail = error => ({
  type: GET_ONBOARDING_MEMBERS_FAIL,
  payload: error,
});

export const getNonAttendees = () => ({
  type: GET_NON_ATTENDEES,
});

export const getNonAttendeesSuccess = nonAttendees => ({
  type: GET_NON_ATTENDEES_SUCCESS,
  payload: nonAttendees,
});

export const getNonAttendeesFail = error => ({
  type: GET_NON_ATTENDEES_FAIL,
  payload: error,
});

export const getDoorEntries = (doorId) => ({
  type: GET_DOOR_ENTRIES,
  payload: { doorId }
})

export const getDoorEntriesSuccess = entries => ({
  type: GET_DOOR_ENTRIES_SUCCESS,
  payload: entries
})

export const getDoorEntriesFail = error => ({
  type: GET_DOOR_ENTRIES_FAIL,
  payload: error
})

export const onboardingScheduled = (email) => ({
  type: ONBOARDING_SCHEDULED,
  payload: { email }
})

export const onboardingScheduledSuccess = () => ({
  type: ONBOARDING_SCHEDULED_SUCCESS,
})

export const onboardingScheduledFail = error => ({
  type: ONBOARDING_SCHEDULED_FAIL,
  payload: error
})

export const getUserPauses = (id) => ({
  type: GET_USER_PAUSES,
  payload: { id }
})

export const getUserPausesSuccess = pauses => ({
  type: GET_USER_PAUSES_SUCCESS,
  payload: pauses
})

export const getUserPausesFail = error => ({
  type: GET_USER_PAUSES_FAIL,
  payload: error
})

export const getClubLeaderMembers = (subClubId) => ({
  type: GET_CLUB_LEADER_MEMBERS,
  payload: subClubId
});

export const getClubLeaderMembersSuccess = members => ({
  type: GET_CLUB_LEADER_MEMBERS_SUCCESS,
  payload: members,
});

export const getClubLeaderMembersFail = error => ({
  type: GET_CLUB_LEADER_MEMBERS_FAIL,
  payload: error,
});

export const sendEmailToUserByLeader = (data, showToast) => ({
  type: SEND_EMAIL_CLUB_LEADER,
  payload: { data, showToast },
});