import {
    Chat,
    Channel,
    ChannelList,
    useCreateChatClient,
} from 'stream-chat-react';
import React, { useEffect, useMemo, useState } from 'react'
import 'stream-chat-react/dist/css/v2/index.css';
import CustomChannelPreview from './Components/CustomChannelPreview';
import CustomSimpleMessage from './Components/CustomSimpleMessage';
import CustomChatContent from './Components/CustomChatContent';

const localUser = JSON.parse(localStorage.getItem("authUser"))
const apiKey = 've6cgy4a2jyv';
const userToken = localUser?.streamChatToken

const sort = { last_message_at: -1 };
const filters = {
    members: { $in: [localUser?.id] },
};

const SelectedCategoryTypeEnum = {
    All: ['support', 'leader_sub_club', 'dm', 'event_chat', 'sub_club'],
    Clubs: ['support', 'leader_sub_club', 'sub_club'],
    Events: ['event_chat'],
    Dm: ['dm']
}

const ChannelsSelectEnum = {
    All: 'all',
    Clubs: 'clubs',
    Events: 'events',
    Dm: 'dm'
}

const DashboardChat = () => {
    const [selectedCategory, setSelectedCategory] = useState(ChannelsSelectEnum.All);
    const [selectedChannelTypes, setSelectedChannelTypes] = useState(SelectedCategoryTypeEnum.All)

    let client

    if (localUser?.streamChatToken !== undefined) {
        client = useCreateChatClient({
            apiKey,
            tokenOrProvider: userToken,
            userData: { id: localUser?.id },
        });
    }

    const SELECT = useMemo(() => [
        {
            title: 'All',
            select: ChannelsSelectEnum.All,
        },
        {
            title: 'Groups',
            select: ChannelsSelectEnum.Clubs,
        },
        {
            title: 'Events',
            select: ChannelsSelectEnum.Events,
        },
        {
            title: 'DMs',
            select: ChannelsSelectEnum.Dm,
        },
    ], [])

    const DATA = useMemo(() => {
        switch (selectedCategory) {
            case ChannelsSelectEnum.All:
                return [
                    {
                        title: 'General Chats',

                        types: ['support', 'leader_sub_club']

                    },
                    {
                        title: 'Other',
                        types: ['dm', 'event_chat', 'sub_club']
                    },
                ]
                break;
            case ChannelsSelectEnum.Clubs:
                return [
                    {
                        title: 'General Chats',
                        types: ['support', 'leader_sub_club']
                    },
                    {
                        title: 'Groups',
                        types: ['sub_club']
                    },
                ]
                break;
            case ChannelsSelectEnum.Events:
                return [

                    {
                        title: '',
                        types: ['event_chat']
                    },
                ]
                break;
            case ChannelsSelectEnum.Dm:
                return [
                    {
                        title: '',
                        types: ['dm']
                    },
                ]
                break;
            default:
                break;
        }
    }, [selectedCategory])


    useEffect(() => {
        switch (selectedCategory) {
            case ChannelsSelectEnum.All:
                setSelectedChannelTypes(SelectedCategoryTypeEnum.All)
                break;
            case ChannelsSelectEnum.Clubs:
                setSelectedChannelTypes(SelectedCategoryTypeEnum.Clubs)
                break;
            case ChannelsSelectEnum.Events:
                setSelectedChannelTypes(SelectedCategoryTypeEnum.Events)
                break;
            case ChannelsSelectEnum.Dm:
                setSelectedChannelTypes(SelectedCategoryTypeEnum.Dm)
                break;

            default:
                break;
        }
    }, [selectedCategory])

    if (!client) {
        return null
    }
    return (
        <Chat client={client}>
            <div style={{
                display: 'flex',
                flex: 1,
                maxHeight: '100vh',
                flexDirection: 'row',
                overflow: 'auto',
                bottom: 0,
                zIndex: 100
            }}>
                <div style={{
                    display: 'flex',
                    width: '30vw',
                    marginTop: 70,
                    flexDirection: 'column'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        backgroundColor: 'white',
                        alignItems: 'center',

                    }}>
                        {SELECT.map(item => <div key={item.title} style={{ padding: '10px 0px', fontWeight: '500', display: 'flex', cursor: 'pointer', backgroundColor: selectedCategory === item.select ? 'grey' : 'white', flex: 1, justifyContent: 'center', alignItems: 'center', color: selectedCategory === item.select ? 'white' : 'black' }} onClick={() => setSelectedCategory(item.select)}>{item.title}</div>)}
                    </div>
                    {DATA.map((item, index) => <>
                        {item.title.length > 0 && <div style={{ padding: 10, }}>
                            <span style={{ fontWeight: '500', fontSize: 14 }}>{item.title}</span>
                        </div>}
                        <ChannelList
                            key={index}
                            filters={filters}
                            sort={sort}
                            Preview={CustomChannelPreview}
                            channelRenderFilterFn={(channels) => {
                                const channelsFiltered = channels.filter(channel => {
                                    if (selectedChannelTypes === SelectedCategoryTypeEnum.Dm || selectedChannelTypes === SelectedCategoryTypeEnum.Events) {
                                        if (item.types.includes(channel.type) && channel.state.last_message_at !== null) {
                                            return channel
                                        }
                                    } else {
                                        if (selectedChannelTypes === SelectedCategoryTypeEnum.All || selectedChannelTypes === SelectedCategoryTypeEnum.Clubs) {
                                            if (item.types.includes('support')) {
                                                if (item.types.includes(channel.type)) {
                                                    return channel
                                                }
                                            } else {
                                                if (channel.type === 'dm' || channel.type === 'event_chat') {
                                                    if (channel.state.last_message_at !== null && item.types.includes(channel.type)) {
                                                        return channel
                                                    }
                                                } else {
                                                    if (item.types.includes(channel.type)) {
                                                        return channel
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })

                                return channelsFiltered
                            }}
                        />
                    </>)}

                </div>


                <div style={{
                    display: 'flex',
                    marginTop: 70

                }}>

                    <Channel
                        multipleUploads={true}
                        TypingIndicator={() => null}
                        Message={CustomSimpleMessage}
                    >
                        <CustomChatContent />

                    </Channel>
                </div>

            </div>

        </Chat>


    )
};

export default DashboardChat;
