export const GET_SUB_CLUBS = "GET_SUB_CLUBS";
export const GET_SUB_CLUBS_SUCCESS = "GET_SUB_CLUBS_SUCCESS";
export const GET_SUB_CLUBS_FAIL = "GET_SUB_CLUBS_FAIL";

export const GET_CLUB_LEADER_SUB_CLUBS = "GET_CLUB_LEADER_SUB_CLUBS";
export const GET_CLUB_LEADER_SUB_CLUBS_SUCCESS = "GET_CLUB_LEADER_SUB_CLUBS_SUCCESS";
export const GET_CLUB_LEADER_SUB_CLUBS_FAIL = "GET_CLUB_LEADER_SUB_CLUBS_FAIL";

export const GET_SUB_CLUBS_BY_CLUB_ID = "GET_SUB_CLUBS_BY_CLUB_ID";
export const GET_SUB_CLUBS_BY_CLUB_ID_SUCCESS = "GET_SUB_CLUBS_BY_CLUB_ID_SUCCESS";
export const GET_SUB_CLUBS_BY_CLUB_ID_FAIL = "GET_SUB_CLUBS_BY_CLUB_ID_FAIL";