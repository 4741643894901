import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import classnames from "classnames";

import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import QRCode from "qrcode.react";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { cloneEvent, getClubLeaderPastEvents, getClubLeaderSubClubs, getClubs } from "store/actions";

class ClubLeaderPastEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clubLeaderSubClubs: [],
            activeSubClub: null,
        };
    }

    handleValidDate = date => {
        const date1 = moment.utc(date).format("ddd, DD MMM Y");
        return date1;
    };

    componentDidMount() {
        const {
            onGetSubClubs,
            onEventClone,
            onGetClubs
        } = this.props;
        onGetSubClubs();
        onEventClone(null)
        onGetClubs()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.clubLeaderSubClubs !== prevProps.clubLeaderSubClubs) {
            this.setState({
                clubLeaderSubClubs: this.props.clubLeaderSubClubs,
                activeSubClub: this.props.clubLeaderSubClubs[0]
            });
        }
        if (this.state.activeSubClub !== prevState.activeSubClub) {
            this.props.onGetClubLeaderPastEvents(this.state.activeSubClub.id)
        }
    }

    viewBookingsFormater = (cell, row, rowIndex, formatExtraData) => {
        return (
            <Button
                onClick={() => this.props.history.push(`/bookings/${row.title?.replace(/[?/]/g, '-')}/${row.id}`)}
                color="info"
            >
                Bookings
            </Button>
        );
    };
    render() {

        const { loadingClubLeaderPastEvents, clubLeaderPastEvents, history, onEventClone } = this.props;
        const { SearchBar } = Search;

        const eventsColumns = [
            {
                dataField: "image",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <img src={event.image} alt="" className="avatar-sm" />
                ),
            },
            {
                text: "Events",
                dataField: "events",
                // eslint-disable-next-line react/display-name
                filterValue: (_, event) => event?.title + event?.description,
                formatter: (_, event) => (
                    <>
                        <h5 className="font-size-14">
                            {event.title}
                        </h5>
                        <p
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            className="text-muted mb-0"
                        >
                            {event.description}
                        </p>
                    </>
                ),
            },
            {
                text: "Bookings",
                dataField: "",
                formatter: this.viewBookingsFormater
            },
            {
                text: "Action",
                dataField: '',
                formatter: (_, event) => (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="card-drop"
                                tag="a"
                            >
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                    onClick={() => {
                                        onEventClone(event)
                                        history.push('/suggest-event')
                                    }}
                                >
                                    <i className="mdi mdi-clipboard-file font-size-16 text-danger me-1" />{" "}
                                    Clone Event
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>

                )
            },
            {
                text: "Event start",
                dataField: "startDate",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <>
                        {this.handleValidDate(event.start_date)}
                        <p style={{ marginTop: 10 }}>
                            {moment(event.start_time, "HH:mm:ss").format("hh:mmA")} -{' '}
                            {moment(event.end_time, "HH:mm:ss").format("hh:mmA")}
                        </p>
                    </>
                ),
            },
            {
                dataField: "booking_count",
                text: "Total Bookings",
            },
            {
                dataField: "capacity",
                text: "Capacity",
            },
            {
                dataField: "price",
                text: "Price",
            },

        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: clubLeaderPastEvents.length, // replace later with size(customers),
            custom: true,
        };

        return (
            <React.Fragment>
                <QRCode
                    id="eventQRCode"
                    style={{ width: 100 }}
                    value={this.state.qrCodeText}
                    hidden={true}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Past Events List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Past Events" breadcrumbItem="Past Events List" />
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Nav
                                                        pills
                                                        className="bg-light rounded"
                                                        role="tablist"
                                                    >
                                                        {this.state.clubLeaderSubClubs?.map((item, index) => {
                                                            let abrv = ''
                                                            if (this.props.clubs) {
                                                                const club = this.props.clubs.find(club => club.id === item.club_id)
                                                                if (club) {
                                                                    abrv = club.abbreviation
                                                                }
                                                            }
                                                            return (
                                                                <NavItem key={index}>
                                                                    <NavLink
                                                                        className={classnames({
                                                                            active: this.state.activeSubClub.id === item.id,
                                                                        })}
                                                                        style={{
                                                                            backgroundColor:
                                                                                this.state.activeSubClub.id === item.id
                                                                                    ? "#032224"
                                                                                    : "transparent",
                                                                        }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                activeSubClub: item,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {item.name} - {abrv}
                                                                    </NavLink>
                                                                </NavItem>
                                                            )
                                                        })}
                                                    </Nav>

                                                </div>

                                                {clubLeaderPastEvents && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={eventsColumns}
                                                        data={clubLeaderPastEvents}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={eventsColumns}
                                                                data={clubLeaderPastEvents}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                                style={{ marginTop: 10 }}
                                                                            />
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingClubLeaderPastEvents ? (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="text-success"
                                                                                            >
                                                                                                <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                                                                Loading
                                                                                            </Link>{" "}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <BootstrapTable
                                                                                            responsive
                                                                                            bordered={false}
                                                                                            striped={false}
                                                                                            classes={
                                                                                                "table align-middle table-nowrap"
                                                                                            }
                                                                                            headerWrapperClasses={
                                                                                                "thead-light"
                                                                                            }
                                                                                            {...toolkitProps.baseProps}
                                                                                            {...paginationTableProps}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

ClubLeaderPastEvents.propTypes = {
    className: PropTypes.any,
    onQrDownload: PropTypes.array,
    clubLeaderSubClubs: PropTypes.array,
    clubLeaderPastEvents: PropTypes.array,
    loadingClubLeaderPastEvents: PropTypes.bool,
    onGetSubClubs: PropTypes.func,
    onGetClubLeaderPastEvents: PropTypes.func,
    onEventClone: PropTypes.func,
    onGetClubs: PropTypes.func,
    clubs: PropTypes.array,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ events, subclubs, system }) => ({
    clubLeaderSubClubs: subclubs.clubLeaderSubClubs,
    clubLeaderPastEvents: events.clubLeaderPastEvents,
    loadingClubLeaderPastEvents: events.loadingClubLeaderPastEvents,
    clubs: system.clubs,
});

const mapDispatchToProps = dispatch => ({
    onGetSubClubs: () => dispatch(getClubLeaderSubClubs()),
    onEventClone: (event) => dispatch(cloneEvent(event)),
    onGetClubs: () => dispatch(getClubs()),
    onGetClubLeaderPastEvents: (subClubId) => dispatch(getClubLeaderPastEvents(subClubId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ClubLeaderPastEvents));
