/* eslint-disable react/prop-types */
import { decryptMessage } from 'helpers/encryptionService'
import React, { useEffect, useState } from 'react'
import {
    MessageSimple,
    useChannelStateContext,
    useMessageContext
} from 'stream-chat-react'

function CustomSimpleMessage(props) {
    const { message } = useMessageContext()

    const { channel } = useChannelStateContext()

    const [messageProp, setMessageProp] = useState(message)

    useEffect(() => {
        if (channel.type === 'dm') {
            if (message?.attachments.length > 0) {
                setMessageProp(message)
            } else {
                if (message.text.includes('/giphy')) {
                    setMessageProp(message)
                } else {
                    setMessageProp({ ...props.message, text: decryptMessage(message.text) })
                }
            }
        } else {
            setMessageProp(message)
        }
    }, [channel])


    return (
        <MessageSimple {...props} message={messageProp} />
    )
}

export default CustomSimpleMessage