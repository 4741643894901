import CryptoJS from 'crypto-js';

const secretKey = CryptoJS.enc.Hex.parse('7096fed0e566431def8576fe0b5aaab90711060285deea46b0a9f97fdd773680');
const iv = CryptoJS.enc.Hex.parse('ee86eabc775087c4f3082dc933f7c835');

export const encryptMessage = (message) => {
    const encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(message),
        secretKey,
        {
            keySize: 256 / 32,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );

    return encrypted.toString();
};

export const decryptMessage = (encryptedMessage) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedMessage, secretKey, {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return CryptoJS.enc.Utf8.stringify(decrypted);
};