export const EVENTS = "/event/admin";

export const CREATE_EVENT = '/event'

export const SUGGEST_EVENT = '/event/suggest'

export const DRAFT_EVENT = '/event-drafts'

export const SEND_EMAIL_LEADER = "/sub-club/leader/email"

export const CREATE_EVENT_INSTRUCTOR = '/event/instructor'

export const NEW_EVENT_NOTIFICATION = '/notification/new_event'

export const MUTATE_EVENT = `/event`

export const MUTATE_EVENT_CLUB_LEADER = '/event/club-leader/update'

export const MUTATE_INSTRUCTOR_EVENT = `/event/instructor/update`

export const GET_EVENTS_BY_CLUB = (id) => `/event/admin/club/${id}`

export const GET_EVENT_ATTENDANCE = (userId) => `/event/booked/${userId}`

export const GET_EVENT_POSSIBLE_MATCHES = (eventId) => `/user/possible-matches/${eventId}`

export const UPDATE_SPOTLIGHT = (eventId) => `/event/spotlight/${eventId}`

export const PAST_EVENTS = (clubId) => `/event/past/admin/club/${clubId}`

export const EVENTS_BY_INSTRUCTOR = (id) => `/event/instructor/${id}`

export const PAST_EVENTS_BY_INSTRUCTOR = (id) => `/event/past/instructor/${id}`

export const CANCEL_EVENT = (eventId) => `/event/cancel/${eventId}`

export const INSTRUCTOR = (id) => `/user/role/${id}`

export const SPACE_BOOKINGS = (clubId, date) => `/spaces/bookings/date/${date}/club/${clubId}`

export const GET_GUESTS = (clubId, date) => `/guest/date/${date}/club/${clubId} `

export const GET_ONBOARDING_MEMBERS = (clubId) => `/user/not-on-boarded/${clubId}`

export const CHECK_IN_MEMBERS = (id) => `/booking/${id}/event/check-in`

export const DOORDECK_ENTRIES_MEMBER = (email) => `/doordeck/email/${email}`

export const AIRTABLE_RECORDS = 'https://api.airtable.com/v0/appbuTuvOmp41hXWZ/tblDHzBELUGbMiotA'

export const PUBLIC_BOOKINGS = (eventId) => `/booking/event/public/${eventId}`

export const BOOKINGS = (id) => `/booking/event/${id}`

export const BOOKINGS_LEADER = (id) => `/sub-club/event/${id}/bookings`

export const CANCEL_BOOKING = (bookingId, eventId, userId) => `/booking/event/${bookingId}/${eventId}/${userId}`

export const MEMBERS = (clubId) => `/user/members/club/${clubId}`

export const CANCELED_MEMBERS = (clubId) => `/user/members/canceled/club/${clubId}`

export const MEMBER_BY_ID = (id) => `/user/member/${id}`

export const MEMBER_BY_ID_BY_SUBCLUB = (subClubId, userId) => `/sub-club/${subClubId}/users/${userId}/profile`

export const NEW_MEMBERS_BY_CLUB_ID = (clubId) => `/user/new-members/${clubId}`

export const MEMBER_BY_EMAIL = (email) => `/user/email/${email}`

export const GET_COUPONS_APPLIED = `/coupon`

export const GET_COUPONS = `/coupon/unique`

export const APPLY_COUPON = `/coupon/apply`

export const USER_SUBSCRIPTION_BY_ID = (id) => `/user/subscription/${id}`

export const NOTES_FOR_MEMBER = '/user/new-note'

export const GET_NOTES_FOR_MEMBER = (userId) => `/user/note/${userId}`

export const CANCEL_MEMBERSHIP = '/user/member/cancel/'

export const UPDATE_ONBOARDING_SESSION = '/user/member/onboarding/'

export const UPDATE_FIRST_MONTH_CHECHIN = '/user/member/checkin/'

export const UPDATE_LOGOUT_FROM_APP = '/user/member/logout/app'

export const CHILDREN_CATEGORIES = '/system/category/children/all'

export const SUB_CLUBS = '/system/sub-clubs'

export const CLUB_LEADER_SUB_CLUBS = (userId) => `sub-club/leader/${userId}`

export const CLUBS = '/system/admin/clubs'

export const NON_ATTENDEES = '/user/inactive/members'

export const CALENDLY_SCHEDULED_EVENTS = 'https://api.calendly.com/scheduled_events'

export const DOORDECK_API = (doorId, startDate, endDate) => `/doordeck/entry?deviceId=${doorId}&startDate=${startDate}&endDate=${endDate}`

export const CANCEL_SPACE_BOOKING = (bookingId) => `/spaces/admin/booking/${bookingId}`

export const USER_SPACE_BOOKINGS = (userId) => `/spaces/admin/bookings/${userId}`

export const ONBOARDING_SCHEDULED = (email) => `/user/not-onboarded/${email}`

export const GET_PRODUCTS = (clubId) => `/store/product/${clubId}`

export const GET_ORDERS = (clubId) => `/store/orders?clubId=${clubId}`

export const ORDER_RECEIVED = (id) => `/store/order?id=${id}`

export const GET_ANNOUNCMENTS = (clubId) => `/announcement/club/${clubId}`

export const ARCHIVE_ANNOUNCMENT = `/announcement/archive`

export const ANNOUNCMENT = `/announcement/`

export const DELETE_ANNOUNCMENT = (id) => `/announcement/${id}`

export const DELETE_PRODUCT = (productId) => `/store/product/${productId}`

export const PRODUCT = `/store/product`

export const SEND_EMAIL = `/user/custom-emails`

export const SEND_NOTIFICATION = `/user/custom-notifications`

export const GET_ONBOARDING_BOOKINGS = '/user/onboard/bookings'

export const GET_HIGH_RISK_MEMBERS = '/user/membership/high-risk'

export const UPDATE_ONBOARDING_BOOKING = (id) => `/user/onboard/booking/${id}`

export const CANCEL_ONBOARDING_BOOKING = (id) => `/user/cancel/onboard-booking/${id}`

export const USER_PAUSES = (id) => `/pause/user/${id}`

export const EVENT_WAITLIST = (eventId) => `/event/booking/waitlist/${eventId}`

export const EVENT_WAITLIST_LEADER = (eventId) => `/sub-club/event/${eventId}/waitlist`

export const BOOK_SPACE = `/spaces/book`

export const SPACES = (clubId) => `/spaces/club/${clubId}`

export const APPLICANTS = `/applicant`

export const LATEST_APPLICANTS = `/applicant/latest`

export const SUGGESTED_EVENTS_BY_CLUB_ID = (clubId) => `/event/suggest?clubId=${clubId}`

export const CLUB_LEADER_UPCOMING_EVENTS = (subclubId) => `/sub-club/${subclubId}/events/upcoming`

export const CLUB_LEADER_PAST_EVENTS = (subclubId) => `/sub-club/${subclubId}/events/past`

export const CLUB_LEADER_MEMBERS_BY_SUBCLUB = (subclubId) => `/sub-club/members?subClubId=${subclubId}`

export const SUGGESTED_EVENTS_BY_USER_ID = (userId) => `/event/suggested?userId=${userId}`

export const DRAFTS_BY_USER_ID = (userId) => `/event-drafts?userId=${userId}`

export const DELETE_DRAFT = (id) => `/event-drafts?id=${id}`

export const SEND_EMAIL_TO_ATTENDEES = `/event/attendees/email`

export const DELETE_SUGGESTED_EVENT = (id) => `/event/suggested/${id}`

export const SUB_CLUBS_BY_CLUB = (id) => `/sub-club/clubs?ids=${id}`

export const DELETE_NOTE = (id) => `/user/note/${id}`

export const UPDATE_NOTE = '/user/note'

export const UPDATE_USER_AT_RISK = '/user/members/at-risk'

export const DOORDECK_ACCESS = '/doordeck/access'