import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import FileUpload from "../../components/FileUpload/FileUpload";
import classnames from "classnames";
import Select from "react-select";

import {
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
    Button,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
    setImage,
    getClubs,
    getAnnouncments,
    deleteAnnouncment,
    updateAnnouncment,
    archiveAnnouncment,
} from "store/actions";
import DefaultModal from "components/Common/DefaultModal";

class AnnouncmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            announcments: [],
            announcment: "",
            deleteModal: false,
            archiveModal: false,
            modal: false,
            selectedFiles: [],
            dropdownClubOpen: false,
            clubId: null,
            club: "No Club Selected",
            clubError: false,
            dropdownOpen: false,
            activeClub: null,
            typeError: false,
            hidden: false,
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            clubSelected: { label: "No Club Selected", value: '' },
            clubsOptions: [],
        };

        this.handleAnnouncmentClick = this.handleAnnouncmentClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickArchive = this.onClickArchive.bind(this);
        this.toggleClubs = this.toggleClubs.bind(this);
    }

    toggleClubs() {
        this.setState(prevState => ({
            dropdownClubOpen: !prevState.dropdownClubOpen,
        }));
    }

    handleClubSelect = club => {
        this.setState({
            clubSelected: club,
            activeClub: club.value
        })
    }

    clubItemSelected(name, id) {
        this.setState({
            club: name,
            clubId: id,
            clubError: false,
        });
    }

    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    componentDidMount() {
        const {
            announcments,
            onGetClubs,
            clubs
        } = this.props;
        const authUser = localStorage.getItem("authUser")
        if (JSON.parse(authUser)?.clubId) {
            this.setState({
                activeClub: JSON.parse(authUser).clubId
            })
        }
        if (clubs.length !== 0) {
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        } else {
            onGetClubs();
        }

        this.setState({
            announcments: announcments.filter(item => item.archive === 1),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeClub } = this.state;
        const { onGetAnnouncments } = this.props;

        if (this.props.clubs !== prevProps.clubs) {
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
                    clubSelected: { label: club.name, value: club.id }
                })
            }
        }
        if (activeClub !== prevState.activeClub) {
            if (activeClub) {
                onGetAnnouncments(activeClub)
            }
        }
        if (this.props.announcments !== prevProps.announcments) {
            this.setState({
                announcments: this.props.announcments.filter(item => item.archive === 1),
            });
        }
    }

    onClickDelete = announcment => {
        this.setState({ announcment });
        this.setState({ deleteModal: true });
    };
    onClickArchive = announcment => {
        this.setState({ announcment });
        this.setState({ archiveModal: true });
    };

    handleArchiveAnnouncment = () => {
        const { onArchiveAnnouncment, onGetAnnouncments } = this.props;
        const { announcment, activeClub } = this.state;
        if (announcment.id !== undefined) {
            onArchiveAnnouncment({ id: announcment.id, archive: 0 });
            this.setState({ archiveModal: false });
        }
        setTimeout(() => {
            onGetAnnouncments(activeClub)
        }, 500);
    };

    handleDeleteAnnouncment = () => {
        const { onDeleteAnnouncment, onGetAnnouncments } = this.props;
        const { announcment, activeClub } = this.state;
        if (announcment.id !== undefined) {
            onDeleteAnnouncment(announcment.id);
            this.setState({ deleteModal: false });
        }
        setTimeout(() => {
            onGetAnnouncments(activeClub)
        }, 500);
    };

    handleHiddenCheckbox = () => {
        this.setState({
            hidden: !this.state.hidden,
        });
    };

    handleAnnouncmentClick = arg => {
        const announcment = arg;
        this.props.onSetImage(announcment.image);
        this.setState({
            announcment,
            isEdit: true,
            clubId: announcment.club_id,
            club: this.props.clubs.find(item => item.id === announcment.club_id)?.name,
            typeError: false,
        });
        this.toggle();
    };

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    handleValidDate = date => {
        const date1 = moment(date).utc().format("DD MMM Y");
        return date1;
    };

    render() {
        const { clubs, onUpdateAnnouncment, loadingAnnouncments } = this.props;
        const { announcments } = this.state
        const { deleteModal, activeClub, announcment } = this.state;
        const { SearchBar } = Search;
        const role = localStorage.getItem("roleId");
        const announcmentsColumns = [
            {
                dataField: "image",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, announcment) => (
                    <>
                        <Link>
                            <img src={announcment.image} alt="" className="avatar-sm" />
                        </Link>
                    </>
                ),
            },
            {
                text: "Announcement",
                dataField: "title",
                // eslint-disable-next-line react/display-name
                filterValue: (_, announcment) => announcment?.title + announcment?.description,
                formatter: (_, announcment) => (
                    <>
                        <h5 className="font-size-14">
                            <Link
                                className="text-dark"
                            >
                                {announcment.title}
                            </Link>
                        </h5>
                        <p
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            className="text-muted mb-0"
                        >
                            <Link
                                className="text-dark"
                            >
                                {announcment.description}
                            </Link>
                        </p>
                    </>
                ),
            },


            {
                text: "Action",
                dataField: "",
                formatter: (_, announcment) => (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle className="card-drop" tag="a">
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                    onClick={() => this.handleAnnouncmentClick(announcment)}
                                >
                                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                    Edit
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => this.onClickArchive(announcment)}
                                >
                                    <i className="mdi mdi-archive font-size-16 text-danger me-1" />{" "}
                                    Unrchive
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => this.onClickDelete(announcment)}
                                >
                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                ),
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: announcments?.length,
            custom: true,
        };

        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteAnnouncment}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <DefaultModal
                    title={'Are you sure you want to archive this announcment?'}
                    description={'You will not be able to revert this!'}
                    show={this.state.archiveModal}
                    onSendClick={this.handleArchiveAnnouncment}
                    onCloseClick={() => this.setState({ archiveModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Announcements List | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Announcements" breadcrumbItem="Announcements List" />
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                {this.state.isMobile ? <Row>
                                                    <Col lg="1">
                                                        <div style={{ marginBottom: 10 }}>
                                                            <Link to="/add-announcment">
                                                                <Button
                                                                    // color="#032224"
                                                                    style={{ backgroundColor: "#032224" }}
                                                                    className="font-16 btn-block btn btn-primary"

                                                                >
                                                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                                                    Add Announcment
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                        <Select
                                                            styles={{
                                                            }}
                                                            onChange={
                                                                this.handleClubSelect
                                                            }
                                                            options={this.state.clubsOptions}
                                                            value={this.state.clubSelected}
                                                            classNamePrefix="select2-selection"
                                                        />


                                                    </Col>
                                                </Row> : <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Nav
                                                        pills
                                                        className="bg-light rounded"
                                                        role="tablist"
                                                    >
                                                        <NavItem>
                                                        </NavItem>
                                                        {clubs?.map((item, index) => (
                                                            <NavItem key={index}>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeClub === item.id,
                                                                    })}
                                                                    style={{
                                                                        backgroundColor:
                                                                            activeClub === item.id
                                                                                ? "#032224"
                                                                                : "transparent",
                                                                    }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            activeClub: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                    </Nav>
                                                    <div className="text-sm-end">
                                                        <Link to="/add-announcment">
                                                            <Button
                                                                // color="#032224"
                                                                style={{ backgroundColor: "#032224" }}
                                                                className="font-16 btn-block btn btn-primary"

                                                            >
                                                                <i className="mdi mdi-plus-circle-outline me-1" />
                                                                Add Announcment
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </div>}
                                                {announcments && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={announcmentsColumns}
                                                        data={announcments}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={announcmentsColumns}
                                                                data={announcments}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                                style={{ marginTop: 10 }}
                                                                            />
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingAnnouncments ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={"thead-light"}
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                        <Modal
                                            isOpen={this.state.modal}
                                            className={this.props.className}
                                        >
                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                {"Edit Announcement"}
                                            </ModalHeader>
                                            <ModalBody>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{
                                                        id: (announcment && announcment.id) || "",
                                                        title: (announcment && announcment.title) || "",
                                                        description: (announcment && announcment.description) || "",
                                                        clubId: (announcment && announcment.clubId) || "",
                                                        club:
                                                            announcment && this.props.clubs
                                                                ? this.props.clubs.find(
                                                                    item => item.id === announcment.clubId
                                                                )?.name
                                                                : "",

                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        title: Yup.string().required("Please Enter Title"),
                                                        description: Yup.string().required("Please Enter Description"),

                                                    })}
                                                    onSubmit={values => {
                                                        if (this.state.clubId === null) {
                                                            this.setState({
                                                                clubError: true,
                                                            });
                                                        } else {
                                                            const updateAnnouncment = {
                                                                title: values.title,
                                                                description: values.description,
                                                                clubId: parseInt(this.state.clubId),
                                                                id: announcment.id,
                                                                image: this.state?.selectedFiles[0],
                                                            };
                                                            onUpdateAnnouncment(updateAnnouncment);
                                                            this.toggle();
                                                            setTimeout(() => {
                                                                this.props.onGetAnnouncments(activeClub)
                                                            }, 500);
                                                        }
                                                    }}
                                                >
                                                    {({ errors, status, touched }) => (
                                                        <Form>
                                                            <Row>
                                                                <Col className="col-12">
                                                                    <div className="mb-3">
                                                                        <Field name="img" type="hidden" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Field name="team" type="hidden" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">Title</Label>
                                                                        <Field
                                                                            name="title"
                                                                            type="text"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.title && touched.title
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="title"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label">
                                                                            Description
                                                                        </Label>
                                                                        <Field
                                                                            name="description"
                                                                            type="text"
                                                                            component="textarea"
                                                                            rows="6"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.description &&
                                                                                    touched.description
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>



                                                                    <Row className="mb-4">

                                                                        <Label
                                                                            htmlFor="clubs"
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Clubs
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <Dropdown
                                                                                isOpen={this.state.dropdownClubOpen}
                                                                                toggle={this.toggleClubs}
                                                                            >
                                                                                <DropdownToggle caret>
                                                                                    {this.state.club}
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    {this.props.clubs?.map((club, index) => {
                                                                                        return (
                                                                                            <DropdownItem
                                                                                                onClick={() =>
                                                                                                    this.clubItemSelected(
                                                                                                        club.name,
                                                                                                        club.id
                                                                                                    )
                                                                                                }
                                                                                                key={index}
                                                                                            >
                                                                                                {club.name}
                                                                                            </DropdownItem>
                                                                                        );
                                                                                    })}
                                                                                </DropdownMenu>
                                                                            </Dropdown>
                                                                            {this.state.clubError && (
                                                                                <p
                                                                                    style={{
                                                                                        color: "red",
                                                                                    }}
                                                                                >
                                                                                    Please Select a Club
                                                                                </p>
                                                                            )}
                                                                        </Col>
                                                                    </Row>

                                                                    <FormGroup className="mb-3" row>
                                                                        <Label className="col-form-label col-lg-2">
                                                                            Attach Image
                                                                        </Label>
                                                                        <Col lg="10">
                                                                            <FileUpload
                                                                                onFileUploaded={this.onFileUploaded}
                                                                            ></FileUpload>

                                                                            <div
                                                                                className="dropzone-previews mt-3"
                                                                                id="file-previews"
                                                                            >
                                                                                {this.state.selectedFiles.map(
                                                                                    (f, i) => {
                                                                                        return (
                                                                                            <Card
                                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                                key={i + "-file"}
                                                                                            >
                                                                                                <div className="p-2">
                                                                                                    <Row className="align-items-center">
                                                                                                        <Col className="col-auto">
                                                                                                            <img
                                                                                                                data-dz-thumbnail=""
                                                                                                                height="80"
                                                                                                                className="avatar-sm rounded bg-light"
                                                                                                                alt={f.name}
                                                                                                                src={f.preview}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Link
                                                                                                                to="#"
                                                                                                                className="text-muted font-weight-bold"
                                                                                                            >
                                                                                                                {f.name}
                                                                                                            </Link>
                                                                                                            <p className="mb-0">
                                                                                                                <strong>
                                                                                                                    {f.formattedSize}
                                                                                                                </strong>
                                                                                                            </p>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            </Card>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>

                                                                    <div className="text-end">
                                                                        {(() => {
                                                                            if (role == 1 || role == 666) {
                                                                                return (
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-success save-user"
                                                                                    >
                                                                                        Save
                                                                                    </button>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

AnnouncmentList.propTypes = {
    announcments: PropTypes.array,
    className: PropTypes.any,
    onUpdateAnnouncment: PropTypes.func,
    onDeleteAnnouncment: PropTypes.func,
    onArchiveAnnouncment: PropTypes.func,
    onSetImage: PropTypes.func,
    onGetAnnouncments: PropTypes.func,
    onGetClubs: PropTypes.func,
    loadingAnnouncments: PropTypes.bool,
    clubs: PropTypes.array,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ announcments, system }) => ({
    announcments: announcments.announcments,
    loadingAnnouncments: announcments.loadingAnnouncments,
    clubs: system.clubs,

});

const mapDispatchToProps = dispatch => ({
    onGetClubs: () => dispatch(getClubs()),
    onUpdateAnnouncment: (announcment) => dispatch(updateAnnouncment(announcment)),
    onDeleteAnnouncment: (id) => dispatch(deleteAnnouncment(id)),
    onArchiveAnnouncment: (data) => dispatch(archiveAnnouncment(data)),
    onGetAnnouncments: clubId => dispatch(getAnnouncments(clubId)),
    onSetImage: image => dispatch(setImage(image)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AnnouncmentList));
