import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button, Nav, NavItem, NavLink } from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from 'moment-timezone';
import classnames from "classnames"
import Select from "react-select";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import { cancelSpaceBooking, getClubs, getSpaceBookings } from "store/actions";
import DeleteModal from "components/Common/DeleteModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class SpaceBookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      spaceBookings: [],
      selectedType: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      activeClub: null,
      deleteModal: false,
      bookingToDelete: null,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      clubSelected: { label: "No Club Selected", value: '' },
      clubsOptions: [],
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onCancelHandler.bind(this)
    this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
  }

  handleDeleteEvent() {
    const { onCancelSpaceBooking } = this.props
    const { bookingToDelete, spaceBookings } = this.state
    onCancelSpaceBooking(bookingToDelete.bookingId)
    const spaceBookingsCopy = spaceBookings.filter(item => bookingToDelete.id !== item.id)
    this.setState({
      deleteModal: false,
      spaceBookings: spaceBookingsCopy,
      bookingToDelete: null
    })
  }

  handleClubSelect = club => {
    this.setState({
      clubSelected: club,
      activeClub: club.value
    })
  }

  onCancelHandler(bookingToDelete) {
    this.setState({
      bookingToDelete,
      deleteModal: true
    })
  }

  cancelFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => {
          this.onCancelHandler(row);
        }}
        color="danger"
      >
        Cancel
      </Button>
    );
  };

  componentDidMount() {
    const { onGetClubs, clubs } = this.props;
    const authUser = localStorage.getItem("authUser")
    if (JSON.parse(authUser)?.clubId) {
      this.setState({
        activeClub: JSON.parse(authUser).clubId
      })
    }
    if (clubs.length !== 0) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    } else {
      onGetClubs();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { clubs, spaceBookings, onGetSpaceBookings } = this.props;
    const { activeClub } = this.state;
    if (clubs !== prevProps.clubs) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    }
    if (activeClub !== prevState.activeClub) {
      if (activeClub) {
        onGetSpaceBookings(activeClub, this.state.selectedDate);
      }
    }
    if (spaceBookings !== prevProps.spaceBookings) {
      this.setState({
        spaceBookings,
        selectedType: 0

      })
    }
  }

  handleOnChange(event) {
    let data = this.props?.spaceBookings
    switch (event.target.value) {
      case '0':
        break;
      case 'booth':
        data = this.props.spaceBookings?.filter(item => item.type === event.target.value)
        break
      case 'private_room':
        data = this.props.spaceBookings?.filter(item => item.type === event.target.value)
        break
      default:
        break;
    }
    this.setState({
      spaceBookings: data,
      selectedType: event.target.value
    })
  }

  render() {

    const { clubs } = this.props;
    const { activeClub, deleteModal } = this.state


    const columns = [
      {
        text: 'User',
        formatter: (_, space) => (
          <>
            <h5 className="font-size-14">
              {space.first_name} {space.last_name}
            </h5>
            <p
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              className="text-muted mb-0"
            >
              {space.email}
            </p>
          </>
        ),
      },
      {
        dataField: "title",
        text: "Space",
      },
      {
        dataField: "type",
        text: "Type",
      },
      {
        dataField: "duration",
        text: "Duration",
        formatter: (_, space) => (
          <>
            {space.duration} minutes
          </>
        ),
      },
      {
        dataField: "start_time",
        text: "Start time",
        formatter: (_, space) => (
          <>
            {moment(space.start_time, "HH:mm:ss").format("hh:mm A")}
          </>
        ),

      },
      {
        dataField: "end_time",
        text: "End Time",
        formatter: (_, space) => (
          <>
            {moment(space.end_time, "HH:mm:ss").format("hh:mm A")}
          </>
        ),
      },
      {
        dataField: "canceled",
        text: "Cancel Booking",
        formatter: this.cancelFormatter,
        sort: true,
      }
    ];


    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.spaceBookings?.length, // replace later with size(customers),
      custom: true,
    };

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteEvent}
          onCloseClick={() => this.setState({ deleteModal: false })}
          cancel
        />
        <div className="page-content">
          <MetaTags>
            <title> Space Bookings | Groundfloor</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={"Space Bookings"} breadcrumbItem={"Space Bookings List"} />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {this.state.isMobile ? <Row><Col>
                      <div style={{ marginBottom: 10 }}>
                        <Link to="/book-space">
                          <Button
                            style={{ backgroundColor: "#032224" }}
                            className="font-16 btn-block btn btn-primary"
                          >
                            Book a space
                          </Button>
                        </Link>
                      </div>
                      <Select
                        styles={{
                        }}
                        onChange={
                          this.handleClubSelect
                        }
                        options={this.state.clubsOptions}
                        value={this.state.clubSelected}
                        classNamePrefix="select2-selection"
                      />
                    </Col></Row> : <div style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10
                    }}>
                      <Nav pills className="bg-light rounded" role="tablist">
                        {clubs?.map((item, index) => (
                          <NavItem key={index}>
                            <NavLink
                              className={classnames({ active: activeClub === item.id })}
                              style={{
                                backgroundColor: activeClub === item.id ? '#032224' : 'transparent'
                              }}
                              onClick={() => {
                                this.setState({
                                  activeClub: item.id
                                })
                              }}
                            >
                              {item.name}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                      <div className="text-sm-end">
                        <Link to="/book-space">
                          <Button
                            style={{ backgroundColor: "#032224" }}
                            className="font-16 btn-block btn btn-primary"
                          >
                            Book a space
                          </Button>
                        </Link>
                      </div>
                    </div>
                    }
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        startDate: this.state.selectedDate
                      }}
                      validationSchema={Yup.object().shape({
                        startDate: Yup.string().required(
                          "Please Enter Start Date"
                        ),
                      })}
                      onSubmit={values => {
                        const selectedClub = activeClub === 0 ? clubs.map(item => item.id.toString()).join(',') : activeClub
                        this.props.onGetSpaceBookings(selectedClub,
                          values.startDate
                        );
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <React.Fragment>
                          <Form>
                            <Row className="mb-4">
                              <Col lg="10">
                                <Row>
                                  <Col lg="6">
                                    <Field
                                      name="startDate"
                                      type="date"
                                      onChange={value => this.setState({
                                        selectedDate: value.target.value
                                      })}
                                      className={
                                        "form-control" +
                                        (errors.startDate && touched.startDate
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="startDate"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <Button type="submit" color="primary">
                                      Find Space Bookings
                                    </Button>
                                  </Col>
                                  <Col lg='6' style={{ marginTop: 10 }}>
                                    <select onChange={this.handleOnChange} value={this.state.selectedType} className="form-select">
                                      <option value="0">Select Type</option>
                                      <option value="booth">Booth</option>
                                      <option value="private_room">Private Room</option>
                                    </select>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </React.Fragment>
                      )}
                    </Formik>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.spaceBookings}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.spaceBookings}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"email"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SpaceBookings.propTypes = {
  spaceBookings: PropTypes.array,
  onGetSpaceBookings: PropTypes.func,
  onGetClubs: PropTypes.func,
  onCancelSpaceBooking: PropTypes.func,
  clubs: PropTypes.array,
};

const mapStateToProps = ({ bookings, system }) => ({
  spaceBookings: bookings.spaceBookings,
  clubs: system.clubs

});

const mapDispatchToProps = dispatch => ({
  onGetSpaceBookings: (clubId, date) => dispatch(getSpaceBookings(clubId, date)),
  onCancelSpaceBooking: (bookingId) => dispatch(cancelSpaceBooking(bookingId)),
  onGetClubs: () => dispatch(getClubs())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpaceBookings));
