export const ADD_ANNOUNCMENT = "ADD_ANNOUNCMENT";
export const ADD_ANNOUNCMENT_SUCCESS = "ADD_ANNOUNCMENT_SUCCESS";
export const ADD_ANNOUNCMENT_FAIL = "ADD_ANNOUNCMENT_FAIL";

export const GET_ANNOUNCMENTS = 'GET_ANNOUNCMENTS'
export const GET_ANNOUNCMENTS_SUCCESS = 'GET_ANNOUNCMENTS_SUCCESS'
export const GET_ANNOUNCMENTS_FAIL = 'GET_ANNOUNCMENTS_FAIL'

export const UPDATE_ANNOUNCMENT = 'UPDATE_ANNOUNCMENT'
export const UPDATE_ANNOUNCMENT_SUCCESS = 'UPDATE_ANNOUNCMENT_SUCCESS'
export const UPDATE_ANNOUNCMENT_FAIL = 'UPDATE_ANNOUNCMENT_FAIL'

export const DELETE_ANNOUNCMENT = 'DELETE_ANNOUNCMENT'
export const DELETE_ANNOUNCMENT_SUCCESS = 'DELETE_ANNOUNCMENT_SUCCESS'
export const DELETE_ANNOUNCMENT_FAIL = 'DELETE_ANNOUNCMENT_FAIL'

export const ARCHIVE_ANNOUNCMENT = 'ARCHIVE_ANNOUNCMENT'
export const ARCHIVE_ANNOUNCMENT_SUCCESS = 'ARCHIVE_ANNOUNCMENT_SUCCESS'
export const ARCHIVE_ANNOUNCMENT_FAIL = 'ARCHIVE_ANNOUNCMENT_FAIL'