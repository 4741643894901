import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import FileUpload from "../../components/FileUpload/FileUpload";
import classnames from "classnames";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import _ from 'lodash'

import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Badge,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import QRCode from "qrcode.react";
import { jsPDF } from "jspdf";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
  updateEvent,
  deleteEvent,
  setImage,
  getEventInstructors,
  getClubs,
  getEventsByClub,
  sendNewEventNotification,
  checkLoggedOut,
  cloneEvent,
  getAllChildrenCategories,
  updateSpotlight,
  cancelEvent,
  getMembers,
  getSubClubs,
  getDraftEventsByUserId,
  updateDraft,
  deleteDraft,
} from "store/actions";
import SendNotificationModal from "components/Common/SendNotificationModal";
import Switch from "components/Common/Switch";
import { TreeSelect } from "antd";
import DefaultModal from "components/Common/DefaultModal";

const CategoryEnum = {
  event: 'event',
  wellness: 'wellness',
  drafts: 'drafts'
}

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      instructors: [],
      events: [],
      event: "",
      membersSelect: [],
      deleteModal: false,
      cancelEventModal: false,
      showBookClosingTimes: false,
      modal: false,
      selectedFiles: [],
      dropdownClubOpen: false,
      clubId: null,
      club: "No Club Selected",
      clubError: false,
      guestInvitesAllowed: 0,
      requiresPayment: false,
      checkinRequired: false,
      allClubsEvent: false,
      bookingConfirmation: { label: "", value: "" },
      price: 0,
      dropdownOpen: false,
      activeClub: null,
      sendNotificationModal: false,
      qrCodeText: "",
      interestsSelected: [],
      interestsFiltered: [],
      interestsError: false,
      locationSelected: { label: "Select Location", value: '' },
      location: '',
      clubSelected: { label: "No Club Selected", value: '' },
      clubsOptions: [],
      hostSelected: null,
      host: null,
      hostId: null,
      eventImage: null,
      eventCategory: CategoryEnum.event,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      isDraft: false,
      eventIdToDelete: null,
      deleteDraftModal: false,
      scheduleDate: { label: "No date", value: null },
      imageError: false,
      salesEndBeforeEvent: { label: "2 hours before the event", value: 2 },
      viewEvent: false,
    };

    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleDraftClick = this.handleDraftClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickCancelEvent = this.onClickCancelEvent.bind(this);
    this.onClickSendNotification = this.onClickSendNotification.bind(this);
    this.toggleCategory = this.toggleCategory.bind(this);
    this.toggleClubs = this.toggleClubs.bind(this);
    this.toggleInstructor = this.toggleInstructor.bind(this);
    this.onQrDownload = this.onQrDownload.bind(this);
    this.copyEventDeeplink = this.copyEventDeeplink.bind(this);
    this.handleMulti = this.handleMulti.bind(this);
    this.showSuccessToast = this.showSuccessToast.bind(this);

  }

  toggleCategory() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  toggleInstructor() {
    this.setState(prevState => ({
      dropdownInstructorOpen: !prevState.dropdownInstructorOpen,
    }));
  }

  toggleClubs() {
    this.setState(prevState => ({
      dropdownClubOpen: !prevState.dropdownClubOpen,
    }));
  }

  categoryItemSelected(value) {
    this.setState({
      category: value,
    });
  }

  instructorItemSelected(name, id) {
    this.setState({
      instructorName: name,
      instructorId: id,
    });
  }

  handleCheckinRequried = () => {
    this.setState({
      checkinRequired: !this.state.checkinRequired,
    });
  };
  handleAllClubsEvent = () => {
    this.setState({
      allClubsEvent: !this.state.allClubsEvent,
    });
  };

  clubItemSelected(name, id) {
    this.setState({
      club: name,
      clubId: id,
      clubError: false,
    });
  }

  onFileUploaded(file) {
    this.setState({ eventImage: file.url });
  }

  showSuccessToast(message, autoClose) {
    toast.success(message, {
      position: "top-right",
      autoClose: autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }


  copyEventDeeplink(id) {
    this.showSuccessToast("Event deeplink copied to clipboard", 500)
    navigator.clipboard.writeText(
      `https://www.groundfloorclub.com/g68zuqkhzwkodjphs3wjo/?event=${id}`
    );
  }

  onQrDownload = event => {
    const title = encodeURIComponent(event.title)
    window.open(`https://dashboard.groundfloorapp.com/qr/${event.id}/${title}/${event.startDate}/${event.startTime}`, '_blank', 'noopener noreferrer');
  };

  filterCategories = id => {
    const { childrenCategories } = this.props;
    return childrenCategories
      ?.filter(item => item.system_category_id === id)
      .map(item => ({ label: item.name, value: item.name }));
  };

  componentDidMount() {
    const {
      events,
      instructors,
      onGetEventInstructors,
      onGetEventsByClub,
      onGetClubs,
      onCheckLoggedOut,
      onEventClone,
      onGetAllChildrenCategories,
      onGetSubClubs,
      onGetDrafts,
      clubs
    } = this.props;

    const authUser = localStorage.getItem("authUser")
    if (JSON.parse(authUser)?.clubId) {
      this.setState({
        activeClub: JSON.parse(authUser).clubId

      })
    }

    if (clubs.length !== 0) {
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    } else {
      onGetClubs();
    }

    onGetEventInstructors();
    onGetAllChildrenCategories();

    onCheckLoggedOut();
    onGetSubClubs()
    onGetDrafts()
    this.setState({
      instructors, events, interestsFiltered: [
        {
          label: "Interests",
          options: [...this.filterCategories("2"), ...this.filterCategories("11")],
        },
      ],

    });
    onEventClone(null);
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeClub } = this.state;
    const { onGetEventsByClub } = this.props;



    const { childrenCategories, members, onGetMembers } = this.props;
    if (this.props.clubs !== prevProps.clubs) {
      const authUser = localStorage.getItem("authUser")
      onGetMembers(this.props.clubs.map(item => item?.id).join(','))
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    }
    if (activeClub !== prevState.activeClub) {
      if (activeClub) {
        onGetEventsByClub(this.state.activeClub)
      }
    }
    if (this.props.events !== prevProps.events) {
      this.setState({
        events: this.props.events,
      });
    }
    if (childrenCategories !== prevProps.childrenCategories) {
      this.setState({
        interestsFiltered: [
          {
            label: "Interests",
            options: [...this.filterCategories("2"), ...this.filterCategories("11")],
          },
        ],
      });
    }
    if (members !== prevProps.members) {
      const membersSelect = this.props.clubs.map(club => ({ title: `Members ${club.name}`, value: `member;${club?.id}`, children: this.props.members.filter(member => member.main_club_id === club?.id).map(item => ({ label: `${item.first_name} ${item.last_name}`, value: `${item.first_name} ${item.last_name};${item.memberId}` })) }))
      const subClubsSelect = this.props.clubs.map(club => ({ title: `Sub clubs ${club.name}`, value: `subclub;${club?.id}`, children: this.props.subClubs.filter(item => parseInt(item.club_id) === club?.id).map(subClub => ({ title: subClub.name, value: `${subClub.name};${subClub.id}` })) }))
      const treeData = [
        {
          title: "Groundfloor",
          value: "Groundfloor;Groundfloor",
        },
        ...subClubsSelect,
        ...membersSelect,
        {
          title: "Other",
          value: "Other;Other",
        },
      ];

      this.setState({
        treeData
      });
    }
    if (!this.state.modal) {
      this.props.onSetImage(null)
    }
  }

  onClickDelete = event => {
    this.setState({ event: event });
    this.setState({ deleteModal: true });
  };

  onClickCancelEvent = event => {
    this.setState({ event: event });
    this.setState({ cancelEventModal: true });
  };

  onClickSendNotification = event => {
    this.setState({ event: event });
    this.setState({ sendNotificationModal: true });
  };

  handleBookingConfirmationSelect = bookingConfirmation => {
    this.setState({ bookingConfirmation });
  };

  handleScheduleDateSelect = scheduleDate => {
    this.setState({ scheduleDate });
  };

  handleSendNotification = () => {
    const { onSendNewEventNotification } = this.props;
    const { event } = this.state;
    if (event.id !== undefined) {
      onSendNewEventNotification({
        id: event.id,
        title: event.title,
        description: event.description,
      });
      this.setState({ sendNotificationModal: false });
    }
  };

  deleteDraft = () => {
    this.props.onDeleteDraft(this.state.eventIdToDelete)
    setTimeout(() => {
      this.props.onGetDrafts()
    }, 800)
    this.setState({
      deleteDraftModal: false
    })
  }

  deleteDraftHanlder = (draftId) => {
    this.setState({
      deleteDraftModal: true,
      eventIdToDelete: draftId
    })
  }

  handleDeleteEvent = () => {
    const { onDeleteEvent } = this.props;
    const { event } = this.state;
    if (event.id !== undefined) {
      onDeleteEvent(event?.id);
      this.setState({ deleteModal: false });
    }
  };

  handleCancelEvent = () => {
    const { onCancelEvent, onGetEventsByClub } = this.props;
    const { event } = this.state;
    if (event.id !== undefined) {
      onCancelEvent(event?.id);
      this.setState({ cancelEventModal: false });
      setTimeout(() => {
        onGetEventsByClub(this.state.activeClub);
      }, 800);
    }
  };

  handleEventClick = arg => {
    const event = arg;
    this.props.onSetImage(event.image);

    const { instructors, onGetEventInstructors } = this.props;
    onGetEventInstructors();
    this.setState({ instructors });

    const startDate = moment.utc(event.startDate).format('YYYY-MM-DD')

    let difference = moment(`${startDate} ${event.startTime}`, 'YYYY-MM-DD HH:mm:ss').diff(moment(moment.utc(event.salesEnd).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss'), 'hours', true)

    let salesEndBeforeEvent = { label: `${difference} hours before the event`, value: difference }

    if (![0, 2, 12, 24, 48].includes(difference)) {
      salesEndBeforeEvent = { label: "12 hours before the event", value: 12 }
    } else if (difference === 0) {
      salesEndBeforeEvent = { label: "2 hours before the event", value: 2 }
    }

    this.setState({
      event: {
        id: event.id,
        price: event.price,
        userId: this.props.events.find(item => item.id === event.id)?.userId,
        firstName: this.props.events.find(item => item.id === event.id)
          ?.firstName,
        lastName: this.props.events.find(item => item.id === event.id)
          ?.lastName,
        description: event.description,
        capacity: event.capacity,
        guestInvitesAllowed: event.guestInvitesAllowed,
        dueDate: event.dueDate,
        location: event.location,
        link: event.link,
        host: event.host,
        category: event.category,
        instructorId: event.instructorId,
        clubId: event.clubId,
        instructorName: event.instructorName,
        club: this.props.clubs.find(item => item.id === event.clubId)?.name,
        requiresPayment: event.requiresPayment === 1,
        startDate: event.startDate,
        startTime: event.startTime,
        endTime: event.endTime,
        salesEnd: event.salesEnd,
        title: event.title,
      },
      isEdit: true,
      showBookClosingTimes: difference !== 0,
      locationSelected: { label: event.location, value: event.location },
      hostSelected: event.host,
      hostId: event.hostId,
      host: event.host,
      eventImage: event.image,
      interestsSelected: event.interests?.split(';')?.map(item => ({ label: item, value: item })),
      guestInvitesAllowed: event.guestInvitesAllowed,
      bookingConfirmation: { label: event.bookingConfirmation, value: event.bookingConfirmation },
      requiresPayment: event.requiresPayment === 1,
      checkinRequired: event.checkinRequired === 0 ? false : true,
      allClubsEvent: event.allClubs == 0 ? false : true,
      price: event.price,
      instructorId: event.instructorId,
      location: event.location,
      clubId: event.clubId,
      instructorName: event.instructorName,
      club: this.props.clubs.find(item => item.id === event.clubId)?.name,
      category:
        event.category.charAt(0).toUpperCase() + event.category.slice(1),
      isDraft: false,
      salesEndBeforeEvent
    });
    this.toggle();
  };

  handleDraftClick = arg => {
    const event = arg;
    this.props.onSetImage(event.image);
    const { instructors, onGetEventInstructors } = this.props;
    onGetEventInstructors();
    this.setState({ instructors });

    const startDate = moment.utc(event.startDate).format('YYYY-MM-DD')

    let difference = moment(moment.utc(event.sales_end).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').diff(moment(`${startDate} ${event.start_time}`, 'YYYY-MM-DD HH:mm:ss'), 'hours', true)

    let salesEndBeforeEvent = { label: `${difference} hours before the event`, value: difference }

    if (![0, 2, 12, 24, 48].includes(difference)) {
      salesEndBeforeEvent = { label: "12 hours before the event", value: 12 }
    } else if (difference === 0) {
      salesEndBeforeEvent = { label: "2 hours before the event", value: 2 }
    }

    this.setState({
      event: {
        id: event.id,
        price: event.price,
        userId: event.user_id,
        description: event.description,
        capacity: event.capacity,
        guestInvitesAllowed: event.guest_invites_allowed,
        location: event.location,
        link: event.link,
        host: event.host,
        category: event.category,
        instructorId: event.instructor_id,
        clubId: event.club_id,
        instructorName: event.instructor_name,
        club: this.props.clubs.find(item => item.id === event.club_id)?.name,
        requiresPayment: event.requires_payment === 1,
        startDate: event.start_date,
        startTime: event.start_time,
        endTime: event.end_time,
        title: event.title,
      },
      isEdit: true,
      showBookClosingTimes: difference !== 0,
      locationSelected: { label: event.location, value: event.location },
      hostSelected: event.host,
      hostId: event.host_id,
      host: event.host,
      eventImage: event.image,
      interestsSelected: event.interests.length > 0 ? event.interests?.split(';')?.map(item => ({ label: item, value: item })) : [],
      guestInvitesAllowed: event.guest_invites_allowed,
      bookingConfirmation: { label: event.booking_confirmation, value: event.booking_confirmation },
      requiresPayment: event.requires_payment === 1,
      checkinRequired: event.checkin_required === 0 ? false : true,
      allClubsEvent: event.all_clubs == 0 ? false : true,
      price: event.price,
      instructorId: event.instructor_id,
      location: event.location,
      clubId: event.club_id,
      instructorName: event.instructor_name,
      club: this.props.clubs.find(item => item.id === event.club_id)?.name,
      category:
        event.category.charAt(0).toUpperCase() + event.category.slice(1),
      isDraft: true,
      scheduleDate: event.publish_date ? { label: moment(event.publish_date).format('ddd, DD MMM Y'), value: moment(event.publish_date).format('YYYY-MM-DD') } : { label: "No date", value: null },
      salesEndBeforeEvent
    });
    this.toggle();
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleValidDate = date => {
    const date1 = moment.utc(date).format("ddd, DD MMM Y");
    return date1;
  };

  handleMulti = interestsSelected => {
    this.setState({ interestsSelected, interestsError: false });
  };

  handleUpdateSpotlight = (active, event) => {
    const { onUpdateSpotlight } = this.props
    onUpdateSpotlight(event.id, active ? 1 : 0)
  }
  handleShowBookClosingTimes = () => {
    this.setState({
      showBookClosingTimes: !this.state.showBookClosingTimes,
    });
  };

  handleHostSelect = host => {
    const nameValue = host.split(';')
    if (['subclub', 'member'].includes(nameValue[0])) {
      this.setState({
        hostSelected: null,
        host: null,
      })
    } else if (nameValue[0] === 'Other') {
      this.setState({
        hostSelected: nameValue[0],
        host: '',
        hostId: null
      })
    } else if (nameValue[0] === 'Groundfloor') {
      this.setState({
        hostSelected: nameValue[0],
        host: nameValue[0],
        hostId: null
      })
    } else {
      this.setState({
        hostSelected: nameValue[0],
        host: nameValue[0],
        hostId: nameValue[1]
      })
    }
  }

  handleLocationSelect = location => {
    if (location.value === 'Other') {
      this.setState({
        locationSelected: location,
        location: ''
      })
    } else {
      this.setState({
        location: location.value,
        locationSelected: location,
        locationError: false
      })
    }
  }

  handleClubSelect = club => {
    this.setState({
      clubSelected: club,
      activeClub: club.value
    })
  }

  editFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => {
          this.handleEventClick(row)
          this.setState({
            viewEvent: false
          })
        }}
        color="info"
      >
        Edit
      </Button>
    );
  };

  viewFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => {
          this.handleEventClick(row)
          this.setState({
            viewEvent: true
          })
        }}
        color="info"
      >
        View
      </Button>
    );
  };

  viewEditDraftFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => this.handleDraftClick(row)}
        color="info"
      >
        View/Edit
      </Button>
    );
  };
  deleteDraftFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => this.deleteDraftHanlder(row.id)}
        color="danger"
      >
        Delete
      </Button>
    );
  };
  publishDraftFormater = (cell, event, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => {
          this.props.onEventClone({
            id: event.id,
            userId: event.user_id,
            interests: event.interests,
            title: event.title.replace('Draft - ', ""),
            description: event.description,
            startDate: event.start_date,
            startTime: event.start_time,
            endTime: event.end_time,
            image: event.image,
            capacity: event.capacity,
            guestInvitesAllowed: event.guest_invites_allowed,
            requiresPayment: event.requires_payment,
            location: event.location,
            price: event.price,
            link: event.link,
            bookingConfirmation: event.booking_confirmation,
            clubId: event.club_id,
            allClubs: event.all_clubs,
            category: event.category,
            instructorId: event.instructor_id,
            instructorName: event.instructor_name,
            checkinRequired: event.checkin_required,
            host: event.host,
            hostId: event.host_id,
            isDraft: true
          });
          this.props.history.push("/events-create");
        }}
        color="primary"
      >
        Publish
      </Button>
    );
  };
  viewBookingsFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        onClick={() => this.props.history.push(`/bookings/${row.title?.replace(/[?/]/g, '-')}/${row.id}`)}
        color="info"
      >
        Bookings
      </Button>
    );
  };

  handleSalesEndBeforeEventSelect = salesEndBeforeEvent => {
    this.setState({ salesEndBeforeEvent });
  };


  render() {
    const bookingConfirmationSelect = [
      { label: "No link", value: "No link" },
      { label: "Learn more", value: "Learn more" },
      { label: "Purchase tickets", value: "Purchase tickets" },
      { label: "Book a time slot", value: "Book a time slot" },
    ];
    const todayDate = moment().date()

    let thisMonthScheduleDates = []

    if (todayDate >= 1 && todayDate < 15) {
      thisMonthScheduleDates = [
        { label: moment().date(15).format('ddd, DD MMM Y'), value: moment().date(15).format('YYYY-MM-DD') },
      ]
    }

    console.log(this.state.showBookClosingTimes)

    const scheduleDateSelect = [
      { label: "No date", value: null },
      ...thisMonthScheduleDates,
      { label: moment().add(1, 'month').date(1).format('ddd, DD MMM Y'), value: moment().add(1, 'month').date(1).format('YYYY-MM-DD') },
      { label: moment().add(1, 'month').date(15).format('ddd, DD MMM Y'), value: moment().add(1, 'month').date(15).format('YYYY-MM-DD') },
      { label: moment().add(2, 'month').date(1).format('ddd, DD MMM Y'), value: moment().add(2, 'month').date(1).format('YYYY-MM-DD') },
      { label: moment().add(2, 'month').date(15).format('ddd, DD MMM Y'), value: moment().add(2, 'month').date(15).format('YYYY-MM-DD') },
      { label: moment().add(3, 'month').date(1).format('ddd, DD MMM Y'), value: moment().add(3, 'month').date(1).format('YYYY-MM-DD') },
      { label: moment().add(3, 'month').date(15).format('ddd, DD MMM Y'), value: moment().add(3, 'month').date(15).format('YYYY-MM-DD') },
    ]

    const { events, clubs, history, onEventClone, loadingEvents } = this.props;
    const { viewEvent, cancelEventModal, activeClub, sendNotificationModal, interestsSelected, interestsFiltered } = this.state;
    const { onUpdateEvent } = this.props;
    const event = this.state.event;
    const { SearchBar } = Search;
    const role = localStorage.getItem("roleId");
    const userId = localStorage.getItem("userId");

    const salesEndSelect = [
      { label: "2 hours before the event", value: 2 },
      { label: "12 hours before the event", value: 12 },
      { label: "24 hours before the event", value: 24 },
      { label: "48 hours before the event", value: 48 },
    ]

    const locationSelect = [
      { label: "Lounge", value: 'Lounge' },
      { label: "Library", value: 'Library' },
      { label: "Kitchen", value: 'Kitchen' },
      { label: "Wellness Studio", value: 'Wellness Studio' },
      { label: "Patio", value: 'Patio' },
      { label: "Banana Yoga - 376 40th Street, Oakland", value: 'Banana Yoga - 376 40th Street, Oakland' },
      { label: "835 4th St Suite B, San Rafael", value: '835 4th St Suite B, San Rafael' },
      { label: "3976 Piedmont Ave", value: "3976 Piedmont Ave" },
      { label: "2973 16th St 5th Floor", value: '2973 16th St 5th Floor' },
      { label: "239 3rd St, San Rafael, CA 94901", value: '239 3rd St, San Rafael, CA 94901' },
      { label: "Other", value: 'Other' },
    ]

    const eventsColumns = [
      {
        dataField: "image",
        text: "#",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <>
            <img src={event.image} alt="" className="avatar-sm" />
          </>
        ),
      },
      {
        text: "Events",
        dataField: "events",
        // eslint-disable-next-line react/display-name
        filterValue: (_, event) => event?.title + event?.description,
        formatter: (_, event) => (
          <div style={{
            width: 300
          }}>
            <h5
              style={{
                width: 300,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              className="font-size-14">

              {event.title}
            </h5>
            <p
              style={{
                width: 300,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              className="text-muted mb-0"
            >

              {event.description}
            </p>
          </div>
        ),
      },
      {
        text: "Spotlight",
        dataField: "events",
        formatter: (_, event) => (
          <Switch parentFunc={(active) => this.handleUpdateSpotlight(active, event)} active={event.spotlight} />
        ),
      },
      {
        text: "View",
        dataField: "",
        formatter: this.viewFormater
      },
      {
        text: "Edit",
        dataField: "",
        formatter: this.editFormater
      },
      {
        text: "Bookings",
        dataField: "",
        formatter: this.viewBookingsFormater
      },
      {
        text: "Action",
        dataField: "",
        formatter: (_, event) => (
          <UncontrolledDropdown>
            <DropdownToggle className="card-drop" tag="a">
              <i className="mdi mdi-dots-horizontal font-size-18" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              {(() => {
                if (role == 1 || role == 666 || role === 3) {
                  return (
                    <DropdownItem
                      href="#"
                      onClick={() => this.onClickSendNotification(event)}
                    >
                      <i className="mdi mdi-send font-size-16 text-danger me-1" />{" "}
                      Send push notification
                    </DropdownItem>
                  );
                }
              })()}
              <DropdownItem
                onClick={() => this.onQrDownload(event)}
                value={this.state.qrCodeText}
              >
                <i className="mdi mdi-qrcode font-size-16 text-danger me-1" />{" "}
                View Event QR
              </DropdownItem>
              <DropdownItem
                onClick={() => this.copyEventDeeplink(event.id)}
              >
                <i className="mdi mdi-clipboard font-size-16 text-danger me-1" />{" "}
                Event Deeplink
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  onEventClone(event);
                  history.push("/events-create");
                }}
              >
                <i className="mdi mdi-clipboard-file font-size-16 text-danger me-1" />{" "}
                Clone Event
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => this.onClickCancelEvent(event)}
              >
                <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                Cancel Event
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
      },
      {
        text: "Event start",
        dataField: "startDate",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <>

            {this.handleValidDate(event.startDate)}
            <p style={{ marginTop: 10 }}>
              {moment(event.startTime, "HH:mm:ss").format("hh:mmA")} -{' '}
              {moment(event.endTime, "HH:mm:ss").format("hh:mmA")}
            </p>
          </>
        ),
      },
      {
        text: "Sales end",
        dataField: "startTime",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <>
            {event.salesEnd !== null && <div
            >
              {moment.utc(event.salesEnd).format('ddd, DD MMM Y')}
              <p style={{ marginTop: 10 }}>
                {moment.utc(event.salesEnd).format("hh:mmA")}
              </p>
            </div>}
          </>
        )
      },
      {
        dataField: "bookingsCount",
        text: "Total Bookings",
        formatter: (_, event) => (
          <>
            <p className="text-muted mb-0">
              {event.bookingsCount}
            </p>
          </>
        ),
      },
      {
        dataField: "capacity",
        text: "Capacity",
        formatter: (_, event) => (
          <>
            <p className="text-muted mb-0">
              {event.capacity}
            </p>
          </>
        ),
      },
      {
        dataField: "price",
        text: "Price",
        formatter: (_, event) => (
          <>
            <p className="text-muted mb-0">
              {event.price}$
            </p>
          </>
        ),
      },

    ];

    const draftsColumns = [
      {
        dataField: "image",
        text: "#",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <>
            <img src={event.image} alt="" className="avatar-sm" />
          </>
        ),
      },
      {
        text: "Events",
        dataField: "events",
        // eslint-disable-next-line react/display-name
        filterValue: (_, event) => event?.title + event?.description,
        formatter: (_, event) => (
          <>
            <h5 className="font-size-14">

              {event.title}
            </h5>
            <p
              style={{
                width: 300,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              className="text-muted mb-0"
            >

              {event.description}
            </p>
          </>
        ),
      },
      {
        text: "",
        dataField: "start_date",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            {event.publish_date && <Badge
              className={"font-size-12 badge-soft-" + "success"}
              color={'success'}
              pill
            >
              <span style={{ color: 'white' }}>Scheduled</span>

            </Badge>}
            {event.publish_date ? this.handleValidDate(event.publish_date) : ''}
          </div>
        ),
      },
      {
        text: "View/Edit draft",
        dataField: "",
        formatter: this.viewEditDraftFormater
      },
      {
        text: "Publish draft",
        dataField: "",
        formatter: this.publishDraftFormater
      },
      {
        text: "Delete draft",
        dataField: "",
        formatter: this.deleteDraftFormater
      },
      {
        text: "Event start",
        dataField: "start_date",
        // eslint-disable-next-line react/display-name
        formatter: (_, event) => (
          <>
            {this.handleValidDate(event.start_date)}
            <p style={{ marginTop: 10 }}>
              {moment(event.start_time, "HH:mm:ss").format("hh:mmA")} -{' '}
              {moment(event.end_time, "HH:mm:ss").format("hh:mmA")}
            </p>
          </>
        ),
      },
      {
        dataField: "capacity",
        text: "Capacity",
        formatter: (_, event) => (
          <>
            <p className="text-muted mb-0">
              {event.capacity}
            </p>
          </>
        ),
      },
      {
        dataField: "price",
        text: "Price",
        formatter: (_, event) => (
          <>
            <p className="text-muted mb-0">
              {event.price}$
            </p>
          </>
        ),
      },

    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: events?.filter(item => item.category === this.state.eventCategory)?.length,
      custom: true,
    };
    const drafsPageOptions = {
      sizePerPage: 10,
      totalSize: this.props.draftsByUserId.length,
      custom: true,
    };

    return (
      <React.Fragment>
        <QRCode
          id="eventQRCode"
          style={{ width: 100 }}
          value={this.state.qrCodeText}
          hidden={true}
        />
        <DeleteModal
          cancel
          show={cancelEventModal}
          onDeleteClick={this.handleCancelEvent}
          onCloseClick={() => this.setState({ cancelEventModal: false })}
        />
        <SendNotificationModal
          show={sendNotificationModal}
          onSendClick={this.handleSendNotification}
          onCloseClick={() => this.setState({ sendNotificationModal: false })}
        />
        <DefaultModal
          title={'Are you sure you want to delete this draft?'}
          description={'You will not be able to revert this!'}
          show={this.state.deleteDraftModal}
          onSendClick={this.deleteDraft}
          onCloseClick={() => this.setState({ deleteDraftModal: false })}
        />
        <DefaultModal
          title={'Please attach an image?'}
          description={''}
          show={this.state.imageError}
          hideYes
          noLabel={'Ok'}
          onSendClick={this.deleteDraft}
          onCloseClick={() => this.setState({ imageError: false })}
        />

        <div className="page-content">
          <MetaTags>
            <title>Events List | Groundfloor</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Events" breadcrumbItem="Events List" />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Card>
                      <CardBody>
                        {this.state.isMobile ?
                          <Row>
                            <Col lg="1">
                              <div style={{
                                marginBottom: 10
                              }}>
                                <Link to="/events-create">
                                  <Button
                                    // color="#032224"
                                    style={{ backgroundColor: "#032224" }}
                                    className="font-16 btn-block btn btn-primary"
                                  >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Create Event
                                  </Button>
                                </Link>
                              </div>
                              <Select
                                styles={{
                                }}
                                onChange={
                                  this.handleClubSelect
                                }
                                options={this.state.clubsOptions}
                                value={this.state.clubSelected}
                                classNamePrefix="select2-selection"
                              />
                            </Col>
                          </Row>
                          :
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Nav
                              pills
                              className="bg-light rounded"
                              role="tablist"
                            >
                              {clubs?.map((item, index) => (
                                <NavItem key={index}>
                                  <NavLink
                                    className={classnames({
                                      active: activeClub === item.id,
                                    })}
                                    style={{
                                      backgroundColor:
                                        activeClub === item.id
                                          ? "#032224"
                                          : "transparent",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        activeClub: item.id,
                                      });
                                    }}
                                  >
                                    {item.name}
                                  </NavLink>
                                </NavItem>
                              ))}
                            </Nav>
                            <div className="text-sm-end">
                              <Link to="/events-create">
                                <Button
                                  // color="#032224"
                                  style={{ backgroundColor: "#032224" }}
                                  className="font-16 btn-block btn btn-primary"
                                >
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                  Create Event
                                </Button>
                              </Link>
                            </div>
                          </div>}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 10 }}>
                          <div className="text-sm-end" >
                            <Button
                              style={{ backgroundColor: this.state.eventCategory === CategoryEnum.event ? "#032224" : '#F0F2F6', color: this.state.eventCategory === CategoryEnum.event ? "#fff" : "#042225" }}
                              className="font-16 btn-block btn btn-primary"
                              color={'#032224'}
                              onClick={() => this.setState({
                                eventCategory: CategoryEnum.event
                              })}
                            >
                              Events
                            </Button>
                          </div>
                          <div className="text-sm-end" >
                            <Button
                              style={{ backgroundColor: this.state.eventCategory === CategoryEnum.wellness ? "#032224" : '#F0F2F6', color: this.state.eventCategory === CategoryEnum.wellness ? "#fff" : "#042225", marginRight: 10 }}
                              className="font-16 btn-block btn btn-primary"
                              color={'#032224'}
                              onClick={() => this.setState({
                                eventCategory: CategoryEnum.wellness
                              })}
                            >
                              Wellness
                            </Button>
                          </div>
                          {this.props.draftsByUserId?.length > 0 && <div className="text-sm-end" >
                            <Button
                              style={{ backgroundColor: this.state.eventCategory === CategoryEnum.drafts ? "#032224" : '#F0F2F6', color: this.state.eventCategory === CategoryEnum.drafts ? "#fff" : "#042225", marginRight: 10 }}
                              className="font-16 btn-block btn btn-primary"
                              color={'#032224'}
                              onClick={() => this.setState({
                                eventCategory: CategoryEnum.drafts
                              })}
                            >
                              Drafts
                            </Button>
                          </div>}
                        </div>
                        {events && this.state.eventCategory !== CategoryEnum.drafts && (
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            columns={eventsColumns}
                            data={events.filter(item => item.category === this.state.eventCategory)}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={eventsColumns}
                                data={events.filter(item => item.category === this.state.eventCategory)}
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        style={{ marginTop: 10 }}
                                      />
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          {loadingEvents ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Link
                                                to="#"
                                                className="text-success"
                                              >
                                                <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                Loading
                                              </Link>{" "}
                                            </div>
                                          ) : (
                                            <BootstrapTable
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                            />
                                          )}
                                        </div>
                                      </Col>
                                      {/* </Link> */}
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )

                        }
                        {
                          this.state.eventCategory === CategoryEnum.drafts && (
                            <PaginationProvider
                              pagination={paginationFactory(drafsPageOptions)}
                              columns={draftsColumns}
                              data={this.props.draftsByUserId}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="id"
                                  columns={draftsColumns}
                                  data={this.props.draftsByUserId}
                                  search
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row>
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                          style={{ marginTop: 10 }}
                                        />
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            {loadingEvents ? (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="text-success"
                                                >
                                                  <i className="bx bx-loader bx-spin font-size-24 align-middle me-2" />{" "}
                                                  Loading
                                                </Link>{" "}
                                              </div>
                                            ) : (
                                              <BootstrapTable
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                classes={
                                                  "table align-middle table-nowrap"
                                                }
                                                headerWrapperClasses={
                                                  "thead-light"
                                                }
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                              />
                                            )}
                                          </div>
                                        </Col>
                                        {/* </Link> */}
                                      </Row>

                                      <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                          <div className="d-inline">
                                            <SizePerPageDropdownStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                          <div className="text-md-right ms-auto">
                                            <PaginationListStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          )
                        }
                      </CardBody>
                    </Card>
                    <Modal
                      size="xl"
                      isOpen={this.state.modal}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {`${viewEvent ? 'View' : 'Edit'} ${this.state.isDraft ? `draft` : `event`}`}
                      </ModalHeader>
                      <ModalBody>
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            id: (event && event.id) || "",
                            title: (event && event.title.replace('Draft - ', '')) || "",
                            description: (event && event.description) || "",
                            startDate:
                              (event &&
                                moment(event?.startDate)
                                  .utc()
                                  .format("YYYY-MM-DD")) ||
                              "",
                            capacity: event && event.capacity,
                            guestInvitesAllowed:
                              event && event.guestInvitesAllowed,
                            link: (event && event.link) || "",
                            bookingConfirmation:
                              (event && event.bookingConfirmation) || "",
                            checkinRequired:
                              (event && event.checkinRequired) || 0,
                            category: (event && event.category) || "",
                            instructorId: (event && event.instructorId) || "",
                            clubId: (event && event.clubId) || "",
                            instructorName:
                              (event && event.instructorName) || "",
                            club:
                              event && this.props.clubs
                                ? this.props.clubs.find(
                                  item => item.id === event.clubId
                                )?.name
                                : "",
                            price: event && event.price,
                            startTime: (event && event.startTime) || "",
                            endTime: (event && event.endTime) || "",

                          }}
                          validationSchema={Yup.object().shape(
                            this.state.isDraft && this.state.scheduleDate.value ?
                              {
                                title: Yup.string().trim('Please Enter Title').required("Please Enter Title"),
                                description: Yup.string().trim('Please Enter Description').required(
                                  "Please Enter Description"
                                ),
                                startDate: Yup.string().required(
                                  "Please Enter Start Date"
                                ),
                                startTime: Yup.string().required(
                                  "Please Enter Start Time"
                                ),
                                endTime: Yup.string().required(
                                  "Please Enter End Time"
                                ),
                                capacity: Yup.number()
                                  .min(1, "Capacity can not be 0")
                                  .typeError("The amount invalid")
                                  .required("The amount is required"),
                                guestInvitesAllowed: Yup.number().required(
                                  "Please Enter Guest Invite Allowed"
                                ).typeError("It must be a number"),
                                price: Yup.number().required("Please Enter Price")
                                  .typeError("It must be a number")
                                  .test(
                                    'Is positive?',
                                    'ERROR: The number must be greater than or equal to 0!',
                                    (value) => {
                                      return (value) => value === undefined || value === null || value >= 0
                                    }
                                  ),
                              }
                              :
                              {
                                title: !this.state.isDraft && Yup.string().required("Please Enter Title"),
                                description: !this.state.isDraft && Yup.string().required(
                                  "Please Enter Description"
                                ),
                                startDate: !this.state.isDraft && Yup.string().required(
                                  "Please Enter Start Date"
                                ),
                                startTime: !this.state.isDraft && Yup.string().required(
                                  "Please Enter Start Time"
                                ),
                                endTime: !this.state.isDraft && Yup.string().required(
                                  "Please Enter End Time"
                                ),
                                capacity: !this.state.isDraft && Yup.number()
                                  .min(1, "Capacity can not be 0")
                                  .typeError("The amount invalid")
                                  .required("The amount is required"),
                                guestInvitesAllowed: Yup.number().required(
                                  "Please Enter Guest Invite Allowed"
                                ).typeError("It must be a number"),
                                price: !this.state.isDraft && Yup.number().required("Please Enter Price")
                                  .typeError("It must be a number").test(
                                    'Is positive?',
                                    'ERROR: The number must be greater than or equal to 0!',
                                    (value) => {
                                      return (value) => value === undefined || value === null || value >= 0
                                    }
                                  ),
                              }
                          )}
                          onSubmit={values => {
                            if (this.state.isDraft) {
                              let startDate = values.startDate
                              let startTime = values.startTime

                              const startDateAndTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm')
                              let salesEndDateAndTime = startDateAndTime

                              if (this.state.showBookClosingTimes) {
                                salesEndDateAndTime = startDateAndTime.subtract(this.state.salesEndBeforeEvent.value, 'hours')
                              }

                              const startTimeToSave = values.startTime !== '' ? moment(
                                values.startTime,
                                "hh:mm A"
                              ).format("HH:mm:ss") : moment().format("hh:mm A")

                              const endTime = values.endTime !== '' ? moment(
                                values.endTime,
                                "hh:mm A"
                              ).format("HH:mm:ss") : moment().format("hh:mm A")

                              const startDateToSave = values.startDate !== '' ? moment(values.startDate).format(
                                "YYYY-MM-DD"
                              ) : moment().format("YYYY-MM-DD")

                              if (this.state.scheduleDate.value) {
                                if (this.state.clubId === null) {
                                  this.setState({
                                    clubError: true,
                                  });
                                }
                                if (this.state.interestsSelected?.length === 0 || this.state.interestsSelected === undefined) {
                                  this.setState({
                                    interestsError: true
                                  })
                                }
                                if (!this.props.image) {
                                  this.setState({
                                    imageError: true
                                  })
                                }
                                if (!this.state.interestsError && !this.state.clubError && this.props.image) {
                                  this.props.onUpdateDraft({
                                    id: event.id,
                                    title: values.title,
                                    description: values.description,
                                    startTime: startTimeToSave,
                                    endTime,
                                    capacity: values.capacity,
                                    guestInvitesAllowed:
                                      this.state.guestInvitesAllowed,
                                    price: this.state.price,
                                    location: this.state.location,
                                    host: this.state.host,
                                    clubId: parseInt(this.state.clubId),
                                    hostId: this.state.hostId === null ? '' : this.state.hostId,
                                    interests: this.state.interestsSelected
                                      .map(item => item.value)
                                      .join(";"),
                                    startDate: startDateToSave,
                                    requiresPayment: this.state.price > 0 ? 1 : 0,
                                    link: this.state.bookingConfirmation.value === 'No link' ? "" : values.link,
                                    bookingConfirmation: this.state.bookingConfirmation.label,
                                    allClubs: this.state.allClubsEvent ? 1 : 0,
                                    category: this.state.category,
                                    instructorId: this.state.instructorId,
                                    instructorName: this.state.instructorName,
                                    checkinRequired: this.state.checkinRequired ? 1 : 0,
                                    spotlight: '',
                                    salesEnd: moment(
                                      salesEndDateAndTime
                                    ).format("YYYY-MM-DD HH:mm:ss"),
                                    suggestEventId: '',
                                    publishDate: this.state.scheduleDate.value
                                  })
                                }

                              } else {
                                this.props.onUpdateDraft({
                                  id: event.id,
                                  title: values.title,
                                  description: values.description,
                                  startTime: startTimeToSave,
                                  endTime,
                                  capacity: values.capacity,
                                  guestInvitesAllowed:
                                    this.state.guestInvitesAllowed,
                                  price: this.state.price,
                                  location: this.state.location,
                                  host: this.state.host,
                                  clubId: parseInt(this.state.clubId),
                                  hostId: this.state.hostId === null ? '' : this.state.hostId,
                                  interests: this.state.interestsSelected
                                    .map(item => item.value)
                                    .join(";"),
                                  startDate: startDateToSave,
                                  requiresPayment: this.state.price > 0 ? 1 : 0,
                                  link: this.state.bookingConfirmation.value === 'No link' ? "" : values.link,
                                  bookingConfirmation: this.state.bookingConfirmation.label,
                                  allClubs: this.state.allClubsEvent ? 1 : 0,
                                  category: this.state.category,
                                  instructorId: this.state.instructorId,
                                  instructorName: this.state.instructorName,
                                  checkinRequired: this.state.checkinRequired ? 1 : 0,
                                  spotlight: '',
                                  salesEnd: moment(
                                    salesEndDateAndTime
                                  ).format("YYYY-MM-DD HH:mm:ss"),
                                  suggestEventId: '',
                                  publishDate: this.state.scheduleDate.value
                                })
                              }



                              setTimeout(() => {
                                this.props.onGetDrafts()
                              }, 800)
                            } else {

                              let startDate = values.startDate
                              let startTime = values.startTime

                              const startDateAndTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm')
                              let salesEndDateAndTime = startDateAndTime

                              if (this.state.showBookClosingTimes) {
                                salesEndDateAndTime = startDateAndTime.subtract(this.state.salesEndBeforeEvent.value, 'hours')
                              }

                              if (this.state.clubId === null) {
                                this.setState({
                                  clubError: true,
                                });
                              }
                              if (this.state.interestsSelected?.length === 0 || this.state.interestsSelected === undefined) {
                                this.setState({
                                  interestsError: true
                                })
                              }
                              if (!this.state.interestsError && !this.state.clubError) {
                                const updateEvent = {
                                  title: values.title,
                                  description: values.description,
                                  startTime: startTime,
                                  endTime: values.endTime,
                                  salesEnd: moment(
                                    salesEndDateAndTime
                                  ).format("YYYY-MM-DD HH:mm:ss"),
                                  capacity: values.capacity,
                                  guestInvitesAllowed:
                                    this.state.guestInvitesAllowed,
                                  price: this.state.price,
                                  link: this.state.bookingConfirmation.value === 'No link' ? "" : values.link,
                                  location: this.state.location,
                                  bookingConfirmation:
                                    this.state.bookingConfirmation.label,
                                  host: this.state.host,
                                  category: this.state.category,
                                  instructorId: this.state.instructorId,
                                  clubId: parseInt(this.state.clubId),
                                  checkinRequired: this.state.checkinRequired ? 1 : 0,
                                  allClubs: this.state.allClubsEvent ? 1 : 0,
                                  instructorName: this.state.instructorName,
                                  requiresPayment: this.state.price > 0 ? 1 : 0,
                                  id: event.id,
                                  hostId: this.state.hostId === null ? '' : this.state.hostId,
                                  interests: this.state.interestsSelected
                                    .map(item => item.value)
                                    .join(";"),
                                  image: this.state?.selectedFiles[0],
                                  startDate: moment(values.startDate).format(
                                    "YYYY-MM-DD"
                                  ),
                                };
                                onUpdateEvent(updateEvent, this.state.clubId);
                              }

                            }
                            this.setState({ selectedEvent: null });
                            this.toggle();
                          }}
                        >
                          {({ errors, status, touched }) => (
                            <Form>
                              <Row>
                                <h4 className="mb-4">Basic Info</h4>
                                <Row className="mb-4">
                                  <Col lg='10'>
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Title
                                    </Label>
                                    <Field
                                      name="title"
                                      placeholder="Title"
                                      type="text"
                                      disabled={viewEvent}
                                      className={
                                        "form-control" +
                                        (errors.title && touched.title
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="title"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg='10'>
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Description
                                    </Label>
                                    <Field
                                      name="description"
                                      placeholder="Description"
                                      type="text"
                                      component="textarea"
                                      disabled={viewEvent}
                                      rows="6"
                                      className={
                                        "form-control" +
                                        (errors.description &&
                                          touched.description
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="description"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg="10">
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Host
                                    </Label>
                                    <TreeSelect
                                      showSearch
                                      disabled={viewEvent}
                                      style={{ width: "100%" }}
                                      value={this.state.hostSelected}
                                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                      placeholder="Please select host"
                                      allowClear
                                      treeDefaultExpandAll
                                      onChange={this.handleHostSelect}
                                      treeData={this.state.treeData}
                                    />
                                    {this.state.hostSelected === 'Other' && <Field
                                      type="text"
                                      value={this.state.host}
                                      style={{ marginTop: 10 }}
                                      onChange={e =>
                                        this.setState({
                                          host: e.target.value,
                                        })}
                                      className={
                                        "form-control"
                                      }
                                    />}
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg='10'>
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Capacity
                                    </Label>
                                    <Field
                                      name="capacity"
                                      type="number"
                                      disabled={viewEvent}
                                      placeholder="Capacity"
                                      className={
                                        "form-control" +
                                        (errors.capacity && touched.capacity
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />

                                    <ErrorMessage
                                      name="capacity"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg="10">
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Guest Invites Allowed
                                    </Label>
                                    <Field
                                      name="guestInvitesAllowed"
                                      value={this.state.guestInvitesAllowed}
                                      disabled={viewEvent}
                                      placeholder='Guest Invites Allowed'
                                      onChange={e =>
                                        this.setState({
                                          guestInvitesAllowed: e.target.value,
                                        })
                                      }
                                      type="number"
                                      className={
                                        "form-control" +
                                        (errors.guestInvitesAllowed &&
                                          touched.guestInvitesAllowed
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="guestInvitesAllowed"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col lg="10">
                                    <Label
                                      className="col-form-label col-lg-2"
                                    >
                                      Price
                                    </Label>
                                    <Field
                                      name="price"
                                      disabled={viewEvent}
                                      value={this.state.price}
                                      placeholder="Price"
                                      onChange={e =>
                                        this.setState({
                                          price: e.target.value,
                                        })
                                      }
                                      type="number"
                                      className={
                                        "form-control" +
                                        (errors.price && touched.price
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="price"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <hr />
                                <h4 className="mt-4 mb-4">Location</h4>
                                <Row className="mb-4">
                                  <Label
                                    className="col-form-label col-lg-2"
                                  >
                                    Select Club
                                  </Label>
                                  <Col lg="10">
                                    <Dropdown
                                      isOpen={this.state.dropdownClubOpen}
                                      toggle={this.toggleClubs}
                                    >
                                      <DropdownToggle caret>
                                        {this.state.club}
                                      </DropdownToggle>
                                      {!viewEvent ? <DropdownMenu>
                                        {this.props.clubs?.map((club, index) => {
                                          return (
                                            <DropdownItem
                                              onClick={() =>
                                                this.clubItemSelected(
                                                  club.name,
                                                  club.id
                                                )
                                              }
                                              key={index}
                                            >
                                              {club.name}
                                            </DropdownItem>
                                          );
                                        })}
                                      </DropdownMenu> : null}
                                    </Dropdown>
                                    {this.state.clubError && (
                                      <p
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        Please Select a Club
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Label
                                    className="col-form-label col-lg-2"
                                  >
                                    All Clubs Event
                                  </Label>
                                  <Col lg="10">
                                    <Switch
                                      disabled={viewEvent}
                                      active={this.state.allClubsEvent} parentFunc={this.handleAllClubsEvent} />
                                    <p style={{ fontSize: 12, fontWeight: '400', marginTop: 10 }}>Turn this on only if you want this event to appear for all locations!</p>
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Label
                                    className="col-form-label col-lg-2"
                                  >
                                    Location
                                  </Label>
                                  <Col lg="10">
                                    <Select
                                      onChange={
                                        this.handleLocationSelect
                                      }
                                      options={locationSelect}
                                      value={this.state.locationSelected}
                                      classNamePrefix="select2-selection"
                                      disabled={viewEvent}

                                    />
                                    {this.state.locationSelected.value === 'Other' && <Field
                                      type="text"
                                      value={this.state.location}
                                      style={{ marginTop: 10 }}
                                      disabled={viewEvent}

                                      onChange={e =>
                                        this.setState({
                                          location: e.target.value,
                                          locationError: false
                                        })}
                                      className={
                                        "form-control"
                                      }
                                    />}
                                    {this.state.locationError && (
                                      <p
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        Please Select a Location
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <hr />
                                <h4 className="mt-4 mb-5">Date and time</h4>
                                <Row className="mb-4">
                                  <Label
                                    htmlFor="startDate"
                                    className="col-form-label col-lg-2"
                                  >
                                    Start Date and Time
                                  </Label>
                                  <Col lg="10">
                                    <Row className="mb-2">
                                      <Col lg="6">
                                        <Field
                                          name="startDate"
                                          type="date"
                                          disabled={viewEvent}

                                          className={
                                            "form-control" +
                                            (errors.startDate && touched.startDate
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="startDate"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <Field
                                          name="startTime"
                                          type="time"
                                          format="HH:mm"
                                          disabled={viewEvent}

                                          className={
                                            "form-control" +
                                            (errors.startTime && touched.startTime
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="startTime"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Label className="col-form-label col-lg-2">
                                    End Time
                                  </Label>
                                  <Col lg="10">
                                    <Col lg="6">
                                      <Field
                                        name="endTime"
                                        type="time"
                                        disabled={viewEvent}

                                        className={
                                          "form-control" +
                                          (errors.endTime && touched.endTime
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="endTime"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Label
                                    className="col-form-label col-lg-2"
                                  >
                                    Final Attendee Update Deadline
                                  </Label>
                                  <Col lg="10">
                                    <div style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: 10
                                    }}>
                                      <span style={{ marginRight: 10 }}>OFF</span>
                                      <Switch
                                        disabled={viewEvent}

                                        active={this.state.showBookClosingTimes} parentFunc={this.handleShowBookClosingTimes} />
                                      <span style={{ marginLeft: 10 }}>ON</span>
                                    </div>
                                    <span>{`Set this as the cutoff for all attendee list changes, including the waitlist. No bookings or waitlist updates will occur after this time.`}</span>
                                  </Col>

                                </Row>
                                {this.state.showBookClosingTimes && <Row className="mt-5 mb-4">
                                  <Label
                                    htmlFor="bookingConfirmation"
                                    className="col-form-label col-lg-2"
                                  >
                                    Select sales end
                                  </Label>
                                  <Col lg="8">
                                    <Select
                                      onChange={
                                        this.handleSalesEndBeforeEventSelect
                                      }
                                      disabled={viewEvent}

                                      value={this.state.salesEndBeforeEvent}
                                      options={salesEndSelect}
                                      classNamePrefix="select2-selection"
                                    />
                                  </Col>
                                </Row>}
                                <hr />
                                {this.state.isDraft && <>
                                  <h4 className="mt-5 mb-1">Schedule publish date</h4>

                                  <span>Please select a date for the event to be published automatically. This applies only to drafts.</span>

                                  <Row className="mt-5 mb-4">
                                    <Label
                                      htmlFor="bookingConfirmation"
                                      className="col-form-label col-lg-2"
                                    >
                                      Schedule date
                                    </Label>
                                    <Col lg="8">
                                      <Select
                                        onChange={
                                          this.handleScheduleDateSelect
                                        }
                                        disabled={viewEvent}

                                        value={this.state.scheduleDate}
                                        options={scheduleDateSelect}
                                        classNamePrefix="select2-selection"
                                      />
                                    </Col>
                                  </Row>

                                  <hr />
                                </>}
                                <h4 className="mt-4 mb-5">Details</h4>
                                <Row className="mb-4">
                                  <Label
                                    htmlFor="bookingConfirmation"
                                    className="col-form-label col-lg-2"
                                  >
                                    Booking Confirmation
                                  </Label>
                                  <Col lg="10">
                                    <Select
                                      onChange={
                                        this.handleBookingConfirmationSelect
                                      }
                                      disabled={viewEvent}

                                      options={bookingConfirmationSelect}
                                      defaultValue={this.state.bookingConfirmation}
                                      classNamePrefix="select2-selection"
                                    />
                                    <ErrorMessage
                                      name="bookingConfirmation"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>

                                  {this.state.bookingConfirmation.value !== 'No link' && <div className="mb-3">
                                    <Label className="form-label">Link</Label>
                                    <Field
                                      name="link"
                                      type="text"
                                      disabled={viewEvent}

                                      className={
                                        "form-control" +
                                        (errors.link && touched.link
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="link"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>}
                                </Row>
                                <Row className="mb-4">
                                  <Label
                                    htmlFor="bookingConfirmation"
                                    className="col-form-label col-lg-2"
                                  >
                                    Select Interests
                                  </Label>
                                  <Col lg="10">
                                    <p style={{ fontSize: 12, fontWeight: '400' }}>Pick all the relevant and related themes to tag the event, this will help us automate suggestions in future!</p>
                                    <Select
                                      value={interestsSelected}
                                      isMulti={true}
                                      disabled={viewEvent}

                                      onChange={this.handleMulti}
                                      options={interestsFiltered}
                                      classNamePrefix="select2-selection"
                                    />

                                    {this.state.interestsError && (
                                      <p
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        Please Select Interests
                                      </p>
                                    )}
                                  </Col>

                                </Row>
                                <Row className="mb-4">
                                  <Label
                                    htmlFor="category"
                                    className="col-form-label col-lg-2"
                                  >
                                    Category
                                  </Label>
                                  <Col lg="10">
                                    <Dropdown
                                      isOpen={this.state.dropdownOpen}
                                      toggle={this.toggleCategory}
                                    >
                                      <DropdownToggle caret>
                                        {this.state.category}
                                      </DropdownToggle>
                                      {!viewEvent ? <DropdownMenu>
                                        <DropdownItem
                                          onClick={() =>
                                            this.categoryItemSelected("Event")
                                          }
                                          category="Event"
                                        >
                                          Event
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem
                                          onClick={() =>
                                            this.categoryItemSelected(
                                              "Wellness"
                                            )
                                          }
                                          category="Wellness"
                                        >
                                          Wellnes
                                        </DropdownItem>
                                      </DropdownMenu> : null}
                                    </Dropdown>
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Label
                                    htmlFor="instructor"
                                    className="col-form-label col-lg-2"
                                  >
                                    Instructor
                                  </Label>
                                  <Col lg="10">
                                    <Dropdown
                                      isOpen={
                                        this.state.dropdownInstructorOpen
                                      }
                                      toggle={this.toggleInstructor}
                                    >
                                      <DropdownToggle caret>
                                        {this.state.instructorName}
                                      </DropdownToggle>
                                      {!viewEvent ? <DropdownMenu>
                                        {this.state.instructors.map(
                                          (instructor, index) => {
                                            return (
                                              <DropdownItem
                                                onClick={() =>
                                                  this.instructorItemSelected(
                                                    `${instructor.first_name} ${instructor.last_name}`,
                                                    `${instructor.id}`
                                                  )
                                                }
                                                key={index}
                                              >
                                                {instructor.first_name}{" "}
                                                {instructor.last_name}
                                              </DropdownItem>
                                            );
                                          }
                                        )}
                                      </DropdownMenu> : null}
                                    </Dropdown>
                                  </Col>
                                </Row>
                                {/* <Row className="mb-4">
                                  <Label
                                    htmlFor="checkinRequired"
                                    className="col-form-label col-lg-2"
                                  >
                                    Checkin Required
                                  </Label>
                                  <Col lg="10">
                                    <Switch active={this.state.checkinRequired} parentFunc={this.handleCheckinRequried} />
                                  </Col>
                                </Row> */}
                                {!viewEvent ? <FormGroup className="mb-3" row>
                                  <Label className="col-form-label col-lg-2">
                                    Attach Image
                                  </Label>
                                  <Col lg="10">
                                    <FileUpload
                                      onFileUploaded={this.onFileUploaded}
                                      image={this.state.eventImage}
                                    ></FileUpload>
                                  </Col>
                                </FormGroup> : null}

                              </Row>
                              {!viewEvent ? <Row>
                                <Col>
                                  <div className="text-end">
                                    {(() => {
                                      if (
                                        userId == event.userId ||
                                        role == 1 ||
                                        role == 666
                                      ) {
                                        return (
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            Save
                                          </button>
                                        );
                                      }
                                    })()}
                                  </div>
                                </Col>
                              </Row> : null}
                            </Form>
                          )}
                        </Formik>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

EventList.propTypes = {
  events: PropTypes.array,
  className: PropTypes.any,
  token: PropTypes.any,
  image: PropTypes.any,
  onDeleteEvent: PropTypes.func,
  onCancelEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onGetMembers: PropTypes.func,
  onSetImage: PropTypes.func,
  instructors: PropTypes.array,
  onGetEventInstructors: PropTypes.func,
  onGetEventsByClub: PropTypes.func,
  onSendNewEventNotification: PropTypes.func,
  onGetClubs: PropTypes.func,
  loadingEvents: PropTypes.bool,
  onCheckLoggedOut: PropTypes.func,
  onEventClone: PropTypes.func,
  onUpdateSpotlight: PropTypes.func,
  onGetAllChildrenCategories: PropTypes.func,
  clubs: PropTypes.array,
  draftsByUserId: PropTypes.array,
  onQrDownload: PropTypes.array,
  childrenCategories: PropTypes.array,
  members: PropTypes.array,
  onGetSubClubs: PropTypes.func,
  onGetDrafts: PropTypes.func,
  onUpdateDraft: PropTypes.func,
  onDeleteDraft: PropTypes.func,
  subClubs: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = ({ Login, events, system, members, subclubs }) => ({
  events: events.events,
  loadingEvents: events.loadingEvents,
  token: Login.token,
  instructors: events.instructors,
  childrenCategories: system.childrenCategories,
  clubs: system.clubs,
  subClubs: subclubs.subClubs,
  members: members.members,
  draftsByUserId: events.draftsByUserId,
  image: events.image
});

const mapDispatchToProps = dispatch => ({
  onGetEventInstructors: () => dispatch(getEventInstructors()),
  onGetClubs: () => dispatch(getClubs()),
  onUpdateEvent: (event, clubId) => dispatch(updateEvent(event, clubId)),
  onDeleteEvent: (id, clubId) => dispatch(deleteEvent(id, clubId)),
  onCancelEvent: (id, clubId) => dispatch(cancelEvent(id, clubId)),
  onGetEventsByClub: id => dispatch(getEventsByClub(id)),
  onSetImage: image => dispatch(setImage(image)),
  onSendNewEventNotification: data => dispatch(sendNewEventNotification(data)),
  onCheckLoggedOut: () => dispatch(checkLoggedOut()),
  onUpdateSpotlight: (eventId, spotlight) => dispatch(updateSpotlight(eventId, spotlight)),
  onEventClone: event => dispatch(cloneEvent(event)),
  onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),
  onGetMembers: clubId => dispatch(getMembers(clubId)),
  onGetSubClubs: () => dispatch(getSubClubs()),
  onGetDrafts: () => dispatch(getDraftEventsByUserId()),
  onUpdateDraft: (event, history) => dispatch(updateDraft(event, history)),
  onDeleteDraft: (id) => dispatch(deleteDraft(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventList));
