import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as moment from "moment";
import classnames from "classnames";

import {
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    FormGroup,
    Button,
    Badge,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TreeSelect } from "antd";
import Switch from "components/Common/Switch";
import Select from "react-select";



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import {
    addEvent,
    deleteSuggestedEvent,
    getAllChildrenCategories,
    getClubs,
    getEventInstructors,
    getMembers,
    getSubClubs,
    getSuggestedEventsByClubId,
    setImage,
    updateSuggestedEvent,
} from "store/actions";
import FileUpload from "components/FileUpload/FileUpload";
import DefaultModal from "components/Common/DefaultModal";

const CategoryEnum = {
    event: 'event',
    wellness: 'wellness'
}

class SuggestEventListByClub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event: {},
            activeClub: null,
            instructors: [],
            modal: false,
            treeData: [],
            selectedFiles: [],
            showBookClosingTimes: false,
            clubId: null,
            club: "No Club Selected",
            clubError: false,
            dropdownClubOpen: false,
            guestInvitesAllowed: 0,
            requiresPayment: false,
            checkinRequired: false,
            allClubsEvent: false,
            bookingConfirmation: { label: "", value: "" },
            instructorName: 'No instructor',
            price: 0,
            dropdownOpen: false,
            interestsSelected: [],
            interestsFiltered: [],
            interestsError: false,
            locationSelected: { label: "Select Location", value: '' },
            location: '',
            category: '',
            hostSelected: null,
            image: '',
            host: null,
            hostId: null,
            eventCategory: CategoryEnum.event,
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
            clubSelected: { label: "No Club Selected", value: '' },
            clubsOptions: [],
            modalType: null,
            discardEventModal: false,
            eventToDeleteId: null,
            scheduleDate: { label: "No date", value: null },
            salesEndBeforeEvent: { label: "2 hours before the event", value: 2 },


        };
        this.handleEventClick = this.handleEventClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleClubs = this.toggleClubs.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
        this.toggleCategory = this.toggleCategory.bind(this);

    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    toggleCategory() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    filterCategories = id => {
        const { childrenCategories } = this.props;
        return childrenCategories
            ?.filter(item => item.system_category_id === id)
            .map(item => ({ label: item.name, value: item.name }));
    };

    componentDidMount() {
        const {
            onGetClubs,
            onGetEventInstructors,
            onGetAllChildrenCategories,
            onGetSubClubs,
            clubs

        } = this.props;
        const authUser = localStorage.getItem("authUser")
        if (JSON.parse(authUser)?.clubId) {
            this.setState({
                activeClub: JSON.parse(authUser).clubId
            })
        }
        if (clubs.length !== 0) {
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item?.id })),
                    clubSelected: { label: club.name, value: club?.id }
                })
            }
        } else {
            onGetClubs();
        }
        onGetSubClubs()
        onGetEventInstructors();
        onGetAllChildrenCategories();
    }

    toggleClubs() {
        this.setState(prevState => ({
            dropdownClubOpen: !prevState.dropdownClubOpen,
        }));
    }

    categoryItemSelected(value) {
        this.setState({
            category: value,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeClub } = this.state;
        const { members, onGetMembers, onGetSuggestedEventsByClubId, childrenCategories } = this.props

        if (this.props.clubs !== prevProps.clubs) {
            onGetMembers(this.props.clubs.map(item => item?.id).join(','))
            const authUser = localStorage.getItem("authUser")
            const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
            if (club) {
                this.setState({
                    clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item?.id })),
                    clubSelected: { label: club.name, value: club?.id }
                })
            }
        }
        if (activeClub !== prevState.activeClub) {
            if (activeClub) {
                onGetSuggestedEventsByClubId(activeClub);
            }
        }
        if (childrenCategories !== prevProps.childrenCategories) {
            this.setState({
                interestsFiltered: [
                    {
                        label: "Interests",
                        options: [...this.filterCategories("2"), ...this.filterCategories("11")],
                    },
                ],
            });
        }
        if (members !== prevProps.members) {
            const membersSelect = this.props.clubs.map(club => ({ title: `Members ${club.name}`, value: `member;${club?.id}`, children: this.props.members.filter(member => member.main_club_id === club?.id).map(item => ({ label: `${item.first_name} ${item.last_name}`, value: `${item.first_name} ${item.last_name};${item.memberId}` })) }))
            const subClubsSelect = this.props.clubs.map(club => ({ title: `Sub clubs ${club.name}`, value: `subclub;${club?.id}`, children: this.props.subClubs.filter(item => parseInt(item.club_id) === club?.id).map(subClub => ({ title: subClub.name, value: `${subClub.name};${subClub?.id}` })) }))
            const treeData = [
                {
                    title: "Groundfloor",
                    value: "Groundfloor;Groundfloor",
                },
                ...subClubsSelect,
                ...membersSelect,
                {
                    title: "Other",
                    value: "Other;Other",
                },
            ];

            this.setState({
                treeData
            });
        }
    }

    handleAllClubsEvent = () => {
        this.setState({
            allClubsEvent: !this.state.allClubsEvent,
        });
    };

    handleLocationSelect = location => {
        if (location.value === 'Other') {
            this.setState({
                locationSelected: location,
                location: ''
            })
        } else {
            this.setState({
                location: location.value,
                locationSelected: location,
                locationError: false
            })
        }
    }

    handleMulti = interestsSelected => {
        this.setState({ interestsSelected, interestsError: false });
    };

    handleCheckinRequried = () => {
        this.setState({
            checkinRequired: !this.state.checkinRequired,
        });
    };


    handleEventClick = arg => {
        const event = arg;
        this.props.onSetImage(event.image);
        const { instructors, onGetEventInstructors } = this.props;
        onGetEventInstructors();
        this.setState({ instructors });
        this.setState({
            event: {
                id: event?.id,
                price: event.price,
                userId: event.user_id,
                description: event.description,
                capacity: event.capacity,
                guestInvitesAllowed: event.guest_invites_allowed,
                location: event.location,
                host: event.host,
                clubId: event.club_id,
                club: this.props.clubs.find(item => item?.id === event.club_id)?.name,
                startDate: event.start_date,
                startTime: event.start_time,
                endTime: event.end_time,
                title: event.title,
            },
            price: event.price,
            isEdit: true,
            hostId: event.host_id,
            host: event.host,
            hostSelected: event.host,
            category: 'Event',
            showBookClosingTimes: false,
            eventImage: event.image,
            bookingConfirmation: { label: 'No link', value: 'No link' },
            locationSelected: { label: event.location, value: event.location },
            interestsSelected: event.interests?.split(';')?.map(item => ({ label: item, value: item })),
            guestInvitesAllowed: event.guest_invites_allowed,
            location: event.location,
            clubId: event.club_id,
            club: this.props.clubs.find(item => item?.id === event.club_id)?.name,
            modalType: event.modalType,
            leaderAndClubEvents: false,
            scheduleDate: event.publish_date ? { label: moment(event.publish_date).format('ddd, DD MMM Y'), value: moment(event.publish_date).format('YYYY-MM-DD') } : { label: "No date", value: null },
        });
        this.toggle();
    };

    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    discardEvent = () => {
        this.props.onDeleteSuggestedEvent(this.state.eventToDeleteId)
        setTimeout(() => {
            this.props.onGetSuggestedEventsByClubId(this.state.activeClub)
        }, 800);
        this.setState({
            discardEventModal: false
        })
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    handleBookingConfirmationSelect = bookingConfirmation => {
        this.setState({ bookingConfirmation });
    };

    handleScheduleDateSelect = scheduleDate => {
        this.setState({ scheduleDate });
    };


    clubItemSelected(name, id) {
        this.setState({
            club: name,
            clubId: id,
            clubError: false,
        });
    }

    handleValidDate = date => {
        const date1 = moment.utc(date).format("DD MMM Y");
        return date1;
    };

    handleShowBookClosingTimes = () => {
        this.setState({
            showBookClosingTimes: !this.state.showBookClosingTimes,
        });
    };

    handleHostSelect = host => {
        const nameValue = host.split(';')
        if (['subclub', 'member'].includes(nameValue[0])) {
            this.setState({
                hostSelected: null,
                host: null,
            })
        } else if (nameValue[0] === 'Other') {
            this.setState({
                hostSelected: nameValue[0],
                host: '',
                hostId: null
            })
        } else if (nameValue[0] === 'Groundfloor') {
            this.setState({
                hostSelected: nameValue[0],
                host: nameValue[0],
                hostId: null
            })
        } else {
            this.setState({
                hostSelected: nameValue[0],
                host: nameValue[0],
                hostId: nameValue[1]
            })
        }
    }

    handleClubSelect = club => {
        this.setState({
            clubSelected: club,
            activeClub: club.value
        })
    }


    handleSalesEndBeforeEventSelect = salesEndBeforeEvent => {
        this.setState({ salesEndBeforeEvent });
    };

    render() {
        const { suggestedEventsByClubId, clubs, loadingSuggestedEventsByClubId } = this.props;
        const { interestsSelected, interestsFiltered, activeClub, event } = this.state;
        const { SearchBar } = Search;
        const role = localStorage.getItem("roleId");
        const userId = localStorage.getItem("userId");

        const todayDate = moment().date()

        let thisMonthScheduleDates = []

        if (todayDate >= 1 && todayDate < 15) {
            thisMonthScheduleDates = [
                { label: moment().date(15).format('ddd, DD MMM Y'), value: moment().date(15).format('YYYY-MM-DD') },
            ]
        }

        const salesEndSelect = [
            { label: "2 hours before the event", value: 2 },
            { label: "12 hours before the event", value: 12 },
            { label: "24 hours before the event", value: 24 },
            { label: "48 hours before the event", value: 48 },
        ]

        const scheduleDateSelect = [
            { label: "No date", value: null },
            ...thisMonthScheduleDates,
            { label: moment().add(1, 'month').date(1).format('ddd, DD MMM Y'), value: moment().add(1, 'month').date(1).format('YYYY-MM-DD') },
            { label: moment().add(1, 'month').date(15).format('ddd, DD MMM Y'), value: moment().add(1, 'month').date(15).format('YYYY-MM-DD') },
            { label: moment().add(2, 'month').date(1).format('ddd, DD MMM Y'), value: moment().add(2, 'month').date(1).format('YYYY-MM-DD') },
            { label: moment().add(2, 'month').date(15).format('ddd, DD MMM Y'), value: moment().add(2, 'month').date(15).format('YYYY-MM-DD') },
            { label: moment().add(3, 'month').date(1).format('ddd, DD MMM Y'), value: moment().add(3, 'month').date(1).format('YYYY-MM-DD') },
            { label: moment().add(3, 'month').date(15).format('ddd, DD MMM Y'), value: moment().add(3, 'month').date(15).format('YYYY-MM-DD') },
        ]

        const suggestedEventsByClubColumns = [
            {
                dataField: "image",
                text: "#",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <>
                        <Link>
                            <img src={event.image} alt="" className="avatar-sm" />
                        </Link>
                    </>
                ),
            },
            {
                text: "Event",
                dataField: "title",
                // eslint-disable-next-line react/display-name
                filterValue: (_, event) => event?.title + event?.description,
                formatter: (_, event) => (
                    <>
                        <h5 className="font-size-14">
                            <Link
                                className="text-dark"
                            >
                                {event.title}
                            </Link>
                        </h5>
                        <p
                            style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                            className="text-muted mb-0"
                        >
                            <Link
                                className="text-dark"
                            >
                                {event.description}
                            </Link>
                        </p>
                    </>
                ),
            },
            {
                text: "",
                dataField: "start_date",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        {event.publish_date && <Badge
                            className={"font-size-12 badge-soft-" + "success"}
                            color={'success'}
                            pill
                        >
                            <span style={{ color: 'white' }}>Scheduled</span>

                        </Badge>}
                        {event.publish_date ? this.handleValidDate(event.publish_date) : ''}
                    </div>
                ),
            },
            {
                dataField: "host",
                text: "Suggested by",
                formatter: (_, event) => (
                    <span className="text-muted mb-0">
                        {event.host}
                    </span>
                ),
            },
            {
                text: "Created At",
                dataField: "createdAt",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <span>
                        {this.handleValidDate(event.created)}
                    </span>
                ),
            },
            {
                text: "Event start",
                dataField: "startDate",
                // eslint-disable-next-line react/display-name
                formatter: (_, event) => (
                    <>
                        <span>
                            {this.handleValidDate(event.start_date)}
                        </span>
                        <p style={{ marginTop: 10 }}>
                            {moment(event.start_time, "HH:mm:ss").format("hh:mmA")} -{' '}
                            {moment(event.end_time, "HH:mm:ss").format("hh:mmA")}
                        </p>
                    </>
                ),
            },
            {
                dataField: "capacity",
                text: "Capacity",
                formatter: (_, event) => (
                    <span className="text-muted mb-0">
                        {event.capacity}
                    </span>
                ),
            },
            {
                text: "Action",
                dataField: "",
                formatter: (_, event) => (
                    <>
                        <UncontrolledDropdown>
                            <DropdownToggle className="card-drop" tag="a">
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">

                                <DropdownItem
                                    href="#"
                                    onClick={() => this.handleEventClick({ ...event, modalType: 'Approve' })}
                                >
                                    <i className="mdi mdi-check-bold font-size-16 text-danger me-1" />{" "}
                                    Approve
                                </DropdownItem>

                                <DropdownItem
                                    href="#"
                                    onClick={() => this.handleEventClick({ ...event, modalType: 'Edit' })}
                                >
                                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                    Edit
                                </DropdownItem>

                                <DropdownItem
                                    onClick={() => {
                                        this.setState({
                                            eventToDeleteId: event?.id,
                                            discardEventModal: true
                                        })
                                    }}
                                >
                                    <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                                    Discard
                                </DropdownItem>


                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>
                ),
            },

        ];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.leaderAndClubEvents ? suggestedEventsByClubId.filter(item => item.leader == 1 || item.club == 1)?.length : suggestedEventsByClubId?.length, // replace later with size(customers),
            custom: true,
        };

        const locationSelect = [
            { label: "Lounge", value: 'Lounge' },
            { label: "Library", value: 'Library' },
            { label: "Kitchen", value: 'Kitchen' },
            { label: "Wellness Studio", value: 'Wellness Studio' },
            { label: "Patio", value: 'Patio' },
            { label: "Banana Yoga - 376 40th Street, Oakland", value: 'Banana Yoga - 376 40th Street, Oakland' },
            { label: "835 4th St Suite B, San Rafael", value: '835 4th St Suite B, San Rafael' },
            { label: "3976 Piedmont Ave", value: "3976 Piedmont Ave" },
            { label: "2973 16th St 5th Floor", value: '2973 16th St 5th Floor' },
            { label: "239 3rd St, San Rafael, CA 94901", value: '239 3rd St, San Rafael, CA 94901' },
            { label: "Other", value: 'Other' },
        ]

        const bookingConfirmationSelect = [
            { label: "No link", value: "No link" },
            { label: "Learn more", value: "Learn more" },
            { label: "Purchase tickets", value: "Purchase tickets" },
            { label: "Book a time slot", value: "Book a time slot" },
        ];


        return (
            <React.Fragment>
                <div className="page-content">
                    <DefaultModal
                        title={'Are you sure you want to discard this event?'}
                        description={'You will not be able to revert this!'}
                        show={this.state.discardEventModal}
                        onSendClick={this.discardEvent}
                        onCloseClick={() => this.setState({ discardEventModal: false })}
                    />
                    <MetaTags>
                        <title>Suggested Events | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Suggested Events" breadcrumbItem="Suggested Events List" />
                        <Row>
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Card>
                                            <CardBody>
                                                {this.state.isMobile ?
                                                    <Row>
                                                        <Col lg="1">
                                                            <Select
                                                                styles={{
                                                                }}
                                                                onChange={
                                                                    this.handleClubSelect
                                                                }
                                                                options={this.state.clubsOptions}
                                                                value={this.state.clubSelected}
                                                                classNamePrefix="select2-selection"
                                                            />


                                                        </Col>
                                                    </Row>
                                                    :
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <Nav
                                                            pills
                                                            className="bg-light rounded"
                                                            role="tablist"
                                                        >
                                                            {clubs?.map((item, index) => (
                                                                <NavItem key={index}>
                                                                    <NavLink
                                                                        className={classnames({
                                                                            active: activeClub === item?.id,
                                                                        })}
                                                                        style={{
                                                                            backgroundColor:
                                                                                activeClub === item?.id
                                                                                    ? "#032224"
                                                                                    : "transparent",
                                                                        }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                activeClub: item?.id,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </NavLink>
                                                                </NavItem>
                                                            ))}
                                                        </Nav>
                                                    </div>}
                                                <Button
                                                    style={{ backgroundColor: this.state.leaderAndClubEvents ? "#032224" : '#F0F2F6', color: this.state.leaderAndClubEvents ? "#fff" : "#042225", marginTop: 10 }}
                                                    className="font-16 btn-block btn btn-primary"
                                                    color={'#032224'}
                                                    onClick={() => this.setState({
                                                        leaderAndClubEvents: !this.state.leaderAndClubEvents
                                                    })}
                                                >
                                                    Sub clubs
                                                </Button>
                                                {suggestedEventsByClubId && (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        columns={suggestedEventsByClubColumns}
                                                        data={this.state.leaderAndClubEvents ? suggestedEventsByClubId.filter(item => item.leader == 1 || item.club == 1) : suggestedEventsByClubId}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={suggestedEventsByClubColumns}
                                                                data={this.state.leaderAndClubEvents ? suggestedEventsByClubId.filter(item => item.leader == 1 || item.club == 1) : suggestedEventsByClubId}
                                                                search
                                                            >
                                                                {toolkitProps => (
                                                                    <React.Fragment>
                                                                        <Row>
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                                style={{ marginTop: 10 }}
                                                                            />
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    {loadingSuggestedEventsByClubId ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-24 align-middle me-2" /> Loading</Link> </div> : <BootstrapTable
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={"thead-light"}
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />}
                                                                                </div>
                                                                            </Col>
                                                                            {/* </Link> */}
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="inner-custom-pagination d-flex">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="text-md-right ms-auto">
                                                                                    <PaginationListStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                )}
                                            </CardBody>
                                        </Card>
                                        <Modal
                                            size="xl"
                                            isOpen={this.state.modal}
                                        >
                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                {`${this.state.modalType} Event`}
                                            </ModalHeader>
                                            <ModalBody>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{
                                                        id: (event && event?.id) || "",
                                                        title: (event && event.title) || "",
                                                        description: (event && event.description) || "",
                                                        startDate:
                                                            (event &&
                                                                moment(event?.startDate)
                                                                    .utc()
                                                                    .format("YYYY-MM-DD")) ||
                                                            "",
                                                        capacity: event && event.capacity,
                                                        guestInvitesAllowed:
                                                            event && event.guestInvitesAllowed,
                                                        link: (event && event.link) || "",
                                                        bookingConfirmation:
                                                            (event && event.bookingConfirmation) || "",
                                                        checkinRequired:
                                                            (event && event.checkinRequired) || 0,
                                                        category: (event && event.category) || "",
                                                        instructorId: (event && event.instructorId) || "",
                                                        clubId: (event && event.clubId) || "",
                                                        instructorName:
                                                            (event && event.instructorName) || "",
                                                        club:
                                                            event && this.props.clubs
                                                                ? this.props.clubs.find(
                                                                    item => item?.id === event.clubId
                                                                )?.name
                                                                : "",
                                                        price: event && event.price,
                                                        startTime: (event && event.startTime) || "",
                                                        endTime: (event && event.endTime) || "",

                                                    }}
                                                    validationSchema={Yup.object().shape({
                                                        title: Yup.string().trim('Please Enter Title').required("Please Enter Title"),
                                                        description: Yup.string().trim('Please Enter Description').required(
                                                            "Please Enter Description"
                                                        ),
                                                        startDate: Yup.string().required(
                                                            "Please Enter Start Date"
                                                        ),
                                                        startTime: Yup.string().required(
                                                            "Please Enter Start Time"
                                                        ),
                                                        endTime: Yup.string().required(
                                                            "Please Enter End Time"
                                                        ),
                                                        capacity: Yup.number()
                                                            .min(1, "Capacity can not be 0")
                                                            .typeError("The amount invalid")
                                                            .required("The amount is required"),
                                                        guestInvitesAllowed: Yup.number().min(0, 'Can not be lower than 0!').required(
                                                            "Please Enter Guest Invite Allowed"
                                                        ),
                                                    })}
                                                    onSubmit={values => {
                                                        let startDate = values.startDate
                                                        let startTime = values.startTime



                                                        const startDateAndTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm')
                                                        let salesEndDateAndTime = startDateAndTime

                                                        if (this.state.showBookClosingTimes) {
                                                            salesEndDateAndTime = startDateAndTime.subtract(this.state.salesEndBeforeEvent.value, 'hours')
                                                        }

                                                        if (this.state.clubId === null) {
                                                            this.setState({
                                                                clubError: true,
                                                            });
                                                        }
                                                        if (this.state.interestsSelected?.length === 0 || this.state.interestsSelected === undefined) {
                                                            this.setState({
                                                                interestsError: true
                                                            })
                                                        }
                                                        if (!this.state.interestsError && !this.state.clubError && !this.state.salesEndDateError) {
                                                            const createEventData = {
                                                                title: values.title,
                                                                description: values.description,
                                                                startTime: startTime,
                                                                endTime: values.endTime,
                                                                salesEnd: moment(
                                                                    salesEndDateAndTime
                                                                ).format("YYYY-MM-DD HH:mm:ss"),
                                                                capacity: values.capacity,
                                                                guestInvitesAllowed:
                                                                    this.state.guestInvitesAllowed,
                                                                price: this.state.price,
                                                                link: this.state.bookingConfirmation.value === 'No link' ? "" : values.link,
                                                                location: this.state.location,
                                                                bookingConfirmation:
                                                                    this.state.bookingConfirmation.label,
                                                                category: this.state.category,
                                                                instructorId: '',
                                                                clubId: parseInt(this.state.clubId),
                                                                checkinRequired: this.state.checkinRequired ? 1 : 0,
                                                                allClubs: this.state.allClubsEvent ? 1 : 0,
                                                                instructorName: '',
                                                                requiresPayment: this.state.price > 0 ? 1 : 0,
                                                                id: event?.id,
                                                                spotlight: false,
                                                                sendPushNotification: false,
                                                                hostId: this.state.hostId === null ? '' : this.state.hostId,
                                                                interests: this.state.interestsSelected
                                                                    .map(item => item.value)
                                                                    .join(";"),
                                                                image: this.state.eventImage,
                                                                suggestEventId: event?.id,
                                                                startDate: moment(values.startDate).format(
                                                                    "YYYY-MM-DD"
                                                                ),
                                                                host: this.state.host,
                                                            };
                                                            if (this.state.modalType === 'Approve') {
                                                                if (this.state.scheduleDate.value) {
                                                                    this.props.onUpdateSuggestedEvent({ ...createEventData, id: this.state.event?.id, publishDate: this.state.scheduleDate.value })
                                                                } else {
                                                                    this.props.onAddEvent(createEventData);
                                                                }
                                                            } else {
                                                                this.props.onUpdateSuggestedEvent({ ...createEventData, id: this.state.event?.id, publishDate: this.state.scheduleDate.value })
                                                            }
                                                            this.setState({ event: null });
                                                            this.props.onSetImage(null);
                                                            setTimeout(() => {
                                                                this.props.onGetSuggestedEventsByClubId(this.state.activeClub)
                                                            }, 1000)
                                                            this.toggle();
                                                        }
                                                    }}
                                                >
                                                    {({ errors, status, touched }) => (
                                                        <Form>
                                                            <Row>
                                                                <h4 className="mb-4">Basic Info</h4>
                                                                <Row className="mb-4">
                                                                    <Col lg='10'>
                                                                        <Label
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Title
                                                                        </Label>
                                                                        <Field
                                                                            name="title"
                                                                            placeholder="Title"
                                                                            type="text"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.title && touched.title
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="title"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Col lg='10'>
                                                                        <Label
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Description
                                                                        </Label>
                                                                        <Field
                                                                            name="description"
                                                                            placeholder="Description"
                                                                            type="text"
                                                                            component="textarea"
                                                                            rows="6"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.description &&
                                                                                    touched.description
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Col lg="10">
                                                                        <Label
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Host
                                                                        </Label>
                                                                        <TreeSelect
                                                                            showSearch
                                                                            style={{ width: "100%" }}
                                                                            value={this.state.hostSelected}
                                                                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                                                            placeholder="Please select host"
                                                                            allowClear
                                                                            treeDefaultExpandAll
                                                                            onChange={this.handleHostSelect}
                                                                            treeData={this.state.treeData}
                                                                        />
                                                                        {this.state.hostSelected === 'Other' && <Field
                                                                            type="text"
                                                                            value={this.state.host}
                                                                            style={{ marginTop: 10 }}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    host: e.target.value,
                                                                                })}
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Col lg='10'>
                                                                        <Label
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Capacity
                                                                        </Label>
                                                                        <Field
                                                                            name="capacity"
                                                                            type="number"
                                                                            placeholder="Capacity"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.capacity && touched.capacity
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />

                                                                        <ErrorMessage
                                                                            name="capacity"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Col lg="10">
                                                                        <Label
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Guest Invites Allowed
                                                                        </Label>
                                                                        <Field
                                                                            name="guestInvitesAllowed"
                                                                            value={this.state.guestInvitesAllowed}
                                                                            placeholder='Guest Invites Allowed'
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    guestInvitesAllowed: e.target.value,
                                                                                })
                                                                            }
                                                                            type="number"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.guestInvitesAllowed &&
                                                                                    touched.guestInvitesAllowed
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="guestInvitesAllowed"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Col lg="10">
                                                                        <Label
                                                                            className="col-form-label col-lg-2"
                                                                        >
                                                                            Price
                                                                        </Label>
                                                                        <Field
                                                                            name="price"
                                                                            value={this.state.price}
                                                                            placeholder="Price"
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    price: e.target.value,
                                                                                })
                                                                            }
                                                                            type="number"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.price && touched.price
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="price"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                                <h4 className="mt-4 mb-4">Location</h4>
                                                                <Row className="mb-4">
                                                                    <Label
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Select Club
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <Dropdown
                                                                            isOpen={this.state.dropdownClubOpen}
                                                                            toggle={this.toggleClubs}
                                                                        >
                                                                            <DropdownToggle caret>
                                                                                {this.state.club}
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                {this.props.clubs?.map((club, index) => {
                                                                                    return (
                                                                                        <DropdownItem
                                                                                            onClick={() =>
                                                                                                this.clubItemSelected(
                                                                                                    club.name,
                                                                                                    club?.id
                                                                                                )
                                                                                            }
                                                                                            key={index}
                                                                                        >
                                                                                            {club.name}
                                                                                        </DropdownItem>
                                                                                    );
                                                                                })}
                                                                                {this.state.clubError && (
                                                                                    <p
                                                                                        style={{
                                                                                            color: "red",
                                                                                        }}
                                                                                    >
                                                                                        Please Select a Club
                                                                                    </p>
                                                                                )}
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                        {this.state.clubError && (
                                                                            <p
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                Please Select a Club
                                                                            </p>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Label
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        All Clubs Event
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <Switch active={this.state.allClubsEvent} parentFunc={this.handleAllClubsEvent} />
                                                                        <p style={{ fontSize: 12, fontWeight: '400', marginTop: 10 }}>Turn this on only if you want this event to appear for all locations!</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Label
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Location
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <Select
                                                                            onChange={
                                                                                this.handleLocationSelect
                                                                            }
                                                                            options={locationSelect}
                                                                            value={this.state.locationSelected}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        {this.state.locationSelected.value === 'Other' && <Field
                                                                            type="text"
                                                                            value={this.state.location}
                                                                            style={{ marginTop: 10 }}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    location: e.target.value,
                                                                                    locationError: false
                                                                                })}
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />}
                                                                        {this.state.locationError && (
                                                                            <p
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                Please Select a Location
                                                                            </p>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                                <h4 className="mt-4 mb-5">Date and time</h4>
                                                                <Row className="mb-4">
                                                                    <Label
                                                                        htmlFor="startDate"
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Start Date and Time
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <Row className="mb-2">
                                                                            <Col lg="6">
                                                                                <Field
                                                                                    name="startDate"
                                                                                    type="date"
                                                                                    className={
                                                                                        "form-control" +
                                                                                        (errors.startDate && touched.startDate
                                                                                            ? " is-invalid"
                                                                                            : "")
                                                                                    }
                                                                                />
                                                                                <ErrorMessage
                                                                                    name="startDate"
                                                                                    component="div"
                                                                                    className="invalid-feedback"
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg="6">
                                                                                <Field
                                                                                    name="startTime"
                                                                                    type="time"
                                                                                    format="HH:mm"
                                                                                    className={
                                                                                        "form-control" +
                                                                                        (errors.startTime && touched.startTime
                                                                                            ? " is-invalid"
                                                                                            : "")
                                                                                    }
                                                                                />
                                                                                <ErrorMessage
                                                                                    name="startTime"
                                                                                    component="div"
                                                                                    className="invalid-feedback"
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Label className="col-form-label col-lg-2">
                                                                        End Time
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <Col lg="6">
                                                                            <Field
                                                                                name="endTime"
                                                                                type="time"
                                                                                className={
                                                                                    "form-control" +
                                                                                    (errors.endTime && touched.endTime
                                                                                        ? " is-invalid"
                                                                                        : "")
                                                                                }
                                                                            />
                                                                            <ErrorMessage
                                                                                name="endTime"
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Label
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Final Attendee Update Deadline
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            marginBottom: 10
                                                                        }}>
                                                                            <span style={{ marginRight: 10 }}>OFF</span>
                                                                            <Switch active={this.state.showBookClosingTimes} parentFunc={this.handleShowBookClosingTimes} />
                                                                            <span style={{ marginLeft: 10 }}>ON</span>
                                                                        </div>
                                                                        <span>{`Set this as the cutoff for all attendee list changes, including the waitlist. No bookings or waitlist updates will occur after this time.`}</span>
                                                                    </Col>

                                                                </Row>
                                                                {this.state.showBookClosingTimes && <Row className="mt-5 mb-4">
                                                                    <Label
                                                                        htmlFor="bookingConfirmation"
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Select sales end
                                                                    </Label>
                                                                    <Col lg="8">
                                                                        <Select
                                                                            onChange={
                                                                                this.handleSalesEndBeforeEventSelect
                                                                            }
                                                                            options={salesEndSelect}
                                                                            defaultValue={{ label: "2 hours before the event", value: 2 }}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </Col>
                                                                </Row>}
                                                                <hr />

                                                                <h4 className="mt-5 mb-1">Schedule publish date</h4>

                                                                <span>Please select a date for the event to be published automatically. This applies only to drafts.</span>

                                                                <Row className="mt-5 mb-4">
                                                                    <Label
                                                                        htmlFor="bookingConfirmation"
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Schedule date
                                                                    </Label>
                                                                    <Col lg="8">
                                                                        <Select
                                                                            onChange={
                                                                                this.handleScheduleDateSelect
                                                                            }
                                                                            value={this.state.scheduleDate}
                                                                            options={scheduleDateSelect}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <hr />
                                                                <h4 className="mt-4 mb-5">Details</h4>
                                                                <Row className="mb-4">
                                                                    <Label
                                                                        htmlFor="bookingConfirmation"
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Booking Confirmation
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <Select
                                                                            onChange={
                                                                                this.handleBookingConfirmationSelect
                                                                            }
                                                                            options={bookingConfirmationSelect}
                                                                            defaultValue={this.state.bookingConfirmation}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        <ErrorMessage
                                                                            name="bookingConfirmation"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </Col>

                                                                    {this.state.bookingConfirmation.value !== 'No link' && <div className="mb-3">
                                                                        <Label className="form-label">Link</Label>
                                                                        <Field
                                                                            name="link"
                                                                            type="text"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.link && touched.link
                                                                                    ? " is-invalid"
                                                                                    : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="link"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>}
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Label
                                                                        htmlFor="bookingConfirmation"
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Select Interests
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <p style={{ fontSize: 12, fontWeight: '400' }}>Pick all the relevant and related themes to tag the event, this will help us automate suggestions in future!</p>
                                                                        <Select
                                                                            value={interestsSelected}
                                                                            isMulti={true}
                                                                            onChange={this.handleMulti}
                                                                            options={interestsFiltered}
                                                                            classNamePrefix="select2-selection"
                                                                        />

                                                                        {this.state.interestsError && (
                                                                            <p
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                Please Select Interests
                                                                            </p>
                                                                        )}
                                                                    </Col>

                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Label
                                                                        htmlFor="category"
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Category
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <Dropdown
                                                                            isOpen={this.state.dropdownOpen}
                                                                            toggle={this.toggleCategory}
                                                                        >
                                                                            <DropdownToggle caret>
                                                                                {this.state.category}
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem
                                                                                    onClick={() =>
                                                                                        this.categoryItemSelected("Event")
                                                                                    }
                                                                                    category="Event"
                                                                                >
                                                                                    Event
                                                                                </DropdownItem>
                                                                                <DropdownItem divider />
                                                                                <DropdownItem
                                                                                    onClick={() =>
                                                                                        this.categoryItemSelected(
                                                                                            "Wellness"
                                                                                        )
                                                                                    }
                                                                                    category="Wellness"
                                                                                >
                                                                                    Wellnes
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mb-4">
                                                                    <Label
                                                                        htmlFor="instructor"
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Instructor
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <Dropdown
                                                                            isOpen={
                                                                                this.state.dropdownInstructorOpen
                                                                            }
                                                                            toggle={this.toggleInstructor}
                                                                        >
                                                                            <DropdownToggle caret>
                                                                                {this.state.instructorName}
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                {this.state.instructors.map(
                                                                                    (instructor, index) => {
                                                                                        return (
                                                                                            <DropdownItem
                                                                                                onClick={() =>
                                                                                                    this.instructorItemSelected(
                                                                                                        `${instructor.first_name} ${instructor.last_name}`,
                                                                                                        `${instructor?.id}`
                                                                                                    )
                                                                                                }
                                                                                                key={index}
                                                                                            >
                                                                                                {instructor.first_name}{" "}
                                                                                                {instructor.last_name}
                                                                                            </DropdownItem>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    </Col>
                                                                </Row>
                                                                {/* <Row className="mb-4">
                                                                    <Label
                                                                        htmlFor="checkinRequired"
                                                                        className="col-form-label col-lg-2"
                                                                    >
                                                                        Checkin Required
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <Switch active={this.state.checkinRequired} parentFunc={this.handleCheckinRequried} />
                                                                    </Col>
                                                                </Row> */}
                                                                <FormGroup className="mb-3" row>
                                                                    <Label className="col-form-label col-lg-2">
                                                                        Image
                                                                    </Label>
                                                                    <Col lg="10">
                                                                        <FileUpload
                                                                            image={this.state.eventImage}
                                                                            onFileUploaded={this.onFileUploaded}
                                                                        ></FileUpload>
                                                                    </Col>
                                                                </FormGroup>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <div className="text-end">
                                                                        {(() => {
                                                                            if (
                                                                                role == 1 ||
                                                                                role == 666
                                                                            ) {
                                                                                return (
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-success save-user"
                                                                                    >
                                                                                        {`${this.state.modalType}`}
                                                                                    </button>
                                                                                );
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

SuggestEventListByClub.propTypes = {
    suggestedEventsByClubId: PropTypes.array,
    className: PropTypes.any,
    onGetSuggestedEventsByClubId: PropTypes.func,
    onGetClubs: PropTypes.func,
    onSetImage: PropTypes.func,
    onGetEventInstructors: PropTypes.func,
    onGetAllChildrenCategories: PropTypes.func,
    onGetSubClubs: PropTypes.func,
    instructors: PropTypes.array,
    childrenCategories: PropTypes.array,
    loadingSuggestedEventsByClubId: PropTypes.bool,
    members: PropTypes.array,
    onGetMembers: PropTypes.func,
    subClubs: PropTypes.array,
    onAddEvent: PropTypes.func,
    onUpdateSuggestedEvent: PropTypes.func,
    onDeleteSuggestedEvent: PropTypes.func,
    clubs: PropTypes.array,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = ({ events, system, members, subclubs }) => ({
    suggestedEventsByClubId: events.suggestedEventsByClubId,
    loadingSuggestedEventsByClubId: events.loadingSuggestedEventsByClubId,
    clubs: system.clubs,
    subClubs: subclubs.subClubs,
    instructors: events.instructors,
    childrenCategories: system.childrenCategories,
    members: members.members,

});

const mapDispatchToProps = dispatch => ({
    onGetEventInstructors: () => dispatch(getEventInstructors()),
    onGetClubs: () => dispatch(getClubs()),
    onSetImage: image => dispatch(setImage(image)),
    onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),
    onGetSuggestedEventsByClubId: clubId => dispatch(getSuggestedEventsByClubId(clubId)),
    onGetSubClubs: () => dispatch(getSubClubs()),
    onGetMembers: clubId => dispatch(getMembers(clubId)),
    onAddEvent: (event) => dispatch(addEvent(event)),
    onUpdateSuggestedEvent: (event, history) => dispatch(updateSuggestedEvent(event, history)),
    onDeleteSuggestedEvent: (event, history) => dispatch(deleteSuggestedEvent(event, history)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SuggestEventListByClub));
