import {
  DOORDECK_ACCESS_STATUS,
  DOORDECK_ACCESS_STATUS_FAIL,
  DOORDECK_ACCESS_STATUS_SUCCESS,
  GET_ALL_CHILDREN_CATEGORIES,
  GET_ALL_CHILDREN_CATEGORIES_FAIL,
  GET_ALL_CHILDREN_CATEGORIES_SUCCESS,
  GET_CLUBS, GET_CLUBS_FAIL, GET_CLUBS_SUCCESS, SEND_EMAILS, SEND_EMAILS_FAIL, SEND_EMAILS_SUCCESS, SEND_NOTIFICATIONS, SEND_NOTIFICATIONS_FAIL, SEND_NOTIFICATIONS_SUCCESS
} from "./actionTypes";

export const getClubs = () => ({
  type: GET_CLUBS,
});

export const getClubsSuccess = clubs => ({
  type: GET_CLUBS_SUCCESS,
  payload: clubs,
});

export const getClubsFail = error => ({
  type: GET_CLUBS_FAIL,
  payload: error,
});

export const sendEmail = (clubId, fromEmail, fromName, replyTo, subject, text, showToast) => ({
  type: SEND_EMAILS,
  payload: { clubId, fromEmail, fromName, replyTo, subject, text, showToast }
});

export const sendEmailSuccess = () => ({
  type: SEND_EMAILS_SUCCESS,
});

export const sendEmailFail = error => ({
  type: SEND_EMAILS_FAIL,
  payload: error,
});

export const sendNotifications = (clubId, title, body, showToast) => ({
  type: SEND_NOTIFICATIONS,
  payload: { clubId, title, body, showToast }
});

export const sendNotificationsSuccess = () => ({
  type: SEND_NOTIFICATIONS_SUCCESS,
});

export const sendNotificationsFail = error => ({
  type: SEND_NOTIFICATIONS_FAIL,
  payload: error,
});


export const getAllChildrenCategories = () => ({
  type: GET_ALL_CHILDREN_CATEGORIES,
});

export const getAllChildrenCategoriesSuccess = allChildrenCategories => ({
  type: GET_ALL_CHILDREN_CATEGORIES_SUCCESS,
  payload: allChildrenCategories,
});

export const getAllChildrenCategoriesFail = error => ({
  type: GET_ALL_CHILDREN_CATEGORIES_FAIL,
  payload: error,
});

export const doorAccessStatus = (
  id,
  firstName,
  lastName,
  email,
  status,
  doordeckId,
  showToast
) => ({
  type: DOORDECK_ACCESS_STATUS,
  payload: {
    id,
    firstName,
    lastName,
    email,
    status,
    doordeckId,
    showToast
  }
});

export const doorAccessStatusSuccess = () => ({
  type: DOORDECK_ACCESS_STATUS_SUCCESS,
});

export const doorAccessStatusFail = () => ({
  type: DOORDECK_ACCESS_STATUS_FAIL,
});
