import { get, post, put } from "./api_helper";
import * as url from "./url_helper";

const getSpaceBookings = (token, clubId, date) => {
    return get(url.SPACE_BOOKINGS(clubId, date), {
        headers: {
            Authorization: token,
        },
    });
};

const getBookings = (token, id) => {
    return get(url.BOOKINGS(id), {
        headers: {
            Authorization: token,
        },
    });
};
const getBookingsLeaderAPI = (token, id) => {
    return get(url.BOOKINGS_LEADER(id), {
        headers: {
            Authorization: token,
        },
    });
};
const getEventWaitlistAPI = (token, id) => {
    return get(url.EVENT_WAITLIST(id), {
        headers: {
            Authorization: token,
        },
    });
};
const getEventWaitlistLeaderAPI = (token, id) => {
    return get(url.EVENT_WAITLIST_LEADER(id), {
        headers: {
            Authorization: token,
        },
    });
};

const getUserSpaceBookingsAPI = (token, userId) => {
    return get(url.USER_SPACE_BOOKINGS(userId), {
        headers: {
            Authorization: token,
        },
    });
};

const getPublicBookingsReq = (eventId) => {
    return get(url.PUBLIC_BOOKINGS(eventId));
};

const cancelBookingReq = (token, bookingId, eventId, userId) => {
    return put(url.CANCEL_BOOKING(bookingId, eventId, userId), {}, {
        headers: {
            Authorization: token,
        },
    });
};

const cancelSpaceBookingReq = (token, bookingId) => {
    return put(url.CANCEL_SPACE_BOOKING(bookingId), {}, {
        headers: {
            Authorization: token,
        },
    });
};

const checkInMembersBooking = (token, id) => {
    return put(
        url.CHECK_IN_MEMBERS(id),
        {
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const bookSpaceAPI = (token, spaceId, type, date, startTime, endTime) => {
    return post(
        url.BOOK_SPACE,
        {
            spaceId,
            type,
            date,
            startTime,
            endTime,
        }
        , {
            headers: {
                Authorization: token,
            },
        });
};

const getSpacesAPI = (token, clubId) => {
    return get(url.SPACES(clubId), {
        headers: {
            Authorization: token,
        },
    });
};

export {
    getSpaceBookings, checkInMembersBooking, getBookings, cancelBookingReq, cancelSpaceBookingReq,
    getPublicBookingsReq, getUserSpaceBookingsAPI, getEventWaitlistAPI, bookSpaceAPI,
    getSpacesAPI, getBookingsLeaderAPI, getEventWaitlistLeaderAPI
};