import { ADD_EVENT_FAIL, ADD_EVENT_SUCCESS, DELETE_EVENT_FAIL, DELETE_EVENT_SUCCESS, GET_EVENTS_FAIL, GET_PAST_EVENTS_FAIL, GET_PAST_EVENTS_SUCCESS, GET_EVENTS_SUCCESS, UPDATE_EVENT_FAIL, UPDATE_EVENT_SUCCESS, GET_EVENT_INSTRUCTOR_SUCCESS, GET_EVENT_INSTRUCTOR_FAIL, GET_EVENTS_BY_INSTRUCTOR_SUCCESS, GET_EVENTS_BY_INSTRUCTOR_FAIL, SET_IMAGE_SUCCESS, GET_CALENDLY_SCHEDULED_EVENTS_SUCCESS, GET_CALENDLY_SCHEDULED_EVENTS_FAIL, GET_CALENDLY_EVENT_SUCCESS, GET_CALENDLY_EVENT_FAIL, GET_CALENDLY_EVENT_INVITEES_SUCCESS, GET_CALENDLY_EVENT_INVITEES_FAIL, CLONE_EVENT, GET_EVENTS_BY_CLUB, GET_PAST_EVENTS, GET_CALENDLY_SCHEDULED_EVENTS, GET_SUGGESTED_EVENTS, GET_SUGGESTED_EVENTS_SUCCESS, GET_SUGGESTED_EVENTS_FAIL, GET_EVENT_ATTENDANCE, GET_EVENT_ATTENDANCE_SUCCESS, GET_EVENT_ATTENDANCE_FAIL, CANCEL_EVENT_SUCCESS, CANCEL_EVENT_FAIL, GET_EVENT_POSSIBLE_MATCHES, GET_EVENT_POSSIBLE_MATCHES_SUCCESS, GET_EVENT_POSSIBLE_MATCHES_FAIL, GET_PAST_EVENTS_BY_INSTRUCTOR_SUCCESS, GET_PAST_EVENTS_BY_INSTRUCTOR_FAIL, GET_SUGGESTED_EVENTS_BY_CLUB_ID, GET_SUGGESTED_EVENTS_BY_CLUB_ID_SUCCESS, GET_SUGGESTED_EVENTS_BY_CLUB_ID_FAIL, GET_CLUB_LEADER_UPCOMING_EVENTS, GET_CLUB_LEADER_UPCOMING_EVENTS_SUCCESS, GET_CLUB_LEADER_UPCOMING_EVENTS_FAIL, GET_CLUB_LEADER_PAST_EVENTS, GET_CLUB_LEADER_PAST_EVENTS_SUCCESS, GET_CLUB_LEADER_PAST_EVENTS_FAIL, GET_SUGGESTED_EVENTS_BY_USER_ID, GET_SUGGESTED_EVENTS_BY_USER_ID_SUCCESS, GET_SUGGESTED_EVENTS_BY_USER_ID_FAIL, GET_DRAFT_EVENTS_BY_USER_ID, GET_DRAFT_EVENTS_BY_USER_ID_SUCCESS, GET_DRAFT_EVENTS_BY_USER_ID_FAIL, SUGGEST_EVENT, SUGGEST_EVENT_SUCCESS, SUGGEST_EVENT_FAIL, CREATE_DRAFT, CREATE_DRAFT_SUCCESS, CREATE_DRAFT_FAIL, RESET_LOADING_STATE } from "./actionTypes";
const INIT_STATE = {
  events: [],
  loadingEvents: false,

  eventDetail: {},

  pastEvents: [],
  loadingPastEvents: false,

  instructors: [],
  calendlyEvents: [],
  calendlyEventInitees: [],
  calendlyEvent: null,
  error: {},
  image: null,
  eventToClone: null,

  loadingCalendlyEvents: false,

  suggestedEvents: [],
  loadingSuggestedEvents: false,

  eventAttendance: [],
  loadingEventAttendance: false,

  possibleMatches: [],
  loadingPossibleMatches: false,

  suggestedEventsByClubId: [],
  loadingSuggestedEventsByClubId: false,

  clubLeaderUpcomingEvents: [],
  loadingClubLeaderUpcomingEvents: false,

  clubLeaderPastEvents: [],
  loadingClubLeaderPastEvents: false,

  suggestedEventsByUserId: [],
  loadingSuggestedEventsByUserId: false,

  draftsByUserId: [],

  loadingDraftsByUserId: false,

  loadingSuggestEvent: false,

  loadingSaveDraft: false,

};

const events = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS_BY_CLUB:
      return {
        ...state,
        loadingEvents: true
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        loadingEvents: false
      };

    case GET_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingEvents: false
      };

    case GET_SUGGESTED_EVENTS_BY_CLUB_ID:
      return {
        ...state,
        loadingSuggestedEventsByClubId: true
      };
    case GET_SUGGESTED_EVENTS_BY_CLUB_ID_SUCCESS:
      return {
        ...state,
        suggestedEventsByClubId: action.payload,
        loadingSuggestedEventsByClubId: false
      };

    case GET_SUGGESTED_EVENTS_BY_CLUB_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loadinloadingSuggestedEventsByClubIdgEvents: false
      };

    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, action.payload],
      };

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      };

    case UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CANCEL_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.id.toString()
        ),
      };

    case CANCEL_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PAST_EVENTS:
      return {
        ...state,
        loadingPastEvents: true
      };
    case GET_PAST_EVENTS_SUCCESS:
      return {
        ...state,
        pastEvents: action.payload,
        loadingPastEvents: false
      };

    case GET_PAST_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingPastEvents: false
      };
    case GET_SUGGESTED_EVENTS:
      return {
        ...state,
        loadingSuggestedEvents: true
      }
    case GET_SUGGESTED_EVENTS_SUCCESS:
      return {
        ...state,
        suggestedEvents: action.payload,
        loadingSuggestedEvents: false
      };

    case GET_SUGGESTED_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSuggestedEvents: false
      };

    case GET_SUGGESTED_EVENTS_BY_USER_ID:
      return {
        ...state,
        loadingSuggestedEventsByUserId: true
      }
    case GET_SUGGESTED_EVENTS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        suggestedEventsByUserId: action.payload,
        loadingSuggestedEventsByUserId: false
      };

    case GET_SUGGESTED_EVENTS_BY_USER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSuggestedEventsByUserId: false
      };


    case GET_DRAFT_EVENTS_BY_USER_ID:
      return {
        ...state,
        loadingDraftsByUserId: true
      }
    case GET_DRAFT_EVENTS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        draftsByUserId: action.payload,
        loadingDraftsByUserId: false
      };

    case GET_DRAFT_EVENTS_BY_USER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDraftsByUserId: false
      };

    case GET_EVENT_POSSIBLE_MATCHES:
      return {
        ...state,
        loadingPossibleMatches: true
      }
    case GET_EVENT_POSSIBLE_MATCHES_SUCCESS:
      return {
        ...state,
        possibleMatches: action.payload,
        loadingPossibleMatches: false
      };

    case GET_EVENT_POSSIBLE_MATCHES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingPossibleMatches: false
      };

    case GET_EVENT_ATTENDANCE:
      return {
        ...state,
        loadingEventAttendance: true
      }
    case GET_EVENT_ATTENDANCE_SUCCESS:
      return {
        ...state,
        eventAttendance: action.payload,
        loadingEventAttendance: false
      };

    case GET_EVENT_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingEventAttendance: false
      };
    case SUGGEST_EVENT:
      return {
        ...state,
        loadingSuggestEvent: true
      }
    case SUGGEST_EVENT_SUCCESS:
      return {
        ...state,
        loadingSuggestEvent: false
      };

    case SUGGEST_EVENT_FAIL:
      return {
        ...state,
        loadingSuggestEvent: false
      };
    case CREATE_DRAFT:
      return {
        ...state,
        loadingSaveDraft: true
      }
    case CREATE_DRAFT_SUCCESS:
      return {
        ...state,
        loadingSaveDraft: false
      };

    case CREATE_DRAFT_FAIL:
      return {
        ...state,
        loadingSaveDraft: false
      };
    case GET_CALENDLY_SCHEDULED_EVENTS:
      return {
        ...state,
        loadingCalendlyEvents: true
      }
    case GET_CALENDLY_SCHEDULED_EVENTS_SUCCESS:
      return {
        ...state,
        calendlyEvents: action.payload,
        loadingCalendlyEvents: false
      };

    case GET_CALENDLY_SCHEDULED_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingCalendlyEvents: false
      };

    case GET_CALENDLY_EVENT_SUCCESS:
      return {
        ...state,
        calendlyEvent: action.payload,
      };

    case GET_CALENDLY_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CALENDLY_EVENT_INVITEES_SUCCESS:
      return {
        ...state,
        calendlyEventInitees: action.payload,
      };

    case GET_CALENDLY_EVENT_INVITEES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EVENT_INSTRUCTOR_SUCCESS:
      return {
        ...state,
        instructors: action.payload,
      };

    case GET_EVENT_INSTRUCTOR_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_EVENTS_BY_INSTRUCTOR_SUCCESS:
      return {
        ...state,
        events: action.payload,
      };

    case GET_EVENTS_BY_INSTRUCTOR_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PAST_EVENTS_BY_INSTRUCTOR_SUCCESS:
      return {
        ...state,
        pastEvents: action.payload,
      };

    case GET_PAST_EVENTS_BY_INSTRUCTOR_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SET_IMAGE_SUCCESS:
      return {
        ...state,
        image: action.payload,
      };
    case CLONE_EVENT:
      return {
        ...state,
        eventToClone: action.payload
      }

    case GET_CLUB_LEADER_UPCOMING_EVENTS:
      return {
        ...state,
        loadingClubLeaderUpcomingEvents: true
      };
    case GET_CLUB_LEADER_UPCOMING_EVENTS_SUCCESS:
      return {
        ...state,
        clubLeaderUpcomingEvents: action.payload,
        loadingClubLeaderUpcomingEvents: false
      };

    case GET_CLUB_LEADER_UPCOMING_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingClubLeaderUpcomingEvents: false
      };


    case GET_CLUB_LEADER_PAST_EVENTS:
      return {
        ...state,
        loadingClubLeaderPastEvents: true
      };
    case GET_CLUB_LEADER_PAST_EVENTS_SUCCESS:
      return {
        ...state,
        clubLeaderPastEvents: action.payload,
        loadingClubLeaderPastEvents: false
      };

    case GET_CLUB_LEADER_PAST_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingClubLeaderPastEvents: false
      };
    case RESET_LOADING_STATE:
      return {
        ...state,
        loadingEvents: false,
        loadingPastEvents: false,
        loadingCalendlyEvents: false,
        loadingSuggestedEvents: false,
        loadingEventAttendance: false,
        loadingPossibleMatches: false,
        loadingSuggestedEventsByClubId: false,
        loadingClubLeaderUpcomingEvents: false,
        loadingClubLeaderPastEvents: false,
        loadingSuggestedEventsByUserId: false,
        loadingDraftsByUserId: false,
        loadingSuggestEvent: false,
        loadingSaveDraft: false,
      };

    default:
      return state;
  }
};

export default events;
