import { ADD_ANNOUNCMENT, ADD_ANNOUNCMENT_FAIL, ADD_ANNOUNCMENT_SUCCESS, ARCHIVE_ANNOUNCMENT, ARCHIVE_ANNOUNCMENT_FAIL, ARCHIVE_ANNOUNCMENT_SUCCESS, DELETE_ANNOUNCMENT, DELETE_ANNOUNCMENT_FAIL, DELETE_ANNOUNCMENT_SUCCESS, GET_ANNOUNCMENTS, GET_ANNOUNCMENTS_FAIL, GET_ANNOUNCMENTS_SUCCESS, UPDATE_ANNOUNCMENT, UPDATE_ANNOUNCMENT_FAIL, UPDATE_ANNOUNCMENT_SUCCESS } from "./actionTypes";

export const addAnnouncment = (announcment) => ({
    type: ADD_ANNOUNCMENT,
    payload: { announcment },
});

export const addAnnouncmentSuccess = (announcment) => ({
    type: ADD_ANNOUNCMENT_SUCCESS,
    payload: announcment,
});

export const addAnnouncmentFail = error => ({
    type: ADD_ANNOUNCMENT_FAIL,
    payload: error,
});

export const getAnnouncments = (clubId) => ({
    type: GET_ANNOUNCMENTS,
    payload: clubId
})

export const getAnnouncmentsSuccess = (announcments) => ({
    type: GET_ANNOUNCMENTS_SUCCESS,
    payload: announcments
})

export const getAnnouncmentsFail = (error) => ({
    type: GET_ANNOUNCMENTS_FAIL,
    payload: error
})

export const updateAnnouncment = (announcment) => ({
    type: UPDATE_ANNOUNCMENT,
    payload: announcment
})

export const updateAnnouncmentSuccess = () => ({
    type: UPDATE_ANNOUNCMENT_SUCCESS
})

export const updateAnnouncmentFail = (error) => ({
    type: UPDATE_ANNOUNCMENT_FAIL,
    payload: error
})

export const archiveAnnouncment = (data) => ({
    type: ARCHIVE_ANNOUNCMENT,
    payload: data
})

export const archiveAnnouncmentSuccess = () => ({
    type: ARCHIVE_ANNOUNCMENT_SUCCESS
})

export const archiveAnnouncmentFail = (error) => ({
    type: ARCHIVE_ANNOUNCMENT_FAIL,
    payload: error
})

export const deleteAnnouncment = (id) => ({
    type: DELETE_ANNOUNCMENT,
    payload: id
})

export const deleteAnnouncmentSuccess = () => ({
    type: DELETE_ANNOUNCMENT_SUCCESS
})

export const deleteAnnouncmentFail = (error) => ({
    type: DELETE_ANNOUNCMENT_FAIL,
    payload: error
})

