import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import moment from 'moment';
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import Select from "react-select";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../datatables.scss";
import {
  getAllChildrenCategories,
  getClubs,
  getDoorEntries,
  getMemberByEmail,
  getMembers,
  getNewMembers,
} from "../../store/actions";

const DoordeckIds = {
  1: "30d14d60-90e0-11ec-89ec-191041eeceb8",
  2: "7f8a6c10-d253-11ed-a6aa-b9ad18da3daf",
  3: "662f4cb0-609a-11ee-b238-0d02e451d4c8",
  4: "b64b7b90-eba7-11ee-9ee7-c54fd371014f"
};

class DoorEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      selectedCategory: null,
      selectedMulti: null,
      membersList: [],
      activeClub: null,
      showlist: false,
      showNewMembers: false,
      trialButtonText: 'Show new members',
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      clubSelected: { label: "No Club Selected", value: '' },
      clubsOptions: [],
    };

  }

  handleClubSelect = club => {
    this.setState({
      clubSelected: club,
      activeClub: club.value,
      showNewMembers: false
    })
  }

  componentDidMount() {
    const { onGetClubs, clubs, onGetNewMembers } = this.props;
    const authUser = localStorage.getItem("authUser")
    if (JSON.parse(authUser)?.clubId) {
      this.setState({
        activeClub: JSON.parse(authUser).clubId
      })
    }
    if (clubs.length === 0) {
      onGetClubs()
    } else {
      onGetNewMembers(clubs.map(item => item.id).join(','))
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { members, newMembers, clubs, onGetNewMembers, onGetDoorEntries } = this.props;
    const { activeClub } = this.state
    if (clubs !== prevProps.clubs) {
      onGetNewMembers(clubs.map(item => item.id).join(','))
      const authUser = localStorage.getItem("authUser")
      const club = this.props.clubs.find(item => item?.id === JSON.parse(authUser).clubId)
      if (club) {
        this.setState({
          clubsOptions: this.props.clubs.map(item => ({ label: item.name, value: item.id })),
          clubSelected: { label: club.name, value: club.id }
        })
      }
    }
    if (activeClub !== prevState.activeClub) {
      if (activeClub) {
        onGetDoorEntries(DoordeckIds[activeClub])
      }
    }
    if (members !== prevProps.members) {
      this.setState({
        membersList: members,
      });
    }
    if (this.state.showNewMembers !== prevState.showNewMembers) {

      if (this.state.showNewMembers) {
        this.setState({
          membersList: members.filter(item => {
            const newMemberFound = newMembers.find(item2 => item2.email === item.email)
            if (newMemberFound) {
              return item
            }
          }),
          trialButtonText: 'Show all'
        })
      } else {
        this.setState({
          membersList: members,
          trialButtonText: 'Show new members'
        })
      }
    }
    if (newMembers !== prevProps.newMembers) {
      setTimeout(() => {
        this.setState({
          showlist: true
        })
      }, 1000);
    }
  }

  dateFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>{moment
        .unix(row.timestamp)
        .tz("America/Los_Angeles")
        .format("MMMM Do YYYY HH:mm A")}</span>
    );
  };

  isNewMember = (email) => {
    const member = this.props.newMembers.find(item => item.email === email)
    if (member) {
      const isWithin15DaysOfStartDate = moment().isBetween(moment(member.subscription_date, 'YYYY-MM-DDTHH:mm:ss.000Z'), moment(member.start_date, 'YYYY-MM-DDTHH:mm:ss.000Z'))

      if (isWithin15DaysOfStartDate && member.trial === 1) {
        return <span style={{
          color: 'red'
        }}>{' (Trial)'}</span>
      } else {
        return <span style={{
          color: 'red'
        }}>{' (New)'}</span>
      }
    } else
      return null
  }



  render() {
    const { clubs, onGetDoorEntries } = this.props;
    const { membersList, activeClub } = this.state;



    const columns = [
      {
        dataField: "displayName",
        text: "Display Name",
        sort: true,
        formatter: (_, member) =>
        (
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
            <span>{member.displayName}</span>
            {this.isNewMember(member.email)}
          </div>
        )
      },
      {
        dataField: "email",
        text: "Email",
        sort: false,
      },
      {
        dataField: "message",
        text: "Message",
        sort: false,
      },
      {
        dataField: "timestamp",
        text: "Date and Time",
        sort: false,
        formatter: this.dateFormater,

      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: membersList?.length,
      custom: true,
    };

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.onGetUserByEmail(row.email)
      },
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> Door Entry | Groundfloor</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title={"Door Entry"} breadcrumbItem={"Door Entry"} />
            <p>Total entries: {membersList.length} </p>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {this.state.isMobile ? <Row>
                      <Col>
                        <Button
                          style={{ backgroundColor: this.state.showNewMembers ? "#032224" : '#F0F2F6', color: this.state.showNewMembers ? "#fff" : "#042225", marginBottom: 10 }}
                          className="font-16 btn-block btn btn-primary"
                          color={'#032224'}
                          onClick={() => this.setState({
                            showNewMembers: !this.state.showNewMembers
                          })}
                        >
                          {this.state.trialButtonText}
                        </Button>
                        <Select
                          styles={{
                          }}
                          onChange={
                            this.handleClubSelect
                          }
                          options={this.state.clubsOptions}
                          value={this.state.clubSelected}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                    </Row> : <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <Nav pills className="bg-light rounded" role="tablist">
                        <NavItem>
                        </NavItem>
                        {clubs.map((item, index) => (
                          <div key={index}>
                            <NavItem >
                              <NavLink
                                className={classnames({
                                  active: activeClub === item.id,
                                })}
                                style={{
                                  backgroundColor:
                                    activeClub === item.id
                                      ? "#032224"
                                      : "transparent",
                                }}
                                onClick={() => {
                                  this.setState({
                                    activeClub: item.id,
                                    showNewMembers: false
                                  });
                                }}
                              >
                                {item.name}
                              </NavLink>
                            </NavItem>
                          </div>
                        ))}
                      </Nav>
                      <div className="text-sm-end" >
                        <Button
                          style={{ backgroundColor: this.state.showNewMembers ? "#032224" : '#F0F2F6', color: this.state.showNewMembers ? "#fff" : "#042225", marginRight: 10 }}
                          className="font-16 btn-block btn btn-primary"
                          color={'#032224'}
                          onClick={() => this.setState({
                            showNewMembers: !this.state.showNewMembers
                          })}
                        >
                          {this.state.trialButtonText}
                        </Button>
                      </div>
                    </div>}
                    {membersList && this.state.showlist && (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={membersList}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={membersList}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>
                                  {/* <Link
                                  to="/profile"
                                  className="text-muted font-weight-bold"
                                > */}
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"email"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        rowEvents={tableRowEvents}
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                  {/* </Link> */}
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DoorEntry.propTypes = {
  members: PropTypes.array,
  allMembers: PropTypes.array,
  newMembers: PropTypes.array,
  onGetMembers: PropTypes.func,
  onGetAllChildrenCategories: PropTypes.func,
  childrenCategories: PropTypes.array,
  onGetClubs: PropTypes.func,
  onGetNewMembers: PropTypes.func,
  onGetDoorEntries: PropTypes.func,
  onGetUserByEmail: PropTypes.func,
  clubs: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const mapStateToProps = ({ members, system }) => ({
  members: members.doorEntries,
  childrenCategories: system.childrenCategories,
  allMembers: members.members,
  newMembers: members.newMembers,
  clubs: system.clubs,
});

const mapDispatchToProps = dispatch => ({
  onGetMembers: clubId => dispatch(getMembers(clubId)),
  onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),
  onGetClubs: () => dispatch(getClubs()),
  onGetUserByEmail: (email) => dispatch(getMemberByEmail(email)),
  onGetDoorEntries: (doorId) => dispatch(getDoorEntries(doorId)),
  onGetNewMembers: (clubId) => dispatch(getNewMembers(clubId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DoorEntry));
