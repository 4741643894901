import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import FileUpload from "../../components/FileUpload/FileUpload";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select"
import * as Yup from "yup";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import { addProduct, getAllChildrenCategories, getClubs, setImage } from "store/actions";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class AddProduct extends Component {
    constructor() {
        super();
        this.state = {
            selectedFiles: [],
            events: [],
            event: "",
            eventImage: null,
            price: 0,
            dropdownProductTypeOpen: false,
            clubId: null,
            club: "No Club Selected",
            clubError: false,
            typeError: false,
            clubsSelected: [],
            clubsFiltered: [],
            productTypes: [],
            availableSizes: [],
            availableSizesFiltered: [],
            availableSizesError: false,
            vendor: [],
            vendorFiltered: [],
            productType: '0',
            productLabel: 'No Type Selected'
        };
        this.handleAcceptedFiles.bind(this);
        this.toggleProductType = this.toggleProductType.bind(this);
        this.onFileUploaded = this.onFileUploaded.bind(this);
        this.handleMultiClub = this.handleMultiClub.bind(this)
        this.handleAvailableSizesSelect = this.handleAvailableSizesSelect.bind(this)
        this.handleVendorSelect = this.handleVendorSelect.bind(this)
    }

    toggleProductType() {
        this.setState(prevState => ({
            dropdownProductTypeOpen: !prevState.dropdownProductTypeOpen,
        }));
    }

    clubItemSelected(name, id) {
        this.setState({
            club: name,
            clubId: id,
            clubError: false,
        });
    }

    filterClubs = () => {
        const { clubs } = this.props
        return clubs.map(item => ({ label: item.name, value: item.id }))
    }

    onFileUploaded(file) {
        this.setState({ eventImage: file.url });
    }

    productTypeItemSelected(name, id) {
        this.setState({
            productType: id,
            productLabel: name,
            typeError: false
        });
    }

    componentDidMount() {
        const { onGetClubs, onGetAllChildrenCategories } = this.props;
        onGetClubs();
        onGetAllChildrenCategories()
        this.setState({
            clubsFiltered: [
                {
                    label: 'Clubs',
                    options: this.filterClubs()
                }
            ],
            availableSizesFiltered: [
                {
                    label: 'Available Sizes',
                    options: [
                        { label: 'S', value: 'S' },
                        { label: 'M', value: 'M' },
                        { label: 'L', value: 'L' },
                        { label: 'XL', value: 'XL' },
                        { label: 'XXL', value: 'XXL' },
                        { label: 'XXXL', value: 'XXXL' },
                    ]
                }
            ],
            vendorFiltered: [
                {
                    label: 'Vendors',
                    options: [
                        { label: 'Obet&Dels', value: 'Obet&Dels' },
                        { label: 'Coaster Cookies', value: 'Coaster Cookies' },
                    ]
                }
            ]
            ,
            productTypes: [...this.filterCategories("15"), { label: 'Wellness', value: 1000 }]
        })
    }

    filterCategories = (id) => {
        const { childrenCategories } = this.props;
        return childrenCategories
            ?.filter(item => item.system_category_id === id)
            .map(item => ({ label: item.name, value: item.id }));
    }

    componentDidUpdate(prevProps, prevState) {
        const { clubs, childrenCategories } = this.props;
        if (clubs !== prevProps.clubs) {
            this.setState({
                clubsFiltered: [
                    {
                        label: 'Clubs',
                        options: this.filterClubs()
                    }
                ],
            })
        }
        if (childrenCategories !== prevProps.childrenCategories) {
            this.setState({
                productTypes: [...this.filterCategories("15"), { label: 'Wellness', value: 1000 }]
            })
        }
    }

    handleMultiClub = clubsSelected => {
        this.setState({ clubsSelected, clubError: false })
    }

    handleAvailableSizesSelect = availableSizes => {
        this.setState({ availableSizes, availableSizesError: false })
    }
    handleVendorSelect = vendor => {
        this.setState({ vendor })
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );

        this.setState({ selectedFiles: files });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };


    render() {
        const { clubsFiltered, clubsSelected, productType, availableSizes, availableSizesFiltered, vendor, vendorFiltered } = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Add New Product | Groundfloor</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Products" breadcrumbItem="Add New" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Add New Product</CardTitle>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                title: "",
                                                description: "",
                                                price: 0,
                                                typeId: '0'
                                            }}
                                            validationSchema={Yup.object().shape({
                                                title: Yup.string().required("Please Enter Title"),
                                                price: Yup.number().min(0, 'Can not be lower than 0!').required("Please Enter Price"),
                                            })}
                                            onSubmit={values => {
                                                if (clubsSelected.length === 0) {
                                                    this.setState({
                                                        clubError: true,
                                                    });
                                                } else if (this.state.productType === '0') {
                                                    this.setState({
                                                        typeError: true
                                                    })
                                                } else if (productType === 488 && availableSizes.length === 0) {
                                                    this.setState({
                                                        availableSizesError: true
                                                    })
                                                } else {
                                                    clubsSelected.map(item => {
                                                        this.props.onAddProduct(
                                                            {
                                                                title: values.title,
                                                                description: values.description,
                                                                price: this.state.price,
                                                                clubId: parseInt(item.value),
                                                                image: this.state?.selectedFiles[0],
                                                                typeId: this.state.productType,
                                                                size: this.state.availableSizes.map(item => item.label).join(','),
                                                                vendor: vendor.length === 0 ? null : vendor.value
                                                            },
                                                            this.props.history
                                                        );
                                                    })
                                                }
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <React.Fragment>
                                                    <Form>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="name"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Title
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="title"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.title && touched.title
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="title"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="description"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Description
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="description"
                                                                    component="textarea"
                                                                    rows="6"
                                                                    type="textarea"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.description && touched.description
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="description"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>


                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="price"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Price
                                                            </Label>
                                                            <Col lg="10">
                                                                <Field
                                                                    name="price"
                                                                    value={this.state.price}
                                                                    onChange={e =>
                                                                        this.setState({ price: e.target.value })
                                                                    }
                                                                    type="number"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.price && touched.price
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="price"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="duration"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Select Club
                                                            </Label>
                                                            <Col lg="10">
                                                                <Select
                                                                    value={clubsSelected}
                                                                    isMulti={true}
                                                                    onChange={this.handleMultiClub}
                                                                    options={clubsFiltered}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                {this.state.clubError && (
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Please Select a Club
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                        <Row className="mb-4">
                                                            <Label
                                                                htmlFor="instructor"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Select Product Type
                                                            </Label>
                                                            <Col lg="10">
                                                                <Dropdown
                                                                    isOpen={this.state.dropdownProductTypeOpen}
                                                                    toggle={this.toggleProductType}
                                                                >
                                                                    <DropdownToggle caret>
                                                                        {this.state.productLabel}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        {this.state.productTypes.map(
                                                                            (item, index) => {
                                                                                return (
                                                                                    <DropdownItem
                                                                                        onClick={() =>
                                                                                            this.productTypeItemSelected(item.label, item.value)
                                                                                        }
                                                                                        key={index}
                                                                                    >
                                                                                        {item.label}
                                                                                    </DropdownItem>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </Col>
                                                            {this.state.typeError && (
                                                                <p
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    Please Select a type
                                                                </p>
                                                            )}
                                                        </Row>

                                                        {productType === 488 && <Row className="mb-4">
                                                            <Label
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Available Sizes
                                                            </Label>
                                                            <Col lg="10">
                                                                <Select
                                                                    value={availableSizes}
                                                                    isMulti={true}
                                                                    onChange={this.handleAvailableSizesSelect}
                                                                    options={availableSizesFiltered}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                {this.state.availableSizesError && (
                                                                    <p
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Please Select Available Sizes
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>}
                                                        <Row className="mb-4">
                                                            <Label
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Vendor
                                                            </Label>

                                                            <Col lg="10">
                                                                <span>This field is optional.</span>
                                                                <Select
                                                                    value={vendor}
                                                                    onChange={this.handleVendorSelect}
                                                                    options={vendorFiltered}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <FormGroup className="mb-4" row>
                                                            <Label className="col-form-label col-lg-2">
                                                                Attach Image
                                                            </Label>
                                                            <Col lg="10">
                                                                <FileUpload
                                                                    maxFiles={productType === 488 ? 4 : 1}
                                                                    onFileUploaded={this.onFileUploaded}
                                                                ></FileUpload>


                                                            </Col>
                                                        </FormGroup>

                                                        <Row className="justify-content-end">
                                                            <Col lg="10">
                                                                <Button type="submit" color="primary">
                                                                    Add Product
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </React.Fragment>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

AddProduct.propTypes = {
    products: PropTypes.array,
    onAddProduct: PropTypes.func,
    history: PropTypes.object,
    onGetClubs: PropTypes.func,
    clubs: PropTypes.array,
    onSetImage: PropTypes.func,
    childrenCategories: PropTypes.array,
    onGetAllChildrenCategories: PropTypes.func,

};

const mapStateToProps = ({ products, members, system }) => ({
    products: products.products,
    clubs: system.clubs,
    childrenCategories: system.childrenCategories,

});

const mapDispatchToProps = dispatch => ({
    onGetClubs: () => dispatch(getClubs()),
    onAddProduct: (product, history) => dispatch(addProduct(product, history)),
    onSetImage: (image) => dispatch(setImage(image)),
    onGetAllChildrenCategories: () => dispatch(getAllChildrenCategories()),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddProduct));
